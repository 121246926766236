import { ImagesGrid, SectionTab } from 'polotno/side-panel';
import React, { useState } from 'react';

import { DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { StoreType } from 'polotno/model/store';
import { overrideTheme } from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-theme.utils';

export function ImageThemeSelectTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="이미지 설정" {...props}>
      <PictureOutlined
        style={{
          fontSize: 20,
        }}
      />
    </SectionTab>
  );
}
ImageThemeSelectTab.displayName = '';

export interface ImageThemeSelectPanelProps {
  store: StoreType;
  onSelectAssetButtonClick?: () => void;
  imageUrls?: string[];
  onAssetDeleted?: (updatedUrls: string[]) => void;
}

export function ImageThemeSelectPanel(props: ImageThemeSelectPanelProps) {
  const {
    onAssetDeleted,
    store,
    onSelectAssetButtonClick,
    imageUrls = [],
  } = props;

  const [images, setImages] = useState<{ url: string }[]>(
    imageUrls.map((url) => ({
      url,
    })),
  );

  const deleteImage = (image: { url: string }) => {
    const updatedImages = images.filter(({ url }) => {
      return url !== image.url;
    });
    setImages(updatedImages);
    if (onAssetDeleted) {
      onAssetDeleted(updatedImages.map(({ url }) => url));
    }
  };

  const handleImageSelect = async (image: { url: string }) => {
    const { url } = image;
    store.selectElements([]);
    const json: any = store.toJSON();
    const newJson = overrideTheme(json, {
      randomImageUrl: url,
    });
    store.loadJSON(newJson, true);
  };

  const renderDeleteAction = (image: { url: string }) => {
    return (
      <div>
        <Button
          icon={<DeleteOutlined />}
          onClick={async (e) => {
            e.stopPropagation();
            deleteImage(image);
          }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginBottom: '16px',
        }}
      >
        <Button onClick={onSelectAssetButtonClick}>이미지 선택</Button>
      </div>

      <div>
        <ImagesGrid
          crossOrigin="anonymous"
          images={images}
          onSelect={handleImageSelect}
          isLoading={false}
          getPreview={(image) => image.url}
          getCredit={renderDeleteAction}
        />
      </div>
    </div>
  );
}
