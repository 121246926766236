export enum MemberGroup {
  OMA = 'OMA',
  OV = 'OV',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
}

export enum MemberTranslations {
  OV = 'OV',
  OMA = 'OMA',
  ADMIN = '관리자',
  PARTNER = '병원 사용자',
}

export const MEMBER_GROUP_OPTIONS = Object.values(MemberGroup).map((value) => ({
  label: MemberTranslations[value],
  value,
}));

export const MEMBER_ACCOUNT_TYPE_OPTIONS = [
  {
    label: 'ROOT',
    value: true,
  },
  {
    label: '일반',
    value: false,
  },
];

export const MEMBER_BOOSTREE_ACCOUNT_TYPE_OPTIONS = [
  {
    label: '부스트리',
    value: true,
  },
  {
    label: '외부',
    value: false,
  },
];
