import { css } from 'styled-components';
import colorSet from './colors';

const agGridCustom = css`
  div.full-grid div div.ag-root-wrapper-body {
    height: 100%;
  }
  .ag-not-hover {
    .ag-row-hover::before {
      background-color: inherit !important;
    }
  }
  .ag-theme-alpine.ag-focus-not-hover {
    --ag-row-hover-color: #fff !important;
    .ag-row {
      cursor: auto !important;
    }
  }
  .ag-cell-wrapper {
    width: 100%;
  }
  .ag-body-viewport.ag-layout-normal {
    overflow-y: scroll;
  }
  .ag-theme-alpine {
    .row-disabled {
      cursor: not-allowed !important;
    }
    /* Theme 색상 */

    --ag-header-background-color: ${colorSet.gray12};
    --ag-selected-row-background-color: ${colorSet.primary10};
    --ag-row-hover-color: ${colorSet.primary10};
    --ag-cell-horizontal-padding: 16px;

    /* Root 테이블 테두리 */
    .ag-root-wrapper {
      border: none;
    }

    /* Row 스타일 */
    .ag-row {
      border-bottom: 1px solid ${colorSet.gray10} !important;
      cursor: pointer;
    }
    .ag-row-odd,
    .ag-row-even {
      background: ${colorSet.gray13};
    }

    /* Cell 스타일 */
    .ag-cell-focus {
      border: 1px solid transparent !important;
    }

    /* Header 스타일 */
    .ag-header {
      border-bottom: 1px solid ${colorSet.gray10} !important;
    }
    .ag-header-cell {
      cursor: auto;
    }

    /* Checkbox */
    --ag-icon-size: 24px;
    --ag-checkbox-border-radius: 6px;
    .ag-checkbox-cell {
      --ag-border-color: ${colorSet.gray9};
      border: none !important;
    }

    .ag-checkbox-input-wrapper {
      border: 1px solid ${colorSet.gray9};
      overflow: hidden;
      cursor: pointer !important;

      .ag-input-field-input {
        cursor: pointer;
      }

      --ag-input-focus-box-shadow: none;
      &:not(.ag-checked)::after {
        content: '';
      }
      &.ag-checked {
        position: relative;
        background: ${colorSet.primary3};
      }
      &.ag-checked::after {
        content: '';
        position: absolute;
        width: 70%;
        height: 70%;
        background: url('/assets/icons/icon-checked.svg') no-repeat center
          center / contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ag-checkbox-cell.row-disabled {
      --ag-border-color: ${colorSet.gray9};
      border: none !important;

      .ag-checkbox-input {
        pointer-events: none !important;
      }
      .ag-input-wrapper {
        &::after {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          background: url('/assets/icons/icon-check-disabled.svg') no-repeat
            center center / contain;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .ag-checkbox-input-wrapper {
        cursor: not-allowed !important;
        background: ${colorSet.gray10} !important;
        border: 1px solid ${colorSet.gray9} !important;
      }
    }

    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
    .ag-right-aligned-cell {
      justify-content: right;
    }
    .ag-pinned-left-header {
      border: none;
    }

    .ag-header-icon.ag-header-cell-menu-button {
      display: none;
    }
  }

  /* rowClassRules row disabled */
  .row-disabled {
    background-color: ${colorSet.gray12} !important;
    cursor: not-allowed !important;

    --ag-row-hover-color: ${colorSet.gray12} !important;
    > .ag-cell {
      pointer-events: none !important;
    }
  }

  /* rowClassRules row disabled without background effect */
  .row-disabled-without-background {
    cursor: not-allowed !important;
    --ag-row-hover-color: ${colorSet.gray13} !important;
    > .ag-cell {
      pointer-events: none !important;
    }
  }

  .row-not-hover {
    cursor: auto !important;
    --ag-row-hover-color: ${colorSet.gray13} !important;

    .cell-cursor {
      svg {
        cursor: pointer !important;
      }
    }
  }

  /* Sorting */
  .ag-sort-indicator-container {
    cursor: pointer;
    margin-left: auto;
  }
  .ag-icon-asc {
    &::before {
      content: url('/assets/icons/icon-sort_asc.svg');
    }
  }
  .ag-icon-desc {
    &::before {
      content: url('/assets/icons/icon-sort_desc.svg');
    }
  }
  .ag-icon-none {
    &::before {
      content: url('/assets/icons/icon-sort_none.svg');
    }
  }

  /* DnD */
  .ag-drag-handle {
    cursor: move;
    margin-right: 4px !important;

    .ag-icon-grip::before {
      content: '';
      display: flex;
      width: 24px;
      height: 24px;
      background: url('/assets/icons/icon_drag-handler.svg') no-repeat center
        center / contain;
    }
  }
  .ag-drag-header-cell {
    padding-left: 32px;
  }
  .ag-drag-cell {
    padding: 4px !important;
  }

  /* .ag-row:last-child {
    border-bottom: 0px !important;
  } */
`;

export default agGridCustom;
