import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Table, TableProps } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  requestDeleteVirtualCall,
  requestVirtualCalls,
} from '@apis/virtual-calls';
import { getFormatTelNumber } from 'utils/format';
import { Button } from '@components/atoms';
import { virtualCallsQueryKeys } from '@apis/virtual-calls/queryKeys';
import { MARKETING_CHANNEL_TO_KOREAN } from 'constant';
import { MarketingChannel } from '@types';
import { useAlertStore } from '@store/useAlertStore';
import EditVirtualCallModal from './EditVirtualCallModal';

type VirtualCallsTableProps = {
  partnerId: number;
};
function VirtualCallsTable({ partnerId }: VirtualCallsTableProps) {
  const { show: showAlert } = useAlertStore((state) => state);
  const queryClient = useQueryClient();

  const { data: virtualCalls = [], isFetching } = useQuery({
    queryKey: virtualCallsQueryKeys.partner(partnerId),
    queryFn: () => requestVirtualCalls({ partnerId }),
    select: (d) => d.rows,
    refetchOnWindowFocus: false,
    enabled: !!partnerId,
  });

  const { mutate } = useMutation({
    mutationFn: requestDeleteVirtualCall,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        virtualCallsQueryKeys.partner(partnerId),
      );
    },
  });

  const [callIdForEdit, setCallIdForEdit] = useState<string | null>(null);
  const editCall = virtualCalls.find(
    (call) => call.id === Number(callIdForEdit),
  );

  const showDeleteAlert = (id: number) => {
    showAlert({
      size: 360,
      title: '가상번호 삭제',
      message: '정말 가상번호를 삭제하시겠습니까?',
      actions: [
        { label: '취소' },
        {
          label: '삭제',
          onClick: () => mutate({ id }),
        },
      ],
    });
  };

  const cols: TableProps['columns'] = [
    {
      width: 120,
      dataIndex: 'marketingChannel',
      title: '마케팅 채널',
      key: 'marketingChannel',
      render: (_, record) => {
        const { marketingChannel } = record;
        return MARKETING_CHANNEL_TO_KOREAN[
          marketingChannel as MarketingChannel
        ];
      },
    },
    {
      width: 120,
      dataIndex: 'virtualCallName',
      title: '가상번호명',
      key: 'virtualCallName',
    },
    {
      width: 120,
      dataIndex: 'virtualCallNumber',
      title: '가상번호',
      key: 'virtualCallNumber',
      render: (_, record) => {
        const { virtualCallNumber } = record;
        return getFormatTelNumber(virtualCallNumber);
      },
    },
    {
      width: 120,
      dataIndex: 'receivingNumber',
      title: '착신번호',
      key: 'receivingNumber',
      render: (_, record) => {
        const { receivingNumber } = record;
        return receivingNumber ? getFormatTelNumber(receivingNumber) : '-';
      },
    },
    {
      width: 60,
      dataIndex: 'actions',
      title: '수정/삭제',
      key: 'actions',
      render: (_, record) => {
        const { id } = record;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <Button
              buttonSize={28}
              buttonColor="gray"
              onClick={() => setCallIdForEdit(id)}
            >
              수정
            </Button>
            <Button
              buttonSize={28}
              buttonColor="lightRed"
              onClick={() => showDeleteAlert(id)}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Table
        style={{
          width: '100%',
        }}
        size="middle"
        pagination={false}
        columns={cols}
        loading={isFetching}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 20,
              }}
            >
              등록된 가상번호 정보가 없습니다
            </div>
          ),
        }}
        dataSource={virtualCalls.map((call) => {
          return {
            ...call,
            key: call.id,
          };
        })}
      />
      {editCall &&
        createPortal(
          <EditVirtualCallModal
            partnerId={partnerId}
            initialValues={editCall}
            onClose={() => setCallIdForEdit(null)}
          />,
          document.body,
        )}
    </>
  );
}

export default VirtualCallsTable;
