import colorSet, { ColorType } from '@styles/colors';
import styled from 'styled-components';

export const BulletContainer = styled.p<{ color: ColorType }>`
  display: flex;
  align-items: center;
  gap: 12px;

  > .bullet {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ color }) => colorSet[color]};
  }
`;
