import { css } from 'styled-components';

const BOLD = 700;
const MEDIUM = 500;
const REGULAR = 400;

type FontType =
  | 'display'
  | 'head'
  | 'body_medium'
  | 'body_regular'
  | 'buttons'
  | 'label';

const getFontWeight = (fontType: FontType) => {
  switch (fontType) {
    case 'display':
    case 'head':
      return css`
        font-weight: ${BOLD};
      `;

    case 'label':
    case 'buttons':
    case 'body_medium':
      return css`
        font-weight: ${MEDIUM};
      `;

    case 'body_regular':
      return css`
        font-weight: ${REGULAR};
      `;
    default:
      return css`
        font-weight: ${REGULAR};
      `;
  }
};

const typo = {
  // Display
  d1: css`
    ${getFontWeight('display')}
    font-size: 60px;
    line-height: 84px;
  `,
  d2: css`
    ${getFontWeight('display')}
    font-size: 48px;
    line-height: 68px;
  `,
  d3: css`
    ${getFontWeight('display')}
    font-size: 40px;
    line-height: 56px;
  `,
  d4: css`
    ${getFontWeight('display')}
    font-size: 32px;
    line-height: 44px;
  `,

  // heading
  h1: css`
    ${getFontWeight('head')}
    font-size: 28px;
    line-height: 42px;
  `,
  h2: css`
    ${getFontWeight('head')}
    font-size: 24px;
    line-height: 36px;
  `,
  h3: css`
    ${getFontWeight('head')}
    font-size: 22px;
    line-height: 34px;
  `,
  h4: css`
    ${getFontWeight('head')}
    font-size: 20px;
    line-height: 30px;
  `,
  h5: css`
    ${getFontWeight('head')}
    font-size: 19px;
    line-height: 28px;
  `,
  h6: css`
    ${getFontWeight('head')}
    font-size: 18px;
    line-height: 26px;
  `,
  h7: css`
    ${getFontWeight('head')}
    font-size: 17px;
    line-height: 26px;
  `,
  h8: css`
    ${getFontWeight('head')}
    font-size: 16px;
    line-height: 24px;
  `,
  h9: css`
    ${getFontWeight('head')}
    font-size: 15px;
    line-height: 22px;
  `,
  h10: css`
    ${getFontWeight('head')}
    font-size: 14px;
    line-height: 20px;
  `,
  h11: css`
    ${getFontWeight('head')}
    font-size: 13px;
    line-height: 20px;
  `,
  h12: css`
    ${getFontWeight('head')}
    font-size: 12px;
    line-height: 18px;
  `,
  h13: css`
    ${getFontWeight('head')}
    font-size: 11px;
    line-height: 18px;
  `,

  // Body medium
  b1m: css`
    ${getFontWeight('body_medium')}
    font-size: 28px;
    line-height: 42px;
  `,
  b2m: css`
    ${getFontWeight('body_medium')}
    font-size: 24px;
    line-height: 36px;
  `,
  b3m: css`
    ${getFontWeight('body_medium')}
    font-size: 22px;
    line-height: 34px;
  `,
  b4m: css`
    ${getFontWeight('body_medium')}
    font-size: 20px;
    line-height: 30px;
  `,
  b5m: css`
    ${getFontWeight('body_medium')}
    font-size: 19px;
    line-height: 28px;
  `,
  b6m: css`
    ${getFontWeight('body_medium')}
    font-size: 18px;
    line-height: 26px;
  `,
  b7m: css`
    ${getFontWeight('body_medium')}
    font-size: 17px;
    line-height: 26px;
  `,
  b8m: css`
    ${getFontWeight('body_medium')}
    font-size: 16px;
    line-height: 24px;
  `,
  b9m: css`
    ${getFontWeight('body_medium')}
    font-size: 15px;
    line-height: 22px;
  `,
  b10m: css`
    ${getFontWeight('body_medium')}
    font-size: 14px;
    line-height: 20px;
  `,
  b11m: css`
    ${getFontWeight('body_medium')}
    font-size: 13px;
    line-height: 20px;
  `,
  b12m: css`
    ${getFontWeight('body_medium')}
    font-size: 12px;
    line-height: 18px;
  `,
  b13m: css`
    ${getFontWeight('body_medium')}
    font-size: 11px;
    line-height: 16px;
  `,
  b14m: css`
    ${getFontWeight('body_medium')}
    font-size: 10px;
    line-height: 14px;
  `,

  // Body regular
  b1: css`
    ${getFontWeight('body_regular')}
    font-size: 28px;
    line-height: 42px;
  `,
  b2: css`
    ${getFontWeight('body_regular')}
    font-size: 24px;
    line-height: 36px;
  `,
  b3: css`
    ${getFontWeight('body_regular')}
    font-size: 22px;
    line-height: 34px;
  `,
  b4: css`
    ${getFontWeight('body_regular')}
    font-size: 20px;
    line-height: 30px;
  `,
  b5: css`
    ${getFontWeight('body_regular')}
    font-size: 19px;
    line-height: 28px;
  `,
  b6: css`
    ${getFontWeight('body_regular')}
    font-size: 18px;
    line-height: 26px;
  `,
  b7: css`
    ${getFontWeight('body_regular')}
    font-size: 17px;
    line-height: 26px;
  `,
  b8: css`
    ${getFontWeight('body_regular')}
    font-size: 16px;
    line-height: 24px;
  `,
  b9: css`
    ${getFontWeight('body_regular')}
    font-size: 15px;
    line-height: 22px;
  `,
  b10: css`
    ${getFontWeight('body_regular')}
    font-size: 14px;
    line-height: 20px;
  `,
  b11: css`
    ${getFontWeight('body_regular')}
    font-size: 13px;
    line-height: 20px;
  `,
  b12: css`
    ${getFontWeight('body_regular')}
    font-size: 12px;
    line-height: 18px;
  `,
  b13: css`
    ${getFontWeight('body_regular')}
    font-size: 11px;
    line-height: 16px;
  `,
  b14: css`
    ${getFontWeight('body_regular')}
    font-size: 10px;
    line-height: 14px;
  `,

  // buttons
  btn1: css`
    ${getFontWeight('buttons')};
    font-size: 17px;
    line-height: 24px;
  `,
  btn2: css`
    ${getFontWeight('buttons')};
    font-size: 15px;
    line-height: 20px;
  `,
  btn3: css`
    ${getFontWeight('buttons')};
    font-size: 13px;
    line-height: 18px;
  `,
  btn4: css`
    ${getFontWeight('buttons')};
    font-size: 12px;
    line-height: 16px;
  `,

  // label
  label1: css`
    ${getFontWeight('label')}
    font-size: 15px;
    line-height: 16px;
  `,
  label2: css`
    ${getFontWeight('label')}
    font-size: 13px;
    line-height: 14px;
  `,
  label3: css`
    ${getFontWeight('label')}
    font-size: 11px;
    line-height: 12px;
  `,
};

export default typo;

type FontSizeType = keyof typeof typo;
export { type FontSizeType };
