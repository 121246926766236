/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Input from '@components/v2/antd/data-entry/Input';
import Select from '@components/v2/antd/data-entry/Select';
import { BlogPartnerInfoForm } from '@pages/private/blog/blog-subscriptions/types/BlogPartnerInfoForm';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import { AppError } from '@networks/index';
import BlogSubscriptionTable from '@pages/private/blog/blog-subscriptions/components/BlogSubscriptionTable';
import AddBlogSubscriptionModal from '@pages/private/blog/blog-subscriptions/components/AddBlogSubscriptionModal';
import { Switch } from 'antd';
import {
  BLOG_CONSULTATION_CHANNEL_OPTIONS,
  BLOG_PERSONA_TYPE_OPTIONS,
  BlogPersonaType,
} from '../../../../@types/blog-partner-info/constants/blog-subscription-info.constants';
import {
  createBlogPartnerInfo,
  fetchBlogPartnerInfoDetail,
  updateBlogPartnerInfo,
} from '../../../../@apis/blog-partner-infos';
import { BlogPartnerInfo } from '../../../../@types/blog-partner-info/models/BlogPartnerInfo';
import { CreateBlogPartnerInfoBody } from '../../../../@types/blog-partner-info/params/CreateBlogPartnerInfoBody';
import { UpdateBlogPartnerInfoBody } from '../../../../@types/blog-partner-info/params/UpdateBlogPartnerInfoBody';
import { Partner } from '../../../../@types/partner/models/Partner';
import { fetchPartnerDetail } from '../../../../@apis/partners';
import { fetchBlogSubscriptions } from '../../../../@apis/blog-subscriptions';
import { BlogSubscription } from '../../../../@types/blog-subscription/models/BlogSubscription';
import { PHONE_NUM_REGEX } from '../../../../utils/format';

const INITIAL_VALUES: BlogPartnerInfoForm = {
  atmosphere: '',
  brandedBlogAccount: '',
  brandedBlogDisplayName: '',
  brandedBlogUrl: '',
  consultationChannelArray: [],
  extraSurveyText: '',
  locationSeoKeywords: [],
  mainAdvantage: '',
  personaType: BlogPersonaType.Kind,
  brandedPersonaType: BlogPersonaType.Kind,
  philosophy: '',
  seoBlogAccount: '',
  seoBlogDisplayName: '',
  seoBlogUrl: '',
  receptionTel: '',
  alimTalkReceivable: false,
};

function BlogSubscriptionDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [blogPartnerInfo, setBlogPartnerInfo] = useState<BlogPartnerInfo>();
  const [partnerInfo, setPartnerInfo] = useState<Partner>();
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState<BlogSubscription[]>([]);
  const [addSubscriptionModalVisible, setAddSubscriptionModalVisible] =
    useState(false);

  const formValidationSchema = yup.lazy((value) => {
    const shape: Record<string, any> = {
      atmosphere: yup.string().required(),
      consultationChannelArray: yup.array().min(1),
      locationSeoKeywords: yup.array().min(1),
      mainAdvantage: yup.string().required(),
      personaType: yup.string().required(),
      brandedPersonaType: yup.string().required(),
      philosophy: yup.string().required(),
      seoBlogAccount: yup.string().required(),
      seoBlogDisplayName: yup.string().required(),
      seoBlogUrl: yup.string().required(),
    };

    if (value.alimTalkReceivable) {
      shape.receptionTel = yup.string().matches(PHONE_NUM_REGEX).required();
    }

    return yup.object(shape);
  });

  const formik = useFormik<BlogPartnerInfoForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      const { extraSurveyText, ...rest } = values;
      if (id) {
        if (blogPartnerInfo) {
          await updateData(id, {
            ...rest,
            extraSurveyText: extraSurveyText || null,
          });
        } else {
          await addData(id, {
            ...rest,
            extraSurveyText: extraSurveyText || null,
          });
        }
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { rows } = await fetchBlogSubscriptions(id);
      setSubscriptions(rows);
    } catch (e) {
      const error = e as AppError;
      if (error.statusCode === 404) {
        setSubscriptions([]);
      }
    }

    try {
      const { row: partnerInfo } = await fetchPartnerDetail(id);
      setPartnerInfo(partnerInfo);
      const { row } = await fetchBlogPartnerInfoDetail(id);
      setBlogPartnerInfo(row);
      const {
        atmosphere,
        brandedBlogAccount,
        brandedBlogDisplayName,
        brandedBlogUrl,
        consultationChannelArray,
        extraSurveyText,
        locationSeoKeywords,
        mainAdvantage,
        personaType,
        philosophy,
        seoBlogAccount,
        seoBlogDisplayName,
        seoBlogUrl,
        brandedPersonaType,
        alimTalkReceivable,
        receptionTel,
      } = row;

      setFormInitialValues({
        atmosphere,
        brandedBlogAccount,
        brandedBlogDisplayName,
        brandedBlogUrl,
        consultationChannelArray,
        extraSurveyText,
        locationSeoKeywords,
        mainAdvantage,
        personaType,
        philosophy,
        seoBlogAccount,
        seoBlogDisplayName,
        seoBlogUrl,
        brandedPersonaType,
        alimTalkReceivable,
        receptionTel,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (
    partnerId: string | number,
    body: CreateBlogPartnerInfoBody,
  ) => {
    try {
      await createBlogPartnerInfo(partnerId, body);
      showToast({
        description: '블로그 정보가 업데이트 되었어요.',
        status: 'Primary',
      });
      setFormInitialValues(body);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    partnerId: number | string,
    body: UpdateBlogPartnerInfoBody,
  ) => {
    try {
      await updateBlogPartnerInfo(partnerId, body);
      showToast({
        description: '블로그 정보가 업데이트 되었어요.',
        status: 'Primary',
      });
      setFormInitialValues({
        ...formik.values,
        ...body,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const requestFetchSubscriptions = async (partnerId: number | string) => {
    try {
      const { rows } = await fetchBlogSubscriptions(partnerId);
      setSubscriptions(rows);
    } catch (e) {
      const error = e as AppError;
      if (error.statusCode === 404) {
        setSubscriptions([]);
      }
    }
  };

  const handleSaveClick = () => {
    const alertTitle = '구독정보 수정';
    const alertMessage = '작성하신 내용대로 정보를 수정하시겠어요?';
    const actionLabel = '수정';

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogPartnerInfoForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
  }) => {
    const {
      mode,
      labelName,
      required = false,
      key,
      placeholder,
      options,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          mode={mode}
          showSearch
          allowClear
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderSwitchForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogPartnerInfoForm;
  }) => {
    const { labelName, required = false, key } = info;

    return (
      <FormItem label={labelName} optional={!required}>
        <Switch
          checked={formik.values[key] as boolean}
          onChange={(checked) => {
            updateForm(key, checked);
          }}
        />
      </FormItem>
    );
  };

  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogPartnerInfoForm;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogPartnerInfoForm;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      labelName,
      required = false,
      disabled,
      key,
      placeholder,
      infoLabel,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
            disabled={disabled}
          />
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout
      headerRight={renderFormActions()}
      title={partnerInfo?.displayName || partnerInfo?.partnerName}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card
            title={'구독정보'}
            right={
              <Button
                onClick={() => {
                  setAddSubscriptionModalVisible(true);
                }}
              >
                구독등록
              </Button>
            }
          >
            <FormLayout>
              <BlogSubscriptionTable subscriptions={subscriptions} />
            </FormLayout>
          </Card>
          <Card title={'블로그 기본정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '블로그 계정명',
                key: 'seoBlogAccount',
                required: true,
              })}
              {renderInputForm({
                labelName: '블로그명',
                key: 'seoBlogDisplayName',
                required: true,
              })}
              {renderInputForm({
                labelName: '블로그 URL',
                key: 'seoBlogUrl',
                required: true,
              })}
              {renderTextArea({
                labelName: '병원 철학',
                key: 'philosophy',
                required: true,
              })}
              {renderTextArea({
                labelName: '병원 특장점',
                key: 'mainAdvantage',
                required: true,
              })}
              {renderTextArea({
                labelName: '병원 분위기',
                key: 'atmosphere',
                required: true,
              })}
              {renderTextArea({
                labelName: '병원 추가정보',
                key: 'extraSurveyText',
                required: false,
              })}
              {renderSelectForm({
                labelName: '검색최적화 페르소나 유형',
                key: 'personaType',
                options: BLOG_PERSONA_TYPE_OPTIONS,
                required: true,
              })}
              {renderSelectForm({
                labelName: '브랜디드 페르소나 유형',
                key: 'brandedPersonaType',
                options: BLOG_PERSONA_TYPE_OPTIONS,
                required: true,
              })}
              {renderSelectForm({
                labelName: '지역검색 키워드',
                key: 'locationSeoKeywords',
                options: [],
                required: true,
                mode: 'tags',
              })}
              {renderSelectForm({
                labelName: '상담채널',
                key: 'consultationChannelArray',
                options: BLOG_CONSULTATION_CHANNEL_OPTIONS,
                required: true,
                mode: 'multiple',
              })}

              {renderInputForm({
                labelName: '추가 블로그 계정명',
                placeholder:
                  '추가로 운영중인 블로그 계정명을 입력해주세요 (브랜드형)',
                key: 'brandedBlogAccount',
                required: false,
              })}
              {renderInputForm({
                labelName: '추가 블로그명',
                placeholder:
                  '추가로 운영중인 블로그명을 입력해주세요 (브랜드형)',
                key: 'brandedBlogDisplayName',
                required: false,
              })}
              {renderInputForm({
                labelName: '추가 블로그 URL',
                placeholder:
                  '추가로 운영중인 블로그URL을 입력해주세요 (브랜드형)',
                key: 'brandedBlogUrl',
                required: false,
              })}
              {renderSwitchForm({
                labelName: '알림톡 수신 유무',
                key: 'alimTalkReceivable',
                required: true,
              })}
              {formik.values.alimTalkReceivable &&
                renderInputForm({
                  labelName: '알림톡 수신 번호',
                  key: 'receptionTel',
                  required: true,
                })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
      {addSubscriptionModalVisible && (
        <AddBlogSubscriptionModal
          partnerId={partnerInfo?.id}
          onClose={() => {
            setAddSubscriptionModalVisible(false);
          }}
          onDataAdd={async () => {
            if (partnerInfo) {
              await requestFetchSubscriptions(partnerInfo.id);
            }
          }}
        />
      )}
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default BlogSubscriptionDetailPage;
