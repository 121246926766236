export enum ManufactureCountries {
  DOMESTIC = '국산',
  INCOME = '수입',
  ETC = '기타',
}

export const MANUFACTURE_COUNTRY_OPTIONS = [
  {
    label: ManufactureCountries.DOMESTIC,
    value: ManufactureCountries.DOMESTIC,
  },
  {
    label: ManufactureCountries.INCOME,
    value: ManufactureCountries.INCOME,
  },
  {
    label: ManufactureCountries.ETC,
    value: ManufactureCountries.ETC,
  },
];

export const GLOBAL_THROUGHPUT_ITEM_TRANSLATIONS: Record<string, string> = {};
