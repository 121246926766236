import { Select, Typo } from '@components/atoms';
import { FontSizeType } from '@components/atoms/Typo/types';
import { ColorType } from '@styles/colors';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

interface SelectLabelProps {
  gap?: number;
  labelColor?: ColorType;
  typoType?: FontSizeType;
  labelAutoWidth?: boolean;
  label: string | ReactNode;
  placeholder?: string;
  options?: { label: string; value: any }[];
  onSelected?: (val: any) => void;
  value?: any;
}

function SelectLabel({
  gap = 8,
  labelAutoWidth = false,
  label,
  labelColor = 'gray6',
  typoType = 'b11',
  placeholder,
  options,
  onSelected,
  value = '',
}: SelectLabelProps) {
  return (
    <SelectLabelWrapper gap={gap}>
      <TypoBox labelAutoWidth={labelAutoWidth}>
        <Typo typoType={typoType} color={labelColor}>
          {label}
        </Typo>
      </TypoBox>
      <SelectBox>
        <Select
          mode="tags"
          value={value[0] === '' ? undefined : value}
          options={options}
          placeholder={
            <Typo typoType="b9" color="gray7">
              {placeholder}
            </Typo>
          }
          onDeselect={onSelected}
          onChange={onSelected}
        />
      </SelectBox>
    </SelectLabelWrapper>
  );
}

export default SelectLabel;

const SelectLabelWrapper = styled.div<{ gap: number }>`
  display: flex;
  align-items: flex-start;
  gap: ${({ gap }) => `${gap}`}px;
  width: 100%;
`;

const TypoBox = styled.div<{ labelAutoWidth: boolean }>`
  display: flex;
  width: 100%;
  max-width: 120px;
  align-items: center;

  ${({ labelAutoWidth }) =>
    labelAutoWidth && `max-width: 100%; white-space: nowrap; width: auto;`}
  height: 40px;
`;

const SelectBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 520px;
  min-height: 40px;
  & > div {
    flex-grow: 1;
    display: flex;
    cursor: pointer;
  }

  & > * > .ant-select-selector {
    flex-grow: 1;
    cursor: auto !important;
  }

  & > * > .ant-select-selector > div.ant-select-selection-overflow {
    & > div.ant-select-selection-overflow-item-suffix {
      display: none;
    }
    & > div.ant-select-selection-overflow-item > span {
      background-color: #fff;
      & > span.ant-select-selection-item-remove {
        display: none;
      }
    }
  }
`;

// const DrowdonwCustomWrapper = styled.div`
//   &
//     div
//     > div
//     > div
//     > div
//     > div.ant-select-item.ant-select-item-option.ant-select-item-option-selected
//     > div {
//     color: ${colorSet.primary3};
//   }
// `;
