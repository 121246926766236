import { AppError, request, Row, Rows } from '@networks/index';
import { AuthorityGroup } from '../@types/authority-group/models/AuthorityGroup';
import { FetchAuthorityGroupListQuery } from '../@types/authority-group/params/FetchAuthorityGroupListQuery';
import { CreateAuthorityGroupBody } from '../@types/authority-group/params/CreateAuthorityGroupBody';
import { UpdateAuthorityGroupBody } from '../@types/authority-group/params/UpdateAuthorityGroupBody';

const API_ROOT = 'admin/v2/member-authorities';

export const createAuthorityGroup = async (body: CreateAuthorityGroupBody) => {
  try {
    const { data } = await request<Row<AuthorityGroup>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAuthorityGroups = async (
  query: FetchAuthorityGroupListQuery,
) => {
  try {
    const { data } = await request<Rows<AuthorityGroup>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
export const fetchAuthorityGroupDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AuthorityGroup>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateAuthorityGroup = async (
  id: number | string,
  body: UpdateAuthorityGroupBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AuthorityGroup>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
