/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import { PartnerGroupForm } from '@pages/private/customer/partner-groups/types/PartnerGroupForm';
import Input from '@components/v2/antd/data-entry/Input';
import {
  createPartnerGroup,
  fetchPartnerGroupDetail,
  updatePartnerGroup,
} from '../../../../@apis/partner-groups';
import { UpdatePartnerGroupBody } from '../../../../@types/partner-group/params/UpdatePartnerGroupBody';
import { CreatePartnerGroupBody } from '../../../../@types/partner-group/params/CreatePartnerGroupBody';

const INITIAL_VALUES: PartnerGroupForm = {
  partnerGroupName: '',
  partnerGroupCode: '',
};

function PartnerGroupDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    partnerGroupName: yup.string().required(),
    partnerGroupCode: yup.string().required(),
  });

  const formik = useFormik<PartnerGroupForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchPartnerGroupDetail(id);
      const {
        partnerGroupCode,
        partnerGroupName,
        // 상세 정보
      } = row;

      setFormInitialValues({
        partnerGroupCode,
        partnerGroupName,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreatePartnerGroupBody) => {
    try {
      await createPartnerGroup(body);
      showToast({
        description: '파트너 그룹 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdatePartnerGroupBody,
  ) => {
    try {
      await updatePartnerGroup(id, body);
      showToast({
        description: '파트너 그룹 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '파트너 그룹 등록';
    let alertMessage = '작성하신 내용대로 파트너 그룹 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '파트너 그룹 수정';
      alertMessage = '작성하신 내용대로 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerGroupForm;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
  }) => {
    const { labelName, required = false, disabled, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Input
          id={key}
          name={key}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          className="input-container"
          placeholder={placeholderText}
          disabled={disabled}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '그룹명',
                required: true,
                key: 'partnerGroupName',
              })}
              {renderInputForm({
                labelName: '그룹코드',
                required: true,
                key: 'partnerGroupCode',
                disabled: !!id,
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default PartnerGroupDetailPage;
