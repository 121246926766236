import { create } from 'zustand';
import { AlertProps, AlertStore } from './types';

const initialData: AlertProps = {
  size: 320,
  visible: false,
  title: undefined,
  message: undefined,
  content: undefined,
  messageContent: undefined,
  actions: [{ label: 'button' }],
  dismissable: false,
};
const useAlertStore = create<AlertStore>()((set) => ({
  ...initialData,
  show: (showParam) =>
    set({
      visible: true,
      messageContent: showParam.messageContent || null,
      message: showParam.message || null,
      ...showParam,
    }),
  onDismiss: () => set({ visible: false }),
}));

export default useAlertStore;
