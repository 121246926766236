import React from 'react';
import { ColorPicker as AntColorPicker, ColorPickerProps } from 'antd';

interface Props extends ColorPickerProps {}

const ColorPicker: React.FC<Props> = (props: Props) => {
  return <AntColorPicker {...props} />;
};

export default ColorPicker;
