import { create } from 'zustand';
import { ErrorNavigateStore } from './types';

const initialData = {
  url: '',
};

const useErrorNavigate = create<ErrorNavigateStore>()((set) => ({
  ...initialData,
  setReplaceUrl: (value) => {
    set({ url: value });
  },
}));

export default useErrorNavigate;
