/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_PATHS } from '@routes/constants';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Input from '@components/v2/antd/data-entry/Input';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import Select from '@components/v2/antd/data-entry/Select';
import { fetchGlobalCatalogCategories } from '../../../../@apis/global-catalogs';
import { CreateGlobalThroughputItemBody } from '../../../../@types/global-throughput-item/params/CreateGlobalThroughputItemBody';
import {
  createGlobalThroughputItem,
  fetchGlobalThroughputItemDetail,
  updateGlobalThroughputItem,
} from '../../../../@apis/global-throughput-items';
import { UpdateGlobalThroughputItemBody } from '../../../../@types/global-throughput-item/params/UpdateGlobalThroughputItemBody';
import { MANUFACTURE_COUNTRY_OPTIONS } from '../../../../@types/global-throughput-item/constants/global-throughput-item.constants';

const INITIAL_VALUES: CreateGlobalThroughputItemBody = {
  globalCatalogCategoryId: undefined,
  throughputItemName: '',
  throughputItemCode: '',
  throughputItemBrand: '',
  throughputItemManufactureCountry: undefined,
};

function GlobalThroughputItemDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    throughputItemName: yup.string().required(),
    throughputItemCode: yup.string().required(),
    globalCatalogCategoryId: yup.number().required(),
  });

  const formik = useFormik<CreateGlobalThroughputItemBody>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchGlobalThroughputItemDetail(id);
      const {
        // 기본정보
        globalCatalogCategory,
        throughputItemName,
        throughputItemCode,
        throughputItemCompany,
        throughputItemManufactureCountry,
        throughputItemBrand,
      } = row;
      let globalCatalogCategoryId;
      if (globalCatalogCategory) {
        const { id } = globalCatalogCategory;
        globalCatalogCategoryId = id;
      }

      setFormInitialValues({
        // 기본정보
        globalCatalogCategoryId,
        throughputItemName,
        throughputItemCode,
        throughputItemCompany,
        throughputItemManufactureCountry,
        throughputItemBrand,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalCatalogCategoryOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogCategories();
      setCategoryOptions(
        rows.map(({ globalCatalogCategoryName, id }) => ({
          label: globalCatalogCategoryName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchGlobalCatalogCategoryOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateGlobalThroughputItemBody) => {
    try {
      await createGlobalThroughputItem(body);
      showToast({
        description: '장비 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEMS, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateGlobalThroughputItemBody,
  ) => {
    try {
      await updateGlobalThroughputItem(id, body);
      showToast({
        description: '장비 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEMS, { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '장비 정보 등록';
    let alertMessage = '작성하신 내용대로 장비를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '장비 정보 수정';
      alertMessage = '작성하신 내용대로 장비 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateGlobalThroughputItemBody;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, placeholder, options } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateGlobalThroughputItemBody;
    placeholder?: string;
    numberMode?: boolean;
  }) => {
    const {
      numberMode = false,
      labelName,
      required = false,
      key,
      placeholder,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        {numberMode ? (
          <InputNumber
            id={key}
            style={{
              width: '100%',
            }}
            name={key}
            min={0}
            value={formik.values[key] as number}
            onChange={(value) => {
              updateForm(key, value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        ) : (
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        )}
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;
    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'장비 기본정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '카테고리',
                required: true,
                key: 'globalCatalogCategoryId',
                options: categoryOptions,
              })}
              {renderInputForm({
                labelName: '장비명',
                required: true,
                key: 'throughputItemName',
              })}
              {renderInputForm({
                labelName: '장비코드',
                required: true,
                key: 'throughputItemCode',
              })}
              {renderInputForm({
                labelName: '제조사',
                key: 'throughputItemCompany',
              })}
              {renderInputForm({
                labelName: '브랜드',
                key: 'throughputItemBrand',
              })}
              {renderSelectForm({
                labelName: '국산/수입',
                key: 'throughputItemManufactureCountry',
                options: MANUFACTURE_COUNTRY_OPTIONS,
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default GlobalThroughputItemDetailPage;
