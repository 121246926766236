import React from 'react';

import LoginPage from '@pages/public/login';
import AIModelBaseImageListPage from '@pages/private/ai-design/ai-model-base-images/AIModelBaseImageListPage';
import GlobalCatalogListPage from '@pages/private/ai-data/global-catalogs/GlobalCatalogListPage';
import GlobalCatalogDetailPage from '@pages/private/ai-data/global-catalogs/GlobalCatalogDetailPage';
import AIModelImageListPage from '@pages/private/ai-design/ai-model-images/AIModelImageListPage';
import GlobalThroughputListPage from '@pages/private/ai-data/global-throughput-items/GlobalThroughputListPage';
import GlobalThroughputItemDetailPage from '@pages/private/ai-data/global-throughput-items/GlobalThroughputItemDetailPage';
import AIDesignTemplateDetailV2Page from '@pages/private/ai-design/ai-design-templates-v2/AIDesignTemplateDetailV2Page';
import AIDesignerTemplateListV2Page from '@pages/private/ai-design/ai-design-templates-v2/AIDesignTemplateListV2Page';
import BlogLabelListPage from '@pages/private/ai-data/blog-labels/BlogLabelListPage';
import BlogLabelDetailPage from '@pages/private/ai-data/blog-labels/BlogLabelDetailPage';
import PartnerGroupListPage from '@pages/private/customer/partner-groups/PartnerGroupListPage';
import PartnerGroupDetailPage from '@pages/private/customer/partner-groups/PartnerGroupDetailPage';
import PartnerListPage from '@pages/private/customer/partners/PartnerListPage';
import PartnerDetailPage from '@pages/private/customer/partners/PartnerDetailPage';
import AIDesignAssetListPage from '@pages/private/ai-design/ai-design-assets/AIDesignAssetListPage';
import AIDesignAssetDetailPage from '@pages/private/ai-design/ai-design-assets/AIDesignAssetDetailPage';
import BlogSubscriptionListPage from '@pages/private/blog/blog-subscriptions/BlogSubscriptionListPage';
import BlogSubscriptionDetailPage from '@pages/private/blog/blog-subscriptions/BlogSubscriptionDetailPage';
import BlogReferenceListPage from '@pages/private/ai-data/blog-references/BlogReferenceListPage';
import BlogReferenceDetailPage from '@pages/private/ai-data/blog-references/BlogReferenceDetailPage';
import MenuListPage from '@pages/private/settings/menus/MenuListPage';
import MenuDetailPage from '@pages/private/settings/menus/MenuDetailPage';
import AuthorityGroupListPage from '@pages/private/settings/authority-groups/AuthorityGroupListPage';
import AuthorityGroupDetailPage from '@pages/private/settings/authority-groups/AuthorityGroupDetailPage';
import AdsPartners from '@pages/private/ads/AdsPartnersPage';
import AdsCampaigns from '@pages/private/ads/AdsCampaignsPage';
import AdsCampaignDetailPage from '@pages/private/ads/AdsCampaignDetail';
import AdsCampaignAddPage from '@pages/private/ads/AdsCampaignAddPage';
import PartnerAccountListPage from '@pages/private/customer/partner-accounts/PartnerAccountListPage';
import PartnerAccountDetailPage from '@pages/private/customer/partner-accounts/PartnerAccountDetailPage';
import ReviewTemplateListPage from '@pages/private/review-config/review-templates/ReviewTemplateListPage';
import ReviewQuestionDetailPage from '@pages/private/review-config/review-questions/ReviewQuestionDetailPage';
import ReviewTemplateDetailPage from '@pages/private/review-config/review-templates/ReviewTemplateDetailPage';
import ReviewQuestionListPage from '@pages/private/review-config/review-questions/ReviewQuestionListPage';
import AIConsultationResponseListPage from '@pages/private/customer/ai-consultation-responses/AIConsultationResponseListPage';
import AIConsultationResponseDetailPage from '@pages/private/customer/ai-consultation-responses/AIConsultationResponseDetailPage';
import GlobalPromptListPage from '@pages/private/ai-data/global-prompts/GlobalPromptListPage';
import GlobalPromptDetailPage from '@pages/private/ai-data/global-prompts/GlobalPromptDetailPage';

import CrmReservationListPage from '@pages/private/customer/crm-reservations/CrmReservationListPage';
import { SideMenuItem } from './types';
import { PAGE_PATHS } from './constants';

export const publicRoutes = [
  {
    path: PAGE_PATHS.LOGIN,
    element: <LoginPage />,
  },
];
export const privateRoutes: { path: string; element: React.JSX.Element }[] = [
  /**
   * 고객 관리
   */
  {
    path: PAGE_PATHS.CUSTOMER_PARTNERS,
    element: <PartnerListPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_ADD,
    element: <PartnerDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_DETAIL,
    element: <PartnerDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_GROUPS,
    element: <PartnerGroupListPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_GROUP_ADD,
    element: <PartnerGroupDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_GROUP_DETAIL,
    element: <PartnerGroupDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNTS,
    element: <PartnerAccountListPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_ADD,
    element: <PartnerAccountDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_DETAIL,
    element: <PartnerAccountDetailPage />,
  },
  //aa
  {
    path: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSES,
    element: <AIConsultationResponseListPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_ADD,
    element: <AIConsultationResponseDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_DETAIL,
    element: <AIConsultationResponseDetailPage />,
  },
  {
    path: PAGE_PATHS.CUSTOMER_CRM_RESERVATIONS,
    element: <CrmReservationListPage />,
  },

  /**
   * AI 데이터
   */
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_CATALOGS,
    element: <GlobalCatalogListPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_ADD,
    element: <GlobalCatalogDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_DETAIL,
    element: <GlobalCatalogDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEMS,
    element: <GlobalThroughputListPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_ADD,
    element: <GlobalThroughputItemDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_DETAIL,
    element: <GlobalThroughputItemDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_LABELS,
    element: <BlogLabelListPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_LABELS_ADD,
    element: <BlogLabelDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_LABELS_DETAIL,
    element: <BlogLabelDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_REFERENCES,
    element: <BlogReferenceListPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_REFERENCE_ADD,
    element: <BlogReferenceDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_BLOG_REFERENCE_DETAIL,
    element: <BlogReferenceDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_PROMPTS,
    element: <GlobalPromptListPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_ADD,
    element: <GlobalPromptDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_DETAIL,
    element: <GlobalPromptDetailPage />,
  },
  /**
   * AI 디자인
   */
  {
    path: PAGE_PATHS.AI_MODEL_IMAGES,
    element: <AIModelImageListPage />,
  },
  {
    path: PAGE_PATHS.AI_MODEL_BASE_IMAGES,
    element: <AIModelBaseImageListPage />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_TEMPLATES_V2,
    element: <AIDesignerTemplateListV2Page />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_TEMPLATE_ADD_V2,
    element: <AIDesignTemplateDetailV2Page />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_TEMPLATE_DETAIL_V2,
    element: <AIDesignTemplateDetailV2Page />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_ASSETS,
    element: <AIDesignAssetListPage />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_ASSET_ADD,
    element: <AIDesignAssetDetailPage />,
  },
  {
    path: PAGE_PATHS.AI_DESIGNER_ASSET_DETAIL,
    element: <AIDesignAssetDetailPage />,
  },
  {
    path: PAGE_PATHS.BLOG_SUBSCRIPTIONS,
    element: <BlogSubscriptionListPage />,
  },
  {
    path: PAGE_PATHS.BLOG_SUBSCRIPTIONS_DETAIL,
    element: <BlogSubscriptionDetailPage />,
  },
  /**
   * 메뉴/권한 설정
   */
  {
    path: PAGE_PATHS.SETTINGS_MENUS,
    element: <MenuListPage />,
  },
  {
    path: PAGE_PATHS.SETTINGS_MENU_ADD,
    element: <MenuDetailPage />,
  },
  {
    path: PAGE_PATHS.SETTINGS_MENUS_DETAIL,
    element: <MenuDetailPage />,
  },
  {
    path: PAGE_PATHS.SETTINGS_AUTHORITY_GROUPS,
    element: <AuthorityGroupListPage />,
  },
  {
    path: PAGE_PATHS.SETTINGS_AUTHORITY_GROUP_ADD,
    element: <AuthorityGroupDetailPage />,
  },
  {
    path: PAGE_PATHS.SETTINGS_AUTHORITY_GROUPS_DETAIL,
    element: <AuthorityGroupDetailPage />,
  },
  {
    path: PAGE_PATHS.ADS_PARTNERS,
    element: <AdsPartners />,
  },
  {
    path: PAGE_PATHS.ADS_CAMPAIGNS,
    element: <AdsCampaigns />,
  },
  {
    path: PAGE_PATHS.ADS_CAMPAIGN_DETAIL,
    element: <AdsCampaignDetailPage />,
  },
  {
    path: PAGE_PATHS.ADS_CAMPAIGN_ADD,
    element: <AdsCampaignAddPage />,
  },
  /**
   * 리뷰 설정
   */
  {
    path: PAGE_PATHS.REVIEW_CONFIG_TEMPLATES,
    element: <ReviewTemplateListPage />,
  },
  {
    path: PAGE_PATHS.REVIEW_CONFIG_TEMPLATES_DETAIL,
    element: <ReviewTemplateDetailPage />,
  },
  {
    path: PAGE_PATHS.REVIEW_CONFIG_TEMPLATE_ADD,
    element: <ReviewTemplateDetailPage />,
  },
  {
    path: PAGE_PATHS.REVIEW_CONFIG_QUESTIONS,
    element: <ReviewQuestionListPage />,
  },
  {
    path: PAGE_PATHS.REVIEW_CONFIG_QUESTION_ADD,
    element: <ReviewQuestionDetailPage />,
  },
  {
    path: PAGE_PATHS.REVIEW_CONFIG_QUESTIONS_DETAIL,
    element: <ReviewQuestionDetailPage />,
  },
];

export const sideNavList1: SideMenuItem[] = [
  {
    key: PAGE_PATHS.CUSTOMER,
    value: '고객 관리',
    icon: 'icon-member',
    children: [
      {
        key: PAGE_PATHS.CUSTOMER_PARTNERS,
        value: '파트너 관리',
        children: [
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_ADD,
            value: '파트너 등록',
          },
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_DETAIL,
            value: '파트너 정보 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.CUSTOMER_PARTNER_GROUPS,
        value: '파트너 그룹 관리',
        children: [
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_GROUP_ADD,
            value: '파트너 그룹 등록',
          },
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_GROUP_DETAIL,
            value: '파트너 그룹 정보 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNTS,
        value: '사용자 계정 관리',
        children: [
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_ADD,
            value: '사용자 계정 등록',
          },
          {
            key: PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_DETAIL,
            value: '사용자 계정 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSES,
        value: 'AI 상담 데이터 관리',
        children: [
          {
            key: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_ADD,
            value: 'AI 상담 응답 생성',
          },
          {
            key: PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_DETAIL,
            value: 'AI 상담 응답 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.CUSTOMER_CRM_RESERVATIONS,
        value: 'CRM 예약 동기화 모니터링',
        children: [],
      },
    ],
  },
  {
    key: PAGE_PATHS.AI_DATA,
    value: 'AI 데이터',
    icon: 'icon-ai-data',
    children: [
      {
        key: PAGE_PATHS.AI_DATA_GLOBAL_CATALOGS,
        value: '시술 정보 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_ADD,
            value: '시술 정보 등록',
          },
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_DETAIL,
            value: '시술 정보 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEMS,
        value: '시술 장비 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_ADD,
            value: '시술 장비 등록',
          },
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_DETAIL,
            value: '시술 장비 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_DATA_BLOG_LABELS,
        value: '블로그 라벨링 정보 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DATA_BLOG_LABELS_ADD,
            value: '블로그 라벨링 정보 등록',
          },
          {
            key: PAGE_PATHS.AI_DATA_BLOG_LABELS_DETAIL,
            value: '블로그 라벨링 정보 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_DATA_BLOG_REFERENCES,
        value: '블로그 레퍼런스 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DATA_BLOG_REFERENCE_ADD,
            value: '블로그 레퍼런스 등록',
          },
          {
            key: PAGE_PATHS.AI_DATA_BLOG_REFERENCE_DETAIL,
            value: '블로그 레퍼런스 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_DATA_GLOBAL_PROMPTS,
        value: '글로벌 프롬프트 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_ADD,
            value: '글로벌 프롬프트 등록',
          },
          {
            key: PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_DETAIL,
            value: '글로벌 프롬프트 상세',
          },
        ],
      },
    ],
  },
  {
    key: PAGE_PATHS.AI_DESIGNER,
    value: 'AI 디자이너',
    icon: 'icon-dashboard',
    children: [
      {
        key: PAGE_PATHS.AI_DESIGNER_TEMPLATES_V2,
        value: '디자인 템플릿 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DESIGNER_TEMPLATE_ADD_V2,
            value: '디자인 템플릿 등록',
          },
          {
            key: PAGE_PATHS.AI_DESIGNER_TEMPLATE_DETAIL_V2,
            value: '디자인 템플릿 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_DESIGNER_ASSETS,
        value: '디자인 에셋 관리',
        children: [
          {
            key: PAGE_PATHS.AI_DESIGNER_ASSET_ADD,
            value: '디자인 에셋 등록',
          },
          {
            key: PAGE_PATHS.AI_DESIGNER_ASSET_DETAIL,
            value: '디자인 에셋 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.AI_MODEL_BASE_IMAGES,
        value: 'AI 모델 기준 이미지 관리',
      },
      {
        key: PAGE_PATHS.AI_MODEL_IMAGES,
        value: 'AI 모델 이미지 관리',
      },
    ],
  },
  {
    key: PAGE_PATHS.BLOG,
    value: '블로그',
    icon: 'icon-dashboard',
    children: [
      {
        key: PAGE_PATHS.BLOG_SUBSCRIPTIONS,
        value: '구독 관리',
        children: [
          {
            key: PAGE_PATHS.BLOG_SUBSCRIPTIONS_DETAIL,
            value: '구독 정보 상세',
          },
        ],
      },
    ],
  },
  {
    key: PAGE_PATHS.ADS,
    value: '퍼포먼스 광고',
    icon: 'icon-dashboard',
    children: [
      {
        key: PAGE_PATHS.ADS_PARTNERS,
        value: '파트너 리스트',
        children: [
          {
            key: PAGE_PATHS.ADS_CAMPAIGNS,
            value: '캠페인 리스트',
            children: [
              {
                key: PAGE_PATHS.ADS_CAMPAIGN_DETAIL,
                value: '캠페인 상세',
              },
              {
                key: PAGE_PATHS.ADS_CAMPAIGN_ADD,
                value: '캠페인 등록',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: PAGE_PATHS.SETTINGS,
    value: '메뉴/권한',
    icon: 'icon-dashboard',
    children: [
      {
        key: PAGE_PATHS.SETTINGS_MENUS,
        value: '메뉴 관리',
        children: [
          {
            key: PAGE_PATHS.SETTINGS_MENU_ADD,
            value: '메뉴 등록',
          },
          {
            key: PAGE_PATHS.SETTINGS_MENUS_DETAIL,
            value: '메뉴 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.SETTINGS_AUTHORITY_GROUPS,
        value: '권한 그룹 관리',
        children: [
          {
            key: PAGE_PATHS.SETTINGS_AUTHORITY_GROUP_ADD,
            value: '권한 그룹 등록',
          },
          {
            key: PAGE_PATHS.SETTINGS_AUTHORITY_GROUPS_DETAIL,
            value: '권한 그룹 상세',
          },
        ],
      },
    ],
  },
  {
    key: PAGE_PATHS.REVIEW_CONFIG,
    value: '리뷰 설정',
    icon: 'icon-dashboard',
    children: [
      {
        key: PAGE_PATHS.REVIEW_CONFIG_TEMPLATES,
        value: '리뷰 템플릿 관리',
        children: [
          {
            key: PAGE_PATHS.REVIEW_CONFIG_TEMPLATE_ADD,
            value: '리뷰 템플릿 등록',
          },
          {
            key: PAGE_PATHS.REVIEW_CONFIG_TEMPLATES_DETAIL,
            value: '리뷰 템플릿 상세',
          },
        ],
      },
      {
        key: PAGE_PATHS.REVIEW_CONFIG_QUESTIONS,
        value: '리뷰 문항 관리',
        children: [
          {
            key: PAGE_PATHS.REVIEW_CONFIG_QUESTION_ADD,
            value: '리뷰 문항 등록',
          },
          {
            key: PAGE_PATHS.REVIEW_CONFIG_QUESTIONS_DETAIL,
            value: '리뷰 문항 상세',
          },
        ],
      },
    ],
  },
];

export const allSideNavList: SideMenuItem[] = [...sideNavList1];
