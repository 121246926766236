import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button, Tooltip } from '@blueprintjs/core';
import { t } from 'polotno/utils/l10n';
import Select from '@components/v2/antd/data-entry/Select';
import { AI_DESIGN_BLOG_COPY_SUBJECT_OPTIONS } from '../../../../../../../@types/ai-design-template/constants/ai-naver-blog-copy-constants';

export const BlogSubjectSelectPageControls = observer(
  // @ts-ignore
  ({ store, page, xPadding, yPadding }) => {
    const hasManyPages = store.pages.length > 1;
    const index = store.pages.indexOf(page);

    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: `${yPadding - 40}px`,
            left: `${xPadding}px`,
          }}
        >
          <Select
            allowClear
            placeholder="블로그 페이지 주제"
            value={page.custom?.subject || undefined}
            style={{
              width: '200px',
            }}
            options={AI_DESIGN_BLOG_COPY_SUBJECT_OPTIONS}
            onChange={(value) => {
              page.set({
                custom: {
                  subject: value,
                },
              });
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: `${yPadding - 40}px`,
            right: `${xPadding}px`,
          }}
        >
          {hasManyPages && (
            <Tooltip content={t('workspace.moveUp')} disabled={index === 0}>
              <Button
                icon="chevron-up"
                minimal
                disabled={index === 0}
                onClick={() => {
                  page.setZIndex(index - 1);
                }}
              />
            </Tooltip>
          )}
          {hasManyPages && (
            <Tooltip
              content={t('workspace.moveDown')}
              disabled={index === store.pages.length - 1}
            >
              <Button
                icon="chevron-down"
                minimal
                disabled={index === store.pages.length - 1}
                onClick={() => {
                  const index = store.pages.indexOf(page);
                  page.setZIndex(index + 1);
                }}
              />
            </Tooltip>
          )}
          <Tooltip content={t('workspace.duplicatePage')}>
            <Button
              icon="duplicate"
              minimal
              onClick={() => {
                page.clone();
              }}
            />
          </Tooltip>
          {hasManyPages && (
            <Tooltip content={t('workspace.removePage')}>
              <Button
                icon="trash"
                minimal
                onClick={() => {
                  store.deletePages([page.id]);
                }}
              />
            </Tooltip>
          )}
          <Tooltip content={t('workspace.addPage')}>
            <Button
              icon="insert"
              minimal
              onClick={() => {
                const newPage = store.addPage({
                  bleed: store.activePage?.bleed || 0,
                });
                const index = store.pages.indexOf(page);
                newPage.setZIndex(index + 1);
              }}
            />
          </Tooltip>
        </div>
      </>
    );
  },
);
