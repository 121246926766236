import React, { ButtonHTMLAttributes, useMemo } from 'react';
import { css, styled } from 'styled-components';
import colorSet from '@styles/colors';
import { ReactComponent as CloseSvg } from '@assets/svg/Close.svg';
import { ReactComponent as AddSvg } from '../../../../assets/svg/Plus.svg';
import { ReactComponent as EditSvg } from '../../../../assets/svg/Edit.svg';
import { ReactComponent as DeleteSvg } from '../../../../assets/svg/Trashcan.svg';
import { ReactComponent as ExcelSvg } from '../../../../assets/svg/Excel.svg';
import { IconButtonSize, IconButtonType } from './types';
import { getIconSize } from './utils';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconType: IconButtonType;
  size?: IconButtonSize;
}

function IconButton({
  iconType,
  size = 'lg',
  type = 'button',
  ...rest
}: IconButtonProps) {
  const icon = useMemo(() => {
    if (iconType === 'close') return <CloseIcon size={size} />;
    if (iconType === 'add') return <AddIcon size={size} />;
    if (iconType === 'edit') return <EditIcon size={size} />;
    if (iconType === 'delete') return <DeleteIcon size={size} />;
    if (iconType === 'excel') return <ExcelIcon size={size} />;
    return <div />;
  }, [iconType, size]);

  return (
    <ButtonContainer type={type} iconType={iconType} size={size} {...rest}>
      {icon}
    </ButtonContainer>
  );
}

export default IconButton;

const ButtonContainer = styled.button<{
  iconType: IconButtonType;
  size?: IconButtonSize;
}>`
  display: flex;
  padding: ${({ size }) => (size === 'lg' ? '8px' : '6px')};
  border-radius: ${({ size }) => (size === 'lg' ? '10px' : '8px')};
  &:focus-visible,
  &:hover {
    cursor: pointer;
    background-color: ${({ iconType }) => {
      if (iconType === 'delete') return colorSet.red8;
      if (iconType === 'excel') return colorSet.primary9;
      return colorSet.gray11;
    }};
    svg > path {
      ${({ iconType }) =>
        (iconType === 'add' || iconType === 'delete' || iconType === 'edit') &&
        css`
          fill: ${iconType === 'delete' ? colorSet.red3 : colorSet.gray7};
        `}
    }
  }
`;

const AddIcon = styled(AddSvg)<{ size?: IconButtonSize }>`
  ${({ size }) => getIconSize(size)};
`;

const EditIcon = styled(EditSvg)<{ size?: IconButtonSize }>`
  ${({ size }) => getIconSize(size)};
`;

const DeleteIcon = styled(DeleteSvg)<{ size?: IconButtonSize }>`
  ${({ size }) => getIconSize(size)};
`;

const ExcelIcon = styled(ExcelSvg)<{ size?: IconButtonSize }>`
  ${({ size }) => getIconSize(size)};
`;

const CloseIcon = styled(CloseSvg)<{ size?: IconButtonSize }>`
  ${({ size }) => getIconSize(size)};
`;
