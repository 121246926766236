import { AppError, request, Rows } from '@networks/index';
import { AIModelImage } from '../@types/ai-model-image/models/AIModelImage';
import { FetchAIModelImageListQuery } from '../@types/ai-model-image/params/FetchAIModelImageListQuery';
import { UpdateAIModelImageBody } from '../@types/ai-model-image/params/UpdateAIModelImageBody';

const API_ROOT = 'admin/v1/ai-model-images';

/**
 * AI 모델 벌크 생성 요청
 */
export const generateAIModelImages = async (
  imageUrl: string,
  requestCount: number,
) => {
  try {
    const { data } = await request<Rows<AIModelImage>>({
      method: 'post',
      url: API_ROOT,
      requestBody: {
        requestCount,
        imageUrl,
      },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 이미지 목록 조회
 */
export const fetchAIModelImages = async (query: FetchAIModelImageListQuery) => {
  try {
    const { data } = await request<Rows<AIModelImage>>({
      method: 'get',
      url: API_ROOT,
      queryParams: {
        ...query,
        folder: 'ai/model',
        rand: new Date().getTime(),
      },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 이미지 정보 수정
 */
export const updateAIModelImage = async (
  id: number | string,
  body: UpdateAIModelImageBody,
) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 이미지 삭제
 */
export const deleteAIModelBaseImage = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
