import React from 'react';
import { Typo, typo } from '@components/atoms';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { getPathNameDepth } from 'utils/pathName';
import colorSet from '@styles/colors';
import { zIndex } from '@styles/zIndex';
import { MediaQuery } from '@styles/mediaQuery';
import { ReactComponent as Back } from '../../../assets/svg/Back.svg';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';

interface ContentLayoutProps {
  headerRight?: React.ReactNode;
  children: React.ReactNode;
  // alertTitle?: string;
  // alertContent?: string;
  // 커스터마이징이 필요한 타이틀일 경우
  title?: string;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onGoBack?: (path: string) => void;
}

function ContentLayout({
  headerRight,
  children,
  // alertTitle,
  // alertContent,
  title,
  contentStyle,
  onGoBack,
  style,
}: ContentLayoutProps) {
  const navigate = useNavigate();
  const renderBackIcon = () => {
    if (pathName.lastPath) {
      if (onGoBack) {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <BackIcon
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
          </div>
        );
      }

      return (
        <BackIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
      );
    }

    return '';
  };

  const pathName = getPathNameDepth(window.location.pathname);

  const renderTitle = () => {
    if (title) return title;
    return pathName.lastPath
      ? pathName.lastPath?.value
      : pathName.subPath?.value || pathName.mainPath?.value;
  };

  return (
    <LayoutWarpper style={style}>
      <TitleWarpper id="haeder" className="flex flex-column">
        <TitleLeft style={{ gap: '6px' }}>
          {/* 1 Depth */}
          <TopDepth>{pathName.mainPath?.value}</TopDepth>
          {/* 2 Depth */}
          {pathName.subPath && <ArrowIcon />}
          <TopDepth>{pathName.subPath?.value}</TopDepth>
          {/* 3 Depth */}
          {/* {pathName.lastPath && <ArrowRight />}
          {pathName.lastPath && (
            <Typo typoType="b10m" color="gray7">
              {pathName.lastPath?.value}
            </Typo>
          )} */}
        </TitleLeft>
        <TitleHeader>
          <TitleLeft>
            {/* 3 Depth 있을 시 */}
            {renderBackIcon()}
            <BottomDepth>{renderTitle()}</BottomDepth>
          </TitleLeft>
          <TitleRight>{headerRight}</TitleRight>
        </TitleHeader>
      </TitleWarpper>
      <ContentWarpper style={contentStyle}>{children}</ContentWarpper>
    </LayoutWarpper>
  );
}

export default ContentLayout;

const LayoutWarpper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TitleWarpper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 20px;
  background: ${colorSet.gray12};
  z-index: ${zIndex.HEADER};
`;

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const TitleLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContentWarpper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 60px;
`;

const TitleRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  white-space: nowrap;
`;

const TopDepth = styled(Typo)`
  color: ${colorSet.gray7};
  ${typo.b10m}
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    ${typo.b11m}
  }
`;

const BottomDepth = styled(Typo)`
  color: ${colorSet.gray2};
  ${typo.d4}
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    ${typo.h2}
  }
`;

const ArrowIcon = styled(ArrowRight)`
  width: 20px;
  height: 20px;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    width: 16px;
    height: 16px;
  }
`;

const BackIcon = styled(Back)`
  width: 32px;
  height: 32px;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    width: 24px;
    height: 24px;
  }
`;
