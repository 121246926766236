import React from 'react';
import dayjs from 'dayjs';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  LLMIntentResponseTemplate,
  LLMLanguage,
} from '../../../../@types/ai-consultation-prompt/models/LLMIntentResponseTemplate';

export const LLM_LANGUAGE_TO_KOREAN: Record<LLMLanguage, string> = {
  ko: '한국어',
  en: '영어',
  zh: '중국어',
  ja: '일본어',
  th: '태국어',
  vi: '베트남어',
};

export const LLM_LANGUAGE_OPTIONS = Object.entries(LLM_LANGUAGE_TO_KOREAN).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

export const colDefs = (intentOptions: Record<string, string>): ColDef[] => [
  {
    field: 'partnerId',
    minWidth: 153,
    width: 153,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({
      data,
    }: ICellRendererParams<LLMIntentResponseTemplate>) => {
      if (data) {
        const { partnerName, displayName } = data.partner;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'intent',
    minWidth: 170,
    width: 170,
    headerName: '상담 데이터 유형',
    sortable: false,
    cellRenderer: ({
      data,
    }: ICellRendererParams<LLMIntentResponseTemplate>) => {
      if (data) return intentOptions[data.intent] ?? '-';
      return '-';
    },
  },
  {
    field: 'language',
    minWidth: 80,
    width: 80,
    headerName: '언어',
    sortable: false,
    cellRenderer: ({
      data,
    }: ICellRendererParams<LLMIntentResponseTemplate>) => {
      if (data && data.language) return LLM_LANGUAGE_TO_KOREAN[data.language];
      return '-';
    },
  },
  {
    field: 'message',
    minWidth: 200,
    width: 200,
    flex: 1,
    headerName: '메시지',
    sortable: false,
  },
  {
    field: 'createdAt',
    minWidth: 153,
    width: 153,
    headerName: '등록일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
  {
    field: 'updatedAt',
    minWidth: 153,
    width: 153,
    headerName: '마지막 수정일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
];
