import { AppError, request, Row, Rows } from '@networks/index';
import { BlogSubscription } from '../@types/blog-subscription/models/BlogSubscription';
import { CreateBlogSubscriptionBody } from '../@types/blog-subscription/models/CreateBlogSubscriptionBody';

const API_ROOT = 'admin/partners';

export const createBlogSubscription = async (
  partnerId: number | string,
  body: CreateBlogSubscriptionBody,
) => {
  const url = `${API_ROOT}/${partnerId}/blog-subscriptions`;
  try {
    const { data } = await request<Row<BlogSubscription>>({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchBlogSubscriptions = async (partnerId: number | string) => {
  const url = `${API_ROOT}/${partnerId}/blog-subscriptions`;
  try {
    const { data } = await request<Rows<BlogSubscription>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
