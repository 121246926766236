import { create } from 'zustand';
import { ToastStore } from './types';

const initialData = {
  toastList: [],
  delay: 1200,
};

const useToastStore = create<ToastStore>()((set) => ({
  ...initialData,

  removeTost: (value) =>
    set((state) => {
      return {
        ...state,
        toastList: state.toastList.filter((li) => li.id !== value),
      };
    }),
  showToast: (item) =>
    set((state) => {
      return {
        ...state,
        toastList: [...state.toastList, { id: new Date().getTime(), ...item }],
      };
    }),
}));

export default useToastStore;
