import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import { FormLayout } from '@components/utils/layout-utils';
import FormItem from '@components/molecules/FormItem';
import Select from '@components/v2/antd/data-entry/Select';
import { Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { useToastStore } from '@store/useToastStore';
import { uploadAIModelBaseImages } from '../../../../../@apis/ai-model-base-images';

export interface AIModelBaseImageUploadModalProps {
  onCancel?: () => void;
  onDataChange?: () => void;
}
function AIModelBaseImageUploadModal(props: AIModelBaseImageUploadModalProps) {
  const { onCancel, onDataChange } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const { showToast } = useToastStore();
  const [uploading, setUploading] = useState(false);

  const requestUploadAIModelBaseImages = async () => {
    try {
      setUploading(true);
      await uploadAIModelBaseImages(fileList as any[], searchTags);
      showToast({
        description: '이미지가 등록되었습니다',
        status: 'Primary',
      });
      if (onDataChange) onDataChange();
      if (onCancel) onCancel();
    } catch (e) {
      console.error(e);
    } finally {
      setUploading(false);
    }
  };

  const canUpload = () => {
    return fileList.length > 0 && !uploading;
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleModalClose = () => {
    if (onCancel) onCancel();
  };

  const handleModalConfirm = async () => {
    await requestUploadAIModelBaseImages();
  };
  const handlePreview = async (file: UploadFile) => {
    const fileUrl = await getBase64(file as RcFile);
    setPreviewImage(fileUrl);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
  };

  return (
    <>
      <Modal
        size={560}
        title="이미지 업로드"
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onConfirm={handleModalConfirm}
        confirmLabel="업로드"
        cancelLabel="닫기"
        disabled={!canUpload()}
      >
        <FormLayout>
          <FormItem label={'이미지'}>
            <Upload
              accept="image/*"
              onPreview={handlePreview}
              fileList={fileList}
              listType="picture-card"
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
              }}
              beforeUpload={async (file) => {
                const newFile = file;
                // @ts-ignore
                newFile.url = await getBase64(file);
                setFileList([...fileList, newFile]);
                return false;
              }}
            >
              <div>
                <PlusOutlined />
                <div>업로드</div>
              </div>
            </Upload>
          </FormItem>
          <FormItem label={'검색태그'} optional>
            <Select
              value={searchTags}
              placeholder="검색태그를 입력해주세요"
              onChange={(value) => {
                setSearchTags(value);
              }}
              mode="tags"
              style={{
                width: '100%',
              }}
            />
          </FormItem>
        </FormLayout>
      </Modal>
      {previewOpen && (
        <Modal
          confirmLabel={'확인'}
          onConfirm={() => {
            setPreviewOpen(false);
          }}
          size={860}
          title={previewTitle}
          onClose={() => {
            setPreviewOpen(false);
          }}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
    </>
  );
}

export default AIModelBaseImageUploadModal;
