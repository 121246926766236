import { observer } from 'mobx-react-lite';
import {
  EVENT_MENUBOOK_TEMPLATE_KEY_OPTIONS,
  EventMenuBookTemplateKey,
} from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
import React from 'react';
import { Cascader } from 'antd';

// @ts-ignore
const EventMenuBookKeySelect = observer(({ element }) => {
  const { custom = {} } = element;
  const options = EVENT_MENUBOOK_TEMPLATE_KEY_OPTIONS;
  const getValue = () => {
    let label;
    let value: EventMenuBookTemplateKey | undefined;
    if (custom) {
      const { eventMenuBook } = custom;
      value = eventMenuBook;
    }

    if (value) {
      const matchedOption = options.find((option) => {
        const { children } = option;
        return !!children.find(({ value: optionValue }) => {
          return optionValue === value;
        });
      });
      if (matchedOption) {
        label = matchedOption.value;
      }
    }

    if (label && value) {
      return [label, value];
    }

    return undefined;
  };

  return (
    <Cascader
      allowClear
      placeholder="메뉴북 필드"
      value={getValue()}
      style={{
        width: '120px',
      }}
      options={options}
      displayRender={(label) => {
        return <div>{label[label.length - 1]}</div>;
      }}
      onChange={(value) => {
        element.set({
          custom: {
            ...custom,
            eventMenuBook: value[value.length - 1],
          },
        });
      }}
    />
  );
});

export default EventMenuBookKeySelect;
