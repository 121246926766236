import colorSet from '@styles/colors';
import { MediaQuery } from '@styles/mediaQuery';
import { ReactComponent as HomeLogo } from '@assets/svg/HomeLogo.svg';
import { zIndex } from '@styles/zIndex';
import { styled } from 'styled-components';
import { Typo, typo } from '@components/atoms';

export const HeaderContainer = styled.header`
  position: relative;
  z-index: ${zIndex.HEADER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    padding: 14px 20px;

    .pc-dropdown {
      display: none;
    }
  }
  ${MediaQuery.FROM_PHONE} {
    padding: 8px 20px;

    .tablet-dropdown {
      display: none;
    }
  }
`;

export const HeaderLeftAccessory = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  > h1 {
  }

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

export const Dividier = styled.span`
  display: block;
  margin: 0 16px;
  height: 100%;
  padding: 2px 0;
  width: 1px;
  background-color: ${colorSet.gray10};
`;

export const HeaderRightAccessory = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: none;
  }
`;
export const HeaderAuthDropdownTrigger = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  > .icon {
    display: flex;
    width: 16px;
    height: 16px;
    background: url('/assets/icons/icon-arrow_black.svg') no-repeat center
      center / contain;
  }
`;
export const HeaderAuthDropdown = styled.div`
  min-width: 220px;
  padding: 20px 8px;
  background: #ffffff;
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 20px;
  text-align: center;

  > div {
    padding: 8px 0 20px;
    border-bottom: 1px solid #dee2e6;
  }

  > ul {
    padding-top: 12px;

    > li {
      padding: 10px 16px;
      text-align: left;
    }
  }
`;
export const MobileAccessory = styled.div`
  display: none;

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > button.icon {
      width: 32px;
      height: 32px;
      background: url('/assets/icons/icon-menu.svg') no-repeat center center /
        contain;
    }

    > h1 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const MobilePartnerText = styled(Typo)`
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    ${typo.h6}
  }
  ${MediaQuery.FROM_PHONE} {
    ${typo.h8}
  }
`;

export const LogoHomeIcon = styled(HomeLogo)`
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    width: 24px;
    height: 24px;
  }
  ${MediaQuery.FROM_PHONE} {
    width: 20px;
    height: 20px;
  }
`;

export const MobilDropDownView = styled.div`
  display: none;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
`;
