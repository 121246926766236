import styled from 'styled-components';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { autorun } from 'mobx';
import { createPortal } from 'react-dom';
import { createStore, StoreType } from 'polotno/model/store';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { DEFAULT_SECTIONS, SidePanel } from 'polotno/side-panel';
import Toolbar from 'polotno/toolbar/toolbar';
import Workspace from 'polotno/canvas/workspace';
import ZoomButtons from 'polotno/toolbar/zoom-buttons';
import DownloadButton from 'polotno/toolbar/download-button';
import Button from '@components/v2/antd/general/Button';
import { useAlertStore } from '@store/useAlertStore';
import PolotnoImageUploadSection from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/PolotnoImageUploadTab';
import { Tooltip } from 'polotno/canvas/tooltip';
import BlogPromptKeySelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/BlogPromptKeySelect';
import { BlogSubjectSelectPageControls } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/BlogSubjectSelectPageControls';
import {
  PolotnoTemplatesPanel,
  PolotnoTemplatesTab,
} from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/PolotnoTemplatesTab';
import ColorThemeKeySelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/ColorThemeKeySelect';
import {
  ColorThemeEditorTab,
  ThemeEditorPanel,
} from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/ColorThemeEditorTab';
import ImageCategorySelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/ImageCategorySelect';
import { observer } from 'mobx-react-lite';
import {
  FontEditorPanel,
  FontThemeEditorTab,
} from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/FontThemeEditorTab';
import TextFontTypeSelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/TextFontTypeSelect';
import {
  ImageThemeSelectPanel,
  ImageThemeSelectTab,
} from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/ImageThemeSelectTab';
import ImageAssetSelectModal from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/common/ImageAssetSelectModal';
import { uniq } from 'lodash';
import TemplateTestModal from '@pages/private/ai-design/ai-design-templates-v2/components-v2/TemplateTestModal';
import { PolotnoElement } from '@pages/private/ai-design/ai-design-templates-v2/types/PolotnoPageJSON';
import { ImageCategory } from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
import { PolotnoLogoImageSection } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/PolotnoLogoImageTab';
import CalendarSection from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/side-panels/CalendarTab';
import { CUSTOM_FONTS } from '@pages/private/ai-design/ai-design-templates-v2/utils/polotno-font.utils';
import ThumbnailKeySelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/ThumbnailKeySelect';
import EventMenuBookKeySelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/tooltips/EventMenuBookKeySelect';
import { ColorTheme } from '../../../../../@types/ai-design-template/models/ColorTheme';
import { AiDesignTemplateType } from '../../../../../@types/ai-design-template/constants/ai-design.constants';

export interface TemplateEditorModalProps {
  onClose?: () => void;
  onDataChange?: (
    sceneJson: string,
    colorTheme?: ColorTheme,
    randomMainFontFamilies?: string[],
    randomSubFontFamilies?: string[],
    randomImageUrlsRef?: string[],
  ) => void;
  initialData?: string;
  colorTheme?: ColorTheme;
  randomMainFontFamilies?: string[];
  randomSubFontFamilies?: string[];
  randomImageUrls?: string[];
  templateType?: AiDesignTemplateType | string;
}

function TemplateEditorModal(props: TemplateEditorModalProps) {
  const {
    onClose,
    onDataChange,
    initialData,
    colorTheme,
    randomMainFontFamilies,
    randomSubFontFamilies,
    randomImageUrls,
    templateType,
  } = props;
  const { show: showAlert } = useAlertStore();
  const colorThemeRef = useRef<ColorTheme>();
  const mainFontsThemeRef = useRef<string[]>();
  const subFontsThemeRef = useRef<string[]>();
  const randomImageUrlsRef = useRef<string[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [assetSelectModalVisible, setAssetSelectModalVisible] = useState(false);
  const [testModalVisible, setTestModalVisible] = useState(false);
  const [templateTypeState, setTemplateTypeState] = useState<
    AiDesignTemplateType | string
  >();
  const store = useMemo(
    () =>
      createStore({
        key: process.env.REACT_APP_POLOTNO_KEY || '',
        showCredit: true,
      }),
    [],
  );

  useEffect(() => {
    if (templateType) {
      setTemplateTypeState(templateType);
    }
  }, [templateType]);

  useEffect(() => {
    if (colorTheme) {
      colorThemeRef.current = colorTheme;
    }
  }, [colorTheme]);

  useEffect(() => {
    if (randomMainFontFamilies) {
      mainFontsThemeRef.current = randomMainFontFamilies;
    }
  }, [randomMainFontFamilies]);

  useEffect(() => {
    if (randomSubFontFamilies) {
      subFontsThemeRef.current = randomSubFontFamilies;
    }
  }, [randomSubFontFamilies]);

  useEffect(() => {
    if (randomImageUrls) {
      randomImageUrlsRef.current = randomImageUrls;
    }
  }, [randomImageUrls]);

  React.useEffect(() => {
    return autorun(() => {
      let logoSelected = false;
      const [selectedElement] = store.selectedElements;
      if (selectedElement) {
        const element = selectedElement as PolotnoElement;
        const { custom } = element;
        if (custom) {
          const { imageCategory } = custom;
          if (imageCategory === ImageCategory.Logo) {
            logoSelected = true;
          }
        }
      }

      if (logoSelected) {
        store.openSidePanel('logo-assets');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialData) {
      try {
        const json = JSON.parse(initialData);
        store.loadJSON(json);
        setTimeout(() => {
          store.selectPage(store.pages[0].id);
        }, 300);
      } catch (e) {
        let errorDetail = '';
        if (e) {
          errorDetail = `올바르지 않은 JSON 파일이 입력되었습니다.\n입력값 : ${initialData}\n${errorDetail}`;
        }
        console.warn(errorDetail);
      }
    }
  }, [initialData, store]);

  useEffect(() => {
    const json = store.toJSON();

    if (store) {
      CUSTOM_FONTS.forEach((font) => {
        store.addFont(font);
      });
    }

    if (!json.pages || json.pages.length === 0) {
      store.addPage();
    }
    const off = store.on('change', () => {
      setIsEdited(true);
    });
    return () => {
      if (off) off();
    };
  }, [store]);

  const handleCloseClick = () => {
    if (!isEdited) {
      if (onClose) onClose();
      return;
    }
    showAlert({
      size: 360,
      title: '편집 취소',
      message:
        '템플릿 편집을 취소하시겠어요?\n편집하신 내용은 저장되지 않아요.',
      actions: [
        { label: '취소', color: 'gray', style: 'line' },
        {
          label: '확인',
          color: 'red',
          onClick: () => {
            if (onClose) onClose();
          },
        },
      ],
    });
  };

  const handleSaveClick = (store: StoreType) => {
    if (onDataChange)
      onDataChange(
        JSON.stringify(store.toJSON()),
        colorThemeRef.current,
        mainFontsThemeRef.current,
        subFontsThemeRef.current,
        randomImageUrlsRef.current,
      );
    if (onClose) onClose();
  };

  const renderActionControls = (store: StoreType) => {
    return (
      <ActionControls>
        <DownloadButton store={store} />
        <Button
          type="default"
          onClick={() => {
            setTestModalVisible(true);
          }}
        >
          템플릿 테스트
        </Button>
        <Button type="default" onClick={handleCloseClick}>
          닫기
        </Button>
        <Button
          onClick={() => {
            handleSaveClick(store);
          }}
        >
          저장
        </Button>
      </ActionControls>
    );
  };

  const getWorkSpaceComponent = useCallback(() => {
    const components: Record<string, any> = {
      Tooltip,
      ImageCategorySelector: ImageCategorySelect,
      TextFontCategorySelector: TextFontTypeSelect,
      TextThemeKeySelector: ColorThemeKeySelect,
      FigureThemeKeySelector: ColorThemeKeySelect,
    };

    switch (templateTypeState) {
      case AiDesignTemplateType.NaverBlog:
        components.TextBlogKeySelector = BlogPromptKeySelect;
        components.PageControls = BlogSubjectSelectPageControls;
        break;
      case AiDesignTemplateType.ProductThumbnail:
        components.TextThumbnailThumbnailKeySelector = ThumbnailKeySelect;
        break;

      case AiDesignTemplateType.EventMenuBook:
        components.TextEventMenuBookKeySelector = EventMenuBookKeySelect;
        break;
      default:
        break;
    }

    return components;
  }, [templateTypeState]);

  return createPortal(
    <ModalContainer>
      <PolotnoContainer style={{ width: '100%', height: '100%' }}>
        <SidePanelWrap>
          <SidePanel
            store={store}
            sections={[
              {
                name: '템플릿2',
                Tab: PolotnoTemplatesTab,
                Panel: observer(({ store }: { store: StoreType }) => {
                  return (
                    <PolotnoTemplatesPanel
                      store={store}
                      onTemplateChange={(template) => {
                        const {
                          colorTheme,
                          randomMainFontFamilies,
                          randomSubFontFamilies,
                          randomImageUrls,
                        } = template;
                        colorThemeRef.current = colorTheme;
                        mainFontsThemeRef.current =
                          randomMainFontFamilies || [];
                        subFontsThemeRef.current = randomSubFontFamilies || [];
                        randomImageUrlsRef.current = randomImageUrls || [];

                        if (template.templateSceneJson) {
                          try {
                            store.loadJSON(template.templateSceneJson);
                            setTemplateTypeState(template.templateType);
                          } catch (e) {
                            console.log(e);
                          }
                        }
                      }}
                    />
                  );
                }),
              },
              PolotnoLogoImageSection,
              CalendarSection,
              PolotnoImageUploadSection,
              ...DEFAULT_SECTIONS.filter(({ name }) => {
                return (
                  name !== 'upload' && name !== 'photos' && name !== 'templates'
                );
              }),
              {
                name: '색 설정',
                Tab: ColorThemeEditorTab,
                Panel: observer(({ store }: { store: StoreType }) => {
                  return (
                    <ThemeEditorPanel
                      store={store}
                      initialColorTheme={colorThemeRef.current}
                      onColorThemeChange={(colorTheme) => {
                        colorThemeRef.current = colorTheme;
                      }}
                    />
                  );
                }),
              },
              {
                name: '폰트 설정',
                Tab: FontThemeEditorTab,
                Panel: observer(({ store }: { store: StoreType }) => {
                  return (
                    <FontEditorPanel
                      randomMainFontFamilies={mainFontsThemeRef.current}
                      randomSubFontFamilies={subFontsThemeRef.current}
                      store={store}
                      onFontChange={(mainFonts, subFonts) => {
                        mainFontsThemeRef.current = mainFonts;
                        subFontsThemeRef.current = subFonts;
                      }}
                    />
                  );
                }),
              },
              {
                name: '이미지 설정',
                Tab: ImageThemeSelectTab,
                Panel: observer(({ store }: { store: StoreType }) => {
                  return (
                    <ImageThemeSelectPanel
                      imageUrls={randomImageUrlsRef.current}
                      store={store}
                      onSelectAssetButtonClick={() => {
                        setAssetSelectModalVisible(true);
                      }}
                      onAssetDeleted={(updatedUrls) => {
                        randomImageUrlsRef.current = updatedUrls;
                      }}
                    />
                  );
                }),
              },
            ]}
          />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            downloadButtonEnabled
            components={{
              ActionControls: (value: { store: StoreType }) => {
                return renderActionControls(value.store);
              },
            }}
          />
          <Workspace store={store} components={getWorkSpaceComponent()} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
      {assetSelectModalVisible && (
        <ImageAssetSelectModal
          onClose={() => {
            setAssetSelectModalVisible(false);
          }}
          onAssetSelect={(assetUrls) => {
            randomImageUrlsRef.current = uniq([
              ...randomImageUrlsRef.current,
              ...assetUrls,
            ]);
          }}
        />
      )}
      {testModalVisible && (
        <TemplateTestModal
          randomMainFontFamilies={mainFontsThemeRef.current}
          randomSubFontFamilies={subFontsThemeRef.current}
          colorTheme={colorThemeRef.current}
          randomImageUrls={randomImageUrlsRef.current}
          initialData={store.toJSON() as any}
          onClose={() => {
            setTestModalVisible(false);
          }}
        />
      )}
    </ModalContainer>,
    window.document.body,
  );
}

export default TemplateEditorModal;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  width: 100%;
  height: 100vh;
  z-index: 13;
  background: #fff;
`;

const ActionControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
