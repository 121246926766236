import React from 'react';
import colorSet from '@styles/colors';

export interface ImagePreviewPanelProps {
  imagePanel: React.ReactNode;
  informationPanel: React.ReactNode;
}

function ImagePreviewPanel(props: ImagePreviewPanelProps) {
  const { imagePanel, informationPanel } = props;
  return (
    <div
      style={{
        display: 'flex',
        background: 'white',
        height: '80vh',
        overflow: 'hidden',
        width: '90vw',
        borderRadius: '24px',
      }}
    >
      <div
        style={{
          width: '50vw',
          background: `${colorSet.gray1}`,
          flexGrow: 1,
          position: 'relative',
        }}
      >
        {imagePanel}
      </div>
      <div
        style={{
          background: 'white',
          flexBasis: '20vw',
          flexGrow: 1,
          padding: '20px',
          textAlign: 'left',
        }}
      >
        {informationPanel}
      </div>
    </div>
  );
}

export default ImagePreviewPanel;
