import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { FetchPartnerGroupListQuery } from '../../../../@types/partner-group/params/FetchPartnerGroupListQuery';
import { fetchPartnerGroups } from '../../../../@apis/partner-groups';
import { PartnerGroup } from '../../../../@types/partner-group/models/PartnerGroup';

function PartnerGroupListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {};

  // const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchPartnerGroupListQuery> =
  //   {
  //     filterKey: 'treatmentName',
  //     searchInputPlaceholder: '시술명 검색',
  //   };

  const COLS: ColDef[] = [
    {
      field: 'partnerGroupName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '그룹명',
      sortable: false,
    },
    {
      field: 'partnerGroupCode',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '그룹코드',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: FetchPartnerGroupListQuery) => {
    const { rows, count } = await fetchPartnerGroups(query);
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: PartnerGroup) => {
    const { id } = data;
    navigate(PAGE_PATHS.CUSTOMER_PARTNER_GROUP_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_PARTNER_GROUP_ADD}>
          <Button>파트너 그룹 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      // keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default PartnerGroupListPage;
