import { MarketingChannel, PartnerAssistantLanguage } from '@types';

export const MARKETING_CHANNEL_TO_KOREAN: Record<MarketingChannel, string> = {
  NAVER_POWER_LINK: '네이버 파워링크',
  NAVER_BRAND_ADS: '네이버 브랜드광고',
  NAVER_PLACE: '네이버 플레이스',
  NAVER_POWER_CONTENT: '네이버 파워 컨텐츠',
  GOOGLE_ADS: '구글 온드미디어 (자체 채널)',
  YOUTUBE: '유튜브 온드미디어 (자체 채널)',
  INSTAGRAM: '인스타그램 온드미디어 (자체 채널)',
  FACEBOOK: '페이스북 온드미디어 (자체 채널)',
  META_PERFORMANCE: '메타 퍼포먼스',
  HOMEPAGE: '홈페이지',
  KAKAO_PLUS_FRIEND: '카카오 플러스 친구',
  ETC: '기타',
  DOCNAWA: '닥나와',
  GLOBAL_HOMEPAGE: '글로벌 홈페이지',
  GANGNAMUNNI: '강남언니',
  YEOSHIN_TICKET: '여신티켓',
  BABITALK: '바비톡',
  MODOODOC: '모두닥',
  MEITUAN: '메이투안',
  XIAOHONGSHU: '샤오홍슈',
  TIKTOK: '틱톡',
  CREATRIP: '크리에이트립',
  MIMO: '미모',
  KAKAO_MAP: '카카오맵',
  NAVER_BLOG: '네이버 블로그',
  NAVER_KNOWLEDGE_IN: '네이버 지식인',
  NAVER_CAFE: '네이버 카페',
  GOOGLE_MAP: '구글맵',
  ONLY_ME_DOCTOR: '온리미',
  GOOD_DOC: '굿닥',
  KAKAO_CHANNEL: '카카오 채널',
  T_MAP: '티맵',
  DANGGEUN_MARKET_CHANNEL: '당근마켓 채널',
  DANGGEUN_MARKET_ADS: '당근마켓 광고',
  X_ADS: 'X 온드미디어',
};

export const ASSISTANT_LANGUAGE_TO_KOREAN: Record<
  PartnerAssistantLanguage,
  string
> = {
  ko: '한국어',
  en: '영어',
  ja: '일본어',
  th: '태국어',
  vi: '베트남어',
  zh: '중국어',
};
