export enum AIDesignAssetStatus {
  Usable = 'usable',
  UnUsable = 'unUsable',
}

export enum AIDesignAssetTranslations {
  usable = '사용가능',
  unUsable = '사용대기',
}

export const AI_DESIGN_ASSET_STATUS_OPTIONS = [
  {
    label: AIDesignAssetTranslations[AIDesignAssetStatus.UnUsable],
    value: AIDesignAssetStatus.UnUsable,
  },
  {
    label: AIDesignAssetTranslations[AIDesignAssetStatus.Usable],
    value: AIDesignAssetStatus.Usable,
  },
];

export const AI_DESIGN_ASSET_DEFAULT_DISPLAY_CHANNEL_OPTIONS = [
  {
    label: '네이버 블로그',
    value: '네이버 블로그',
  },
  {
    label: '인스타그램',
    value: '인스타그램',
  },
  {
    label: '페이스북',
    value: '페이스북',
  },
  {
    label: '카카오플친',
    value: '카카오플친',
  },
  {
    label: '원내출력물',
    value: '원내출력물',
  },
  {
    label: '기타',
    value: '기타',
  },
];

export const AI_DESIGN_ASSET_DEFAULT_CATEGORY_OPTIONS = [
  {
    label: '인물',
    value: '인물',
  },
  {
    label: '장비',
    value: '장비',
  },
  {
    label: '배경 이미지',
    value: '배경 이미지',
  },
  {
    label: '로고',
    value: '로고',
  },
  {
    label: '정형외과/의료공통',
    value: '정형외과/의료공통',
  },
  {
    label: '정형외과/질환',
    value: '정형외과/질환',
  },
];
