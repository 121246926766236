export enum BlogReferenceCategory {
  BASIC = 'BASIC',
  EXPERT = 'EXPERT',
  ETC = 'ETC',
}

export enum BlogReferenceTranslations {
  BASIC = '기본형',
  EXPERT = '전문가형',
  ETC = '기타',
}

export const BLOG_REFERENCE_CATEGORY_OPTIONS = Object.values(
  BlogReferenceCategory,
).map((value) => ({
  label: BlogReferenceTranslations[value],
  value,
}));
