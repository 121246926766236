import { Typo } from '@components/atoms';
import colorSet, { ColorType } from '@styles/colors';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

interface TooltipColorInterface {
  padding?: 12 | 20;
  icon?: ReactNode;
  text?: ReactNode;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  color?: ColorType;
  right?: ReactNode;
}

function TooltipColor({
  padding = 12,
  icon,
  text,
  borderColor,
  backgroundColor = 'yellow6',
  color,
  right,
}: TooltipColorInterface) {
  return (
    <TootipColorWrapper
      padding={padding}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      color={color}
    >
      {icon && icon}
      <TooltipText typoType="b10m" color="gray5">
        {text}
      </TooltipText>
      {right && right}
    </TootipColorWrapper>
  );
}

export default TooltipColor;

interface TooltipColorWrapperProps {
  padding: number;
  borderColor?: ColorType;
  backgroundColor: ColorType;
  color?: ColorType;
}

const TootipColorWrapper = styled.div<TooltipColorWrapperProps>`
  background-color: ${({ backgroundColor }) => `${colorSet[backgroundColor]}`};
  padding: ${({ padding }) => `${padding}px`};
  display: flex;
  border-radius: 10px;
  align-items: center;
  gap: 10px;

  ${({ color }) => color && `color: ${colorSet[color]};`}
  ${({ borderColor }) =>
    borderColor && `border: 1px solid ${colorSet[borderColor]};`}
`;

const TooltipText = styled(Typo)`
  white-space: pre-wrap;
  width: 100%;
`;
