import {
  PolotnoElement,
  PolotnoPageJSON,
} from '@pages/private/ai-design/ai-design-templates-v2/types/PolotnoPageJSON';
import { PromptResult } from '@pages/private/ai-design/ai-design-templates-v2/types/PromptResult';

export const extractBlogKeysObject = (
  elements: PolotnoElement[],
  keys: string[],
) => {
  elements.forEach((element) => {
    const { type, children = [], custom } = element;
    if (type === 'text') {
      if (custom) {
        const { blogKey } = custom;
        if (blogKey) {
          keys.push(blogKey);
        }
      }
    }
    if (type === 'group') {
      const results = extractBlogKeysObject(children, keys);
      keys.concat(results);
    }
  });

  return keys;
};

export const getAIPromptOutputStructureParamV2 = (json: PolotnoPageJSON) => {
  const results: PromptResult[] = [];
  const { pages = [] } = json;
  pages.forEach(({ custom, children = [] }) => {
    if (custom) {
      const { subject } = custom;
      if (subject) {
        const result: Record<string, string> = {};
        result.subject = subject;
        let keys: string[] = [];
        keys = extractBlogKeysObject(children, keys);
        keys.forEach((key) => {
          result[key] = '';
        });
        // @ts-ignore
        results.push({
          ...result,
        });
      }
    }
  });

  return results;
};
