import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { useNavigate } from 'react-router-dom';
import { fetchPartners } from '../../../../@apis/partners';
import { FetchPartnerListQuery } from '../../../../@types/partner/params/FetchPartnerListQuery';
import { Partner } from '../../../../@types/partner/models/Partner';

function BlogSubscriptionListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {};

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchPartnerListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'partnerName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '파트너명',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<Partner>) => {
        if (data) {
          const { partnerName, displayName } = data;
          if (displayName) return displayName;
          return partnerName;
        }
        return '-';
      },
    },
    {
      field: 'partnerCeoName',
      minWidth: 170,
      width: 170,
      headerName: '대표자명',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams<Partner>) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: FetchPartnerListQuery) => {
    const { rows, count } = await fetchPartners(query);
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: Partner) => {
    const { id } = data;
    navigate(PAGE_PATHS.BLOG_SUBSCRIPTIONS_DETAIL.replace(':id', `${id}`));
  };

  return (
    <PaginationTablePage
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default BlogSubscriptionListPage;
