import styled from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  flex: 1;
`;
export const TableLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000010;
  z-index: 1;
`;
export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
`;
