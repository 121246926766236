import React from 'react';
import { getNumberComma, getNumberNoneString } from 'utils/regex';
import { NumberRangeInputContainer } from './styles';

interface NumberRangeInputProps {
  placeholder?: string;
  minValue: string;
  onMinValueChange: (value: string) => void;
  maxValue: string;
  onMaxValueChange: (value: string) => void;
}

function NumberRangeInput(props: NumberRangeInputProps) {
  const {
    placeholder,
    minValue,
    maxValue,
    onMinValueChange,
    onMaxValueChange,
  } = props;

  return (
    <NumberRangeInputContainer>
      <input
        value={Number(minValue) ? getNumberComma(Number(minValue)) : ''}
        placeholder={placeholder || '숫자만 입력'}
        onChange={(e) => {
          onMinValueChange(getNumberNoneString(e.target.value));
        }}
        maxLength={5}
      />
      <span>-</span>
      <input
        value={Number(maxValue) ? getNumberComma(Number(maxValue)) : ''}
        placeholder={placeholder || '숫자만 입력'}
        onChange={(e) => {
          onMaxValueChange(getNumberNoneString(e.target.value));
        }}
        maxLength={5}
      />
    </NumberRangeInputContainer>
  );
}

export default NumberRangeInput;
