import React, { useCallback, useMemo } from 'react';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import {
  LLM_LANGUAGE_OPTIONS,
  colDefs,
} from '@pages/private/customer/ai-consultation-responses/datas';
import { useQuery } from 'react-query';
import { fetchAiConsultationResponses } from '@apis/ai-consultation-prompts';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchPartners } from '@apis/partners';
import { FindLLMIntentResponseTemplateListQuery } from '../../../../@types/ai-consultation-prompt/params/FindLLMIntentResponseTemplateListQuery';
import { LLMIntentResponseTemplate } from '../../../../@types/ai-consultation-prompt/models/LLMIntentResponseTemplate';
import { useIntentOptions } from './queries';

const FILTER_INITIAL_VALUES = {};

function AIConsultationDataListPage() {
  const navigate = useNavigate();

  const { data: partners = [] } = useQuery({
    queryKey: ['AIConsultationDataListPage', 'partners'],
    queryFn: () => fetchPartners({ page: 1, pageSize: 10000 }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data.rows,
    staleTime: 0,
    cacheTime: 0,
  });

  const { data: intentOptions = [] } = useIntentOptions();

  const INTENT_TO_KOREAN = useMemo(
    () =>
      intentOptions.reduce<Record<string, string>>((acc, current) => {
        acc[current.value] = current.label;
        return acc;
      }, {}),
    [intentOptions],
  );

  const partnerOptions = useMemo(() => {
    return partners.map((p) => ({
      label: p.displayName ?? p.partnerName,
      value: p.id,
    }));
  }, [partners]);

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'partnerId',
      options: partnerOptions,
      label: '파트너',
      placeholder: '파트너 선택',
    },
    {
      type: 'select',
      field: 'intent',
      options: intentOptions,
      label: '상담 데이터 유형',
      placeholder: '상담 데이터 유형 선택',
    },
    {
      type: 'select',
      field: 'language',
      options: LLM_LANGUAGE_OPTIONS,
      label: '언어',
      placeholder: '언어 선택',
    },
  ];

  const dataFetcher = useCallback(
    async (query: FindLLMIntentResponseTemplateListQuery) => {
      const { rows, count } = await fetchAiConsultationResponses({
        ...query,
      });
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: LLMIntentResponseTemplate) => {
    const { id } = data;
    navigate(
      PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_DETAIL.replace(
        ':id',
        `${id}`,
      ),
    );
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_AI_CONSULTATION_RESPONSE_ADD}>
          <Button>상담 데이터 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={colDefs(INTENT_TO_KOREAN)}
      filterInitialValues={FILTER_INITIAL_VALUES}
      filterForms={filterForms}
      onRowClick={handleTableRowClick}
    />
  );
}

export default AIConsultationDataListPage;
