export function getNumberComma(num: number) {
  const returnString = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return returnString;
}

export function getNumberNoneString(string: string, max?: number) {
  const regex = /[^0-9]/g;
  let result = string.replace(regex, '');

  if (max) {
    const number = Number(result);
    if (number > max) {
      result = max.toString();
    }
  }

  return result;
}

export function getDecimalPointNumberString(
  string: string,
  min?: number,
  max?: number,
  decimal?: number,
) {
  const regex = /[^0-9.]/g;
  let result = string.replace(regex, '');

  if (min) {
    const number = Number(result);
    if (number < min) {
      result = min.toString();
    }
  }

  if (max) {
    const number = Number(result);
    if (number > max) {
      result = max.toString();
    }
  }

  if (decimal) {
    const parts = result.split('.');
    if (parts.length > 1) {
      const integerPart = parts[0];
      const decimalPart = parts[1].slice(0, decimal); // 소수점 이하 자릿수 제한
      result = `${integerPart}.${decimalPart}`;
    }
  }

  return result;
}

export function isNotNumberExist(string: string) {
  const regex = /[^0-9]/g;
  return regex.test(string);
}

export function replaceVideoURL(string: string) {
  if (string.includes('youtu.be/')) {
    return string.replace('youtu.be/', 'https://youtube.com/embed/');
  }
  return string.replace('vimeo.com/', 'https://player.vimeo.com/video/');
}

export function isEmailExist(string: string) {
  const regex =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  return regex.test(string);
}
