import React, { useState, useRef, useLayoutEffect } from 'react';
import { Check, Input, Radio, Typo } from '@components/atoms';
import DatePicker from '@components/atoms/DatePicker';
import MultiSelect from '@components/atoms/MultiSelect';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import NumberRangeInput from '@components/atoms/NumberRangeInput';
import Select from '@components/v2/antd/data-entry/Select';
import { FilterAccordionProps } from './types';

function FilterAccordion(props: FilterAccordionProps) {
  const { onFilterValueChange, field, label, type, value, placeholder } = props;

  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const contentInnerRef = useRef<HTMLDivElement>(null);

  const handleToggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const renderFilterContent = () => {
    switch (type) {
      case 'multiSelect':
        return (
          <MultiSelect
            value={value}
            onChange={(value) => {
              if (onFilterValueChange) {
                onFilterValueChange(field, value, type);
              }
            }}
            options={(props?.options as any) || []}
            placeholder={placeholder}
            hasAllSelect
          />
        );
      case 'select': {
        return (
          <Select
            allowClear
            showSearch
            style={{ width: '100%' }}
            value={typeof value === 'boolean' ? value : value || undefined}
            onChange={(value) => {
              if (onFilterValueChange) {
                onFilterValueChange(field, value, type);
              }
            }}
            options={(props?.options as any) || []}
            placeholder={placeholder}
          />
        );
      }

      case 'text':
        return (
          <Input
            value={value}
            onChange={(e) => {
              if (onFilterValueChange) {
                onFilterValueChange(field, e.target.value, type);
              }
            }}
            placeholder={placeholder || '입력'}
          />
        );
      case 'checkbox':
        return (
          <CheckboxGroupContainer>
            {(props?.options || []).map((item) => (
              <Check
                value={item.value}
                checked={(value || []).includes(item.value)}
                onChange={(e) => {
                  let values = [];
                  if (e.target.checked) {
                    values = [...(value || []), e.target.value];
                  } else {
                    values = (value || []).filter(
                      (valueItem) => valueItem !== e.target.value,
                    );
                  }
                  if (onFilterValueChange) {
                    onFilterValueChange(field, values, type);
                  }
                }}
              >
                {item.label}
              </Check>
            ))}
          </CheckboxGroupContainer>
        );
      case 'radio':
        return (
          <RadioGroupContainer>
            {(props?.options || []).map((item) => (
              <Radio
                id={field}
                name={field}
                value={item.value}
                checked={value === item.value}
                onChange={(e) => {
                  if (onFilterValueChange) {
                    onFilterValueChange(field, e.target.value, type);
                  }
                }}
              >
                {item.label}
              </Radio>
            ))}
          </RadioGroupContainer>
        );
      case 'dateRange':
        return (
          <DateRangePickerContainer>
            <DatePicker
              placeholder="시작일 선택"
              onChange={(dateValue) => {
                // TODO 수정
                // onChange(
                //   props?.fields?.[0] || field,
                //   dateValue?.format('YYYY-MM-DD') || '',
                //   'dateRange',
                //   true,
                //   props?.fields,
                // );
              }}
              value={value?.[0] ? dayjs(value?.[0]) : undefined}
              changeOnBlur
            />
            <DatePicker
              placeholder="종료일 선택"
              value={value?.[1] ? dayjs(value?.[1]) : undefined}
              onChange={(dateValue) => {
                // TODO 수정
                // onChange(
                //   props?.fields?.[1] || field,
                //   dateValue?.format('YYYY-MM-DD') || '',
                //   'dateRange',
                //   false,
                //   props?.fields,
                // );
              }}
              changeOnBlur
            />
          </DateRangePickerContainer>
        );
      case 'numberRange':
        return (
          <NumberRangeInput
            minValue={value?.[0]}
            onMinValueChange={(changeValue) => {
              // TODO 수정
              // onChange(
              //   props?.fields?.[0] || field,
              //   changeValue,
              //   'numberRange',
              //   true,
              //   props?.fields,
              // );
            }}
            maxValue={value?.[1]}
            onMaxValueChange={(changeValue) => {
              // onChange(
              //   props?.fields?.[1] || field,
              //   changeValue,
              //   'numberRange',
              //   false,
              //   props?.fields,
              // );
            }}
          />
        );
      default:
        return '';
    }
  };

  useLayoutEffect(() => {
    if (contentInnerRef.current) {
      setHeight(contentInnerRef.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <FilterAccordionContainer>
      <FilterAccordionHeader onClick={handleToggleOpen} isOpen={open}>
        <h4>
          <Typo typoType="h11">{label}</Typo>
        </h4>
        <span className="icon" />
      </FilterAccordionHeader>
      <FilterAccordionContent isOpen={open} height={height}>
        <FilterAccordionContentInner ref={contentInnerRef}>
          {renderFilterContent()}
        </FilterAccordionContentInner>
      </FilterAccordionContent>
    </FilterAccordionContainer>
  );
}

export default FilterAccordion;

const FilterAccordionContainer = styled.div`
  margin-bottom: 20px;
`;
const FilterAccordionHeader = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 0 20px;

  > .icon {
    width: 20px;
    height: 20px;
    background: url('/assets/icons/icon-line-arrow_gray.svg') no-repeat center
      center / contain;
    transition: 0.3s;
    transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }
`;
const FilterAccordionContent = styled.div<{
  isOpen: boolean;
  height: number;
}>`
  ${({ isOpen, height }) => css`
    height: ${isOpen ? height : 0}px;
    transition: height 0.3s;
    overflow: hidden;
  `}
`;
const FilterAccordionContentInner = styled.div`
  padding: 16px 20px 4px;
`;

const DateRangePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
