export enum ReviewTarget {
  ALL = 'ALL',
  CONSULTANT = 'CONSULTANT',
  DOCTOR = 'DOCTOR',
}

export enum ReviewResponseType {
  SCORE = 'SCORE',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
}

export const ReviewTranslations: Record<string, string> = {
  ALL: '전체',
  CONSULTANT: '상담실장별',
  DOCTOR: '의사별',

  SCORE: '등급',
  CHECKBOX: '다중선택',
  TEXT: '직접입력',
};

export const REVIEW_TARGET_OPTIONS = Object.values(ReviewTarget).map(
  (value) => {
    return {
      label: ReviewTranslations[value],
      value,
    };
  },
);

export const REVIEW_RESPONSE_TYPE_OPTIONS = Object.values(
  ReviewResponseType,
).map((value) => {
  return {
    label: ReviewTranslations[value],
    value,
  };
});
