/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps, Space } from 'antd';
import { PolotnoFont } from '@pages/private/ai-design/ai-design-templates-v2/utils/polotno-font.utils';

export interface PolotnoFontSelectProps {
  fonts?: PolotnoFont[];
  placeholder?: string;
  value?: string[] | string;
  onChange?: (value: string[] | string) => void;
  mode?: 'multiple';
}

function PolotnoFontSelect(props: PolotnoFontSelectProps) {
  const { mode, onChange, value, fonts, placeholder = [] } = props;
  const [fontOptions, setFontOptions] = useState<SelectProps['options']>([]);

  useEffect(() => {
    if (fonts) {
      const options = fonts.map((value) => {
        const { fontFamily, url } = value;
        return {
          label: fontFamily,
          value: fontFamily,
          url,
        };
      });
      setFontOptions(options);
    }
  }, [fonts]);

  async function loadFont({ fontFamily, url }: PolotnoFont) {
    const fontFace = new FontFace(fontFamily, `url(${url})`);
    try {
      await fontFace.load();
      document.fonts.add(fontFace);
    } catch (e) {
      console.error(`Font ${fontFamily} failed to load`);
    }
  }

  return (
    <Select
      value={value}
      placeholder={placeholder}
      mode={mode}
      style={{
        width: '100%',
      }}
      options={fontOptions}
      onChange={(value) => {
        if (onChange) onChange(value);
      }}
      onFocus={() => {
        fontOptions?.forEach((font) => {
          const { url, value } = font;
          loadFont({
            fontFamily: value as string,
            url,
          });
        });
      }}
      optionRender={(option) => {
        const { data } = option;
        const { label } = data;
        return (
          <Space>
            <span
              style={{
                fontFamily: label,
              }}
            >
              {label}
            </span>
          </Space>
        );
      }}
    />
  );
}

export default PolotnoFontSelect;
