export enum MenuUserGroup {
  OMA = 'OMA',
  OV = 'OV',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
}

export const MENU_TRANSLATIONS: Record<string, string> = {
  OMA: 'OMA',
  OV: 'OV',
  ADMIN: '부스트리 관리자',
  PARTNER: '병원 사용자',
};

export const MENU_USER_GROUP_OPTIONS = Object.values(MenuUserGroup).map(
  (value) => {
    return {
      label: MENU_TRANSLATIONS[value],
      value,
    };
  },
);
