/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import Select from '@components/v2/antd/data-entry/Select';
import { BlogReferenceForm } from '@pages/private/ai-data/blog-references/types/BlogReferenceForm';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import Input from '@components/v2/antd/data-entry/Input';
import { fetchGlobalCatalogs } from '../../../../@apis/global-catalogs';
import { BLOG_LABEL_GRADE_OPTIONS } from '../../../../@types/blog-label/blog-label.constants';
import {
  BLOG_REFERENCE_CATEGORY_OPTIONS,
  BlogReferenceCategory,
} from '../../../../@types/blog-reference/blog-reference.constants';
import {
  createBlogReference,
  deleteBlogReference,
  fetchBlogReferenceDetail,
  updateBlogReference,
} from '../../../../@apis/blog-references';
import { CreateBlogReferenceBody } from '../../../../@types/blog-reference/params/CreateBlogReferenceBody';
import { UpdateBlogReferenceBody } from '../../../../@types/blog-reference/params/UpdateBlogReferenceBody';
import { BlogReference } from '../../../../@types/blog-reference/models/BlogReference';

const INITIAL_VALUES: BlogReferenceForm = {
  // 기본 정보
  globalCatalogIds: [],
  grade: 1,
  title: '',
  treatmentName: [],
  contents: '',
  category: BlogReferenceCategory.EXPERT,
  searchTags: [],
};

function BlogReferenceDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [globalCatalogOptions, setGlobalCatalogOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [blogReference, setBlogReference] = useState<BlogReference>();
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const formValidationSchema = yup.object({
    title: yup.string().required(),
    contents: yup.string().required(),
    globalCatalogIds: yup.array().min(1),
  });

  const formik = useFormik<BlogReferenceForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchBlogReferenceDetail(id);
      setBlogReference(row);
      const {
        globalCatalogIds,
        title,
        contents,
        treatmentName,
        category,
        grade,
        searchTags,
        // 상세 정보
      } = row;

      setFormInitialValues({
        globalCatalogIds,
        title,
        contents,
        treatmentName,
        category,
        grade,
        searchTags,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalCatalogCategoryOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogs({
        page: 1,
        pageSize: 9999,
      });
      setGlobalCatalogOptions(
        rows.map(({ globalCatalogName, id }) => ({
          label: globalCatalogName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchGlobalCatalogCategoryOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateBlogReferenceBody) => {
    try {
      await createBlogReference(body);
      showToast({
        description: '블로그 레퍼런스 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateBlogReferenceBody,
  ) => {
    try {
      await updateBlogReference(id, body);
      showToast({
        description: '블로그 레퍼런스 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteData = async (id: number | string) => {
    try {
      await deleteBlogReference(id);
      showToast({
        description: '블로그 레퍼런스 정보가 삭제되었습니다.',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '블로그 레퍼런스 정보 등록';
    let alertMessage = '작성하신 내용대로 블로그 라벨링 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '블로그 레퍼런스 정보 수정';
      alertMessage = '작성하신 내용대로 블로그 라벨링 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogReferenceForm;
    placeholder?: string;
    numberMode?: boolean;
  }) => {
    const {
      numberMode = false,
      labelName,
      required = false,
      key,
      placeholder,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        {numberMode ? (
          <InputNumber
            id={key}
            style={{
              width: '100%',
            }}
            name={key}
            min={0}
            value={formik.values[key] as number}
            onChange={(value) => {
              updateForm(key, value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        ) : (
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        )}
      </FormItem>
    );
  };

  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogReferenceForm;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderGlobalCatalogSelectForm = () => {
    return (
      <FormItem label="시술선택">
        <Select
          mode="multiple"
          style={{
            width: '100%',
          }}
          showSearch
          allowClear
          value={formik.values.globalCatalogIds || undefined}
          options={globalCatalogOptions}
          onChange={async (value, options) => {
            await formik.setFieldValue('globalCatalogIds', value);
            formik.setFieldValue(
              'treatmentName',
              options.map(
                (option: { label: string; value: any }) =>
                  option.label as string,
              ),
            );
          }}
          placeholder="시술을 선택해주세요"
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogReferenceForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
    disabled?: boolean;
  }) => {
    const {
      mode,
      labelName,
      required = false,
      key,
      placeholder,
      options,
      disabled,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          disabled={disabled}
          mode={mode}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        {id && (
          <Button
            buttonColor="red"
            onClick={() => {
              showAlert({
                size: 360,
                title: '레퍼런스 삭제',
                message:
                  '삭제하신 정보는 복구가 불가능해요.\n정말 삭제하시겠어요?',
                actions: [
                  { label: '취소' },
                  {
                    color: 'red',
                    label: '삭제',
                    onClick: async () => {
                      await deleteData(id);
                    },
                  },
                ],
              });
            }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'기본정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '레퍼런스 유형',
                required: true,
                key: 'category',
                options: BLOG_REFERENCE_CATEGORY_OPTIONS,
              })}
              {renderInputForm({
                labelName: '제목',
                required: true,
                key: 'title',
              })}
              {renderTextArea({
                labelName: '내용',
                required: true,
                key: 'contents',
              })}
              {renderGlobalCatalogSelectForm()}
              {blogReference &&
                blogReference.globalCatalogIds?.length === 0 &&
                renderSelectForm({
                  labelName: '기존 등록 시술명(참고)',
                  required: false,
                  key: 'treatmentName',
                  options: [],
                  mode: 'tags',
                  disabled: true,
                  placeholder: '레거시 데이터 참고용',
                })}
              {renderSelectForm({
                labelName: '등급',
                required: true,
                key: 'grade',
                options: BLOG_LABEL_GRADE_OPTIONS,
              })}
              {renderSelectForm({
                labelName: '검색태그',
                required: false,
                key: 'searchTags',
                options: [],
                mode: 'tags',
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default BlogReferenceDetailPage;
