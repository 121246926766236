import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import colorSet from '@styles/colors';
import { useAlertStore } from '@store/useAlertStore';
import { zIndex } from '@styles/zIndex';
import AlertPartal from './AlertPartal';
import typo from '../Typo/types';
import Button from '../Button';

function AlertDialog() {
  const {
    size,
    title,
    message,
    actions,
    onDismiss,
    onClose,
    content,
    messageContent,
    dismissable,
  } = useAlertStore();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const actionType = useMemo(() => {
    if (actions) {
      const [left, right] = actions;
      return { left, right };
    }
    return undefined;
  }, [actions]);

  const handleLeftAction = useCallback(() => {
    if (actionType?.left?.onClick) {
      actionType.left.onClick();
    }
    onDismiss?.();
  }, [actionType?.left, onDismiss]);

  const handleRightAction = useCallback(() => {
    if (actionType?.right?.onClick) {
      actionType.right.onClick();
    }
    onDismiss?.();
  }, [actionType?.right, onDismiss]);

  const handleKeyDownEnter = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key.includes('Enter')) {
        if (actionType?.right?.onClick) {
          actionType.right.onClick();
        }
      }
      onDismiss?.();
    },
    [actionType, onDismiss],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownEnter);
    return () => window.removeEventListener('keydown', handleKeyDownEnter);
  }, [handleKeyDownEnter]);

  return (
    <AlertPartal>
      <AlertDialogOverlay
        onClick={() => {
          if (dismissable) {
            onDismiss();
          }
        }}
      >
        <AlertDialogArticle
          size={size}
          role="alertdialog"
          aria-labelledby="alertDialogTitle"
          aria-describedby="alertDialogDescription"
          aria-modal="true"
          onClick={(e) => e.stopPropagation()}
        >
          {!content && (
            <CloseIcon
              onClick={() => {
                onClose?.();
                onDismiss();
              }}
            />
          )}
          {content || (
            <>
              <AlertDialogTitle id="alertDialogTitle">
                {title ?? 'title'}
              </AlertDialogTitle>
              {messageContent || (
                <AlertDialogDescription id="alertDialogDescription">
                  {message ?? 'message'}
                </AlertDialogDescription>
              )}

              <AlertButtonFooter>
                {actionType?.right ? (
                  <>
                    <LeftButton
                      buttonSize={48}
                      buttonStyle={
                        actionType?.left?.style
                          ? actionType?.left?.style
                          : 'line'
                      }
                      buttonColor={
                        actionType?.left?.color
                          ? actionType?.left?.color
                          : 'gray'
                      }
                      onClick={handleLeftAction}
                    >
                      {actionType?.left.label}
                    </LeftButton>
                    <Button
                      autoFocus
                      ref={buttonRef}
                      buttonStyle={
                        actionType?.right?.style
                          ? actionType?.right?.style
                          : 'solid'
                      }
                      buttonColor={
                        actionType?.right?.color
                          ? actionType?.right?.color
                          : 'primary'
                      }
                      buttonSize={48}
                      onClick={handleRightAction}
                    >
                      {actionType?.right.label}
                    </Button>
                  </>
                ) : (
                  <Button
                    ref={buttonRef}
                    buttonStyle={
                      actionType?.left?.style
                        ? actionType?.left?.style
                        : 'solid'
                    }
                    buttonColor={
                      actionType?.left?.color
                        ? actionType?.left?.color
                        : 'primary'
                    }
                    buttonSize={48}
                    onClick={handleLeftAction}
                  >
                    {actionType?.left.label}
                  </Button>
                )}
              </AlertButtonFooter>
            </>
          )}
        </AlertDialogArticle>
      </AlertDialogOverlay>
    </AlertPartal>
  );
}

export default AlertDialog;

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -30%) scale(0.2);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

`;

const AlertDialogOverlay = styled.div<{ zIndexCustom?: number }>`
  position: fixed;
  z-index: ${zIndex.ALERT};
  background: rgba(0, 0, 0, 0.45);
  inset: 0;
  animation: ${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const AlertDialogTitle = styled.h4`
  ${typo.h4};
  color: ${colorSet.gray2};
  margin-right: 30px;
`;

const AlertDialogDescription = styled.p`
  ${typo.b8};
  color: ${colorSet.gray5};
  white-space: pre-wrap;
`;

const AlertDialogArticle = styled.article<{ size?: number }>`
  position: relative;
  width: calc(100% - 40px);
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  padding: 24px;
  background: ${colorSet.gray13};
  box-shadow: 0px 4px 24px rgba(94, 101, 110, 0.2);
  border-radius: 16px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
  ${AlertDialogTitle} {
    padding-bottom: 8px;
  }
  ${AlertDialogDescription} {
    padding-bottom: 32px;
  }
`;

const AlertButtonFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`;

const LeftButton = styled(Button)`
  margin-right: 8px;
`;

const CloseIcon = styled.figure`
  position: absolute;
  top: 20px;
  right: 24px;
  width: 24px;
  height: 24px;
  background: url('/assets/icons/icon-close_gray2.svg') no-repeat center center /
    contain;
  &:hover {
    cursor: pointer;
  }
`;
