import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { storageKey } from '@store/types';
import { PartnerStore } from '@store/usePartnerStore/types';

const initialData = {
  partners: [],
  partnerOptions: [],
};

const usePartnerStore = create<PartnerStore>()(
  persist<PartnerStore>(
    (set) => {
      return {
        ...initialData,
        savePartners: (partners) => {
          set({
            partners,
            partnerOptions: partners.map(
              ({ id, displayName, partnerName }) => ({
                label: displayName || partnerName,
                value: id,
              }),
            ),
          });
        },
      };
    },
    {
      name: storageKey.PARTNER_STORE,
    },
  ),
);

export default usePartnerStore;
