import React, { InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import colorSet from '@styles/colors';
import Typo from '../Typo';
import { RadioSizeType } from './types';
import typo from '../Typo/types';

interface RadioItemProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  value?: string | number;
  label?: string;
  size?: RadioSizeType;
  children?: ReactNode;
  disabled?: boolean;
  width?: number;
}

/**
 * @param size 20 | 24
 */
function Radio(props: RadioItemProps) {
  const { size = 24, children, label, disabled, width, ...inputProps } = props;

  return (
    <RaioItemContainer size={Number(size)} disabled={disabled} width={width}>
      <input type={'radio'} disabled={disabled} {...inputProps} />
      <Typo typoType="b8">{children ?? label}</Typo>
    </RaioItemContainer>
  );
}

export default Radio;

const RaioItemContainer = styled.label<{
  size: number;
  disabled?: boolean;
  width?: number;
}>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ width }) => width && `min-width: ${width}px;`}

  & > input {
    flex-shrink: 0;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    appearance: none;
    transition: 0.3s ease-in-out;
    border: 1.5px solid #dcdee0;
    border-radius: 100px;
    margin: 0;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }

    &:checked {
      border: ${({ size }) => size / 4}px solid ${colorSet.gray13};
      box-shadow: 0 0 0 1px ${colorSet.primary3};
      background: ${colorSet.primary3};
    }

    &:disabled {
      border: ${({ size }) => size / 4}px solid ${colorSet.gray9};

      &:checked {
        border: ${({ size }) => size / 4}px solid ${colorSet.gray10};
        outline: 1px solid ${colorSet.gray9};
        background: ${colorSet.gray8};
      }
    }

    &:disabled {
      border: ${({ size }) => size / 4}px solid ${colorSet.gray10};
      outline: 1px solid ${colorSet.gray9};
      background: ${colorSet.gray8};
    }
  }

  & > span {
    margin-left: 8px;
    flex-shrink: 0;
    ${({ size }) => (size === 24 ? typo.b8 : typo.b11)}
  }
`;
