import React, { useCallback } from 'react';
import { ReactComponent as BANNER_SVG } from '@assets/svg/gnb/icon-banner.svg';
import { ReactComponent as BOOSTREE_SVG } from '@assets/svg/gnb/icon-boostree.svg';
import { ReactComponent as COUPON_SVG } from '@assets/svg/gnb/icon-coupon.svg';
import { ReactComponent as CS_SVG } from '@assets/svg/gnb/icon-cs.svg';
import { ReactComponent as DASHBOARD_SVG } from '@assets/svg/gnb/icon-dashboard.svg';
import { ReactComponent as EVENT_SVG } from '@assets/svg/gnb/icon-event.svg';
import { ReactComponent as INFORMATION_SVG } from '@assets/svg/gnb/icon-information.svg';
import { ReactComponent as PAGES_SVG } from '@assets/svg/gnb/icon-pages.svg';
import { ReactComponent as LINK_SVG } from '@assets/svg/gnb/icon-link.svg';
import { ReactComponent as MEMBER_SVG } from '@assets/svg/gnb/icon-member.svg';
import { ReactComponent as PRODUCT_SVG } from '@assets/svg/gnb/icon-product.svg';
import { ReactComponent as RESERVATION_SVG } from '@assets/svg/gnb/icon-reservation.svg';
import { ReactComponent as SCHEDULE_SVG } from '@assets/svg/gnb/icon-schedule.svg';
import { ReactComponent as MARKETING_SVG } from '@assets/svg/gnb/icon-marketing.svg';
import colorSet from '@styles/colors';
import styled, { css } from 'styled-components';

interface NavIconProps {
  name?: string;
  selected?: boolean;
}

function NavIcon({ name, selected }: NavIconProps) {
  const memoizedIcon = useCallback(() => {
    if (name === 'icon-banner') return <BANNER_ICON selected={selected} />;
    if (name === 'icon-boostree') return <BOOSTREE_ICON selected={selected} />;
    if (name === 'icon-coupon') return <COUPON_ICON selected={selected} />;
    if (name === 'icon-cs') return <CS_ICON selected={selected} />;
    if (name === 'icon-dashboard')
      return <DASHBOARD_ICON selected={selected} />;
    if (name === 'icon-event') return <EVENT_ICON selected={selected} />;
    if (name === 'icon-information')
      return <INFORMATION_ICON selected={selected} />;
    if (name === 'icon-pages') return <PAGES_ICON selected={selected} />;
    if (name === 'icon-link') return <LINK_ICON selected={selected} />;
    if (name === 'icon-member') return <MEMBER_ICON selected={selected} />;
    if (name === 'icon-ai-data') return <PRODUCT_ICON selected={selected} />;
    if (name === 'icon-reservation')
      return <RESERVATION_ICON selected={selected} />;
    if (name === 'icon-schedule') return <SCHEDULE_ICON selected={selected} />;
    if (name === 'icon-marketing')
      return <MARKETING_ICON selected={selected} />;
    return null;
  }, [name, selected]);

  return memoizedIcon();
}

export default NavIcon;

const iconStyle = css`
  width: 22px;
  height: 22px;
  > path {
    fill: ${colorSet.gray9};
  }
`;

const BANNER_ICON = styled(BANNER_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const BOOSTREE_ICON = styled(BOOSTREE_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const COUPON_ICON = styled(COUPON_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const CS_ICON = styled(CS_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const DASHBOARD_ICON = styled(DASHBOARD_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const EVENT_ICON = styled(EVENT_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const INFORMATION_ICON = styled(INFORMATION_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const PAGES_ICON = styled(PAGES_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const LINK_ICON = styled(LINK_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const MEMBER_ICON = styled(MEMBER_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const PRODUCT_ICON = styled(PRODUCT_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const RESERVATION_ICON = styled(RESERVATION_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const SCHEDULE_ICON = styled(SCHEDULE_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
const MARKETING_ICON = styled(MARKETING_SVG)<{ selected?: boolean }>`
  ${iconStyle}
`;
