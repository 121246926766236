import { StoreType } from 'polotno/model/store';

export const CALENDAR_ELEMENT_ID = 'calendarWrapper';

export interface PolotnoCalendarConfig {
  year?: number;
  month?: number;
  dates: { year: number; month: number; day: number }[];
  fontFamily?: string;
  fontColor?: string;
  fontSize?: number;
  koreanDayText?: boolean;
}

export const createCalendar = (
  store: StoreType,
  config: PolotnoCalendarConfig,
) => {
  // clearPrevCalendar(store);

  const {
    fontFamily = 'Pretendard Light',
    fontSize = 20,
    fontColor = '#595144',
    koreanDayText = true,
    dates,
  } = config;

  const containerWidth = 600;
  const gridSize = containerWidth / 7;

  const elementIds = [];
  if (store.activePage) {
    const page = store.activePage;
    // Container 생성
    const containerWidth = gridSize * 7;
    const containerHeight = gridSize * Math.ceil(dates.length / 7) + 25;
    const containerId = CALENDAR_ELEMENT_ID;
    elementIds.push(containerId);
    page.addElement({
      type: 'figure',
      subType: 'rect',
      id: containerId,
      width: containerWidth,
      height: containerHeight,
      x: 0,
      y: 0,
      // TEST
      fill: 'transparent',
    });

    // 요일 헤더 추가
    for (let i = 0; i < 7; i += 1) {
      const xPos = gridSize * i;
      const id = `dayText-${i}`;
      let dayText = '';
      const textInfo = DAY_TEXT_INFO[i];
      if (textInfo) {
        if (koreanDayText) {
          dayText = textInfo.ko;
        } else {
          dayText = textInfo.en;
        }
      }

      page.addElement({
        type: 'text',
        id,
        text: dayText,
        width: gridSize,
        x: xPos,
        align: 'center',
        y: 0,
        fontFamily,
        fontSize,
        fill: i === 0 ? 'red' : fontColor,
      });
      elementIds.push(id);
    }

    const coords = getPartialCalendarGrid(dates || []);
    coords.forEach(({ row, col }, index) => {
      const { day } = dates[index];
      const xPos = (col - 1) * gridSize;
      const yPos = (row - 1) * gridSize + gridSize;
      const id = `day-${index + 1}`;
      page.addElement({
        type: 'text',
        id,
        text: `${day}`,
        width: gridSize,
        x: xPos,
        align: 'center',
        y: yPos,
        fontFamily,
        fontSize,
        fill: col === 0 ? 'red' : fontColor,
      });
      elementIds.push(id);
    });
    const group = store.groupElements(elementIds);
    const groupElement = store.getElementById(group.id);
    groupElement.set({
      x: 200,
    });
    groupElement.set({ custom: { CALENDAR_ELEMENT_ID } });
  }
};

const getPartialCalendarGrid = (
  dateInfos: { year: number; month: number; day: number }[],
): { row: number; col: number }[] => {
  const grids: { row: number; col: number }[] = [];
  let currentRow = 1; // 시작 행
  let currentCol: number;

  if (dateInfos.length > 0) {
    const firstDate = new Date(
      dateInfos[0].year,
      dateInfos[0].month - 1,
      dateInfos[0].day,
    );
    currentCol = firstDate.getDay() + 1; // 첫 번째 날짜의 요일에 따라 열 결정
  }

  dateInfos.forEach(() => {
    grids.push({ row: currentRow, col: currentCol });
    currentCol += 1;
    if (currentCol > 7) {
      currentCol = 1;
      currentRow += 1;
    }
  });

  return grids;
};

const DAY_TEXT_INFO: Record<number, { ko: string; en: string }> = {
  0: {
    en: 'SUN',
    ko: '일',
  },
  1: {
    en: 'MON',
    ko: '월',
  },
  2: {
    en: 'TUE',
    ko: '화',
  },
  3: {
    en: 'WED',
    ko: '수',
  },
  4: {
    en: 'THU',
    ko: '목',
  },
  5: {
    en: 'FRI',
    ko: '금',
  },
  6: {
    en: 'SAT',
    ko: '토',
  },
};
