import React from 'react';
import DefaultLayout from '@components/organisms/DefaultLayout';
import { PAGE_PATHS } from '@routes/constants';
import { useAuthStore } from '@store/useAuthStore';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoutesGuard() {
  const { token, loginUser } = useAuthStore((state) => state);

  if (token && loginUser) {
    return (
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    );
  }
  return <Navigate to={PAGE_PATHS.LOGIN} replace />;
}

export default PrivateRoutesGuard;
