/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Input from '@components/v2/antd/data-entry/Input';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import { GlobalCatalogForm } from '@pages/private/ai-data/global-catalogs/types/GlobalCatalogForm';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import Select from '@components/v2/antd/data-entry/Select';

import InputRepeater from '@pages/private/ai-data/global-catalogs/components/InputRepeater';
import FAQRepeater from '@pages/private/ai-data/global-catalogs/components/FAQRepeater';
import Upload from '@components/v2/antd/data-entry/Upload';
import { uniq } from 'lodash';
import {
  createGlobalCatalog,
  fetchGlobalCatalogCategories,
  fetchGlobalCatalogDetail,
  fetchMedicalSubjects,
  updateGlobalCatalog,
} from '../../../../@apis/global-catalogs';
import { UpdateGlobalCatalogBody } from '../../../../@types/global-catalog/params/UpdateGlobalCatalogBody';
import { CreateGlobalCatalogBody } from '../../../../@types/global-catalog/params/CreateGlobalCatalogBody';
import { fetchGlobalThroughputItems } from '../../../../@apis/global-throughput-items';
import {
  GLOBAL_CATALOG_LANGUAGE_TYPE_OPTIONS,
  GLOBAL_CATALOG_WORK_STATUS_OPTIONS,
  GlobalCatalogLanguageType,
  GlobalCatalogWorkStatus,
} from '../../../../@types/global-catalog/constants/global-catalogs.constants';

const INITIAL_VALUES: GlobalCatalogForm = {
  // 기본 정보
  languageType: GlobalCatalogLanguageType.ko,
  globalCatalogCategoryId: undefined,
  subCategory: '',
  globalCatalogName: '',
  chineseName: '',
  englishName: '',
  vietnameseName: '',
  japaneseName: '',
  thaiName: '',
  abbreviationName: '',
  globalCatalogRequiredTime: 0,
  globalThroughputItemId: undefined,
  workStatus: GlobalCatalogWorkStatus.WAITING,
  medicalSubjectIds: [],

  // 상세 정보
  employeeDescription: '',
  descriptionImageUrls: [],
  summary: '',
  types: [],
  videos: [],
  recommendTargets: [],
  recoveryTime: '',
  period: '',
  recommendedCapacity: '',
  maintenancePeriod: '',
  needsAnesthesia: '',
  anesthesiaTime: '',
  steps: [],
  parts: [],
  partsImageUrls: [],
  effects: '',
  faqs: [],
  notices: [],
  reservationSlotTime: 0,
  advantages: [],
  mechanism: '',
  duration: '',
  onsetTime: '',
  thumbnailImageUrls: [],

  // 검색 및 태그
  searchTags: [],
  similarTags: [],
  similarGlobalCatalogs: [],
  relatedRecommendGlobalCatalogs: [],

  // 질환 정보
  symptom: '',
  causation: '',
  diagnosis: '',
  treatmentMethod: '',
  diseaseProgression: '',
  prevention: '',
  diagnosticMethods: '', // 검사 방법
  selfDiagnosis: '', // 자가 진단법
  postOperativeCare: '', // 수술 후 관리

  // AI 관련 정보
  aiCondition: [],
  aiDiagnosis: [],
  aiTreatment: [],
  aiEffect: [],
  aiSearchTags: [],
  aiHiddenTags: [],
};

function GlobalCatalogDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [medicalSubjects, setMedicalSubjects] = useState<
    { label: string; value: any }[]
  >([]);
  const [globalThroughputItemOptions, setGlobalThroughputItemOptions] =
    useState<{ label: string; value: any }[]>([]);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    globalCatalogName: yup.string().required(),
    globalCatalogCategoryId: yup.number().required(),
    globalCatalogRequiredTime: yup.number().required(),
    medicalSubjectIds: yup.array().required(),
  });

  const formik = useFormik<GlobalCatalogForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchGlobalCatalogDetail(id);
      const {
        // 기본정보
        languageType,
        globalCatalogCategory,
        subCategory,
        globalCatalogName,
        chineseName,
        englishName,
        vietnameseName,
        japaneseName,
        thaiName,
        abbreviationName,
        globalCatalogRequiredTime,
        globalThroughputItem,
        workStatus,
        medicalSubjects,
        // 상세 정보
        employeeDescription,
        descriptionImageUrls,
        summary,
        types,
        videos,
        recommendTargets,
        recoveryTime,
        period,
        recommendedCapacity,
        maintenancePeriod,
        needsAnesthesia,
        anesthesiaTime,
        steps,
        parts,
        partsImageUrls,
        effects,
        faqs,
        notices,
        reservationSlotTime,
        advantages,
        mechanism,
        duration,
        onsetTime,
        thumbnailImageUrls,
        // 검색 및 태그
        searchTags,
        similarTags,
        similarGlobalCatalogs,
        relatedRecommendGlobalCatalogs,
        // 질환 정보
        symptom,
        causation,
        diagnosis,
        treatmentMethod,
        diseaseProgression,
        prevention,
        diagnosticMethods, // 검사 방법
        selfDiagnosis, // 자가 진단법
        postOperativeCare, // 수술 후 관리
        // AI 관련 정보
        aiCondition,
        aiDiagnosis,
        aiTreatment,
        aiEffect,
        aiSearchTags,
        aiHiddenTags,
      } = row;
      let globalCatalogCategoryId;
      if (globalCatalogCategory) {
        const { id } = globalCatalogCategory;
        globalCatalogCategoryId = id;
      }

      let globalThroughputItemId;
      if (globalThroughputItem) {
        const { id } = globalThroughputItem;
        globalThroughputItemId = id;
      }

      const medicalSubjectIds: number[] = [];
      if (medicalSubjects?.length) {
        medicalSubjects.forEach((subject) => {
          medicalSubjectIds.push(subject.id);
        });
      }

      setFormInitialValues({
        // 기본정보
        languageType,
        globalCatalogCategoryId,
        subCategory,
        globalCatalogName,
        medicalSubjectIds,
        chineseName,
        englishName,
        vietnameseName,
        japaneseName,
        thaiName,
        abbreviationName,
        globalCatalogRequiredTime,
        globalThroughputItemId,
        workStatus,
        // 상세정보
        employeeDescription,
        descriptionImageUrls,
        types,
        summary,
        videos,
        recommendTargets,
        recoveryTime,
        period,
        recommendedCapacity,
        maintenancePeriod,
        needsAnesthesia,
        anesthesiaTime,
        steps,
        parts,
        partsImageUrls,
        effects,
        faqs,
        notices,
        reservationSlotTime,
        advantages,
        mechanism,
        duration,
        onsetTime,
        thumbnailImageUrls,
        // 검색 및 태그
        searchTags,
        similarTags,
        similarGlobalCatalogs,
        relatedRecommendGlobalCatalogs,
        // 질환 정보
        symptom,
        causation,
        diagnosis,
        treatmentMethod,
        diseaseProgression,
        prevention,
        diagnosticMethods, // 검사 방법
        selfDiagnosis, // 자가 진단법
        postOperativeCare, // 수술 후 관리
        // AI 관련 정보
        aiCondition,
        aiDiagnosis,
        aiTreatment,
        aiEffect,
        aiSearchTags,
        aiHiddenTags,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalCatalogCategoryOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogCategories();
      setCategoryOptions(
        rows.map(({ globalCatalogCategoryName, id }) => ({
          label: globalCatalogCategoryName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchMedicalSubjectOptions = useCallback(async () => {
    try {
      const { rows } = await fetchMedicalSubjects();
      setMedicalSubjects(
        rows.map(({ subjectName, id }) => ({
          label: subjectName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalThroughputItemOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalThroughputItems({
        page: 1,
        pageSize: 2000,
      });
      setGlobalThroughputItemOptions(
        rows.map(({ throughputItemName, id }) => ({
          label: throughputItemName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchMedicalSubjectOptions();
      await fetchGlobalCatalogCategoryOptions();
      await fetchGlobalThroughputItemOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateGlobalCatalogBody) => {
    try {
      await createGlobalCatalog(body);
      showToast({
        description: '시술 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateGlobalCatalogBody,
  ) => {
    try {
      await updateGlobalCatalog(id, body);
      showToast({
        description: '시술 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '시술 정보 등록';
    let alertMessage = '작성하신 내용대로 시술 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '시술 정보 수정';
      alertMessage = '작성하신 내용대로 시술 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderUpload = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
  }) => {
    const { labelName, required = false, key } = info;
    let values: string[] = [];
    if (Array.isArray(formik.values[key])) {
      values = formik.values[key] as string[];
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Upload
          initialImageUrls={values}
          onImageDataChange={(imageUrls: string[]) => {
            formik.setFieldValue(key, imageUrls);
          }}
        />
      </FormItem>
    );
  };

  const renderTagInput = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
    placeholder?: string;
    separateWithSpaceAndComma?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      separateWithSpaceAndComma = false,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let tokenSeparators: string[] | undefined;
    if (separateWithSpaceAndComma) {
      tokenSeparators = [',', ' '];
    }

    let values = formik.values[key] || undefined;
    if (values) {
      values = uniq(values as string[]);
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={tokenSeparators}
          showSearch
          value={values}
          onChange={(values) => {
            updateForm(key, uniq(values));
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderFAQRepeater = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
  }) => {
    const { labelName, required = false, key } = info;
    return (
      <FormItem label={labelName} optional={!required}>
        <FAQRepeater
          label={labelName}
          initialValues={formik.values[key] as { Q: string; A: string }[]}
          onChange={(values) => {
            formik.setFieldValue(key, values);
          }}
        />
      </FormItem>
    );
  };

  const renderInputRepeater = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
  }) => {
    const { labelName, required = false, key } = info;
    return (
      <FormItem label={labelName} optional={!required}>
        <InputRepeater
          label={labelName}
          initialValues={formik.values[key] as string[]}
          onChange={(values) => {
            formik.setFieldValue(key, values);
          }}
        />
      </FormItem>
    );
  };

  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, placeholder, options } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          allowClear
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderMultiSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, placeholder, options } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let tokenSeparators: string[] | undefined;

    let values = formik.values[key] || undefined;
    if (values) {
      values = uniq(values as string[]);
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={tokenSeparators}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(values) => {
            updateForm(key, uniq(values));
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalCatalogForm;
    placeholder?: string;
    numberMode?: boolean;
  }) => {
    const {
      numberMode = false,
      labelName,
      required = false,
      key,
      placeholder,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        {numberMode ? (
          <InputNumber
            id={key}
            style={{
              width: '100%',
            }}
            name={key}
            min={0}
            value={formik.values[key] as number}
            onChange={(value) => {
              updateForm(key, value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        ) : (
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
          />
        )}
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;
    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'시술 기본정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '언어',
                required: true,
                key: 'languageType',
                options: GLOBAL_CATALOG_LANGUAGE_TYPE_OPTIONS,
              })}
              {renderSelectForm({
                labelName: '시술 카테고리',
                required: true,
                key: 'globalCatalogCategoryId',
                options: categoryOptions,
              })}
              {renderInputForm({
                labelName: '시술 2차 카테고리',
                key: 'subCategory',
              })}
              {renderInputForm({
                labelName: '시술명',
                required: true,
                key: 'globalCatalogName',
              })}
              {renderMultiSelectForm({
                labelName: '의료과목',
                required: true,
                key: 'medicalSubjectIds',
                options: medicalSubjects,
              })}

              {renderSelectForm({
                labelName: '검수상태',
                key: 'workStatus',
                options: GLOBAL_CATALOG_WORK_STATUS_OPTIONS,
              })}
              {renderInputForm({
                labelName: '시술명(영문)',
                key: 'englishName',
              })}
              {renderInputForm({
                labelName: '시술명(중문)',
                key: 'chineseName',
              })}
              {renderInputForm({
                labelName: '시술명(일문)',
                key: 'japaneseName',
              })}
              {renderInputForm({
                labelName: '시술명(베트남)',
                key: 'vietnameseName',
              })}
              {renderInputForm({
                labelName: '시술명(태국)',
                key: 'thaiName',
              })}
              {renderInputForm({
                labelName: '시술 축약어',
                key: 'abbreviationName',
              })}
              {renderInputForm({
                labelName: '시술시간(분)',
                required: true,
                key: 'globalCatalogRequiredTime',
                numberMode: true,
              })}
              {renderSelectForm({
                labelName: '장비정보',
                key: 'globalThroughputItemId',
                options: globalThroughputItemOptions,
              })}
            </FormLayout>
          </Card>
          {/* 시술 상세 정보 */}
          <Card title={'시술 상세정보'}>
            <FormLayout>
              {renderTextArea({
                labelName: '시술설명(직원용)',
                key: 'employeeDescription',
              })}

              {renderTextArea({
                labelName: '시술정보요약',
                key: 'summary',
              })}
              {renderInputRepeater({
                labelName: '시술유형',
                key: 'types',
              })}
              {renderInputRepeater({
                labelName: '시술추천대상',
                key: 'recommendTargets',
              })}
              {renderInputForm({
                labelName: '시술회복시간',
                key: 'recoveryTime',
              })}
              {renderInputForm({
                labelName: '시술주기',
                key: 'period',
              })}
              {renderInputForm({
                labelName: '시술권장용량/횟수',
                key: 'recommendedCapacity',
              })}
              {renderInputForm({
                labelName: '시술유지기간',
                key: 'maintenancePeriod',
              })}
              {renderInputForm({
                labelName: '마취필요여부',
                key: 'needsAnesthesia',
              })}
              {renderInputForm({
                labelName: '마취시간',
                key: 'anesthesiaTime',
              })}
              {renderInputRepeater({
                labelName: '시술단계',
                key: 'steps',
              })}
              {renderInputRepeater({
                labelName: '시술부위',
                key: 'parts',
              })}

              {renderTextArea({
                labelName: '시술효과',
                key: 'effects',
              })}
              {renderFAQRepeater({
                labelName: 'FAQ',
                key: 'faqs',
              })}
              {renderInputRepeater({
                labelName: '시술주의사항',
                key: 'notices',
              })}
              {renderInputForm({
                labelName: '예약슬롯시간(분)',
                key: 'reservationSlotTime',
                numberMode: true,
              })}
              {renderInputRepeater({
                labelName: '시술특장점',
                key: 'advantages',
              })}
              {renderTextArea({
                labelName: '시술원리',
                key: 'mechanism',
              })}
              {renderInputForm({
                labelName: '시술시간안내',
                key: 'duration',
              })}
              {renderInputForm({
                labelName: '시술발현시기',
                key: 'onsetTime',
              })}
            </FormLayout>
          </Card>
          <Card title="시술 관련 이미지 및 영상">
            <FormLayout>
              {renderUpload({
                labelName: '시술 설명 이미지',
                key: 'descriptionImageUrls',
              })}
              {renderUpload({
                labelName: '시술 부위 이미지',
                key: 'partsImageUrls',
              })}
              {renderUpload({
                labelName: '상품 썸네일 샘플',
                key: 'thumbnailImageUrls',
              })}
              {/*{renderUpload({*/}
              {/*  labelName: '시술 영상',*/}
              {/*  key: 'videos',*/}
              {/*})}*/}
            </FormLayout>
          </Card>
          {/* 질환 정보 */}
          <Card title={'질환 정보'}>
            <FormLayout>
              {renderTextArea({
                labelName: '증상',
                key: 'symptom',
              })}
              {renderTextArea({
                labelName: '원인',
                key: 'causation',
              })}
              {renderTextArea({
                labelName: '진단',
                key: 'diagnosis',
              })}
              {renderTextArea({
                labelName: '검사 방법',
                key: 'diagnosticMethods',
              })}
              {renderTextArea({
                labelName: '자가진단법',
                key: 'selfDiagnosis',
              })}
              {renderTextArea({
                labelName: '치료법',
                key: 'treatmentMethod',
              })}
              {renderTextArea({
                labelName: '질환경과',
                key: 'diseaseProgression',
              })}
              {renderTextArea({
                labelName: '예방법',
                key: 'prevention',
              })}
              {renderTextArea({
                labelName: '수술 후 관리',
                key: 'postOperativeCare',
              })}
            </FormLayout>
          </Card>
          {/* 검색 및 태그 */}
          <Card title={'검색 및 태그'}>
            <FormLayout>
              {renderTagInput({
                labelName: '검색태그',
                key: 'searchTags',
              })}
              {renderTagInput({
                labelName: '유사태그',
                key: 'similarTags',
              })}
              {renderTagInput({
                labelName: '연관시술',
                key: 'similarGlobalCatalogs',
              })}
              {renderTagInput({
                labelName: '연관추천시술',
                key: 'relatedRecommendGlobalCatalogs',
              })}
            </FormLayout>
          </Card>
          {/* AI 관련 정보 */}
          <Card title={'AI 관련 정보'}>
            <FormLayout>
              {renderTagInput({
                labelName: '환자상태',
                key: 'aiCondition',
                separateWithSpaceAndComma: true,
              })}
              {renderTagInput({
                labelName: '진단',
                key: 'aiDiagnosis',
                separateWithSpaceAndComma: true,
              })}
              {renderTagInput({
                labelName: '치료법',
                key: 'aiTreatment',
                separateWithSpaceAndComma: true,
              })}
              {renderTagInput({
                labelName: '예후',
                key: 'aiEffect',
                separateWithSpaceAndComma: true,
              })}
              {/*{renderTagInput({*/}
              {/*  labelName: 'AI 검색',*/}
              {/*  key: 'aiSearchTags',*/}
              {/*})}*/}
              {/*{renderTagInput({*/}
              {/*  labelName: 'AI 숨김 태그',*/}
              {/*  key: 'aiHiddenTags',*/}
              {/*})}*/}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default GlobalCatalogDetailPage;
