import React, { useEffect, useRef, useState } from 'react';
import {
  TabContainer,
  TabContent,
  TabContentInner,
  TabTrigger,
} from './styles';
import { TabProps } from './types';

function Tab(props: TabProps) {
  // props
  const { defaultOpen, triggerElement, contentElement } = props;

  // ref
  const contentRef = useRef<HTMLDivElement>(null);

  // state
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [contentHeight, setContentHeight] = useState(0);

  // event
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      const { height } = contentRef.current.getBoundingClientRect();

      setContentHeight(height);
    }
  }, []);

  return (
    <TabContainer isOpened={isOpen} height={contentHeight}>
      <TabTrigger onClick={handleToggleOpen}>
        {triggerElement(isOpen)}
      </TabTrigger>
      <TabContent>
        <TabContentInner ref={contentRef}>{contentElement}</TabContentInner>
      </TabContent>
    </TabContainer>
  );
}

export default Tab;
