/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { usePartnerStore } from '@store/usePartnerStore';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_PATHS } from '@routes/constants';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Input from '@components/v2/antd/data-entry/Input';
import Select from '@components/v2/antd/data-entry/Select';
import { Empty, Image, Spin } from 'antd';
import Typo from '@components/atoms/Typo';
import colorSet from '@styles/colors';
import dayjs from 'dayjs';
import TemplateEditorModal from '@pages/private/ai-design/ai-design-templates-v2/components-v2/TemplateEditorModal';
import { createStore } from 'polotno/model/store';
import { isArray } from 'lodash';
import Workspace from 'polotno/canvas/workspace';
import { getAIPromptOutputStructureParamV2 } from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-template.utils';
import { PromptResult } from '@pages/private/ai-design/ai-design-templates-v2/types/PromptResult';
import {
  AI_DESIGN_TEMPLATE_SIZE_TYPE_OPTIONS,
  AI_DESIGN_TEMPLATE_TYPE_OPTIONS,
  AI_DESIGN_WORK_STATUS_OPTIONS,
  AiDesignTemplateSizeType,
  AiDesignTemplateType,
  AiDesignTemplateWorkStatus,
} from '../../../../@types/ai-design-template/constants/ai-design.constants';
import {
  createAIDesignTemplate,
  fetchAIDesignTemplateDetail,
  updateAIDesignTemplate,
} from '../../../../@apis/ai-design-templates';
import { CreateAIDesignTemplateBody } from '../../../../@types/ai-design-template/params/CreateAIDesignTemplateBody';
import { ColorTheme } from '../../../../@types/ai-design-template/models/ColorTheme';
import { AIDesignTemplate } from '../../../../@types/ai-design-template/models/AIDesignTemplate';

type AiDesignTemplateFormType = CreateAIDesignTemplateBody & {
  sceneJsonString?: string;
};

function AIDesignTemplateDetailV2Page() {
  const INITIAL_VALUES: AiDesignTemplateFormType = {
    title: `새 템플릿_${dayjs().format('YYMMDDHHmm')}`,
    templateType: AiDesignTemplateType.NaverBlog,
    sizeType: AiDesignTemplateSizeType.NaverBlog,
    categories: [],
    workStatus: AiDesignTemplateWorkStatus.WorkReady,
    partnerIds: [],
    sceneJsonString: undefined,
  };

  const { partnerOptions } = usePartnerStore();
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [editorModalVisible, setEditorModalVisible] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [generatingImage, setGeneratingImage] = useState(false);
  const [templateEdited, setTemplateEdited] = useState(false);

  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState<AIDesignTemplate>();

  const store = useMemo(
    () =>
      createStore({
        key: process.env.REACT_APP_POLOTNO_KEY || '',
        showCredit: true,
      }),
    [],
  );

  const formValidationSchema = yup.object({
    title: yup.string().required('템플릿명을 입력해주세요'),
  });

  const formik = useFormik<AiDesignTemplateFormType>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, values, templateEdited ? thumbnailUrl : undefined);
      } else {
        await addData(values, thumbnailUrl);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      setDataFetching(true);
      const { row } = await fetchAIDesignTemplateDetail(id);
      setTemplate(row);
      const {
        categories,
        title,
        templateType,
        workStatus,
        partnerIds = [],
        thumbnailUrl,
        templateSceneJson,
        sizeType,
      } = row;

      let sceneJsonString: string | undefined;
      if (templateSceneJson) {
        try {
          sceneJsonString = JSON.stringify(templateSceneJson);
        } catch (e) {
          console.warn(e);
        }
      }
      setThumbnailUrl(thumbnailUrl);

      setFormInitialValues({
        categories,
        title,
        templateType,
        workStatus,
        partnerIds,
        sceneJsonString,
        sizeType,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      (async function fetch() {
        await fetchDetailData(id);
      })();
    }
  }, [id, fetchDetailData]);

  useEffect(() => {
    if (formik.values.sceneJsonString) {
      updatePolotnoPreviewImage(formik.values.sceneJsonString);
    }
  }, [formik.values.sceneJsonString]);

  const createThumbnailUrl = async (jsonString: string) => {
    try {
      const json = JSON.parse(jsonString);
      store.loadJSON(json);
      await store.waitLoading();
      if (isArray(json.pages) && json.pages.length > 0) {
        const [firstPage] = json.pages;
        const { id } = firstPage;
        return await store.toDataURL({ pageId: id });
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const updatePolotnoPreviewImage = (jsonString: string) => {
    setGeneratingImage(true);
    try {
      const json = JSON.parse(jsonString);
      store.loadJSON(json);
      store.waitLoading().then(() => {
        if (isArray(json.pages)) {
          if (json.pages.length > 0) {
            const [firstPage] = json.pages;
            const { id } = firstPage;
            store.toDataURL({ pageId: id }).then((url) => {
              setThumbnailUrl(url);
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
      setThumbnailUrl(undefined);
    } finally {
      setGeneratingImage(false);
    }
  };

  const addData = async (
    body: AiDesignTemplateFormType,
    thumbnailUrl?: string,
    colorTheme?: ColorTheme,
    randomMainFontFamilies?: string[],
    randomSubFontFamilies?: string[],
    randomImageUrls?: string[],
  ) => {
    try {
      const { sceneJsonString, ...rest } = body;
      let thumbnailBlob: Blob | undefined;

      if (thumbnailUrl) {
        const res = await fetch(thumbnailUrl);
        thumbnailBlob = await res.blob();
      }

      let templateSceneJson;
      if (sceneJsonString) {
        templateSceneJson = JSON.parse(sceneJsonString);
      }

      let aiPromptOutputStructure: PromptResult[] = [];
      if (templateSceneJson) {
        aiPromptOutputStructure =
          getAIPromptOutputStructureParamV2(templateSceneJson);
      }

      const { row } = await createAIDesignTemplate({
        templateSceneJson,
        templateThumbnailImage: thumbnailBlob,
        aiPromptOutputStructure,
        ...rest,
        colorTheme,
        randomMainFontFamilies,
        randomSubFontFamilies,
        randomImageUrls,
      });
      showToast({
        description: '템플릿이 등록되었어요',
        status: 'Primary',
      });
      navigate(
        PAGE_PATHS.AI_DESIGNER_TEMPLATE_DETAIL_V2.replace(':id', `${row.id}`),
        {
          replace: true,
        },
      );
      await fetchDetailData(row.id);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: AiDesignTemplateFormType,
    thumbnailUrl?: string,
    colorTheme?: ColorTheme,
    randomMainFontFamilies?: string[],
    randomSubFontFamilies?: string[],
    randomImageUrls?: string[],
  ) => {
    try {
      const { sceneJsonString, ...rest } = body;
      let thumbnailBlob: Blob | undefined;

      if (thumbnailUrl) {
        const res = await fetch(thumbnailUrl);
        thumbnailBlob = await res.blob();
        console.log('THUMB_BLOB', thumbnailBlob);
      }

      let templateSceneJson;
      if (sceneJsonString) {
        templateSceneJson = JSON.parse(sceneJsonString);
      }
      let aiPromptOutputStructure: PromptResult[] = [];
      if (templateSceneJson) {
        aiPromptOutputStructure =
          getAIPromptOutputStructureParamV2(templateSceneJson);
      }

      await updateAIDesignTemplate(id, {
        templateSceneJson,
        templateThumbnailImage: thumbnailBlob,
        aiPromptOutputStructure,
        ...rest,
        colorTheme,
        randomMainFontFamilies,
        randomSubFontFamilies,
        randomImageUrls,
      });
      showToast({
        description: '템플릿 정보가 수정되었어요',
        status: 'Primary',
      });
      await fetchDetailData(id);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '템플릿 등록';
    let alertMessage = '작성하신 내용대로 템플릿을 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '템플릿 수정';
      alertMessage = '작성하신 내용대로 템플릿 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;
    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              <FormItem label="템플릿 명">
                <Input
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={(e) => {
                    updateForm('title', e.target.value);
                  }}
                  placeholder="템플릿명을 입력해주세요"
                />
              </FormItem>
              <FormItem label="컨텐츠 유형">
                <Select
                  style={{
                    width: '100%',
                  }}
                  value={formik.values.templateType}
                  options={AI_DESIGN_TEMPLATE_TYPE_OPTIONS}
                  onChange={(value) => {
                    updateForm('templateType', value);
                  }}
                  placeholder="컨텐츠 유형을 선택해주세요"
                />
              </FormItem>
              <FormItem label="배포채널(사이즈별)">
                <Select
                  style={{
                    width: '100%',
                  }}
                  value={formik.values.sizeType}
                  options={AI_DESIGN_TEMPLATE_SIZE_TYPE_OPTIONS}
                  onChange={(value) => {
                    updateForm('sizeType', value);
                  }}
                  placeholder="컨텐츠 유형을 선택해주세요"
                />
              </FormItem>
              <FormItem label="작업상태">
                <Select
                  style={{
                    width: '100%',
                  }}
                  value={formik.values.workStatus}
                  options={AI_DESIGN_WORK_STATUS_OPTIONS}
                  onChange={(value) => {
                    updateForm('workStatus', value);
                  }}
                  placeholder="작업상태를 선택해주세요"
                />
              </FormItem>
              <FormItem label="검색태그">
                <Select
                  style={{
                    width: '100%',
                  }}
                  mode="tags"
                  value={formik.values.categories}
                  onChange={(values) => {
                    updateForm('categories', values);
                  }}
                  placeholder="검색태그를 입력해주세요 (블로그, 이벤트)"
                />
              </FormItem>
              <FormItem label="사용가능 병원">
                <Select
                  style={{
                    width: '100%',
                  }}
                  mode="multiple"
                  value={formik.values.partnerIds}
                  options={partnerOptions}
                  onChange={(value) => {
                    updateForm('partnerIds', value);
                  }}
                  placeholder="사용가능한 병원을 선택해주세요 (미선택시 전체)"
                />
              </FormItem>
            </FormLayout>
          </Card>
          <Card title={'디자인정보'}>
            <FormLayout>
              {!formik.values.sceneJsonString && (
                <Empty
                  description={
                    <Typo typoType="b10">디자인 템플릿을 등록해주세요</Typo>
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditorModalVisible(true);
                      }}
                    >
                      에디터 열기
                    </Button>
                  </div>
                </Empty>
              )}

              {formik.values.sceneJsonString && (
                <FormItem label={'템플릿 수정'}>
                  <Spin spinning={generatingImage} tip="이미지를 생성중..">
                    <Image
                      style={{
                        border: `1px solid ${colorSet.gray9}`,
                      }}
                      width={200}
                      src={thumbnailUrl}
                      alt="썸네일"
                      preview={{
                        visible: false,
                        onVisibleChange: (value) => {
                          setEditorModalVisible(value);
                        },
                      }}
                    />
                  </Spin>
                </FormItem>
              )}
            </FormLayout>
          </Card>
        </FormSectionLayout>
        <div
          style={{
            display: 'none',
          }}
        >
          <Workspace store={store} />
        </div>
      </form>
      {editorModalVisible && (
        <TemplateEditorModal
          colorTheme={template?.colorTheme}
          initialData={formik.values.sceneJsonString}
          onClose={() => {
            setEditorModalVisible(false);
          }}
          templateType={formik.values.templateType}
          randomSubFontFamilies={template?.randomSubFontFamilies}
          randomMainFontFamilies={template?.randomMainFontFamilies}
          randomImageUrls={template?.randomImageUrls}
          onDataChange={async (
            jsonString: string,
            colorTheme,
            randomMainFontFamilies,
            randomSubFontFamilies,
            randomImageUrls,
          ) => {
            // await formik.setFieldValue('sceneJsonString', jsonString);
            setTemplateEdited(true);
            const thumbnailUrl = await createThumbnailUrl(jsonString);
            if (id) {
              await updateData(
                id,
                {
                  ...formik.values,
                  sceneJsonString: jsonString,
                },
                thumbnailUrl,
                colorTheme,
                randomMainFontFamilies,
                randomSubFontFamilies,
                randomImageUrls,
              );
            } else {
              await addData(
                {
                  ...formik.values,
                  sceneJsonString: jsonString,
                },
                thumbnailUrl,
                colorTheme,
                randomMainFontFamilies,
                randomSubFontFamilies,
                randomImageUrls,
              );
            }
          }}
        />
      )}
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default AIDesignTemplateDetailV2Page;
