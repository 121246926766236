import { Upload as AntUpload, UploadProps as AntUploadProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

export interface UploadProps extends AntUploadProps {
  folderName?: string;
  initialImageUrls?: string[];
  onImageDataChange?: (imageUrls: string[]) => void;
}
function Upload(props: UploadProps) {
  const {
    folderName = 'global-catalogs/',
    initialImageUrls,
    onImageDataChange,
    ...rest
  } = props;
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialImageUrls && initialImageUrls.length > 0 && !initialized) {
      const initialFiles = initialImageUrls.map((url: string) => {
        const file: UploadFile = {
          uid: url,
          name: url.substring(url.lastIndexOf('/') + 1),
          url,
          status: 'done',
        };
        return file;
      });
      setFiles(initialFiles);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialImageUrls]);

  const getUploadUrl = () => {
    return `${process.env.REACT_APP_API_BASE_URL}/media/big-images`;
  };

  const getExtraParams = () => {
    return {
      service: 'BOS',
      folder: folderName,
      isIncludeCdn: true,
    };
  };

  const onDataChange = (updatedFiles: UploadFile[]) => {
    if (onImageDataChange) {
      const imageUrls = updatedFiles
        .filter(({ status, url }) => {
          return status === 'done' && url;
        })
        .map((file) => {
          return file.url;
        });
      if (imageUrls.length > 0) {
        onImageDataChange(imageUrls as string[]);
      }
    }
  };

  const handleUploadChange = (info: UploadChangeParam<UploadFile>) => {
    const { fileList } = info;
    const newFileList: UploadFile[] = fileList.map((file) => {
      let updatedUrl = file.url;
      if (file.status === 'done' && file.response) {
        const { rows = [] } = file.response;
        if (rows.length > 0) {
          const [imageUrl] = rows;
          updatedUrl = imageUrl;
        }
      }
      return {
        ...file,
        url: updatedUrl,
      };
    });
    setFiles(newFileList);
    onDataChange(newFileList);
  };

  return (
    <AntUpload
      multiple
      name="image"
      accept="image/*"
      data={getExtraParams()}
      action={getUploadUrl()}
      fileList={files}
      onChange={handleUploadChange}
      listType="picture-card"
      {...rest}
    >
      <div>
        <PlusOutlined />
        <div>업로드</div>
      </div>
    </AntUpload>
  );
}

export default Upload;
