import { Radio as AntRadio, RadioChangeEvent, Space } from 'antd';
import React from 'react';

interface RadioProps {
  onChange?: (value: any) => void;
  value?: any;
  options?: { label: string | number; value: any }[];
  align?: 'horizontal' | 'vertical';
}

const Radio: React.FC<RadioProps> = (props: RadioProps) => {
  const { onChange, value, align = 'horizontal', options = [] } = props;

  const renderOptions = () => {
    return options.map(({ label, value }) => {
      return (
        <AntRadio key={label} value={value}>
          {label}
        </AntRadio>
      );
    });
  };
  return (
    <AntRadio.Group
      value={value}
      onChange={(e: RadioChangeEvent) => {
        const { target } = e;
        const { value } = target;
        if (onChange) onChange(value);
      }}
    >
      {align === 'vertical' ? (
        <Space direction="vertical">{renderOptions()}</Space>
      ) : (
        renderOptions()
      )}
    </AntRadio.Group>
  );
};

export default Radio;
