import { AppError, request, Row, Rows } from '@networks/index';

import { CreateAIDesignAssetBody } from '../@types/ai-design-asset/params/CreateAIDesignAssetBody';
import { AIDesignAsset } from '../@types/ai-design-asset/models/AIDesignAsset';
import { FetchAIDesignAssetListQuery } from '../@types/ai-design-asset/params/FetchAIDesignAssetListQuery';
import { UpdateAIDesignAssetBody } from '../@types/ai-design-asset/params/UpdateAIDesignAssetBody';

const API_ROOT = 'admin/v1/ai-design-assets';

export const createAIDesignAsset = async (body: CreateAIDesignAssetBody) => {
  try {
    const { data } = await request<Row<AIDesignAsset>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAIDesignAssets = async (
  query: FetchAIDesignAssetListQuery,
) => {
  try {
    const { data } = await request<Rows<AIDesignAsset>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAIDesignAssetDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AIDesignAsset>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateAIDesignAsset = async (
  id: number | string,
  body: UpdateAIDesignAssetBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AIDesignAsset>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
