import { Label, Typo } from '@components/atoms';
import {
  LabelColorType,
  LabelSizeType,
  LabelStyleType,
} from '@components/atoms/Label/types';
import { FontSizeType } from '@components/atoms/Typo/types';
import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';

interface TagLabelProps {
  text?: string;
  label?: string;
  subText?: string;
  title?: string;
  styleType?: LabelStyleType;
  fontType?: FontSizeType;
  labelSize?: LabelSizeType;
  style?: CSSProperties;
}

const badgeLabel = [
  {
    key: 'BEST',
    label: 'Best',
  },
  {
    key: 'NEW',
    label: 'New',
  },
  {
    key: 'HOT',
    label: 'Hot',
  },
  {
    key: 'EVENT',
    label: '특가',
  },
  {
    key: 'LAUNCHING',
    label: '런칭',
  },
  {
    key: 'DISCOUNT',
    label: '할인',
  },
  {
    key: 'RECOMMENDATION',
    label: '추천',
  },
];

enum BadgeType {
  BEST = 'BEST',
  NEW = 'NEW',
  HOT = 'HOT',
  EVENT = 'EVENT',
  LAUNCHING = 'LAUNCHING',
  DISCOUNT = 'LAUNCHING',
  RECOMMENDATION = 'RECOMMENDATION',
}

function TagLabel({
  text,
  label,
  subText,
  title,
  styleType = 'light',
  fontType = 'h9',
  labelSize = 20,
  style,
}: TagLabelProps) {
  let labelColor: LabelColorType = 'primary';
  if (label === 'Best' || label === 'BEST') {
    labelColor = 'pink';
  }
  if (label === 'New' || label === 'NEW') {
    labelColor = 'yellow';
  }
  if (label === 'Hot' || label === 'HOT') {
    labelColor = 'red';
  }
  if (label === 'Event' || label === 'EVENT') {
    labelColor = 'teal';
  }
  if (label === '런칭' || label === 'LAUNCHING') {
    labelColor = 'purple';
  }
  if (label === '할인' || label === 'DISCOUNT') {
    labelColor = 'blue';
  }
  if (label === '추천' || label === 'RECOMMENDATION') {
    labelColor = 'primary';
  }
  if (label === '숨김' || label === '품절') {
    labelColor = 'gray';
  }

  const renderLabel = (labels: string) => {
    return badgeLabel.find((item) => item.key === labels)?.label;
  };
  return (
    <LabelTextWrapper>
      <MainLabelTextWrapper>
        <TypoColumnBox>
          {title && (
            <Typo typoType="b11m" color="gray6">
              {title}
            </Typo>
          )}
          {text && (
            <Typo
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                ...style,
              }}
              typoType={fontType}
            >
              {text}
            </Typo>
          )}
        </TypoColumnBox>
        {label && (
          <Label
            sizeType={labelSize}
            style={{ cursor: 'auto' }}
            colorType={labelColor}
            styleType={styleType}
          >
            {label in BadgeType ? renderLabel(label) : label}
          </Label>
        )}
      </MainLabelTextWrapper>
      {subText && (
        <Typo
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          typoType="b12"
          color="gray7"
        >
          {subText}
        </Typo>
      )}
    </LabelTextWrapper>
  );
}

export default TagLabel;

const LabelTextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MainLabelTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TypoColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;
