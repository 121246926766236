import { AppError, request, Row, Rows } from '@networks/index';
import { GlobalPrompt } from '../@types/global-prompts/models/GlobalPrompt';
import { CreateGlobalPromptBody } from '../@types/global-prompts/params/CreateGlobalPromptBody';
import { FetchGlobalPromptListQuery } from '../@types/global-prompts/params/FetchGlobalPromptListQuery';
import { UpdateGlobalPromptBody } from '../@types/global-prompts/params/UpdateGlobalPromptBody';

const URLS = {
  GLOBAL_PROMPTS: '/admin/consultation/global/prompts',
  PROMPT_ROLES: '/admin/consultation/prompt/role',
};

export const createGlobalPrompt = async (body: CreateGlobalPromptBody) => {
  try {
    const { data } = await request<Row<GlobalPrompt>>({
      method: 'post',
      url: URLS.GLOBAL_PROMPTS,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

// export const fetchGlobalCatalogCategories = async () => {
//   try {
//     const url = `${API_ROOT}/categories`;
//     const { data } = await request<Rows<GlobalCatalogCategory>>({
//       method: 'get',
//       url,
//     });
//     return data;
//   } catch (e) {
//     throw new AppError(e);
//   }
// };

export const fetchGlobalPrompts = async (query: FetchGlobalPromptListQuery) => {
  try {
    const { data } = await request<Rows<GlobalPrompt>>({
      method: 'get',
      url: URLS.GLOBAL_PROMPTS,
      queryParams: query,
    });
    console.log(data);
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalPromptDetail = async (id: number | string) => {
  const url = `${URLS.GLOBAL_PROMPTS}/${id}`;
  try {
    const { data } = await request<Row<GlobalPrompt>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateGlobalPrompt = async (
  id: number | string,
  body: UpdateGlobalPromptBody,
) => {
  const url = `${URLS.GLOBAL_PROMPTS}/${id}`;
  try {
    const { data } = await request<void>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const deleteGlobalPrompt = async (id: number | string) => {
  const url = `${URLS.GLOBAL_PROMPTS}/${id}`;
  try {
    const { data } = await request<void>({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalPromptRoles = async () => {
  try {
    const { data } = await request<Rows<{ role: string; roleName: string }>>({
      method: 'get',
      url: URLS.PROMPT_ROLES,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
