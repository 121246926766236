import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { FetchGlobalThroughputItemListQuery } from '../../../../@types/global-throughput-item/params/FetchGlobalThroughputItemListQuery';
import { fetchGlobalThroughputItems } from '../../../../@apis/global-throughput-items';
import { GlobalThroughputItem } from '../../../../@types/global-throughput-item/models/GlobalThroughputItem';

function GlobalThroughputListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    throughputItemName: '',
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchGlobalThroughputItemListQuery> =
    {
      filterKey: 'throughputItemName',
      searchInputPlaceholder: '장비명 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'globalCatalogCategoryName',
      minWidth: 153,
      width: 153,
      headerName: '카테고리',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<GlobalThroughputItem>) => {
        const { data } = col;
        if (data) {
          const { globalCatalogCategory } = data;
          if (globalCatalogCategory) {
            const { globalCatalogCategoryName } = globalCatalogCategory;
            return globalCatalogCategoryName;
          }
        }
        return '';
      },
    },
    {
      field: 'throughputItemCode',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '장비코드',
      sortable: false,
    },
    {
      field: 'throughputItemName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '장비명',
      sortable: false,
    },
    {
      field: 'throughputItemCompany',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '제조사',
      sortable: false,
    },
    {
      field: 'throughputItemManufactureCountry',
      minWidth: 153,
      width: 153,
      headerName: '국산/수입',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchGlobalThroughputItemListQuery) => {
      const { rows, count } = await fetchGlobalThroughputItems(query);
      return {
        rows,
        count,
      };
    },

    [],
  );

  const handleTableRowClick = (data: GlobalThroughputItem) => {
    const { id } = data;
    navigate(
      PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_DETAIL.replace(':id', `${id}`),
    );
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DATA_GLOBAL_THROUGHPUT_ITEM_ADD}>
          <Button>장비 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default GlobalThroughputListPage;
