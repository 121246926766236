import React from 'react';
// @ts-ignore
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { useAlertStore } from '@store/useAlertStore';
import { PolotnoIconImage } from '@assets/svg/polotno';
import { StoreType } from 'polotno/model/store';
import { fetchAIDesignTemplates } from '../../../../../../../@apis/ai-design-templates';
import { AIDesignTemplate } from '../../../../../../../@types/ai-design-template/models/AIDesignTemplate';
import { AiDesignTemplateWorkStatus } from '../../../../../../../@types/ai-design-template/constants/ai-design.constants';

export function PolotnoTemplatesTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="템플릿" {...props}>
      <PolotnoIconImage />
    </SectionTab>
  );
}
PolotnoTemplatesTab.displayName = '';

export interface TemplatesPanelProps {
  store: StoreType;
  onTemplateChange?: (template: AIDesignTemplate) => void;
}

export const PolotnoTemplatesPanel = (props: TemplatesPanelProps) => {
  const { store, onTemplateChange } = props;
  const showAlert = useAlertStore((state) => state.show);
  // load data
  const { items, isLoading } = useInfiniteAPI({
    getAPI: () => {
      return process.env.REACT_APP_API_BASE_URL || '';
    },
    fetchFunc: async () => {
      const { rows } = await fetchAIDesignTemplates({
        page: 1,
        pageSize: 9999,
        workStatus: AiDesignTemplateWorkStatus.WorkComplete,
      });
      return rows;
    },
  });

  return (
    <div style={{ height: '100%' }}>
      <ImagesGrid
        shadowEnabled={false}
        images={items}
        // @ts-ignore
        getPreview={(item: AIDesignTemplate) => {
          return item.thumbnailUrl;
        }}
        isLoading={isLoading}
        onSelect={async (item: AIDesignTemplate) => {
          const { templateSceneJson } = item;
          if (templateSceneJson) {
            showAlert({
              size: 360,
              title: '템플릿 변경',
              message:
                '작업중이신 이미지, 텍스트 등 모든 콘텐츠가 초기화됩니다.',
              actions: [
                { label: '취소' },
                {
                  label: '변경',
                  color: 'primary',
                  onClick: () => {
                    if (onTemplateChange) {
                      onTemplateChange(item);
                    }
                  },
                },
              ],
            });
          } else {
            showAlert({
              size: 360,
              title: '구버전 템플릿',
              message: '현재 사용이 불가능한 템플릿 입니다.',
              actions: [{ label: '확인' }],
            });
          }
        }}
        rowsNumber={2}
      />
    </div>
  );
};

export const PolotnoTemplatesSection = {
  name: '템플릿',
  Tab: PolotnoTemplatesTab,
  Panel: PolotnoTemplatesPanel,
};
