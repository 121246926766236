import AppError from '@networks/AppError';
import { Rows } from '@networks/types';
import { request } from '@networks/index';
import { Address } from '../@types/common/models/Address';

export const searchAddress = async (keyword: string) => {
  try {
    const { data } = await request<Rows<Address>>({
      method: 'get',
      url: '/addresses',
      queryParams: {
        keyword,
      },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
