import { AppError, request, Row, Rows } from '@networks/index';
import { FetchPartnerListQuery } from '../@types/partner/params/FetchPartnerListQuery';
import { Partner } from '../@types/partner/models/Partner';
import { CreatePartnerBody } from '../@types/partner/params/CreatePartnerBody';
import { UpdatePartnerBody } from '../@types/partner/params/UpdatePartnerBody';

const API_ROOT = 'admin/v1/partners';

export const createPartner = async (body: CreatePartnerBody) => {
  try {
    const { data } = await request<Row<Partner>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updatePartner = async (
  id: number | string,
  body: UpdatePartnerBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Partner>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchPartners = async (query: FetchPartnerListQuery) => {
  try {
    const { data } = await request<Rows<Partner>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchPartnerDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Partner>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
