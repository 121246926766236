import React, { CSSProperties } from 'react';
import {
  Button as AntButton,
  ConfigProvider,
  ButtonProps as AntButtonProps,
} from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

interface ButtonProps extends Omit<AntButtonProps, 'size'> {
  size?: SizeType | 'tiny';
  colorType?: 'primary' | 'black' | 'grey' | 'default';
  style?: CSSProperties;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    type = 'primary',
    colorType = 'primary',
    size = 'small',
    style,
    ...rest
  } = props;

  // const defaultButtonStyle = {
  //   fontWeight: themeConstants.button.fonts.fontWeight,
  // };
  //
  // let buttonSize = size;
  // if (size === 'tiny') buttonSize = 'small';
  //
  // const getExtraButtonSizeStyle = (): CSSProperties | undefined => {
  //   // 버튼 가로 크기 테마 적용 버그로 인하여 개별설정
  //   switch (size) {
  //     case 'large':
  //       return {
  //         paddingLeft: themeConstants.button.sizes.paddingContentHorizontalLG,
  //         paddingRight: themeConstants.button.sizes.paddingContentHorizontalLG,
  //         height: themeConstants.button.sizes.controlHeightLG,
  //         fontSize: themeConstants.button.fonts.fontSizeLG,
  //       };
  //     case 'middle':
  //       return {
  //         paddingLeft: themeConstants.button.sizes.paddingContentHorizontal,
  //         paddingRight: themeConstants.button.sizes.paddingContentHorizontal,
  //         height: themeConstants.button.sizes.controlHeight,
  //         fontSize: themeConstants.button.fonts.fontSize,
  //       };
  //     case 'small':
  //       return {
  //         paddingLeft: themeConstants.button.sizes.paddingContentHorizontalSM,
  //         paddingRight: themeConstants.button.sizes.paddingContentHorizontalSM,
  //         height: themeConstants.button.sizes.controlHeightSM,
  //         fontSize: themeConstants.button.fonts.fontSizeSM,
  //       };
  //     case 'tiny':
  //       return {
  //         paddingLeft: themeConstants.button.sizes.paddingContentHorizontalXSM,
  //         paddingRight: themeConstants.button.sizes.paddingContentHorizontalXSM,
  //         height: themeConstants.button.sizes.controlHeightXSM,
  //         paddingTop: 0,
  //         paddingBottom: 0,
  //         borderRadius: themeConstants.button.sizes.borderRadiusXSM,
  //         fontSize: themeConstants.button.fonts.fontSizeXSM,
  //       };
  //     default:
  //   }
  // };
  //
  // const getExtraButtonColorTheme = () => {
  //   switch (colorType) {
  //     case 'primary':
  //       return {
  //         colorPrimary: themeConstants.button.colors.primary,
  //         colorPrimaryHover: themeConstants.button.colors.primaryHover,
  //         colorPrimaryActive: themeConstants.button.colors.primaryActive,
  //       };
  //     case 'black':
  //       return {
  //         colorPrimary: themeConstants.button.colors.black,
  //         colorPrimaryHover: themeConstants.button.colors.blackHover,
  //         colorPrimaryActive: themeConstants.button.colors.blackActive,
  //       };
  //     case 'grey':
  //       return {
  //         colorPrimary: themeConstants.button.colors.grey,
  //         colorPrimaryHover: themeConstants.button.colors.greyHover,
  //         colorPrimaryActive: themeConstants.button.colors.greyActive,
  //       };
  //     case 'default':
  //       return {
  //         colorPrimary: themeConstants.button.colors.primary,
  //         colorPrimaryHover: themeConstants.button.colors.primaryHover,
  //         colorPrimaryActive: themeConstants.button.colors.primaryActive,
  //       };
  //     default:
  //   }
  // };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // ...getExtraButtonColorTheme(),
          },
        },
      }}
    >
      <AntButton
        type={type}
        // size={buttonSize as SizeType}
        // style={{
        //   ...defaultButtonStyle,
        //   ...getExtraButtonSizeStyle(),
        //   ...style,
        // }}
        style={style}
        {...rest}
      />
    </ConfigProvider>
  );
};

export default Button;
