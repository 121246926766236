import colorSet from '@styles/colors';
import { zIndex } from '@styles/zIndex';
import React, { useCallback, useEffect, useRef } from 'react';
import { keyframes, styled } from 'styled-components';
import { useToastStore } from '@store/useToastStore';
import Typo from '../Typo';
import { ToastType } from './types';

function Toast() {
  const toastContainerRef = useRef<HTMLDivElement>(null);

  const [toastList, delay, removeTost] = useToastStore((state) => [
    state.toastList,
    state.delay,
    state.removeTost,
  ]);

  const handleRemoveToast = useCallback(
    (id: number) => {
      if (toastContainerRef.current) {
        const target = toastContainerRef.current.querySelector(
          `[data-id="${id}"]`,
        );
        if (target) {
          target.classList.add('fade-out');
          target.addEventListener('transitionend', () => {
            removeTost(id);
          });
        }
      }
    },
    [removeTost],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length > 0) {
        handleRemoveToast(toastList[0].id);
      }
    }, delay);
    if (interval && toastList.length < 1) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [toastList, handleRemoveToast, delay]);

  return (
    <ToastContainer ref={toastContainerRef}>
      {toastList.map(({ id, description, status, src }) => (
        <ToastLayout status={status} key={id} data-id={id}>
          <div>
            <figure>
              <p className="toast-info-icon">
                <ToastStatusIcon type={status} src={src} />
              </p>

              <Description typoType="b8" color="gray13">
                {description}
              </Description>
            </figure>

            <button
              aria-label="close"
              type="button"
              className="toast-close-icon"
              onClick={() => handleRemoveToast(id)}
            >
              <ToastIcon />
            </button>
          </div>
        </ToastLayout>
      ))}
    </ToastContainer>
  );
}

export default Toast;

const toastFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ToastContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 2%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  z-index: ${zIndex.TOAST};
`;

const ToastLayout = styled.div<{ status: ToastType }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  gap: 13px;
  min-width: 335px;
  width: 100%;
  height: 56px;
  animation: 0.5s ${toastFadeIn};
  transition: 0.3s;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 18px;
    transition: 0.3s;
    background-color: ${({ status }) => {
      if (status === 'Gray-Red' || status === 'Gray-Primary')
        return colorSet.toastGray;
      if (status === 'Red') return colorSet.toastRed;
      return colorSet.toastPrimary;
    }};
    backdrop-filter: blur(5px);
  }

  &.fade-out {
    height: 0;
    margin: 0;

    & > div {
      opacity: 0;
    }
  }

  figure {
    display: flex;
    align-items: center;
    gap: 13px;

    .toast-info-icon {
      flex-shrink: 0;
    }

    p {
      color: ${colorSet.gray13};
    }
  }

  .toast-close-icon {
    flex-shrink: 0;
    cursor: pointer;
    background: none;
    border: none;
  }
`;

const Description = styled(Typo)`
  max-width: 223px;
  word-break: keep-all;
  white-space: pre-line;
`;

const ToastIcon = styled.figure`
  width: 24px;
  height: 24px;
  background: url('/assets/icons/icon-close.svg') no-repeat center center /
    contain;
`;

const ToastStatusIcon = styled.figure<{ type: ToastType; src?: string }>`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: center / contain;
  background-image: ${({ type, src }) => {
    if (src) return `url('/assets/icons/${src}.svg')`;
    if (type === 'Gray-Primary')
      return `url('/assets/icons/icon-check_fill.svg')`;
    if (type === 'Gray-Red')
      return `url('/assets/icons/icon-warning_fill.svg')`;
    if (type === 'Red') return `url('/assets/icons/icon-warning.svg')`;
    return `url('/assets/icons/icon-check.svg')`;
  }};
`;
