import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { privateRoutes, publicRoutes } from '@routes';
import { Alert } from '@components/atoms';
import { useAlertStore } from '@store/useAlertStore';
import { PrivateRoutesGuard, PublicRoutesGuard } from '@routes/components';
import InitialDataLoader from '@routes/components/InitialDataLoader';
import DefaultRoute from '@routes/components/DefaultRoute';
import { setupPolotnoEditorFont } from '@pages/private/ai-design/ai-design-templates-v2/utils/polotno-font.utils';

const router = createBrowserRouter([
  {
    element: <PublicRoutesGuard />,
    children: publicRoutes.map((item) => ({
      path: item.path,
      element: item.element,
    })),
  },
  {
    element: <PrivateRoutesGuard />,
    children: privateRoutes.map((item) => ({
      path: item.path,
      element: item.element,
    })),
  },
  {
    path: '/*',
    element: <DefaultRoute />,
  },
]);

function App() {
  const [visible] = useAlertStore((state) => [state.visible]);

  useEffect(() => {
    setupPolotnoEditorFont();
  }, []);

  return (
    <>
      {visible && <Alert />}
      <InitialDataLoader>
        <RouterProvider router={router} />
      </InitialDataLoader>
    </>
  );
}

export default App;
