export enum BlogLabelWorkStatus {
  WAITING = 'WAITING', //대기중
  INSPECTING = 'INSPECTING', //검수중
  INSPECTION_COMPLETED = 'INSPECTION_COMPLETED', //검수 완료
  FINAL_COMPLETED = 'FINAL_COMPLETED', //최종검수완료
  REJECTED = 'REJECTED', //반려
}

export enum BlogLabelTranslations {
  WAITING = '대기중',
  INSPECTING = '검수중',
  INSPECTION_COMPLETED = '1차 검수완료',
  FINAL_COMPLETED = '최종 검수완료',
  REJECTED = '반려',
  GREETING = '인삿말',
  CLINIC_PHILOSOPHY = '병원 철학',
  OUTRO = '맺음말',
  DOCTOR_KNOW_HOW = '의사의 노하우',
  PATIENT_CASE = '환자 사례',
  MISCONCEPTIONS_AND_TRUTH = '오해와 진실',
  SIDE_EFFECT_AND_RESOLUTION = '부작용과 케어방법',
  TREATMENT_COMPARISON = '시술 비교',
}

export enum BlogLabelType {
  GREETING = 'GREETING',
  CLINIC_PHILOSOPHY = 'CLINIC_PHILOSOPHY',
  OUTRO = 'OUTRO',
  DOCTOR_KNOW_HOW = 'DOCTOR_KNOW_HOW',
  PATIENT_CASE = 'PATIENT_CASE',
  MISCONCEPTIONS_AND_TRUTH = 'MISCONCEPTIONS_AND_TRUTH',
  SIDE_EFFECT_AND_RESOLUTION = 'SIDE_EFFECT_AND_RESOLUTION',
  TREATMENT_COMPARISON = 'TREATMENT_COMPARISON',
}

export const BLOG_LABEL_TYPE_OPTIONS = [
  {
    label: BlogLabelTranslations[BlogLabelType.GREETING],
    value: BlogLabelType.GREETING,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.CLINIC_PHILOSOPHY],
    value: BlogLabelType.CLINIC_PHILOSOPHY,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.OUTRO],
    value: BlogLabelType.OUTRO,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.DOCTOR_KNOW_HOW],
    value: BlogLabelType.DOCTOR_KNOW_HOW,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.PATIENT_CASE],
    value: BlogLabelType.PATIENT_CASE,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.MISCONCEPTIONS_AND_TRUTH],
    value: BlogLabelType.MISCONCEPTIONS_AND_TRUTH,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.SIDE_EFFECT_AND_RESOLUTION],
    value: BlogLabelType.SIDE_EFFECT_AND_RESOLUTION,
  },
  {
    label: BlogLabelTranslations[BlogLabelType.TREATMENT_COMPARISON],
    value: BlogLabelType.TREATMENT_COMPARISON,
  },
];

export const BLOG_LABEL_WORK_STATUS_OPTIONS = [
  {
    label: BlogLabelTranslations[BlogLabelWorkStatus.WAITING],
    value: BlogLabelWorkStatus.WAITING,
  },
  {
    label: BlogLabelTranslations[BlogLabelWorkStatus.INSPECTING],
    value: BlogLabelWorkStatus.INSPECTING,
  },
  {
    label: BlogLabelTranslations[BlogLabelWorkStatus.INSPECTION_COMPLETED],
    value: BlogLabelWorkStatus.INSPECTION_COMPLETED,
  },
  {
    label: BlogLabelTranslations[BlogLabelWorkStatus.FINAL_COMPLETED],
    value: BlogLabelWorkStatus.FINAL_COMPLETED,
  },
  {
    label: BlogLabelTranslations[BlogLabelWorkStatus.REJECTED],
    value: BlogLabelWorkStatus.REJECTED,
  },
];

export const BLOG_LABEL_GRADE_OPTIONS = [
  {
    label: '상',
    value: 5,
  },
  {
    label: '중상',
    value: 4,
  },
  {
    label: '중',
    value: 3,
  },
  {
    label: '하',
    value: 1,
  },
];
