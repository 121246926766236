import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colorSet, { ColorType } from '@styles/colors';
import typo, { FontSizeType } from './types';

const Component = styled.span<{
  $typoType: FontSizeType;
  $typoColor: ColorType;
  $isLineThrough?: boolean;
  $isPreWrap?: boolean;
}>`
  ${({ $typoType }) => typo[$typoType]};
  color: ${({ $typoColor }) => colorSet[$typoColor]};
  ${({ $isPreWrap }) =>
    $isPreWrap &&
    css`
      white-space: pre-wrap;
    `}
  ${({ $isLineThrough, $typoColor }) =>
    $isLineThrough &&
    `text-decoration:line-through; text-decoration-color: ${colorSet[$typoColor]};`}
`;

interface TypoProps {
  typoType?: FontSizeType;
  color?: ColorType;
  as?: keyof HTMLElementTagNameMap;
  children?: ReactNode;
  className?: string;
  isLineThrough?: boolean;
  isPreWrap?: boolean;
  style?: CSSProperties;
}

function Typo({
  typoType = 'b6',
  as = 'span',
  color = 'gray2',
  className,
  children,
  isLineThrough,
  isPreWrap,
  style,
  ...rest
}: TypoProps) {
  return (
    <Component
      $isLineThrough={isLineThrough}
      as={as}
      $typoType={typoType}
      $typoColor={color}
      className={className}
      $isPreWrap={isPreWrap}
      {...rest}
      style={{ ...style }}
    >
      {children}
    </Component>
  );
}

export default Typo;
