import { AppError, request } from '@networks/index';
import { DeleteAdsCampaignInput } from './types';

const URL = '/admin/advertising-campaign/bulk';

export const deleteAdsCampaign = async (
  queryParams: DeleteAdsCampaignInput,
) => {
  try {
    const { status } = await request({
      method: 'delete',
      url: URL,
      queryParams,
    });
    return status;
  } catch (e) {
    throw new AppError(e);
  }
};
