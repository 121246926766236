import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxGroupProps as AntdCheckboxGroupProps } from 'antd/lib/checkbox/Group';
import styled from 'styled-components';
import { Check, CheckboxType } from '@components/atoms';

const DEFAULT_GAP = 32;

export interface CheckboxGroupProps extends AntdCheckboxGroupProps {
  items: (CheckboxProps & { label?: string; value: string })[];
  size?: number;
  gap?: number;
  checkboxGap?: number;
  checkboxType?: CheckboxType;
  selectedValue?: any;
}

function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    items,
    size,
    gap = DEFAULT_GAP,
    checkboxGap,
    checkboxType,
    selectedValue,
    value,
    ...checkboxGroupProps
  } = props;

  return (
    <StyledCheckboxGroup gap={gap} {...checkboxGroupProps}>
      {items.map((item, idx) => {
        const { value: itemValue, label, ...checkboxItemProps } = item;
        return (
          <Check
            key={idx}
            value={itemValue}
            size={size}
            gap={checkboxGap}
            checkboxType={checkboxType}
            checked={value?.includes(itemValue)}
            {...checkboxItemProps}
          >
            {label}
          </Check>
        );
      })}
    </StyledCheckboxGroup>
  );
}

const StyledCheckboxGroup = styled(Checkbox.Group)<{ gap: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => `${gap / 3}px ${gap}px`};
`;

export default CheckboxGroup;
