import styled from 'styled-components';
import { ReactComponent as ArrowSvg } from '@assets/svg/ChevronDown.svg';
import colorSet from '@styles/colors';

export const ArrowIcon = styled(ArrowSvg)<{ disabled?: boolean }>`
  flex-shrink: 0;
  display: flex;
  width: 20px;
  height: 20px;
  path {
    fill: ${({ disabled }) => (disabled ? colorSet.gray9 : colorSet.gray2)};
  }
`;
