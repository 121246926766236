import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

function AlertPartal({ children }: { children?: ReactNode }) {
  return createPortal(
    children,
    document.getElementById('alert') as HTMLElement,
  );
}

export default AlertPartal;
