import { AppError, request, Rows } from '@networks/index';
import { FetchCrmReservationListQuery } from '../@types/crm-reservation/params/FetchCrmReservationListQuery';
import { CrmReservation } from '../@types/crm-reservation/models/CrmReservation';

const API_ROOT = 'admin/v1/crm-reservations';

export const fetchCrmReservations = async (
  partnerId: number,
  query: FetchCrmReservationListQuery,
) => {
  try {
    const url = `${API_ROOT}/${partnerId}`;
    const { data } = await request<Rows<CrmReservation>>({
      method: 'get',
      url,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
