import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import { styled } from 'styled-components';
import colorSet from '@styles/colors';
import Typo from '../Typo';
import typo from '../Typo/types';

interface ValidationProps extends HTMLAttributes<HTMLDivElement> {
  validationType?: 'ERROR' | 'VALID';
  children?: ReactNode;
}

function Validation({ validationType, children, ...rest }: ValidationProps) {
  const url = useMemo(() => {
    if (validationType === 'VALID') return '/assets/icons/icon-check_fill.svg';
    return '/assets/icons/icon-warning_fill.svg';
  }, [validationType]);

  return (
    <FormValidationMessageContainer {...rest}>
      <ValidationIcon
        isError={validationType === 'ERROR'}
        src={url}
        alt="validtaion"
      />
      <ValidationText isError={validationType === 'ERROR'}>
        {children}
      </ValidationText>
    </FormValidationMessageContainer>
  );
}

export default Validation;

const FormValidationMessageContainer = styled.span`
  display: flex;
  /* align-items: center; */
`;

const ValidationIcon = styled.img<{ isError?: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const ValidationText = styled(Typo)<{ isError?: boolean }>`
  ${typo.b11}
  color: ${({ isError }) => (isError ? colorSet.red3 : colorSet.primary2)};
`;
