import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import { useFormik } from 'formik';
import { BlogSubscriptionForm } from '@pages/private/blog/blog-subscriptions/types/BlogPartnerInfoForm';
import * as yup from 'yup';
import { useToastStore } from '@store/useToastStore';
import { styled } from 'styled-components';
import { FormItem } from '@components/molecules';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import { useAlertStore } from '@store/useAlertStore';
import { createBlogSubscription } from '../../../../../@apis/blog-subscriptions';
import { CreateBlogSubscriptionBody } from '../../../../../@types/blog-subscription/models/CreateBlogSubscriptionBody';

export interface AddressSearchModalProps {
  onDataAdd?: () => void;
  onClose?: () => void;
  partnerId?: number | string;
}

function AddBlogSubscriptionModal(props: AddressSearchModalProps) {
  const { onClose, onDataAdd, partnerId } = props;
  const { showToast } = useToastStore();
  const { show: showAlert } = useAlertStore();
  const [addingData, setAddingData] = useState(false);
  const formValidationSchema = yup.object({
    seoBlogPerMonth: yup.number().min(0).required(),
    participationBlogPerMonth: yup.number().min(0).required(),
    referenceBlogPerMonth: yup.number().min(0).required(),
    philosophyBlogPerMonth: yup.number().min(0).required(),
  });
  const formik = useFormik<BlogSubscriptionForm>({
    initialValues: {
      seoBlogPerMonth: 0,
      informationBlogPerMonth: 12,
      participationBlogPerMonth: 1,
      referenceBlogPerMonth: 1,
      philosophyBlogPerMonth: 1,
      commonSenseBlogPerMonth: 0,
      diseaseInfoBlogPerMonth: 0,
    },
    onSubmit: async (values) => {
      if (partnerId) {
        await addData(partnerId, values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const addData = async (
    partnerId: number | string,
    body: CreateBlogSubscriptionBody,
  ) => {
    setAddingData(true);
    try {
      await createBlogSubscription(partnerId, body);
      showToast({
        description: '블로그 구독정보가 등록되었어요.',
        status: 'Primary',
      });
      if (onDataAdd) {
        onDataAdd();
      }
      if (onClose) {
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setAddingData(false);
    }
  };

  const handleConfirm = () => {
    const alertTitle = '구독등록';
    const alertMessage = '작성하신 내용대로 구독을 등록하시겠어요?';
    const actionLabel = '확인';

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const renderNumberInput = (info: {
    labelName: string;
    key: keyof BlogSubscriptionForm;
  }) => {
    const { labelName, key } = info;
    return (
      <FormItem label={labelName} labelMaxWidth={300}>
        <InputNumber
          value={formik.values[key]}
          placeholder={`${labelName} 입력`}
          style={{
            width: '100%',
          }}
          onChange={(value) => {
            formik.setFieldValue(key, value);
          }}
          min={0}
        />
      </FormItem>
    );
  };

  return (
    <Modal
      size={560}
      confirmLabel="확인"
      cancelLabel="취소"
      disabled={!formik.isValid || addingData}
      title="구독등록"
      onConfirm={() => {
        handleConfirm();
      }}
      onClose={() => {
        if (onClose) onClose();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          {renderNumberInput({
            labelName: '월별 검색최적화형 블로그 포스트 수',
            key: 'seoBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 정보형 블로그 포스트 수',
            key: 'informationBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 참여형 블로그 포스트 수',
            key: 'participationBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 레퍼런스형 블로그 포스트 수',
            key: 'referenceBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 철학형 블로그 포스트 수',
            key: 'philosophyBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 상식형 블로그 포스트 수',
            key: 'commonSenseBlogPerMonth',
          })}
          {renderNumberInput({
            labelName: '월별 질환형 블로그 포스트 수',
            key: 'diseaseInfoBlogPerMonth',
          })}
        </FormLayout>
      </form>
    </Modal>
  );
}

export default AddBlogSubscriptionModal;
export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
