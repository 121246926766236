/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Select from '@components/v2/antd/data-entry/Select';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import Input from '@components/v2/antd/data-entry/Input';
import { PartnerAccountForm } from '@pages/private/customer/partner-accounts/types/PartnerAccountForm';
import { CreateMemberAccountBody } from '../../../../@types/member/params/CreateMemberAccountBody';
import {
  addMemberAccount,
  updateMemberAccount,
  updateMemberAccountPassword,
  fetchMemberAccountDetail,
  fetchSystemAuthorities,
} from '../../../../@apis/members';
import { UpdateMemberAccountBody } from '../../../../@types/member/params/UpdateMemberAccountBody';
import {
  MEMBER_BOOSTREE_ACCOUNT_TYPE_OPTIONS,
  MEMBER_GROUP_OPTIONS,
} from '../../../../@types/member/member.constants';
import { fetchPartners } from '../../../../@apis/partners';

const INITIAL_VALUES: PartnerAccountForm = {
  isBoostree: false,
  isRoot: false,
  memberEmail: '',
  memberGroup: 'OMA',
  memberName: '',
  memberPhone: '',
  partnerId: undefined,
  memberAuthorityId: undefined,
};

function PartnerAccountDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [authorityOptions, setAuthorityOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const formValidationSchema = yup.object({
    memberEmail: yup
      .string()
      .email('올바른 이메일 형식을 입력해주세요')
      .required('필수 입력값 입니다'),
    memberName: yup.string().required('필수 입력값 입니다'),
    memberPhone: yup.string().required('필수 입력값 입니다'),
    partnerId: yup.lazy(() => {
      if (!id) {
        return yup.number().required();
      }
      return yup.number().optional();
    }),
    memberAuthorityId: yup.lazy(() => {
      if (!id) {
        return yup.number().required();
      }
      return yup.number().optional();
    }),
  });

  const formik = useFormik<PartnerAccountForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchMemberAccountDetail(id);

      const {
        memberGroup,
        memberName,
        memberEmail,
        isRoot,
        isBoostree,
        memberPhone,
      } = row;

      setFormInitialValues({
        isBoostree,
        isRoot,
        memberEmail,
        memberGroup,
        memberName,
        memberPhone,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchPartnerOptions = useCallback(async () => {
    try {
      if (!id) {
        const { rows } = await fetchPartners({
          page: 1,
          pageSize: 9999,
        });
        setPartnerOptions(
          rows.map(({ partnerName, displayName, id }) => ({
            label: displayName || partnerName,
            value: id,
          })),
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchMemberAuthorityOptions = useCallback(async () => {
    try {
      if (!id) {
        const { rows } = await fetchSystemAuthorities();
        setAuthorityOptions(
          rows.map(({ authorityName, id }) => ({
            label: authorityName,
            value: id,
          })),
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchPartnerOptions();
      await fetchMemberAuthorityOptions();
      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateMemberAccountBody) => {
    try {
      await addMemberAccount(body);
      showToast({
        description: '사용자 계정이 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateMemberAccountBody,
  ) => {
    try {
      await updateMemberAccount(id, body);
      showToast({
        description: '사용자 계정 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const resetPassword = async () => {
    try {
      await updateMemberAccountPassword(id);
      showToast({
        description: '사용자 계정의 비밀번호가 휴대폰번호로 초기화 되었어요',
        status: 'Primary',
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetPasswordClick = () => {
    const alertTitle = '비밀번호 초기화';
    const alertMessage =
      '사용자 계정의 비밀번호를 휴대폰번호로 초기화 하시겠어요?';
    const actionLabel = '초기화';
    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            resetPassword();
          },
        },
      ],
    });
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '사용자 계정 등록';
    let alertMessage = '작성하신 내용대로 계정 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '계정 정보 수정';
      alertMessage = '작성하신 내용대로 계정 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerAccountForm;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      numberMode = false,
      labelName,
      required = false,
      key,
      placeholder,
      disabled = false,
      infoLabel = '',
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            width: '100%',
          }}
        >
          {numberMode ? (
            <InputNumber
              disabled={disabled}
              id={key}
              style={{
                width: '100%',
              }}
              name={key}
              min={0}
              value={formik.values[key] as number}
              onChange={(value) => {
                updateForm(key, value);
              }}
              className="input-container"
              placeholder={placeholderText}
            />
          ) : (
            <Input
              disabled={disabled}
              id={key}
              name={key}
              value={formik.values[key] as string}
              onChange={(e) => {
                updateForm(key, e.target.value);
              }}
              className="input-container"
              placeholder={placeholderText}
            />
          )}
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerAccountForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      mode,
      labelName,
      required = false,
      key,
      placeholder,
      options,
      disabled,
      infoLabel,
    } = info;
    let placeholderText = `${labelName} 선택`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let value = formik.values[key];

    if (typeof value !== 'boolean') {
      value = value || undefined;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            width: '100%',
          }}
        >
          <Select
            style={{
              width: '100%',
            }}
            disabled={disabled}
            mode={mode}
            showSearch
            value={value}
            options={options}
            onChange={(value) => {
              updateForm(key, value);
            }}
            placeholder={placeholderText}
            id={key}
          />
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card
            title={'계정 정보'}
            right={
              id && (
                <Button
                  disabled={!id}
                  onClick={() => {
                    handleResetPasswordClick();
                  }}
                >
                  비밀번호 초기화
                </Button>
              )
            }
          >
            <FormLayout>
              {renderSelectForm({
                labelName: '사용자 그룹',
                required: true,
                key: 'memberGroup',
                options: MEMBER_GROUP_OPTIONS,
                infoLabel: !id
                  ? '사용자 그룹에 따라 메뉴 노출이 구분됩니다.'
                  : '',
              })}
              {renderInputForm({
                labelName: '이메일(계정명)',
                required: true,
                key: 'memberEmail',
                disabled: !!id,
                infoLabel: !id ? '로그인 아이디로 사용됩니다.' : '',
              })}
              {renderInputForm({
                labelName: '사용자명',
                required: true,
                key: 'memberName',
              })}
              {renderInputForm({
                labelName: '휴대폰 번호',
                required: true,
                key: 'memberPhone',
                infoLabel: !id ? '계정의 초기 비밀번호로 설정됩니다.' : '',
              })}
              {renderSelectForm({
                labelName: '소속',
                required: true,
                key: 'isBoostree',
                options: MEMBER_BOOSTREE_ACCOUNT_TYPE_OPTIONS,
                infoLabel: !id
                  ? '* 부스트리 소속 계정의 경우 권한과 관계없이 모든 병원 및 메뉴가 노출됩니다'
                  : '',
              })}
              {!id &&
                renderSelectForm({
                  labelName: '활성 병원',
                  required: true,
                  key: 'partnerId',
                  options: partnerOptions,
                })}
              {!id &&
                renderSelectForm({
                  labelName: '권한',
                  required: true,
                  key: 'memberAuthorityId',
                  options: authorityOptions,
                })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default PartnerAccountDetailPage;
