import { AppError, request, Row, Rows } from '@networks/index';
import { FindAgencyListQuery } from '../@types/agency/params/FindAgencyListQuery';
import { Agency } from '../@types/agency/models/Agency';

const API = 'admin/v1/agencies';

export const fetchAgencies = async (query: FindAgencyListQuery) => {
  try {
    const { data } = await request<Rows<Agency>>({
      method: 'get',
      url: API,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchAgencyDetail = async (id: number | string) => {
  const url = `${API}/${id}`;
  try {
    const { data } = await request<Row<Agency>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
