import React, { useEffect, useRef, useState } from 'react';
import { ContentLayout, FilterButton } from '@components/molecules';
import { Table, Typo } from '@components/atoms';
import styled, { css } from 'styled-components';
import colorSet from '@styles/colors';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import Pagination from '@components/atoms/Pagination';
import TableFilter from '@components/organisms/TableFilter';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { TableRef } from '@components/atoms/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import InputSearch from '@components/v2/antd/data-entry/InputSearch';
import { getFilterCount } from '../../utils/filterUtil';

export interface TableTabFilterInfo {
  tabs: { label: string; filterValue: string }[];
  filterKey: string;
}

export interface TableHeaderKeywordFilterInfo<QueryParamsType> {
  filterKey: keyof QueryParamsType;
  searchInputPlaceholder: string;
}

export interface PaginationTablePageProps<ApiResponseType, QueryParamsType> {
  // 헤더 우측 Actions
  headerActions?: React.ReactNode;

  // 컬럼 정보
  columns?: ColDef[];
  // 목록 API 호출 함수 (legacy)

  dataFetcher: (
    data: QueryParamsType,
  ) => Promise<{ rows: ApiResponseType[]; count: number }>;

  // 헤더 키워드 검색 정보
  keywordFilterInfo?: TableHeaderKeywordFilterInfo<QueryParamsType>;

  // 탭형 검색 필터 정보
  tabFilterInfo?: TableTabFilterInfo;

  // 사이드바 필터 정보
  filterForms?: FilterForm[];

  // 필터 초기값
  filterInitialValues?: Partial<QueryParamsType>;

  // 테이블 행 클릭 이벤트
  onRowClick?: (row: ApiResponseType, event: RowClickedEvent<any>) => void;

  rowHeight?: number;
}

function PaginationTablePage<ApiResponseType, QueryParamsType>(
  props: PaginationTablePageProps<ApiResponseType, QueryParamsType>,
) {
  const {
    dataFetcher,
    keywordFilterInfo,
    columns = [],
    tabFilterInfo,
    filterForms,
    filterInitialValues = {},
    headerActions,
    onRowClick,
    rowHeight,
  } = props;

  const getFilterValues = () => {
    let filterValues = { ...filterInitialValues };
    if (location) {
      const { search } = location;
      const queries = queryString.parse(search) as Record<string, string>;
      if (queries.filter) {
        try {
          filterValues = JSON.parse(queries.filter);
        } catch (e) {
          // Do nothing
        }
      }
    }
    return filterValues;
  };

  const tableRef = useRef<TableRef>(null);
  const [isError, setIsError] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState<ApiResponseType[]>([]);
  const [count, setCount] = useState(0);
  const [filterValues, setFilterValues] = useState<any>(getFilterValues());

  const removeEmptyValue = (object: Record<string, any>) => {
    const filtered = Object.fromEntries(
      Object.entries(object).filter(([_, value]) => {
        if (typeof value === 'boolean') return true;

        return !!value;
      }),
    );
    return filtered;
  };

  const getPagination = () => {
    if (location) {
      const { search } = location;
      let page = 1;
      let pageSize = 10;
      const queries = queryString.parse(search) as Record<string, string>;
      if (queries.page) {
        page = parseInt(queries.page, 10);
      }
      if (queries.pageSize) {
        pageSize = parseInt(queries.pageSize, 10);
      }
      return {
        page,
        pageSize,
      };
    }
    return {
      page: 1,
      pageSize: 10,
    };
  };

  const { page, pageSize } = getPagination();
  // 서버요청시에 빈문자열, null 값등은 제거 후 전송
  const queryFilterValues = getFilterValues();
  const filterParams = removeEmptyValue(queryFilterValues);

  useEffect(() => {
    setFilterValues(getFilterValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    (async function fetch() {
      try {
        setTableLoading(true);
        const data = await dataFetcher({
          page,
          pageSize,
          ...filterParams,
        } as any);
        setIsError(false);
        const { rows: responseRows, count: responseCount } = data;
        setRows(responseRows);
        setCount(responseCount);
      } catch (e) {
        setIsError(true);
        setRows([]);
        setCount(0);
      } finally {
        setTableLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  const pushPage = (page: number, pageSize: number, filterValues = {}) => {
    const filterString = JSON.stringify(filterValues);
    const path = `${location.pathname}?page=${page}&pageSize=${pageSize}&filter=${filterString}`;
    navigate(path);
  };

  const getSearchInputPlaceholder = () => {
    if (keywordFilterInfo) {
      const { searchInputPlaceholder } = keywordFilterInfo;
      return searchInputPlaceholder;
    }

    return '검색';
  };

  const getSelectedTabKey = (tabKey: string) => {
    if (filterValues[tabKey]) {
      const filterQueryValues = getFilterValues();
      // @ts-ignore
      return filterQueryValues[tabKey] as string;
    }
    return '';
  };

  const getAppliedFilterOptionCount = () => {
    if (filterForms && filterValues) {
      return getFilterCount(filterValues as Record<string, any>, filterForms);
    }
    return 0;
  };

  const handleToggleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handlePageChange = (newPage: number, newPageSize: number) => {
    pushPage(newPage, newPageSize, filterValues);
  };

  const renderTabs = () => {
    if (tabFilterInfo) {
      const { filterKey, tabs } = tabFilterInfo;
      const selectedTabKey = getSelectedTabKey(filterKey);
      const tabsWithAll = [
        {
          label: '전체',
          filterValue: '',
        },
        ...tabs,
      ];
      return (
        <TableTabs>
          {tabsWithAll.map(({ filterValue, label }) => (
            <TableTab
              $isSelected={selectedTabKey === filterValue}
              key={filterValue}
              onClick={async () => {
                const updatedFilters = {
                  ...filterValues,
                  [filterKey]: filterValue,
                };
                setFilterValues(updatedFilters);
                pushPage(1, pageSize, updatedFilters);
              }}
            >
              <Typo as="p" typoType="h10" color="gray8">
                {label} {selectedTabKey === filterValue ? `(${count})` : null}
              </Typo>
            </TableTab>
          ))}
        </TableTabs>
      );
    }
    return (
      <TableTabs>
        <TableTab $isSelected>
          <Typo as="p" typoType="h10" color="gray8">
            전체 {`(${count})`}
          </Typo>
        </TableTab>
      </TableTabs>
    );
  };

  return (
    <TablePageContainer>
      <ContentLayout
        style={{
          flex: 1,
        }}
        contentStyle={{
          flex: 1,
        }}
        headerRight={headerActions}
      >
        <PaginationTableWithFilter>
          <TableWrapper>
            <header>
              {renderTabs()}
              <RightAccessory>
                {filterForms && (
                  <FilterButton
                    filterCount={getAppliedFilterOptionCount()}
                    handleToggleFilterOpen={handleToggleFilterOpen}
                  />
                )}
                {keywordFilterInfo && (
                  <InputSearch
                    allowClear
                    placeholder={getSearchInputPlaceholder()}
                    value={
                      // @ts-ignore
                      (filterValues[keywordFilterInfo.filterKey] as string) ||
                      ''
                    }
                    onChange={(e) => {
                      const updatedFilter = {
                        ...filterValues,
                      };
                      updatedFilter[keywordFilterInfo.filterKey] =
                        e.target.value;
                      setFilterValues(updatedFilter);
                    }}
                    onSearch={(value, event, info) => {
                      if (info?.source === 'clear') {
                        const updatedFilter = {
                          ...filterValues,
                        };
                        updatedFilter[keywordFilterInfo.filterKey] = value;
                        pushPage(1, pageSize, updatedFilter);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        pushPage(1, pageSize, filterValues);
                      }
                    }}
                  />
                )}
              </RightAccessory>
            </header>
            <Table
              columnDefs={columns}
              rowData={rows}
              ref={tableRef}
              isError={isError}
              isLoading={tableLoading}
              rowHeight={rowHeight}
              onRowClick={(event) => {
                if (onRowClick) onRowClick(event.data, event);
              }}
            />
          </TableWrapper>
          <Pagination
            current={page}
            total={count}
            pageSize={pageSize}
            showSizeChanger
            onChange={handlePageChange}
          />
        </PaginationTableWithFilter>
      </ContentLayout>
      {filterForms && (
        <TableFilter
          isOpen={filterOpen}
          handleClose={() => {
            setFilterOpen(false);
          }}
          filterForm={filterForms}
          filterValues={filterValues}
          filterInitialValues={filterInitialValues}
          onSearchSubmit={(values) => {
            pushPage(1, pageSize, values);
          }}
          onSearchReset={() => {
            pushPage(1, pageSize, filterInitialValues);
          }}
        />
      )}
    </TablePageContainer>
  );
}

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: ${colorSet.gray13};
  border-radius: 24px;
  overflow: hidden;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    min-width: 720px;
    overflow: auto;
  }
`;

const TablePageContainer = styled.div`
  display: flex;
`;

const PaginationTableWithFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 24px;
`;
const TableTabs = styled.ul`
  display: flex;
  align-items: center;
`;

const TableTab = styled.li<{ $isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 12px 20px;
  border-bottom: 2px solid transparent;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-bottom: 2px solid ${colorSet.gray1};

      > p {
        color: ${colorSet.gray1};
      }
    `}
`;

const RightAccessory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default PaginationTablePage;
