import { AppError, request, Row, Rows } from '@networks/index';
import { FetchPartnerGroupListQuery } from '../@types/partner-group/params/FetchPartnerGroupListQuery';
import { CreatePartnerGroupBody } from '../@types/partner-group/params/CreatePartnerGroupBody';
import { UpdatePartnerGroupBody } from '../@types/partner-group/params/UpdatePartnerGroupBody';
import { PartnerGroup } from '../@types/partner-group/models/PartnerGroup';

const API_ROOT = 'admin/v1/partner-groups';

export const createPartnerGroup = async (body: CreatePartnerGroupBody) => {
  try {
    const { data } = await request<Row<PartnerGroup>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchPartnerGroups = async (query: FetchPartnerGroupListQuery) => {
  try {
    const { data } = await request<Rows<PartnerGroup>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
export const fetchPartnerGroupDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<PartnerGroup>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updatePartnerGroup = async (
  id: number | string,
  body: UpdatePartnerGroupBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<PartnerGroup>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
