import _, { isEmpty } from 'lodash';

// 시간 분 계산
export const formatHHmmNumber = (num: number) => {
  if (num === 0) return '-';

  let newTime = _.cloneDeep(num);
  let newHour = 0;
  while (newTime >= 60) {
    newHour += 1;
    newTime -= 60;
  }

  if (newHour === 0) {
    return `${newTime}분`;
  }

  return `${newHour}시간 ${newTime}분`;
};

export const numberCommaFormat = (value: string) => {
  let string: string;
  if (!value) string = '';

  if (!/^\d+$/.test(value)) {
    // 입력값이 숫자로만 구성되어 있지 않으면 빈 문자열 반환
    string = '';
  }
  // 입력값에서 숫자만 추출하여 사용
  const numericString = value?.replace(/\D/g, '');

  if (numericString.length === 0) {
    string = '';
  }

  string = numericString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return string;
};

// 분 -> 시간 : 분으로 변경
export const minuteFormat = (value: number) => {
  const hour = Math.floor(value / 60);
  const minute = value % 60;
  if (hour === 0) return `${minute < 10 ? `0${minute}` : minute}분`;
  return `${hour < 10 ? `0${hour}` : hour}시간 ${
    minute < 10 ? `0${minute}` : minute
  }분`;
};

// 맨 앞자리만 대문잘 변환
export const uppercaseToStringFirstWord = (str: string) => {
  // 1. 첫번째 글자 찾기
  const firstChar = str[0];

  // 2. 첫번째 글자를 대문자로 변환하기
  const firstCharUpper = firstChar.toUpperCase();

  // 3. 첫번째 글자를 제외한 나머지 글자 찾기
  const leftChar = str.slice(1, str.length);

  return firstCharUpper + leftChar;
};

// 이미지 cdn 체크
export const handleImgPrefix = (src: string) => {
  if (src) {
    const PREFIX = process.env.REACT_APP_IMAGE_PRIFIX;
    if (src.includes(PREFIX || '')) {
      return src;
    }
    if (src.includes('data:image')) {
      return src;
    }
    return `${PREFIX}${src}`;
  }

  return '';
};

// 핸드폰 번호
export const getPhoneNumReg = (phone?: string) => {
  const regex = /[^0-9]/g;
  const result = phone?.replace(regex, '');

  const regEx = /(\d{3})(\d{3,4})(\d{4})/g;

  return result?.replaceAll(regEx, '$1-$2-$3') || '';
};

export const getDbzero = (num: number) => {
  if (num < 10) return `0${num}`;
  return `${num}`;
};

export const getFormatTelNumber = (str: string) => {
  let result = str;
  if (isEmpty(str)) return '';
  if (str)
    if (str.length === 11)
      result = str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    else if (str.length === 8) result = str.replace(/(\d{4})(\d{4})/, '$1-$2');
    else if (str?.indexOf('02') === 0) {
      if (str?.length === 9) {
        result = str.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        result = str.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      result = str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  return result;
};

export const getBirthString = (str: string) => {
  return str?.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
};

export const base64toFile = (base_data: string, filename: string) => {
  const arr = base_data.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const PHONE_NUM_REGEX =
  /^(01[0|1|6|7|8|9]|02|041|053)-?([0-9]{3,4})-?([0-9]{4})$/;

export const CALL_NUM_REGEX = /^[0-9]{2,4}-?[0-9]{0,4}-?[0-9]{0,4}$/;

export function isPhoneNum(num: string) {
  return PHONE_NUM_REGEX.test(num);
}

export const formatNumberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
