import React from 'react';
import { ReactComponent as Ellipse } from '@assets/svg/Ellipse-1.svg';
import colorSet, { ColorType } from '@styles/colors';
import { styled } from 'styled-components';
import { Typo } from '@components/atoms';
import { FontSizeType } from '@components/atoms/Typo/types';

interface EllipseLabelProps {
  color?: ColorType;
  label: string;
  typoType?: FontSizeType;
}

function EllipseLabel({
  color = 'gray7',
  label,
  typoType = 'b9',
}: EllipseLabelProps) {
  return (
    <LabelWrapper>
      <EllipseIcon color={color} />
      <Typo color={color} typoType={typoType}>
        {label}
      </Typo>
    </LabelWrapper>
  );
}

export default EllipseLabel;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;

interface EllipseIconProps {
  color: ColorType;
}

const EllipseIcon = styled(Ellipse)<EllipseIconProps>`
  ${({ color }) => `& > circle { fill: ${colorSet[color]};}`}
`;
