import { AppError, request, Row } from '@networks/index';
import { AdminSignInBody } from '../@types/administrator/params/AdminSignInBody';
import { Administrator } from '../@types/administrator/models/Administrator';

export const signIn = async (body: AdminSignInBody) => {
  const url = `/admin/administrator/signin`;
  try {
    const { data } = await request<Row<Administrator>>({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchLoginUserInfo = async () => {
  const url = `/admin/administrators/me/session`;
  try {
    const { data } = await request<Row<Administrator>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
