import { useToastStore } from '@store/index';

const { showToast } = useToastStore.getState();

export const showResponseToast = (error: any) => {
  showToast({
    description:
      error?.translate ||
      ((typeof error?.message !== 'string' && error?.message?.[0]
        ? error.message[0]
        : error.message) as string),
    status: 'Red',
  });
};

export const showSuccessToast = (message: string) => {
  showToast({
    description: message,
    status: 'Primary',
  });
};
