import { Rows } from '@networks/types';
import { AppError, request } from '@networks/index';
import { GetAdsCampaignsInput, GetAdsCampaignsOutput } from './types';

const URL = '/admin/advertising-campaigns';

export const getAdsCampaigns = async (queryParams: GetAdsCampaignsInput) => {
  try {
    const { data } = await request<Rows<GetAdsCampaignsOutput>>({
      method: 'get',
      url: URL,
      queryParams,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
