import { CSSProperties } from 'react';
import { css } from 'styled-components';
import colorSet from '@styles/colors';
import { LabelColorType, LabelSizeType } from './types';
import typo from '../Typo/types';

export const getLabelColor = (labelColor: LabelColorType, isLight: boolean) => {
  // black
  const style: CSSProperties = {
    backgroundColor: isLight ? colorSet.gray11 : colorSet.gray1,
    color: colorSet.gray1,
  };

  if (labelColor === 'red') {
    style.backgroundColor = isLight ? colorSet.red8 : colorSet.red4;
    style.color = colorSet.red3;
  }

  if (labelColor === 'yellow') {
    style.backgroundColor = isLight ? colorSet.yellow6 : colorSet.yellow1;
    style.color = colorSet.yellow1;
  }

  if (labelColor === 'primary') {
    style.backgroundColor = isLight ? colorSet.primary9 : colorSet.primary3;
    style.color = colorSet.primary3;
  }

  if (labelColor === 'blue') {
    style.backgroundColor = isLight ? colorSet.blue8 : colorSet.blue3;
    style.color = colorSet.blue3;
  }

  if (labelColor === 'purple') {
    style.backgroundColor = isLight ? colorSet.purple6 : colorSet.purple1;
    style.color = colorSet.purple1;
  }

  if (labelColor === 'gray') {
    style.backgroundColor = isLight ? colorSet.gray13 : colorSet.gray7;
    style.color = colorSet.gray7;
    style.border = isLight ? `1px solid ${colorSet.gray9}` : 'none';
  }

  if (labelColor === 'pink') {
    style.backgroundColor = isLight ? colorSet.pink6 : colorSet.pink2;
    style.color = colorSet.pink2;
  }

  if (labelColor === 'teal') {
    style.backgroundColor = isLight ? colorSet.teal6 : colorSet.teal2;
    style.color = colorSet.teal2;
  }

  return css`
    background-color: ${style.backgroundColor};
    color: ${isLight ? style.color : colorSet.gray13};
    fill: ${isLight ? style.color : colorSet.gray13};
    border: ${style.border ?? undefined};
  `;
};

export const getLabelStyle = (size: LabelSizeType) => {
  // size : 20
  const style: CSSProperties = {
    padding: '4px 8px',
  };
  if (size === 24) {
    style.padding = '5px 10px';
  }
  return css`
    padding: ${style.padding};
    border-radius: 100px;
  `;
};

export const getTypoSize = (size: LabelSizeType) => {
  switch (size) {
    case 24:
      return css`
        ${typo.label2};
      `;
    case 20:
      return css`
        ${typo.label3};
      `;

    default:
      return css`
        ${typo.label2};
      `;
  }
};
