/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { message } from 'antd';
import { handleImgPrefix } from 'utils/format';

const uploadFile = (file: FormData) => {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/media/big-images`, {
    method: 'post',
    body: file,
  });
};

class EditorUploadAdapter {
  private loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file.then(async (file: any) => {
      const hide = message.loading('이미지 업로드중..');
      try {
        const formData = new FormData();

        formData.append('image', file);
        formData.append('service', 'BOS');
        formData.append('folder', 'REVIEW_EVENT');
        formData.append('isIncludeCdn', 'true');
        const uploadResult = await uploadFile(formData);
        // CKEditor 에서 참조 하는 지정 필드 (default)

        const fileUrl = await uploadResult.json();

        return {
          default: handleImgPrefix(fileUrl.rows[0]),
        };
      } catch (e) {
        return { default: null };
      } finally {
        setTimeout(hide, 0);
      }
    });
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    // server.abortUpload();
  }
}

// @ts-ignore
export function EditorUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    // @ts-ignore
    return new EditorUploadAdapter(loader, editor.config.get('uploadInfo'));
  };
}
