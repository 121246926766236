/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import colorSet from '@styles/colors';
import dayjs from 'dayjs';
import { getAdsCampaigns } from '@apis';
import {
  GetAdsCampaignsInput,
  GetAdsCampaignsOutput,
} from '@apis/ads/get-ads-campaigns/types';

function AdsCampaignsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const COLS: ColDef[] = [
    {
      field: 'title',
      width: 128,
      headerName: '캠페인 이미지',
      sortable: false,
      cellRenderer: (col: ICellRendererParams) => {
        const { data } = col;
        if (data) {
          const { thumbnail } = data as GetAdsCampaignsOutput;
          const Title = (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                style={{
                  border: `1px solid ${colorSet.gray10}`,
                }}
                width={100}
                height={100}
                src={thumbnail}
                alt="thumbnail"
              />
            </div>
          );
          return Title;
        }
        return '';
      },
    },
    {
      field: 'title',
      minWidth: 200,
      headerName: '캠페인 제목',
      sortable: false,
    },
    {
      field: 'description',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '캠페인 설명',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: GetAdsCampaignsInput) => {
    const { rows, count } = await getAdsCampaigns({
      ...query,
      ...(params.partnerid && { partnerId: +params.partnerid }),
    });
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: GetAdsCampaignsOutput) => {
    const { id } = data;
    const path = PAGE_PATHS.ADS_CAMPAIGN_DETAIL.replace(
      ':partnerid',
      `${params.partnerid}`,
    ).replace(':campaignid', `${id}`);
    navigate(path);
  };

  const renderContentRightButton = () => (
    <RightButtonWrapper>
      <Link
        to={PAGE_PATHS.ADS_CAMPAIGN_ADD.replace(
          ':partnerid',
          `${params.partnerid}`,
        )}
      >
        <Button>캠페인 등록</Button>
      </Link>
    </RightButtonWrapper>
  );

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      onRowClick={handleTableRowClick}
      rowHeight={120}
    />
  );
}

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;

export default AdsCampaignsPage;
