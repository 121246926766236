import React from 'react';
import { Tooltip as TooltipAntd } from 'antd';
import styled from 'styled-components';
import Typo from '../Typo';

interface TooltipProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  trigger?: 'hover' | 'click';
  defaultOpen?: boolean;
  placement?: 'top' | 'left' | 'right' | 'bottom';
}

function Tooltip(props: TooltipProps) {
  const {
    title,
    description,
    children,
    trigger = 'click',
    defaultOpen = false,
    placement,
  } = props;

  const renderTitle = () => {
    return (
      <TooltipContainer>
        {title && (
          <Typo typoType={'b10m'} color={'gray13'}>
            {title}
          </Typo>
        )}
        {description && (
          <Typo typoType={'b12'} color={'gray13'} isPreWrap>
            {description}
          </Typo>
        )}
      </TooltipContainer>
    );
  };

  return (
    <>
      <style>
        {`
        .ant-tooltip {
          max-width: 1000px;
        }
        `}
      </style>
      <TooltipAntd
        title={renderTitle()}
        trigger={trigger}
        defaultOpen={defaultOpen}
        placement={placement}
      >
        {children}
      </TooltipAntd>
    </>
  );
}

export default Tooltip;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
