import { CSSProperties } from 'react';
import colorSet from '@styles/colors';
import { css } from 'styled-components';
import { TextButtonColorType, TextButtonStyleType } from './types';

// Default style
export const getDefaultColor = (
  buttonColor?: TextButtonColorType,
  buttonStyle?: TextButtonStyleType,
) => {
  const style: CSSProperties = {
    color: colorSet.gray3,
    textDecorationColor: colorSet.gray3,
  };
  if (buttonColor === 'primary') {
    style.color = colorSet.primary3;
    style.textDecorationColor = colorSet.primary3;
  }
  return css`
    color: ${style.color};
    fill: ${style.color};
    text-decoration: ${buttonStyle === 'underline' ? 'underline' : 'none'};
    text-decoration-color: ${style.textDecorationColor};
  `;
};

// Hovered style
export const getHoveredColor = (
  buttonColor?: TextButtonColorType,
  buttonStyle?: TextButtonStyleType,
) => {
  const style: CSSProperties = {
    color: colorSet.gray4,
    textDecorationColor: colorSet.gray4,
  };
  if (buttonColor === 'primary') {
    style.color = colorSet.primary2;
    style.textDecorationColor = colorSet.primary2;
  }
  return css`
    color: ${style.color};
    fill: ${style.color};
    text-decoration: ${buttonStyle === 'underline' ? 'underline' : 'none'};
    text-decoration-color: ${style.textDecorationColor};
  `;
};

// Pressed style
export const getPressedColor = (
  buttonColor?: TextButtonColorType,
  buttonStyle?: TextButtonStyleType,
) => {
  // primary
  const style: CSSProperties = {
    color: colorSet.gray6,
    textDecorationColor: colorSet.gray6,
  };
  if (buttonColor === 'primary') {
    style.color = colorSet.primary1;
    style.textDecorationColor = colorSet.primary1;
  }
  return css`
    color: ${style.color};
    fill: ${style.color};
    text-decoration: ${buttonStyle === 'underline' ? 'underline' : 'none'};
    text-decoration-color: ${style.textDecorationColor};
  `;
};

// Disabled style
export const getDisabledColor = (buttonStyle?: TextButtonStyleType) => {
  return css`
    color: ${colorSet.gray8};
    fill: ${colorSet.gray8};
    text-decoration: ${buttonStyle === 'underline' ? 'underline' : 'none'};
    text-decoration-color: ${colorSet.gray8};
  `;
};
