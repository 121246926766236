import colorSet from '@styles/colors';
import styled from 'styled-components';

export const LoadingTableOverlayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  &::before {
    content: '';
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${colorSet.gray13};
    filter: drop-shadow(2px 2px 32px rgba(34, 37, 41, 0.12));
  }
`;
