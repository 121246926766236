import React, { useState } from 'react';
import { Dropdown as DropdownAntd } from 'antd';
import styled, { css } from 'styled-components';
import colorSet from '@styles/colors';
import Typo from '../Typo';

interface DropdownProps {
  options: { label: string; value: string | number | boolean }[];
  value: string | number | boolean;
  onChange: (value: string | number | boolean) => void;
  placeholder?: string;
}

function Dropdown(props: DropdownProps) {
  const { options, value, onChange, placeholder } = props;
  const [open, setOpen] = useState(false);

  const handleOptionClick = (valueParam: string | number | boolean) => {
    onChange(valueParam);
  };

  const renderDropdown = () => {
    return (
      <SelectList>
        {options.map((item) => (
          <SelectItem
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              handleOptionClick(item.value);
            }}
            data-checked={value === item.value}
          >
            <Typo as="p" typoType="b9">
              {item.label}
            </Typo>
            <span className="icon" />
          </SelectItem>
        ))}
      </SelectList>
    );
  };

  return (
    <DropdownAntd dropdownRender={() => renderDropdown()} trigger={['click']}>
      <DropdownContainer isOpen={open} onClick={() => setOpen(true)}>
        {value ? (
          <Typo as="p" typoType="b9">
            {value}
          </Typo>
        ) : (
          <Typo as="p" typoType="b9" color="gray7">
            {placeholder || '선택'}
          </Typo>
        )}
        <span className="icon" />
      </DropdownContainer>
    </DropdownAntd>
  );
}

export default Dropdown;

const DropdownContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 9px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  cursor: pointer;

  > p {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > .icon {
    display: flex;
    width: 20px;
    height: 20px;
    background: url('/assets/icons/icon-arrow_black.svg') no-repeat center
      center / contain;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: ${colorSet.primary3};
      box-shadow: 0 0 0 2px ${colorSet.primary8};
    `}
`;

const SelectList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 8px;
`;
const SelectItem = styled.ul`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;

  > .icon {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  &[data-checked='true'] {
    > .icon {
      display: flex;
    }
  }
`;
