/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Select from '@components/v2/antd/data-entry/Select';
import { AIDesignAssetForm } from '@pages/private/ai-design/ai-design-assets/types/AIDesignAssetForm';
import Upload from '@components/v2/antd/data-entry/Upload';
import {
  AI_DESIGN_ASSET_DEFAULT_CATEGORY_OPTIONS,
  AI_DESIGN_ASSET_DEFAULT_DISPLAY_CHANNEL_OPTIONS,
  AI_DESIGN_ASSET_STATUS_OPTIONS,
  AIDesignAssetStatus,
} from '../../../../@types/ai-design-asset/constants/ai-design-asset.constants';
import {
  createAIDesignAsset,
  fetchAIDesignAssetDetail,
  updateAIDesignAsset,
} from '../../../../@apis/ai-design-assets';
import { CreateAIDesignAssetBody } from '../../../../@types/ai-design-asset/params/CreateAIDesignAssetBody';
import { UpdateAIDesignAssetBody } from '../../../../@types/ai-design-asset/params/UpdateAIDesignAssetBody';

const INITIAL_VALUES: AIDesignAssetForm = {
  displayChannels: [],
  categories: [],
  tags: [],
  status: AIDesignAssetStatus.UnUsable,
  assetUrls: [],
};

function AIDesignAssetDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    assetUrls: yup.array().min(1),
  });

  const formik = useFormik<AIDesignAssetForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        const { status, tags, categories, displayChannels } = values;
        await updateData(id, {
          status,
          tags,
          categories,
          displayChannels,
        });
      } else {
        await addData(values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchAIDesignAssetDetail(id);
      const {
        assetUrl,
        displayChannels,
        categories,
        status,
        tags,
        // 상세 정보
      } = row;

      setFormInitialValues({
        assetUrls: [assetUrl],
        displayChannels,
        categories,
        status,
        tags,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateAIDesignAssetBody) => {
    try {
      await createAIDesignAsset(body);
      showToast({
        description: '디자인 에셋 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateAIDesignAssetBody,
  ) => {
    try {
      await updateAIDesignAsset(id, body);
      showToast({
        description: '디자인 에셋 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '디자인 에셋 정보 등록';
    let alertMessage = '작성하신 내용대로 디자인 에셋 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '디자인 에셋 정보 수정';
      alertMessage = '작성하신 내용대로 디자인 에셋 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const renderTagInput = (info: {
    labelName: string;
    required?: boolean;
    key: keyof AIDesignAssetForm;
    placeholder?: string;
    options?: { label: string; value: string }[];
    separateWithSpaceAndComma?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      separateWithSpaceAndComma = false,
      options = [],
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let tokenSeparators: string[] = [];
    if (separateWithSpaceAndComma) {
      tokenSeparators = [',', ' '];
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={tokenSeparators}
          showSearch
          value={formik.values[key] || undefined}
          onChange={(values) => {
            updateForm(key, values);
          }}
          options={options}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof AIDesignAssetForm;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, placeholder, options } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderUpload = (info: {
    labelName: string;
    required?: boolean;
    key: keyof AIDesignAssetForm;
  }) => {
    const { labelName, required = false, key } = info;
    let values: string[] = [];
    if (Array.isArray(formik.values[key])) {
      values = formik.values[key] as string[];
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Upload
          disabled={!!id}
          initialImageUrls={values}
          onImageDataChange={(imageUrls: string[]) => {
            formik.setFieldValue(key, imageUrls);
          }}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'기본정보'}>
            <FormLayout>
              {renderUpload({
                labelName: '이미지',
                required: true,
                key: 'assetUrls',
              })}
              {renderSelectForm({
                labelName: '상태',
                required: true,
                key: 'status',
                options: AI_DESIGN_ASSET_STATUS_OPTIONS,
              })}
              {renderTagInput({
                labelName: '활용채널',
                required: true,
                key: 'displayChannels',
                separateWithSpaceAndComma: false,
                options: AI_DESIGN_ASSET_DEFAULT_DISPLAY_CHANNEL_OPTIONS,
              })}
              {renderTagInput({
                labelName: '카테고리',
                required: true,
                key: 'categories',
                separateWithSpaceAndComma: false,
                options: AI_DESIGN_ASSET_DEFAULT_CATEGORY_OPTIONS,
              })}
              {renderTagInput({
                labelName: '검색태그',
                required: true,
                key: 'tags',
                separateWithSpaceAndComma: false,
                placeholder: '검색 태그를 입력해주세요 (울쎄라,리프테라2 등)',
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default AIDesignAssetDetailPage;
