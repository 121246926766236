import { AppError, request } from '@networks/index';
import { CreateAdsCampaignInput } from './types';

const URL = '/admin/advertising-campaigns';

export const updateAdsCampaign = async ({
  id,
  requestBody,
}: CreateAdsCampaignInput) => {
  try {
    const { status } = await request({
      method: 'put',
      url: `${URL}/${id}`,
      requestBody,
    });
    return status;
  } catch (e) {
    throw new AppError(e);
  }
};
