import { AppError, request, Row, Rows } from '@networks/index';
import { FetchBlogLabelListQuery } from '../@types/blog-label/params/FetchBlogLabelListQuery';
import { BlogLabel } from '../@types/blog-label/models/BlogLabel';
import { UpdateBlogLabelBody } from '../@types/blog-label/params/UpdateBlogLabelBody';
import { CreateBlogLabelBody } from '../@types/blog-label/params/CreateBlogLabelBody';

const API_ROOT = 'admin/blog-labels';

/**
 * 블로그 라벨링 생성
 */
export const createBlogLabel = async (body: CreateBlogLabelBody) => {
  try {
    const { data } = await request<Row<BlogLabel>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 라벨링 목록 조회
 */
export const fetchBlogLabels = async (query: FetchBlogLabelListQuery) => {
  try {
    const { data } = await request<Rows<BlogLabel>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 라벨링 상세 조회
 */
export const fetchBlogLabelDetail = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request<Row<BlogLabel>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 라벨링 정보 수정
 */
export const updateBlogLabel = async (
  id: number | string,
  body: UpdateBlogLabelBody,
) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 라벨링 삭제
 */
export const deleteBlogLabel = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
