import React, { useCallback, useEffect, useState } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
  TableTabFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { LabelColorType } from '@components/atoms/Label/types';
import { FilterForm } from '@components/organisms/TableFilter/types';
import {
  fetchGlobalCatalogCategories,
  fetchGlobalCatalogs,
  fetchMedicalSubjects,
} from '../../../../@apis/global-catalogs';
import { FetchGlobalCatalogListQuery } from '../../../../@types/global-catalog/params/FetchGlobalCatalogListQuery';
import { GlobalCatalog } from '../../../../@types/global-catalog/models/GlobalCatalog';
import {
  GLOBAL_CATALOG_LANGUAGE_TYPE_OPTIONS,
  GlobalCatalogTranslations,
  GlobalCatalogWorkStatus,
} from '../../../../@types/global-catalog/constants/global-catalogs.constants';
import { renderTags } from '../../../../utils/tableRenderHelper';
import AddPartnerCatalogModal from './components/AddPartnerCatalogModal';

function GlobalCatalogListPage() {
  const navigate = useNavigate();

  const [
    addPartnerGlobalCatalogModalVisible,
    setAddPartnerGlobalCatalogModalVisible,
  ] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);
  const [medicalSubjects, setMedicalSubjects] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const fetchGlobalCatalogCategoryOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogCategories();
      setCategoryOptions(
        rows.map(({ globalCatalogCategoryName, id }) => ({
          label: globalCatalogCategoryName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchMedicalSubjectOptions = useCallback(async () => {
    try {
      const { rows } = await fetchMedicalSubjects();
      setMedicalSubjects(
        rows.map(({ subjectName, id }) => ({
          label: subjectName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetchData() {
      await fetchGlobalCatalogCategoryOptions();
      await fetchMedicalSubjectOptions();
    })();
  }, [fetchGlobalCatalogCategoryOptions, fetchMedicalSubjectOptions]);

  const tabFilterInfo: TableTabFilterInfo = {
    filterKey: 'workStatus',
    tabs: [
      {
        label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.WAITING],
        filterValue: GlobalCatalogWorkStatus.WAITING,
      },
      {
        label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.INSPECTING],
        filterValue: GlobalCatalogWorkStatus.INSPECTING,
      },
      {
        label:
          GlobalCatalogTranslations[
            GlobalCatalogWorkStatus.INSPECTION_COMPLETED
          ],
        filterValue: GlobalCatalogWorkStatus.INSPECTION_COMPLETED,
      },
      {
        label:
          GlobalCatalogTranslations[GlobalCatalogWorkStatus.FINAL_COMPLETED],
        filterValue: GlobalCatalogWorkStatus.FINAL_COMPLETED,
      },
      {
        label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.REJECTED],
        filterValue: GlobalCatalogWorkStatus.REJECTED,
      },
    ],
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'globalCatalogCategoryId',
      options: categoryOptions,
      label: '카테고리',
      placeholder: '카테고리 선택',
    },
    {
      type: 'select',
      field: 'languageType',
      options: GLOBAL_CATALOG_LANGUAGE_TYPE_OPTIONS,
      label: '언어',
      placeholder: '언어 선택',
    },
    {
      type: 'select',
      field: 'medicalSubjectId',
      options: medicalSubjects,
      label: '진료과목',
      placeholder: '진료과목 선택',
    },
  ];

  const FILTER_INITIAL_VALUES = {
    globalCatalogName: '',
    globalCatalogCategoryId: undefined,
    languageType: undefined,
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchGlobalCatalogListQuery> =
    {
      filterKey: 'globalCatalogName',
      searchInputPlaceholder: '시술명 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'globalCatalogCategoryName',
      minWidth: 153,
      width: 153,
      headerName: '카테고리',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<GlobalCatalog>) => {
        const { data } = col;
        if (data) {
          const { globalCatalogCategory } = data;
          if (globalCatalogCategory) {
            const { globalCatalogCategoryName } = globalCatalogCategory;
            return globalCatalogCategoryName;
          }
        }
        return '';
      },
    },
    {
      field: 'globalCatalogName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '시술명',
      sortable: false,
    },
    {
      field: 'languageTypes',
      minWidth: 100,
      width: 100,
      headerName: '언어',
      cellRenderer: (col: ICellRendererParams<GlobalCatalog>) => {
        const { data } = col;
        if (data) {
          const { languageType } = data;
          return GlobalCatalogTranslations[languageType] || '';
        }

        return '';
      },
      sortable: false,
    },
    {
      field: 'workStatus',
      minWidth: 153,
      width: 153,
      headerName: '검수상태',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<GlobalCatalog>) => {
        const { data } = col;
        if (data) {
          const { workStatus } = data;
          const translation = GlobalCatalogTranslations[workStatus];

          let color: LabelColorType = 'gray';
          switch (workStatus) {
            case GlobalCatalogWorkStatus.WAITING:
              color = 'gray';
              break;
            case GlobalCatalogWorkStatus.INSPECTING:
              color = 'black';
              break;
            case GlobalCatalogWorkStatus.INSPECTION_COMPLETED:
              color = 'blue';
              break;
            case GlobalCatalogWorkStatus.FINAL_COMPLETED:
              color = 'blue';
              break;
            case GlobalCatalogWorkStatus.REJECTED:
              color = 'red';
              break;
            default:
              break;
          }

          return renderTags({
            value: translation,
            color,
            type: 'light',
          });
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchGlobalCatalogListQuery) => {
      const { rows, count } = await fetchGlobalCatalogs(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: GlobalCatalog) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        {addPartnerGlobalCatalogModalVisible && (
          <AddPartnerCatalogModal
            onClose={() => {
              setAddPartnerGlobalCatalogModalVisible(false);
            }}
            onAddCatalog={() => {
              setAddPartnerGlobalCatalogModalVisible(false);
            }}
          />
        )}
        <Button
          onClick={() => {
            setAddPartnerGlobalCatalogModalVisible(true);
          }}
        >
          의원 시술 동기화
        </Button>
        <Link to={PAGE_PATHS.AI_DATA_GLOBAL_CATALOG_ADD}>
          <Button>시술 정보 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      filterForms={filterForms}
      tabFilterInfo={tabFilterInfo}
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default GlobalCatalogListPage;
