import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import DraggableTableRow from '@components/organisms/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

type EditableTableProps = Parameters<typeof Table>[0];
interface InputRepeaterDataType {
  key: number;
  content: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DEFAULT_ROW = {
  key: 1,
  content: '',
};

export interface InputRepeaterProps {
  initialValues?: string[];
  onChange?: (values: string[]) => void;
  label?: string;
}
const InputRepeater: React.FC<InputRepeaterProps> = (
  props: InputRepeaterProps,
) => {
  const { initialValues = [], onChange, label = '내용' } = props;
  const [dataSource, setDataSource] = useState<InputRepeaterDataType[]>([
    DEFAULT_ROW,
  ]);

  useEffect(() => {
    if (initialValues.length === 0) {
      setDataSource([DEFAULT_ROW]);
    } else {
      setDataSource(
        initialValues.map((value, index) => {
          return {
            key: index + 1,
            content: value,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDataChange = (newDataSource: InputRepeaterDataType[]) => {
    setDataSource(newDataSource);
    if (onChange) {
      onChange(
        newDataSource
          .filter(({ content }) => !!content)
          .map(({ content }) => content),
      );
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    handleDataChange(newData);
  };

  const defaultColumns: ColumnTypes[number][] = [
    {
      key: 'sort',
    },
    {
      title: label,
      dataIndex: 'content',
      width: '90%',
      render: (value, record, index) => {
        return (
          <TextArea
            autoSize
            placeholder="내용을 입력해주세요"
            value={value}
            onChange={(e) => {
              const { target } = e;
              const { value } = target;
              const newDataSource = [...dataSource];
              newDataSource.splice(index, 1, {
                ...newDataSource[index],
                content: value,
              });
              handleDataChange(newDataSource);
            }}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                handleDelete(record.key);
              }}
              disabled={dataSource.length === 1}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    const newRow: InputRepeaterDataType = {
      key: dataSource.length + 1,
      content: '',
    };
    const newData = [...dataSource, newRow];
    handleDataChange(newData);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.key === active.id);
      const overIndex = dataSource.findIndex((i) => i.key === over?.id);
      const newData = arrayMove(dataSource, activeIndex, overIndex);
      handleDataChange(newData);
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // @ts-ignore
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={defaultColumns}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>

      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button
          onClick={handleAdd}
          type="primary"
          style={{ marginTop: 16, marginRight: 8 }}
        >
          추가하기
        </Button>
      </div>
    </div>
  );
};

export default InputRepeater;
