import { DefaultOptionType } from 'antd/es/select';
import { GetEventsOutput } from '@apis/ads/get-events/types';
import { GetProductOutput } from '@apis/ads/get-products/types';
import { formatNumberWithCommas } from 'utils/format';
import { GetProductCategoriesOutput } from '@apis/ads/get-product-categories/type';
import { Partner } from '../../../@types/partner/models/Partner';

interface GetPartnerOptionsProps {
  partners: Partner[];
  partnerid: unknown;
}

export const getPartnersOptions = ({
  partnerid,
  partners,
}: GetPartnerOptionsProps): DefaultOptionType[] => {
  const options: DefaultOptionType[] = partners
    .filter((item) => item.id !== Number(partnerid))
    .map(({ id, displayName, regionDepth1, regionDepth2 }) => {
      const value = id;
      const label =
        regionDepth1 && regionDepth2
          ? `${displayName}(${regionDepth1} ${regionDepth2})`
          : displayName;
      return {
        value,
        label,
      };
    });
  return options;
};

export const getEventsOptions = ({ rows }: { rows: GetEventsOutput[] }) => {
  const options: DefaultOptionType[] = rows.map(({ id, eventTitle }) => ({
    value: id,
    label: eventTitle,
  }));
  return options;
};

export const getProductOptions = ({ rows }: { rows: GetProductOutput[] }) => {
  const options: DefaultOptionType[] = [];
  rows.forEach(({ productOptions, isSecret }) => {
    productOptions.forEach(({ id, productOptionName, productOptionPrice }) => {
      const label = `${
        isSecret ? '(시크릿) ' : ''
      }${productOptionName} / ₩${formatNumberWithCommas(productOptionPrice)}`;
      options.push({ label, value: id });
    });
  });
  console.log('getProductOptions:', options);
  return options;
};

export const getCategoriesOptions = ({
  rows,
}: {
  rows: GetProductCategoriesOutput[];
}) => {
  const options: DefaultOptionType[] = rows.map(
    ({ id, productCategoryName }) => ({
      value: id,
      label: productCategoryName,
    }),
  );
  console.log('getCategoriesOptions:', options);
  return options;
};
