import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';

import { FetchReviewQuestionListQuery } from '../../../../@types/review/params/FetchReviewQuestionListQuery';
import { fetchReviewQuestions } from '../../../../@apis/reviews';
import { ReviewQuestion } from '../../../../@types/review/models/ReviewQuestion';
import { ReviewTranslations } from '../../../../@types/review/constants/reviews.constants';

function ReviewQuestionListPage() {
  const navigate = useNavigate();

  const COLS: ColDef[] = [
    {
      field: 'title',

      flex: 1,
      headerName: '질문',
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (col: ICellRendererParams<ReviewQuestion>) => {
        const { data } = col;
        if (data) {
          const { title, globalReviewQuestions } = data;
          return (
            <div className="flex flex-col">
              <div>{`[한국어] ${title}`}</div>
              {globalReviewQuestions.map(({ title, language }) => {
                return (
                  <div
                    key={language.code}
                  >{`[${language.managementName}] ${title}`}</div>
                );
              })}
            </div>
          );
        }
        return '';
      },
    },
    {
      field: 'titleAlias',
      width: 200,
      minWidth: 200,
      headerName: '질문 통계용 별칭',
      sortable: false,
    },
    {
      field: 'responseType',
      minWidth: 100,
      width: 100,
      headerName: '답변유형',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<ReviewQuestion>) => {
        const { data } = col;
        if (data) {
          const { responseType } = data;
          return ReviewTranslations[responseType] || '';
        }
        return '';
      },
    },
    {
      field: 'targetBy',
      minWidth: 100,
      width: 100,
      headerName: '집계유형',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<ReviewQuestion>) => {
        const { data } = col;
        if (data) {
          const { targetBy } = data;
          return ReviewTranslations[targetBy] || '';
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchReviewQuestionListQuery) => {
      const { rows, count } = await fetchReviewQuestions(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: ReviewQuestion) => {
    const { id } = data;
    navigate(PAGE_PATHS.REVIEW_CONFIG_QUESTIONS_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.REVIEW_CONFIG_QUESTION_ADD}>
          <Button>문항 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      rowHeight={160}
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      onRowClick={handleTableRowClick}
    />
  );
}

export default ReviewQuestionListPage;
