import { AppError, request, Row, Rows } from '@networks/index';
import { BlogReference } from '../@types/blog-reference/models/BlogReference';
import { CreateBlogReferenceBody } from '../@types/blog-reference/params/CreateBlogReferenceBody';
import { FetchBlogReferenceListQuery } from '../@types/blog-reference/params/FetchBlogReferenceListQuery';
import { UpdateBlogReferenceBody } from '../@types/blog-reference/params/UpdateBlogReferenceBody';

const API_ROOT = 'admin/references';

export const createBlogReference = async (body: CreateBlogReferenceBody) => {
  try {
    const { data } = await request<Row<BlogReference>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 레퍼런스 목록 조회
 */
export const fetchBlogReferences = async (
  query: FetchBlogReferenceListQuery,
) => {
  try {
    const { data } = await request<Rows<BlogReference>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 레퍼런스 상세 조회
 */
export const fetchBlogReferenceDetail = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request<Row<BlogReference>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 레퍼런스 정보 수정
 */
export const updateBlogReference = async (
  id: number | string,
  body: UpdateBlogReferenceBody,
) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 블로그 레퍼런스 삭제
 */
export const deleteBlogReference = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
