import styled from 'styled-components';
import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { createStore, StoreType } from 'polotno/model/store';
import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import Toolbar from 'polotno/toolbar/toolbar';
import Workspace from 'polotno/canvas/workspace';
import ZoomButtons from 'polotno/toolbar/zoom-buttons';
import DownloadButton from 'polotno/toolbar/download-button';
import Button from '@components/v2/antd/general/Button';
import {
  getRandomColorFromHSB,
  getRandomFromStringArray,
  getRandomSameToneColorsFromHSB,
  mapBlogAIText,
  overrideTheme,
} from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-theme.utils';
import { getRandomBlogTextInfo } from '@pages/private/ai-design/ai-design-templates-v2/constants/blog-text-sample.constants';
import { ColorTheme } from '../../../../../@types/ai-design-template/models/ColorTheme';

export interface TemplateEditorModalProps {
  initialData?: JSON;
  onClose?: () => void;
  colorTheme?: ColorTheme;
  randomMainFontFamilies?: string[];
  randomSubFontFamilies?: string[];
  randomImageUrls?: string[];
}

function TemplateTestModal(props: TemplateEditorModalProps) {
  const {
    onClose,
    colorTheme,
    randomMainFontFamilies = [],
    randomSubFontFamilies = [],
    randomImageUrls = [],
    initialData,
  } = props;

  const store = useMemo(
    () =>
      createStore({
        key: process.env.REACT_APP_POLOTNO_KEY || '',
        showCredit: true,
      }),
    [],
  );

  useEffect(() => {
    if (initialData && store) {
      try {
        store.loadJSON(initialData);
        store.selectPage(store.pages[0].id);
      } catch (e) {
        let errorDetail = '';
        if (e) {
          errorDetail = `올바르지 않은 JSON 파일이 입력되었습니다.\n입력값 : ${initialData}\n${errorDetail}`;
        }
        console.warn(errorDetail);
      }
    }
  }, [initialData, store]);

  const applyRandomTheme = () => {
    let colorInfo: { primaryLight: string; primaryDark: string } | undefined;
    let mainFontFamily: string | undefined;
    let subFontFamily: string | undefined;
    let imageUrl: string | undefined;

    if (colorTheme) {
      const { sameColorTone } = colorTheme;
      let primaryLightHex = '';
      let primaryDarkHex = '';

      if (sameColorTone) {
        const { lightColor, darkColor } = getRandomSameToneColorsFromHSB(
          colorTheme.primaryLight,
          colorTheme.primaryDark,
        );
        colorInfo = {
          primaryLight: lightColor,
          primaryDark: darkColor,
        };
      } else {
        primaryLightHex = getRandomColorFromHSB(colorTheme.primaryLight);
        primaryDarkHex = getRandomColorFromHSB(colorTheme.primaryDark);
        colorInfo = {
          primaryLight: primaryLightHex,
          primaryDark: primaryDarkHex,
        };
      }
    }

    // 폰트 추출
    if (randomMainFontFamilies.length > 0) {
      mainFontFamily = getRandomFromStringArray(randomMainFontFamilies);
    }
    if (randomSubFontFamilies.length > 0) {
      subFontFamily = getRandomFromStringArray(randomSubFontFamilies);
    }

    // 이미지
    if (randomImageUrls.length > 0) {
      imageUrl = getRandomFromStringArray(randomImageUrls);
    }

    const json: any = store.toJSON();
    const newJson = overrideTheme(json, {
      colorInfo,
      mainFontFamily,
      subFontFamily,
      randomImageUrl: imageUrl,
    });
    store.loadJSON(newJson, true);
  };

  const handleCloseClick = () => {
    if (onClose) onClose();
  };

  const renderActionControls = (store: StoreType) => {
    return (
      <ActionControls>
        <DownloadButton store={store} />
        <Button type="default" onClick={handleCloseClick}>
          닫기
        </Button>
        <Button
          onClick={() => {
            const newJson = mapBlogAIText(
              store.toJSON() as any,
              getRandomBlogTextInfo(),
            );
            store.loadJSON(newJson, true);
          }}
        >
          블로그 테스트
        </Button>
        <Button
          onClick={() => {
            applyRandomTheme();
          }}
        >
          이미지 재생성
        </Button>
      </ActionControls>
    );
  };

  return createPortal(
    <Dim>
      <ModalContainer>
        <PolotnoContainer style={{ width: '100%', height: '100%' }}>
          <WorkspaceWrap>
            <Toolbar
              store={store}
              downloadButtonEnabled={false}
              components={{
                ActionControls: (value: { store: StoreType }) => {
                  return renderActionControls(value.store);
                },
              }}
            />
            <Workspace store={store} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </ModalContainer>
    </Dim>,

    window.document.body,
  );
}

export default TemplateTestModal;

const Dim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 100000;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  width: 90%;
  height: 90vh;
  z-index: 13;
  background: #fff;
`;

const ActionControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
