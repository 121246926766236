import React, { useCallback, useState } from 'react';
import { Dropdown } from 'antd';
import { Typo, typo } from '@components/atoms';
import colorSet from '@styles/colors';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PAGE_PATHS } from '@routes/constants';
import { useAuthStore } from '@store/useAuthStore';
import { HeaderProps } from './types';
import * as Styled from './styles';

function Header(props: HeaderProps) {
  const navigate = useNavigate();

  // props
  const { sideNavRef } = props;

  // state
  const [isAuthDropdownOpen, setIsAuthDropdownOpen] = useState(false);
  const [isTabletAuthDropdownOpen, setIsTabletAuthDropdownOpen] =
    useState(false);

  const [loginUser, clearAuthState] = useAuthStore((state) => [
    state.loginUser,
    state.clearAuthState,
  ]);

  // event
  const handleToggleSideNavOpen = () => {
    sideNavRef.current?.handleToggleSubNavOpen();
  };

  const handleLogout = useCallback(() => {
    clearAuthState();
    navigate(PAGE_PATHS.LOGIN);
  }, [clearAuthState, navigate]);

  // renderer
  const renderDropdown = () => {
    return (
      <Styled.HeaderAuthDropdown
        onClick={() => {
          setIsAuthDropdownOpen(false);
        }}
      >
        <AccountDropDownView>
          <Typo typoType="b9m">{loginUser?.administratorName}</Typo>
          <Typo typoType="b11" color="gray5">
            {loginUser?.administratorEmail}
          </Typo>
        </AccountDropDownView>
        <LogoutUl>
          <LogoutTextLi onClick={handleLogout}>
            <LogoutText>로그아웃</LogoutText>
          </LogoutTextLi>
        </LogoutUl>
      </Styled.HeaderAuthDropdown>
    );
  };

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderLeftAccessory>
        <Styled.Logo src={'/assets/icons/icon-logo_symbol.svg'} />
        <Styled.Dividier />
        <Typo typoType="b4m">부스트리 관리자</Typo>
      </Styled.HeaderLeftAccessory>
      <Styled.HeaderRightAccessory>
        <Dropdown
          className="pc-dropdown"
          open={isAuthDropdownOpen}
          menu={{ items: [], selectable: true }}
          onOpenChange={(e) => {
            setIsAuthDropdownOpen(e);
          }}
          trigger={['click']}
          dropdownRender={renderDropdown}
        >
          <Styled.HeaderAuthDropdownTrigger type="button">
            <Typo as="p" typoType="btn2">
              {`${loginUser?.administratorName}님`}
            </Typo>
            <span className="icon" />
          </Styled.HeaderAuthDropdownTrigger>
        </Dropdown>
      </Styled.HeaderRightAccessory>

      <Styled.MobileAccessory>
        <button
          className="icon"
          type="button"
          aria-label="메뉴"
          onClick={handleToggleSideNavOpen}
        />
        <Dropdown
          className="tablet-dropdown"
          open={isTabletAuthDropdownOpen}
          menu={{ items: [], selectable: true }}
          onOpenChange={(e) => {
            setIsTabletAuthDropdownOpen(e);
          }}
          trigger={['click']}
          dropdownRender={renderDropdown}
        >
          <Styled.HeaderAuthDropdownTrigger type="button">
            <p>{loginUser?.administratorName} 님</p>
            <span className="icon" />
          </Styled.HeaderAuthDropdownTrigger>
        </Dropdown>
      </Styled.MobileAccessory>
    </Styled.HeaderContainer>
  );
}

export default Header;

const LogoutUl = styled.ul`
  &:hover {
    cursor: pointer;
  }
`;

const LogoutTextLi = styled.li`
  border-radius: 8px;
  &:hover {
    background-color: ${colorSet.gray12};
  }
`;

const LogoutText = styled(Typo)`
  ${typo.b10}
  color: ${colorSet.red3};
`;

// 계정관리
const AccountDropDownView = styled.div`
  display: flex;
  flex-direction: column;
`;
