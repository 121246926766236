export enum AIModelImageInspectionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export const AI_DESIGN_TRANSLATIONS: Record<string, string> = {
  // 모델 이미지 검수 상태
  [AIModelImageInspectionStatus.Pending]: '검수대기',
  [AIModelImageInspectionStatus.Approved]: '사용가능',
  [AIModelImageInspectionStatus.Rejected]: '반려',
};

export const AI_IMAGE_INSPECTION_STATUS_OPTIONS = [
  {
    label: AI_DESIGN_TRANSLATIONS[AIModelImageInspectionStatus.Pending],
    value: AIModelImageInspectionStatus.Pending,
  },
  {
    label: AI_DESIGN_TRANSLATIONS[AIModelImageInspectionStatus.Approved],
    value: AIModelImageInspectionStatus.Approved,
  },
  {
    label: AI_DESIGN_TRANSLATIONS[AIModelImageInspectionStatus.Rejected],
    value: AIModelImageInspectionStatus.Rejected,
  },
];
