import React from 'react';
import { Checkbox, CheckboxProps, ConfigProvider } from 'antd';
import styled, { css } from 'styled-components';
import colorSet from '@styles/colors';
import { CheckboxType } from './types';

const DEFAULT_SIZE = 24;
const DEFAULT_GAP = 8;

export interface CheckboxItemProps extends CheckboxProps {
  size?: number;
  gap?: number;
  checkboxType?: CheckboxType;
}

function CheckboxItem({
  size = DEFAULT_SIZE,
  gap = DEFAULT_GAP,
  checkboxType = 'square',
  ...radioItemProps
}: CheckboxItemProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:
            checkboxType !== 'transparent'
              ? colorSet.primary3
              : colorSet.gray13,
        },
      }}
    >
      <StyledCheckbox
        size={size}
        gap={checkboxType !== 'transparent' ? gap : 0}
        checkboxType={checkboxType}
        onChange={radioItemProps.onChange}
        checked={radioItemProps.checked}
        {...radioItemProps}
      />
    </ConfigProvider>
  );
}

export default CheckboxItem;

const getCheckboxType = (checkboxType: CheckboxType) => {
  switch (checkboxType) {
    case 'square':
      return css`
        border-radius: 6px;

        &::after {
          background-image: url('/assets/icons/icon_check_white.svg');
        }
      `;

    case 'circle':
      return css`
        border-radius: 100px;

        &::after {
          background-image: url('/assets/icons/icon_check_white.svg');
        }
      `;

    case 'transparent':
      return css`
        border: none;
        background: none;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/assets/icons/icon_check_gray8.svg');
        z-index: -1;

        &::after {
          background-image: url('/assets/icons/icon_check_green3.svg');
          z-index: 0;
        }
      `;
    default:
      return css``;
  }
};

const getCheckboxDisableColor = (checkboxType: CheckboxType) => {
  switch (checkboxType) {
    case 'square':
      return css`
        border-radius: 6px;
        background: ${colorSet.gray10};
        /* border: 1px solid ${colorSet.gray9}; */
      `;
    case 'circle':
      return css`
        border-radius: 100px;
        background: ${colorSet.gray10};
        /* border: 1px solid ${colorSet.gray9}; */
      `;
    case 'transparent':
      return css`
        border: none;
        background: none;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/assets/icons/icon_check_gray10.svg');
      `;
    default:
      return css``;
  }
};

const StyledCheckbox = styled(Checkbox)<{
  size: number;
  gap: number;
  checkboxType: CheckboxType;
}>`
  align-items: center;
  margin: 0;
  padding: 0;
  + .ant-checkbox-wrapper {
    margin: 0;
    width: 200px;
  }

  input {
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  > span {
    font-size: 16px;
    padding: 0;
  }

  .ant-checkbox {
    top: 0;
    margin-right: ${({ gap }) => `${gap}px`};
    transform: none;
  }

  .ant-checkbox-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    ${({ checkboxType }) => getCheckboxType(checkboxType)}

    &::after {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 50%;
      height: 50%;
      border: none;
      transform: none;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox-disabled {
    ${({ checkboxType }) => getCheckboxDisableColor(checkboxType)}
  }
`;
