export enum AiDesignTemplateWorkStatus {
  WorkReady = 'workReady',
  Working = 'working',
  WorkComplete = 'workComplete',
}

export enum AIDesignProductThumbnailCopyType {
  // 타이틀
  productTitle = 'productTitle',
  // 서브 타이틀
  productSubtitle = 'productSubTitle',
  // 모델 이미지
  modelImage = 'modelImage',
}

export enum AiDesignTemplateSizeType {
  // 블로그 이미지
  NaverBlog = 'naverBlog',
  // 인스타
  Instagram = 'instagram',
  // 카플친 (리스트 형)
  KakaoPlusList = 'kakaoPlusList',
  // 카플친 (단건)
  KakaoPlus = 'kakaoPlus',
  // 홈페이지 (팝업)
  HomepagePopUp = 'homepagePopUp',
  // 홈페이지 (썸네일)
  HomepageThumbnail = 'homepageThumbnail',
  // 원내 출력물
  A4 = 'a4',
  A5 = 'a5',
  Etc = 'etc',
}

// 컨텐츠 카테고리
export enum AiDesignTemplateType {
  // 이벤트
  Event = 'event',

  // 멤버십
  Membership = 'membership',

  // 공지사항
  Notice = 'notice',

  // 진료시간 및 일정 안내
  BusinessHourNotice = 'businessHourNotice',

  // 양식
  PaperForm = 'paperForm',

  // 시술정보
  TreatmentInfo = 'treatmentInfo',

  // 메뉴북
  MenuBook = 'menuBook',

  // 이벤트 메뉴북
  EventMenuBook = 'eventMenuBook',

  // 블로그 스킨
  BlogSkin = 'blogSkin',

  // 블로그 이미지
  NaverBlog = 'naverBlog',

  // 시술 썸네일
  ProductThumbnail = 'productThumbnail',

  // 기타
  Etc = 'etc',
}

export const AI_DESIGN_TRANSLATIONS: Record<string, string> = {
  // 작업 상태
  workReady: '작업대기',
  working: '작업중',
  workComplete: '작업완료',

  // AI 디자인 컨텐츠 유형
  event: '이벤트',
  membership: '멤버십',
  notice: '공지사항',
  businessHourNotice: '진료일정안내',
  paperForm: '기타 양식',
  treatmentInfo: '시술정보',
  menuBook: '메뉴북',
  eventMenuBook: '메뉴북(이벤트)',
  blogSkin: '블로그스킨',
  naverBlog: '블로그',
  productThumbnail: '상품썸네일',
  etc: '기타',

  // 컨텐츠 사이즈별
  instagram: '인스타그램',
  kakaoPlusList: '카플친(리스트)',
  kakaoPlus: '카플친(단건)',
  homepagePopUp: '팝업',
  homepageThumbnail: '홈페이지 썸네일',
  a4: 'A4',
  a5: 'A5',
};

export const AI_DESIGN_TEMPLATE_TYPE_OPTIONS = Object.values(
  AiDesignTemplateType,
).map((value) => {
  return {
    label: AI_DESIGN_TRANSLATIONS[value],
    value,
  };
});

export const AI_DESIGN_TEMPLATE_SIZE_TYPE_OPTIONS = Object.values(
  AiDesignTemplateSizeType,
).map((value) => {
  return {
    label: AI_DESIGN_TRANSLATIONS[value],
    value,
  };
});

export const AI_DESIGN_WORK_STATUS_OPTIONS = [
  // 작업대기
  {
    label: AI_DESIGN_TRANSLATIONS[AiDesignTemplateWorkStatus.WorkReady],
    value: AiDesignTemplateWorkStatus.WorkReady,
  },
  // 작업중
  {
    label: AI_DESIGN_TRANSLATIONS[AiDesignTemplateWorkStatus.Working],
    value: AiDesignTemplateWorkStatus.Working,
  },
  // 작업완료
  {
    label: AI_DESIGN_TRANSLATIONS[AiDesignTemplateWorkStatus.WorkComplete],
    value: AiDesignTemplateWorkStatus.WorkComplete,
  },
];
