import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import { useQuery } from 'react-query';
import { Checkbox, List, Tag } from 'antd';
import Input from '@components/v2/antd/data-entry/Input';
import { AIDesignAsset } from '../../../../../../../@types/ai-design-asset/models/AIDesignAsset';
import { fetchAIDesignAssets } from '../../../../../../../@apis/ai-design-assets';

export interface ImageAssetSelectProps {
  onAssetSelect?: (assetUrls: string[]) => void;
  onClose?: () => void;
}

function ImageAssetSelectModal(props: ImageAssetSelectProps) {
  const { onAssetSelect, onClose } = props;
  const [rows, setRows] = useState<AIDesignAsset[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const { refetch, isFetching } = useQuery(
    [`FETCH_AI_DESIGN_ASSETS`],
    () => {
      return fetchAIDesignAssets({
        page: 1,
        pageSize: 9999,
        categories: [],
        keyword: searchKeyword,
      });
    },
    {
      onSuccess: (res) => {
        setRows(res.rows);
      },
      onError: () => {
        setRows([]);
      },
      enabled: true,
      retry: 0,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const isChecked = (assetUrl: string) => {
    return selectedAssets.includes(assetUrl);
  };

  const toggleAssetCheckState = (assetUrl: string) => {
    if (selectedAssets.includes(assetUrl)) {
      setSelectedAssets(
        selectedAssets.filter((url) => {
          return url !== assetUrl;
        }),
      );
    } else {
      setSelectedAssets([...selectedAssets, assetUrl]);
    }
  };

  const confirmText =
    selectedAssets.length > 0
      ? `이미지 등록 (${selectedAssets.length})`
      : '이미지 등록';

  const gutter = 12;
  const colSize = 4;
  const modalWidth = 860;
  const itemWidth = (modalWidth - 48) / colSize - gutter;

  return (
    <Modal
      size={modalWidth}
      title="이미지 선택"
      disabled={selectedAssets.length === 0}
      confirmLabel={confirmText}
      onClose={() => {
        if (onClose) onClose();
      }}
      onConfirm={() => {
        if (onAssetSelect) onAssetSelect(selectedAssets);
        if (onClose) onClose();
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
          gap: 16,
        }}
      >
        <div>
          <Input
            allowClear
            value={searchKeyword}
            size="large"
            style={{
              width: '100%',
            }}
            placeholder={'검색어를 입력해주세요 (카테고리, 시술명, 병원명 등)'}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                refetch();
              }
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'scroll',
          }}
        >
          <List
            grid={{
              column: colSize,
            }}
            loading={isFetching}
            dataSource={rows}
            renderItem={(data) => {
              const { categories = [], tags = [], assetUrl } = data;
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    toggleAssetCheckState(assetUrl);
                  }}
                >
                  <img
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.2)',
                    }}
                    alt="asset"
                    width={itemWidth}
                    src={data.assetUrl}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 6,
                      bottom: 6,
                      display: 'block',
                      backgroundColor: isChecked(assetUrl)
                        ? 'rgba(0,0,0,0.5)'
                        : 'transparent',
                      transition: 'backgroundColor .2s',
                    }}
                  />
                  <Checkbox
                    checked={isChecked(assetUrl)}
                    style={{
                      position: 'absolute',
                      top: 4,
                      left: 4,
                    }}
                  />

                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      bottom: 12,
                      left: 4,
                      width: itemWidth,
                      height: 'auto',
                      flexWrap: 'wrap',
                      gap: 4,
                    }}
                  >
                    {categories.map((category) => {
                      return (
                        <Tag
                          style={{
                            marginInlineEnd: 0,
                          }}
                          color={'#000000'}
                          key={category}
                        >
                          {category}
                        </Tag>
                      );
                    })}
                    {tags.map((tag) => {
                      return (
                        <Tag
                          style={{
                            marginInlineEnd: 0,
                          }}
                          key={tag}
                        >
                          {tag}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ImageAssetSelectModal;
