import { AppError, Row, request } from '@networks/index';

import { GetAdsCampaignInput, GetAdsCampaignOutput } from './types';

const URL = '/admin/advertising-campaigns';

export const getAdsCampaign = async ({ id }: GetAdsCampaignInput) => {
  try {
    const { data } = await request<Row<GetAdsCampaignOutput>>({
      method: 'get',
      url: `${URL}/${id}`,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
