import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';

import { FilterForm } from '@components/organisms/TableFilter/types';
import { Tag } from 'antd';
import { FetchMemberAccountListQuery } from '../../../../@types/member/params/FetchMemberAccountListQuery';
import { Member } from '../../../../@types/member/models/Member';
import { fetchMemberAccounts } from '../../../../@apis/members';
import { getFormatTelNumber } from '../../../../utils/format';
import {
  MEMBER_ACCOUNT_TYPE_OPTIONS,
  MEMBER_BOOSTREE_ACCOUNT_TYPE_OPTIONS,
  MEMBER_GROUP_OPTIONS,
} from '../../../../@types/member/member.constants';

function PartnerAccountListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    keyword: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'memberGroup',
      options: MEMBER_GROUP_OPTIONS,
      label: '사용자 그룹',
      placeholder: '그룹 선택',
    },
    {
      type: 'select',
      field: 'isRoot',
      options: MEMBER_ACCOUNT_TYPE_OPTIONS,
      label: '계정유형',
      placeholder: '계정유형 선택',
    },
    {
      type: 'select',
      field: 'isBoostree',
      options: MEMBER_BOOSTREE_ACCOUNT_TYPE_OPTIONS,
      label: '소속',
      placeholder: '소속 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchMemberAccountListQuery> =
    {
      filterKey: 'keyword',
      searchInputPlaceholder: '계정, 사용자명, 연락처 입력',
    };

  const COLS: ColDef[] = [
    {
      field: 'memberGroup',
      minWidth: 100,
      width: 100,
      headerName: '사용자 그룹',
      cellRenderer: ({ data }: ICellRendererParams<Member>) => {
        if (data) {
          const { memberGroup } = data;
          if (memberGroup) {
            switch (memberGroup) {
              case 'PARTNER':
                return '병원 사용자';
              case 'OV':
                return 'OV';
              case 'OMA':
                return 'OMA';
              case 'ADMIN':
                return '관리자';
              default:
                return '';
            }
          }
        }
        return '';
      },
      sortable: false,
    },
    {
      field: 'memberName',
      minWidth: 153,
      width: 153,
      headerName: '사용자명',
      sortable: false,
    },
    {
      field: 'memberAId',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '계정명',
      sortable: false,
    },
    {
      field: 'isRoot',
      minWidth: 153,
      width: 153,
      headerName: '계정유형',
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }: ICellRendererParams<Member>) => {
        if (data) {
          const { isRoot } = data;

          if (isRoot) {
            const tag = <Tag color="blue">ROOT</Tag>;
            return tag;
          }

          return <Tag>일반</Tag>;
        }
        return '-';
      },
    },
    {
      field: 'isBoostree',
      minWidth: 153,
      width: 153,
      headerName: '소속',
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }: ICellRendererParams<Member>) => {
        if (data) {
          const { isBoostree } = data;

          if (isBoostree) {
            const tag = <Tag color="green">부스트리</Tag>;
            return tag;
          }

          return <Tag>외부</Tag>;
        }
        return '-';
      },
    },
    {
      field: 'memberPhone',
      minWidth: 153,
      width: 153,
      headerName: '휴대폰번호',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<Member>) => {
        if (data) {
          const { memberPhone } = data;
          return getFormatTelNumber(memberPhone);
        }
        return '-';
      },
    },
    // {
    //   field: 'grade',
    //   minWidth: 153,
    //   width: 153,
    //   headerName: '등급',
    //   sortable: false,
    //   cellRenderer: ({ data }: ICellRendererParams<Member>) => {
    //     if (data) {
    //       const { grade } = data;
    //       return getGradeText(grade);
    //     }
    //     return '-';
    //   },
    // },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchMemberAccountListQuery) => {
      const { rows, count } = await fetchMemberAccounts(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: Member) => {
    const { id } = data;
    navigate(
      PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_DETAIL.replace(':id', `${id}`),
    );
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_PARTNER_ACCOUNT_ADD}>
          <Button>사용자 계정 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      filterForms={filterForms}
    />
  );
}

export default PartnerAccountListPage;
