import React, { useEffect, useState } from 'react';
import { useAuthStore } from '@store/useAuthStore';
import { useAgencyStore } from '@store/useAgencyStore';
import { usePartnerStore } from '@store/usePartnerStore';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { fetchLoginUserInfo } from '../../@apis/administrators';
import { fetchPartners } from '../../@apis/partners';
import { fetchAgencies } from '../../@apis/agencies';

interface InitialDataLoaderProps {
  children: React.ReactNode;
}

function InitialDataLoader(props: InitialDataLoaderProps) {
  const { saveLoginUser, token, clearAuthState } = useAuthStore();
  const { saveAgencies } = useAgencyStore();
  const { savePartners } = usePartnerStore();
  const [dataFetching, setDataFetching] = useState(false);
  const { children } = props;

  useEffect(() => {
    (async function fetchData() {
      await fetchInitialData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agencyProcess = async () => {
    const { rows } = await fetchAgencies({
      page: 1,
      pageSize: 1000,
    });
    saveAgencies(rows);
  };

  const partnerProcess = async () => {
    const { rows } = await fetchPartners({
      page: 1,
      pageSize: 1000,
    });
    savePartners(rows);
  };

  /**
   * Redux 초기 데이터 검증 및 설정
   */
  const fetchInitialData = async () => {
    if (token) {
      setDataFetching(true);
      try {
        // 유저정보 검증 및 저장
        const { row } = await fetchLoginUserInfo();
        saveLoginUser(row);
        agencyProcess();
        partnerProcess();
      } catch (e) {
        // 리덕스 유저정보 초기화
        clearAuthState();
      } finally {
        setDataFetching(false);
      }
    }
  };

  if (dataFetching) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }
  return children;
}

export default InitialDataLoader;
