import { observer } from 'mobx-react-lite';
import Select from '@components/v2/antd/data-entry/Select';
import { FONT_CATEGORY_OPTIONS } from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
import React from 'react';

// @ts-ignore
const TextFontTypeSelect = observer(({ element }) => {
  let value;
  const { custom = {} } = element;
  if (custom) {
    const { fontCategory } = custom;
    value = fontCategory;
  }
  return (
    <Select
      allowClear
      placeholder="폰트 타입"
      value={value}
      style={{
        width: '120px',
      }}
      options={FONT_CATEGORY_OPTIONS}
      onChange={(value) => {
        element.set({
          custom: {
            ...custom,
            fontCategory: value,
          },
        });
      }}
    />
  );
});

export default TextFontTypeSelect;
