import { AppError, request, Row, Rows } from '@networks/index';
import { GlobalThroughputItem } from '../@types/global-throughput-item/models/GlobalThroughputItem';
import { CreateGlobalThroughputItemBody } from '../@types/global-throughput-item/params/CreateGlobalThroughputItemBody';
import { FetchGlobalThroughputItemListQuery } from '../@types/global-throughput-item/params/FetchGlobalThroughputItemListQuery';
import { UpdateGlobalThroughputItemBody } from '../@types/global-throughput-item/params/UpdateGlobalThroughputItemBody';
import { GlobalCatalogCategory } from '../@types/global-throughput-item/models/GlobalCatalogCategory';

const API_ROOT = 'admin/v1/global-throughput-items';

export const createGlobalThroughputItem = async (
  body: CreateGlobalThroughputItemBody,
) => {
  try {
    const { data } = await request<Rows<GlobalThroughputItem>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalThroughputItemCategories = async () => {
  try {
    const url = `${API_ROOT}/categories`;
    const { data } = await request<Rows<GlobalCatalogCategory>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalThroughputItems = async (
  query: FetchGlobalThroughputItemListQuery,
) => {
  try {
    const { data } = await request<Rows<GlobalThroughputItem>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalThroughputItemDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<GlobalThroughputItem>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateGlobalThroughputItem = async (
  id: number | string,
  body: UpdateGlobalThroughputItemBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<GlobalThroughputItem>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
