import React from 'react';
import { Table, TableProps } from 'antd';
import dayjs from 'dayjs';
import { BlogSubscription } from '../../../../../@types/blog-subscription/models/BlogSubscription';

export interface BlogSubscriptionTableProps {
  subscriptions: BlogSubscription[];
}

function BlogSubscriptionTable(props: BlogSubscriptionTableProps) {
  const { subscriptions } = props;

  console.log(subscriptions);

  const cols: TableProps<BlogSubscription>['columns'] = [
    {
      width: 150,
      dataIndex: 'createdAt',
      title: '등록일',
      key: 'createdAt',
      render: (_, record) => {
        const { createdAt } = record;
        return createdAt ? dayjs(createdAt).format('YYYY-MM-DD HH:mm') : '-';
      },
    },
    {
      width: 150,
      dataIndex: 'subscriptionStartDateTime',
      title: '구독시작일',
      key: 'subscriptionStartDateTime',
      render: (_, record) => {
        const { subscriptionStartDateTime } = record;
        return subscriptionStartDateTime
          ? dayjs(subscriptionStartDateTime).format('YYYY-MM-DD HH:mm')
          : '-';
      },
    },
    {
      width: 150,
      dataIndex: 'subscriptionEndDateTime',
      title: '구독종료일',
      key: 'subscriptionEndDateTime',
      render: (_, record) => {
        const { subscriptionEndDateTime } = record;
        return subscriptionEndDateTime
          ? dayjs(subscriptionEndDateTime).format('YYYY-MM-DD HH:mm')
          : '-';
      },
    },
    {
      width: 150,
      dataIndex: 'subscriptionCancelDateTime',
      title: '구독취소일',
      key: 'subscriptionCancelDateTime',
      render: (_, record) => {
        const { subscriptionCancelDateTime } = record;
        return subscriptionCancelDateTime
          ? dayjs(subscriptionCancelDateTime).format('YYYY-MM-DD HH:mm')
          : '-';
      },
    },
    {
      width: 160,
      align: 'right',
      dataIndex: 'seoBlogPerMonth',
      title: '월별 검색최적화 포스트 수',
      key: 'seoBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'informationBlogPerMonth',
      title: '월별 정보형 포스트 수',
      key: 'informationBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'participationBlogPerMonth',
      title: '월별 참여형 포스트 수',
      key: 'participationBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'referenceBlogPerMonth',
      title: '월별 레퍼런스형 포스트 수',
      key: 'referenceBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'philosophyBlogPerMonth',
      title: '월별 철학형 포스트 수',
      key: 'philosophyBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'commonSenseBlogPerMonth',
      title: '월별 상식형 포스트 수',
      key: 'commonSenseBlogPerMonth',
    },
    {
      width: 140,
      align: 'right',
      dataIndex: 'diseaseInfoBlogPerMonth',
      title: '월별 질환형 포스트 수',
      key: 'diseaseInfoBlogPerMonth',
    },
  ];
  return (
    <Table
      pagination={false}
      columns={cols}
      locale={{
        emptyText: (
          <div
            style={{
              padding: 20,
            }}
          >
            등록된 구독 정보가 없습니다
          </div>
        ),
      }}
      dataSource={subscriptions.map((subscription) => {
        return {
          ...subscription,
          key: subscription.id,
        };
      })}
    />
  );
}

export default BlogSubscriptionTable;
