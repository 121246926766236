/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Select from '@components/v2/antd/data-entry/Select';
import Input from '@components/v2/antd/data-entry/Input';
import { ReviewQuestionForm } from '@pages/private/review-config/review-questions/types/ReviewQuestionForm';
import { Alert } from 'antd';
import { useQuery } from 'react-query';
import {
  REVIEW_RESPONSE_TYPE_OPTIONS,
  REVIEW_TARGET_OPTIONS,
  ReviewResponseType,
  ReviewTarget,
} from '../../../../@types/review/constants/reviews.constants';
import {
  addReviewQuestion,
  deleteReviewQuestion,
  fetchReviewQuestionDetail,
  updateReviewQuestion,
} from '../../../../@apis/reviews';
import {
  AddGlobalReviewQuestionBody,
  AddReviewQuestionBody,
} from '../../../../@types/review/params/AddReviewQuestionBody';
import { UpdateReviewQuestionBody } from '../../../../@types/review/params/UpdateReviewQuestionBody';
import { fetchLanguages } from '../../../../@apis/languages';

const INITIAL_VALUES: ReviewQuestionForm = {
  targetBy: ReviewTarget.ALL,
  title: '',
  responseType: ReviewResponseType.SCORE,
  responseOptions: [],
  titleAlias: '',
};

function ReviewQuestionDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [editable, setEditable] = useState(true);

  const { data: languages = [], isLoading } = useQuery({
    queryKey: ['fetchLanguages'],
    queryFn: () => fetchLanguages(),
    select: (data) => {
      return data.rows.filter(({ isDefault }) => {
        return !isDefault;
      });
    },
  });

  const formValidationSchema = yup.object({
    title: yup.string().required(),
    targetBy: yup.string().required(),
  });

  const formik = useFormik<ReviewQuestionForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);
      if (id) {
        await updateData(id, values);
      } else {
        const { title, titleAlias, responseType, responseOptions, targetBy } =
          values;
        const globalReviewQuestions: AddGlobalReviewQuestionBody[] = [];

        languages.forEach(({ id }) => {
          const titleKey = `title_${id}`;
          const responseOptionKey = `responseOptions_${id}`;
          const title = values[titleKey];
          const responseOptions = values[responseOptionKey] as string[];
          if (title && typeof title === 'string') {
            globalReviewQuestions.push({
              languageId: id,
              title,
              responseOptions: responseOptions || [],
            });
          }
        });

        await addData({
          title,
          titleAlias,
          responseType,
          responseOptions,
          targetBy,
          globalReviewQuestions,
        });
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchReviewQuestionDetail(id);
      const {
        title,
        targetBy,
        responseOptions,
        responseType,
        editable,
        titleAlias = '',
        globalReviewQuestions = [],
      } = row;
      setEditable(editable);

      const initialValues: ReviewQuestionForm = {
        responseOptions,
        titleAlias,
        responseType,
        title,
        targetBy,
      };

      globalReviewQuestions.forEach(
        ({ language, title, responseOptions = [] }) => {
          const { id } = language;
          initialValues[`title_${id}`] = title;
          initialValues[`responseOptions_${id}`] = responseOptions;
        },
      );

      setFormInitialValues(initialValues);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: AddReviewQuestionBody) => {
    try {
      await addReviewQuestion(body);
      showToast({
        description: '리뷰 문항이 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    values: ReviewQuestionForm,
  ) => {
    const { title, titleAlias, responseType, responseOptions, targetBy } =
      values;
    const globalReviewQuestions: AddGlobalReviewQuestionBody[] = [];

    languages.forEach(({ id }) => {
      const titleKey = `title_${id}`;
      const responseOptionKey = `responseOptions_${id}`;
      const title = values[titleKey];
      const responseOptions = values[responseOptionKey] as string[];
      if (title && typeof title === 'string') {
        globalReviewQuestions.push({
          languageId: id,
          title,
          responseOptions: responseOptions || [],
        });
      }
    });

    try {
      let params: UpdateReviewQuestionBody = {};

      if (editable) {
        params = {
          title,
          titleAlias,
          responseType,
          responseOptions,
          targetBy,
          globalReviewQuestions,
        };
      } else {
        params = {
          titleAlias,
          globalReviewQuestions,
        };
      }

      await updateReviewQuestion(id, params);
      showToast({
        description: '문항 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteData = async (id: number | string) => {
    try {
      await deleteReviewQuestion(id);
      showToast({
        description: '리뷰 문항이 삭제되었습니다.',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };
  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '메뉴 정보 등록';
    let alertMessage = '작성하신 내용대로 메뉴 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '메뉴 정보 수정';
      alertMessage = '작성하신 내용대로 메뉴 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof ReviewQuestionForm;
    placeholder?: string;
    disabled?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      disabled = false,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Input
          disabled={disabled}
          id={key}
          name={key}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          className="input-container"
          placeholder={placeholderText}
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof ReviewQuestionForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
    disabled?: boolean;
  }) => {
    const {
      mode,
      labelName,
      required = false,
      key,
      placeholder,
      options,
      disabled,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          disabled={disabled}
          mode={mode}
          showSearch
          value={formik.values[key]}
          options={options}
          onChange={(value) => {
            updateForm(key, value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderResponseOptionsForm = (info: { label: string; key: string }) => {
    const { label, key } = info;

    let value = [];
    // @ts-ignore
    if (formik.values[key]) {
      // @ts-ignore
      value = formik.values[key];
    }

    return (
      <FormItem label={label}>
        <Select
          disabled={!editable}
          style={{
            width: '100%',
          }}
          mode={'tags'}
          showSearch
          value={value}
          onChange={(value) => {
            updateForm(key, value);
          }}
          placeholder={'선택가능한 항목을 입력해주세요'}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        {id && editable && (
          <Button
            buttonColor="red"
            onClick={() => {
              showAlert({
                size: 360,
                title: '리뷰 문항 삭제',
                message:
                  '삭제하신 정보는 복구가 불가능해요.\n정말 삭제하시겠어요?',
                actions: [
                  { label: '취소' },
                  {
                    color: 'red',
                    label: '삭제',
                    onClick: async () => {
                      await deleteData(id);
                    },
                  },
                ],
              });
            }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching || isLoading ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        {!editable && (
          <div className="mb-4">
            <Alert
              showIcon
              message="이미 고객에게 답변을 받은 문항은 수정이 불가능합니다. 신규 문항을 등록해주세요. (수정 필요시 개발팀에 문의)"
              type="warning"
            />
          </div>
        )}
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '질문',
                required: true,
                key: 'title',
                disabled: !editable,
              })}
              {languages.map(({ id, name }) => {
                return renderInputForm({
                  labelName: `질문 - ${name}`,
                  required: true,
                  // @ts-ignore
                  key: `title_${id}`,
                });
              })}
              {renderInputForm({
                labelName: '통계용 질문 별칭',
                required: false,
                key: 'titleAlias',
              })}
              {renderSelectForm({
                labelName: '답변유형',
                required: true,
                key: 'responseType',
                options: REVIEW_RESPONSE_TYPE_OPTIONS,
                disabled: !editable,
              })}
              {formik.values.responseType === ReviewResponseType.CHECKBOX &&
                renderResponseOptionsForm({
                  label: '선택항목 입력',
                  key: 'responseOptions',
                })}
              {formik.values.responseType === ReviewResponseType.CHECKBOX &&
                languages.map(({ id, name }) => {
                  return renderResponseOptionsForm({
                    label: `선택항목 입력 - ${name}`,
                    key: `responseOptions_${id}`,
                  });
                })}
              {renderSelectForm({
                labelName: '집계유형',
                required: true,
                key: 'targetBy',
                options: REVIEW_TARGET_OPTIONS,
                disabled: !editable,
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default ReviewQuestionDetailPage;
