import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
  TableTabFilterInfo,
} from '@components/templates/PaginationTablePage';
import { usePartnerStore } from '@store/usePartnerStore';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { LabelColorType } from '@components/atoms/Label/types';
import colorSet from '@styles/colors';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { AIDesignTemplate } from '../../../../@types/ai-design-template/models/AIDesignTemplate';
import { fetchAIDesignTemplates } from '../../../../@apis/ai-design-templates';
import { renderTags, TableTag } from '../../../../utils/tableRenderHelper';
import {
  AI_DESIGN_TEMPLATE_SIZE_TYPE_OPTIONS,
  AI_DESIGN_TEMPLATE_TYPE_OPTIONS,
  AI_DESIGN_TRANSLATIONS,
  AiDesignTemplateWorkStatus,
} from '../../../../@types/ai-design-template/constants/ai-design.constants';
import { FetchAIDesignTemplateListQuery } from '../../../../@types/ai-design-template/params/FetchAIDesignTemplateListQuery';

function AIDesignerTemplateListV2Page() {
  const navigate = useNavigate();
  const { partnerOptions } = usePartnerStore();

  const tabFilterInfo: TableTabFilterInfo = {
    filterKey: 'workStatus',
    tabs: [
      {
        label: '작업대기',
        filterValue: AiDesignTemplateWorkStatus.WorkReady,
      },
      {
        label: '작업중',
        filterValue: AiDesignTemplateWorkStatus.Working,
      },
      {
        label: '작업완료',
        filterValue: AiDesignTemplateWorkStatus.WorkComplete,
      },
    ],
  };

  const FILTER_INITIAL_VALUES = {
    title: '',
    workStatus: undefined,
    partnerId: undefined,
    sizeType: undefined,
    templateType: undefined,
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'templateType',
      options: AI_DESIGN_TEMPLATE_TYPE_OPTIONS,
      label: '컨텐츠 유형',
      placeholder: '컨텐츠 유형 선택',
    },
    {
      type: 'select',
      field: 'sizeType',
      options: AI_DESIGN_TEMPLATE_SIZE_TYPE_OPTIONS,
      label: '배포채널',
      placeholder: '배포채널 선택',
    },
    {
      type: 'select',
      field: 'partnerId',
      options: partnerOptions,
      label: '사용가능 병원',
      placeholder: '병원 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchAIDesignTemplateListQuery> =
    {
      filterKey: 'title',
      searchInputPlaceholder: '템플릿명',
    };

  const COLS: ColDef[] = [
    {
      field: 'title',
      minWidth: 300,
      width: 300,
      flex: 1,
      headerName: '템플릿 명',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { title, thumbnailUrl } = data;
          const Title = (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                style={{
                  border: `1px solid ${colorSet.gray10}`,
                }}
                width={100}
                src={thumbnailUrl}
                alt="썸네일"
              />
              <span>{title}</span>
            </div>
          );
          return Title;
        }
        return '';
      },
    },
    {
      field: 'templateType',
      minWidth: 153,
      width: 153,
      headerName: '컨텐츠 유형',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { templateType } = data;
          return AI_DESIGN_TRANSLATIONS[templateType] || '';
        }
        return '';
      },
    },
    {
      field: 'templateType',
      minWidth: 153,
      width: 153,
      headerName: '배포채널',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { sizeType } = data;
          return AI_DESIGN_TRANSLATIONS[sizeType] || '';
        }
        return '';
      },
    },
    {
      field: 'partners',
      minWidth: 300,
      width: 300,
      headerName: '사용가능 병원',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { partnerNames = [] } = data;
          let tags: TableTag[] = partnerNames.map((name) => {
            return {
              value: name,
              type: 'fill',
              color: 'primary',
            };
          });

          if (tags.length === 0) {
            tags = [
              {
                value: '전체',
                type: 'light',
                color: 'gray',
              },
            ];
          }
          return renderTags(tags);
        }
        return '';
      },
    },
    {
      field: 'categories',
      minWidth: 153,
      width: 153,
      headerName: '검색태그',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { categories } = data;
          const tags: TableTag[] = categories.map((category) => {
            return {
              value: category,
              color: 'primary',
              type: 'light',
            };
          });
          return renderTags(tags);
        }
        return '';
      },
    },
    {
      field: 'workStatus',
      minWidth: 153,
      width: 153,
      headerName: '작업상태',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignTemplate>) => {
        const { data } = col;
        if (data) {
          const { workStatus } = data;

          let color: LabelColorType = 'gray';
          switch (workStatus) {
            case AiDesignTemplateWorkStatus.WorkReady:
              color = 'blue';
              break;
            case AiDesignTemplateWorkStatus.Working:
              color = 'red';
              break;
            case AiDesignTemplateWorkStatus.WorkComplete:
              color = 'gray';
              break;
            default:
              break;
          }
          return renderTags({
            value: AI_DESIGN_TRANSLATIONS[workStatus] || '',
            color,
            type: 'light',
          });
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchAIDesignTemplateListQuery) => {
      const { rows, count } = await fetchAIDesignTemplates(query);
      return {
        rows,
        count,
      };
    },

    [],
  );

  const handleTableRowClick = (data: AIDesignTemplate) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DESIGNER_TEMPLATE_DETAIL_V2.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DESIGNER_TEMPLATE_ADD_V2}>
          <Button>템플릿 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      tabFilterInfo={tabFilterInfo}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      rowHeight={120}
      filterForms={filterForms}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default AIDesignerTemplateListV2Page;
