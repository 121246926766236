import { PromptResult } from '@pages/private/ai-design/ai-design-templates-v2/types/PromptResult';

export const BLOG_TEXT_SAMPLES: PromptResult[][] = [
  // 실리프팅
  [
    {
      title: '실리프팅 딤플 해결',
      point1: '#볼오목',
      point2: '#딤플원인',
      subject: '메인썸네일',
      subTitle: '딤플 현상 궁금증 해소',
      description:
        '실리프팅으로 완성된 아름다움에 가끔 나타나는 딤플 현상, 정확한 원인과 해결책을 제시합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 효과 분석',
      point1: '피부 리프팅',
      point2: '콜라겐 촉진',
      point3: '주름 제거',
      point4: '윤곽 조절',
      point5: '볼륨 개선',
      subject: '시술효과',
      subTitle: '딤플 현상 이해하기',
      description:
        '실리프팅으로 인한 딤플 현상의 원인을 분석하고, 효과적인 해결책을 제공합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 원리 상세해설',
      subject: '시술원리',
      subTitle: '딤플 없는 리프팅 과정',
      description:
        '실리프팅은 피부 내부에 의료용 실을 삽입하여 피부 조직과 얽히게 만들고, 상처 회복 과정에서 콜라겐 생성을 촉진하여 탄력을 개선하는 시술입니다. 딤플 현상을 최소화하기 위해 정확한 위치와 깊이에 실을 삽입하는 것이 중요합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 후 주의사항',
      point1: '시술 후 바로 세안 가능합니다.',
      point2: '실이 들어간 자리의 테이프는 반나절 후 제거하세요.',
      point3: '1~2주간 격한 운동과 열탕 목욕은 피해주세요.',
      point4: '부기 완화를 위해 냉찜질을 권장합니다.',
      point5: '얼굴에 압박을 주는 행동은 최소화해주세요.',
      subject: '주의사항',
      subTitle: '딤플 현상 예방 지침',
      description:
        '딤플 현상을 피하기 위한 실리프팅 후 주의사항을 안내해 드립니다. 시술 후 적절한 관리가 중요합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 추천 대상자',
      point1: '늘어진 U라인을 개선하고 싶은 분',
      point2: '탄력 없는 피부에 고민이신 분',
      point3: '볼살 처짐이 고민인 분',
      point4: '콜라겐 재생으로 탄력을 높이고 싶은 분',
      subject: '추천대상',
      subTitle: '딤플 없는 탄력 찾기',
      description:
        '딤플 현상에 대한 우려 없이 실리프팅을 고려할 수 있는 대상자를 안내합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅의 장점과 특징',
      point1: '반복적인 시술 가능',
      point2: '간편한 시술과 빠른 일상 복귀',
      point3: '자연스러운 피부 분해와 흡수',
      point4: '딤플 현상 최소화',
      point5: '숙련된 의료진에 의한 정밀한 시술',
      subject: '시술장점/특징',
      subTitle: '딤플 없는 자연스러운 리프팅',
      description:
        '실리프팅은 미국 FDA 승인을 받은 안전한 의료용 실을 사용하며, 자연스럽게 분해되어 피부에 흡수됩니다. 반복적인 시술이 가능하고, 30분 내외의 간단한 시술로 일상생활로의 복귀가 빠릅니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 시술 가능 부위',
      point1: '전체 얼굴',
      point2: '이마',
      point3: '눈썹',
      point4: '눈가',
      point5: '팔자주름',
      subject: '시술부위',
      subTitle: '탄력 있는 얼굴 윤곽',
      description: '실리프팅으로 개선 가능한 부위를 상세히 안내합니다.',
      treatmentName: '실리프팅',
    },
    {
      title: '실리프팅 Q&A',
      point1: '광대가 커지나요?',
      point2:
        '광대 주변 부위와 헤어 라인 바로 앞쪽에서 실이 들어가기 때문에 광대가 커지지 않습니다.',
      point3: '실리프팅 후 얼굴이 넓어 보일 수도 있나요?',
      point4:
        '실이 사선 방향으로 들어가면 얼굴이 넓어 보일 수 있으나, 상대적으로 얼굴이 갸름해 보이는 효과가 있습니다.',
      subject: 'Q&A',
      subTitle: '딤플 현상과 실리프팅',
      description: '실리프팅에 대한 궁금증을 해소해 드리는 Q&A 세션입니다.',
      treatmentName: '실리프팅',
    },
  ],
  // 피코토닝
  [
    {
      title: '"밝게 빛 나는 피부"',
      point1: '#색소개선',
      point2: '#피부재생',
      subject: '메인썸네일',
      subTitle: '피부고민 해결책',
      description:
        '피코토닝으로 여드름 자국, 기미, 잡티로 인한 피부 고민을 해결하고 맑고 깨끗한 피부로 가꾸어 보세요.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝과 함께하는 변화',
      point1: '피부 깊숙이 작용',
      point2: '색소 문제 해결',
      point3: '통증 최소화',
      point4: '빠른 회복',
      point5: '탄력 증진',
      subject: '시술설명',
      subTitle: '효과적인 색소 치료',
      description:
        '피코토닝은 나노세컨드보다 1/1000배 빠른 레이저를 이용하여 피부 속 깊은 색소 문제를 해결하고, 피부 손상을 최소화하며 탄력을 증가시키는 혁신적인 시술입니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝의 다양한 효과',
      point1: '색소 개선',
      point2: '여드름 자국 완화',
      point3: '홍조 개선',
      point4: '모공 축소',
      point5: '피부 탄력 증가',
      subject: '시술효과',
      subTitle: '피부 재생과 탄력',
      description:
        '피코토닝은 피부의 여드름 자국, 기미, 잡티, 홍조, 모공 개선뿐만 아니라 피부 탄력과 밀도를 증가시켜 건강하고 탄력 있는 피부로 가꾸어 줍니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝의 과학적 원리',
      point1: '피코초 단위 레이저 사용',
      point2: '멜라닌 색소 분해',
      point3: '선택적 제거',
      point4: '주변 조직 보호',
      point5: '피부 재생 촉진',
      subject: '시술원리',
      subTitle: '정밀한 레이저 기술',
      description:
        '피코토닝은 피코초 단위의 레이저를 사용하여 멜라닌 색소를 효과적으로 분해하고, 주변 정상조직의 열 손상 없이 멜라닌을 선택적으로 제거하는 원리로 작용합니다. 이를 통해 피부 색소 문제를 근본적으로 해결하며 피부 재생을 촉진합니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝 \n시술 후 주의사항',
      point1: '시술 후 붉은 기는 수일 내에 사라집니다.',
      point2: '당일 세안과 화장은 가능하지만, 스크럽제는 사용하지 마세요.',
      point3: '시술 후 생긴 딱지는 절대 손으로 떼지 않도록 주의하세요.',
      point4: '과음, 사우나, 찜질방, 열탕 목욕, 격한 운동은 피하세요.',
      point5: '외출 시에는 반드시 선크림을 바르세요.',
      subject: '주의사항',
      subTitle: '건강한 피부 관리법',
      description:
        '피코토닝 시술 후 올바른 관리가 중요합니다. 시술 직후 발생할 수 있는 미세한 붉은 기와 화끈거림은 자연스럽게 사라집니다. 주의사항을 잘 지켜 건강하고 아름다운 피부를 유지하세요.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝 추천 대상',
      point1: '레이저 후 색소가 진해진 분',
      point2: '색소 질환을 겪고 있는 분',
      point3: '환한 피부톤을 원하시는 분',
      point4: '여드름 자국으로 고민하는 분',
      point5: '기미, 잡티가 걱정되는 분',
      subject: '추천대상',
      subTitle: '피부 고민 해결',
      description:
        '피코토닝은 특정 피부 고민을 가진 분들에게 특히 추천됩니다. 레이저 후 색소가 진해진 분, 색소 질환을 겪고 있는 분, 환한 피부톤을 원하는 분들께 효과적인 시술입니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝의 \n장점과 특징',
      point1: '단시간에 효과적인 시술 완료',
      point2: '깊은 층의 색소 침착 제거',
      point3: '다양한 피부 문제에 효과',
      point4: '통증과 부작용 최소화',
      point5: '빠른 일상 복귀 가능',
      subject: '시술장점/특징',
      subTitle: '효율적인 멜라닌 관리',
      description:
        '피코토닝은 미세한 레이저를 사용하여 피부 표면 손상을 최소화하며, 색소 침착뿐만 아니라 여드름 자국, 미세 주름 등 다양한 피부 문제에도 효과를 발휘합니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝 시술 가능 부위',
      point1: '전체 얼굴',
      point2: '상처 회복',
      point3: '상처 회복',
      point4: '상처 회복',
      point5: '',
      subject: '시술부위',
      subTitle: '얼굴 전체 케어',
      description:
        '피코토닝은 얼굴 전체에 적용 가능한 시술로, 특히 색소 문제가 있는 부위에 집중적으로 시술하여 피부 톤을 고르게 만들어 줍니다.',
      treatmentName: '피코토닝',
    },
    {
      title: '피코토닝에 대한 \n궁금증',
      point1: '피코토닝 시술은 무엇인가요?',
      point2:
        '피코토닝은 색소 제거에 효과적인 레이저 시술로, 매우 빠른 레이저를 사용해 피부의 색소를 선택적으로 제거하는 시술입니다.',
      point3: '피코토닝 시술로 피부의 재생력이나 탄력이 향상되나요?',
      point4:
        '피코토닝은 피부의 깊은 부분까지 치료하여 피부의 재생력을 높이고, 탄력과 밀도를 증가시키는 데 도움이 됩니다.',
      subject: 'Q&A',
      subTitle: '전문가 답변 모음',
      description:
        '피코토닝에 대해 궁금한 점이 많으시죠? 시술의 효과와 주의사항부터 후기까지, 전문가의 답변을 통해 궁금증을 해결해드립니다.',
      treatmentName: '피코토닝',
    },
  ],
  // 슈링크
  [
    {
      title: '피부 속 깊은 리프팅',
      point1: '#탄력UP',
      point2: '#주름개선',
      subject: '메인썸네일',
      subTitle: '탄력 넘치는 겨울',
      description:
        '슈링크유니버스로 겨울철 피부 관리를 시작하세요. 피부 속 깊은 곳까지 에너지를 전달하여 리프팅 효과를 선사합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스의 이해',
      subject: '시술설명',
      subTitle: '안전한 HIFU 시술',
      description:
        '슈링크유니버스는 식약처의 허가를 받은 레이저로서, 고강도 집속 초음파를 이용해 피부 속 깊은 곳까지 에너지를 전달하여 탄력과 주름 개선에 효과적인 리프팅 시술입니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스의 효과',
      point1: '리프팅 효과',
      point2: '탄력 개선',
      point3: '주름 감소',
      point4: '통증 최소화',
      point5: '시술 시간 단축',
      subject: '시술효과',
      subTitle: '효과적인 피부 개선',
      description:
        '2가지 초음파 모드로 세밀한 에너지 전달과 빠른 조사 속도로 리프팅 효과를 극대화하며, 콜라겐 재생을 촉진하여 피부 노화를 개선합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스의 원리',
      subject: '시술원리',
      subTitle: 'HIFU 기술의 원리',
      description:
        '슈링크 리프팅은 HIFU 기술을 통해 1.5mm에서 4.5mm 피부 속 깊은 곳까지 에너지를 전달하며, 미세 열응고점을 만들어 자연적인 상처치유 반응을 일으켜 콜라겐을 재생시키고, 피부 노화의 원인 부위를 직접적으로 자극하여 탄력 있고 건강한 피부로 개선합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스 주의사항',
      point1: '시술 후 3일간 미온수로 세안하세요.',
      point2: '자외선 차단제 및 보습제를 발라주세요.',
      point3: '항산화제 섭취를 추천합니다.',
      point4: '저자극 클렌저 사용을 권장합니다.',
      point5: '격한 운동, 음주, 사우나는 피하세요.',
      subject: '주의사항',
      subTitle: '시술 후 관리 필수',
      description:
        '시술 후 피부 관리가 중요합니다. 미온수 세안, 자외선 차단제 및 보습제 사용, 항산화제 섭취 등을 통해 최적의 결과를 얻으세요.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스 추천대상',
      point1: '탄력 저하 개선을 원하는 분',
      point2: '얼굴 윤곽 개선을 원하는 분',
      point3: '탱탱한 탄력을 원하는 분',
      point4: '꼼꼼한 리프팅을 원하는 분',
      point5: '간편한 V라인 리프팅을 원하는 분',
      subject: '추천대상',
      subTitle: '단단한 탄력을 원하는 분',
      description:
        '탄력이 저하된 피부, 늘어진 얼굴 윤곽, 탱탱한 탄력을 원하시는 분들에게 슈링크유니버스 시술을 추천합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스의 장점',
      point1: '곡선 부위 정교한 리프팅 가능',
      point2: '양방향 시술로 시술 시간 단축',
      point3: 'MPT 기술로 강력한 리프팅 효과',
      point4: '7개 카트리지로 맞춤 리프팅 제공',
      point5: '식약처 탄력 개선 허가 장비 사용',
      subject: '시술장점/특징',
      subTitle: '정교하고 안전한 리프팅',
      description:
        '슈링크유니버스의 MPT 기술로 2가지 모드를 복합적으로 시술해 빈틈없이 강력한 리프팅을 제공하며, 다양한 카트리지로 개인 맞춤 리프팅이 가능합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스 시술부위',
      point1: '전체얼굴',
      point2: '눈가',
      point3: '이중턱',
      point4: '팔자주름',
      point5: '심부볼',
      subject: '시술부위',
      subTitle: '다양한 부위 리프팅',
      description:
        '슈링크유니버스는 전체 얼굴부터 눈가, 이중턱, 팔자주름, 심부볼, 목 등 다양한 부위의 리프팅이 가능합니다.',
      treatmentName: '슈링크유니버스',
    },
    {
      title: '슈링크유니버스 Q&A',
      point1: '슈링크와 인모드의 차이점이 뭔가요?',
      point2:
        '슈링크는 초음파 열에너지로 근막층까지 도달하며, 인모드는 진피층 내 고주파 열에너지로 전반적인 잔주름과 타이트닝에 효과적입니다.',
      point3: '슈링크와 슈링크 유니버스의 차이점은 무엇인가요?',
      point4:
        '슈링크 유니버스는 MP 모드 및 울트라 부스터 핸드피스가 추가되어 더욱 섬세한 리프팅이 가능합니다.',
      subject: 'Q&A',
      subTitle: '궁금증 해소',
      description:
        '슈링크유니버스에 대한 궁금한 점을 해결해 드립니다. 여러분의 의문을 속 시원히 풀어드릴 Q&A를 준비했습니다.',
      treatmentName: '슈링크유니버스',
    },
  ],
  // 아쿠아필
  [
    {
      title: '모공 속 클린업!',
      point1: '#모공청소',
      point2: '#피부수분공급',
      subject: '메인썸네일',
      subTitle: '깨끗한 피부 첫걸음',
      description:
        '아쿠아필로 모공 속 블랙헤드와 노폐물을 제거하고, 촉촉한 물광 피부로 가꾸어보세요.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필 시술의 모든 것',
      point1: '모낭충 제거',
      point2: '노폐물 배출',
      point3: '피부 수분 공급',
      point4: '각질 제거',
      point5: '물광 효과',
      subject: '시술설명',
      subTitle: '피부 고민, 효과적 해결',
      description:
        '아쿠아필은 AHA, BHA 필링 용액으로 모낭충과 노폐물을 제거하고, 수분과 진정 용액으로 피부에 촉촉함을 선사하는 시술입니다. 각질 제거와 모공 청소를 통해 맑은 피부결을 만들어냅니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필의 놀라운 효과',
      point1: '모공 청소',
      point2: '각질 제거',
      point3: '피지 조절',
      point4: '여드름 완화',
      point5: '수분 공급',
      subject: '시술효과',
      subTitle: '피부 고민, 여기서 해결',
      description:
        '아쿠아필은 모공 속 깊은 곳의 노폐물을 제거하고, 피부 수분을 공급하여 건강한 피부를 유지시켜줍니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필, 피부 속 깊은 곳까지',
      point1: '대기압에 가까운 흡입압력',
      point2: '모공 속 노폐물 용해',
      point3: '피지와 블랙헤드 제거',
      point4: 'AHA, BHA 필링 용액 사용',
      point5: '수분 및 진정 용액 공급',
      subject: '시술원리',
      subTitle: '첨단 기술로 깨끗한 피부',
      description:
        '아쿠아필은 750mmhg의 흡입압력으로 피부 속 노폐물을 용해시키는 동시에, 필링 솔루션으로 모낭충과 피지를 제거하여 아기 피부처럼 맑고 깨끗한 피부로 개선하는 미세박피술입니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필 시술 후 주의사항',
      point1: '가벼운 세안 및 화장은 시술 다음날부터 가능합니다.',
      point2: '자외선 차단제를 발라 피부를 보호해 주세요.',
      point3: '스크럽제 사용 및 필링 시술은 피해주세요.',
      point4: '붉은기와 일시적인 트러블이 발생할 수 있으나 곧 완화됩니다.',
      point5: '과음, 사우나/찜질방 출입, 격한 운동은 피해주세요.',
      subject: '주의사항',
      subTitle: '피부 관리의 금기사항',
      description:
        '시술 후 피부 보호를 위한 주의사항을 지켜 건강한 피부를 유지하세요.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필을 추천하는 분들',
      point1: '저자극 필링을 원하는 분',
      point2: '피부결 및 피부톤 개선을 원하는 분',
      point3: '피지 분비가 많아 트러블이 고민인 분',
      point4: '노폐물 제거를 원하는 분',
      point5: '여드름 및 모낭염으로 고민인 분',
      subject: '추천대상',
      subTitle: '다양한 피부 고민 해결',
      description:
        '아쿠아필은 다양한 피부 고민을 가진 분들에게 적합한 시술로, 맑고 깨끗한 피부를 원하는 분들에게 추천드립니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필의 장점과 특징',
      point1: '피부에 각질이 생기지 않아 부작용 가능성 낮음',
      point2: '예민한 피부도 부담없이 시술 가능',
      point3: '일상생활에 지장 없고 다운타임 없음',
      point4: '시술 후 촉촉하고 빛나는 물광 피부로 관리',
      point5: '모낭충 제거로 청결한 모공 관리',
      subject: '시술장점/특징',
      subTitle: '효과와 장점을 한눈에',
      description:
        '아쿠아필은 특수 제작된 필링 솔루션으로 부드럽고 자극이 낮은 필링을 제공하며, 강력한 토네이도 진공 원리로 깨끗한 모공 관리를 돕습니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필 시술 가능 부위',
      point1: '전체얼굴',
      subject: '시술부위',
      subTitle: '전체 얼굴을 맑게',
      description:
        '아쿠아필은 전체 얼굴에 시술이 가능하며, 모든 부위의 피부 고민을 해결해드립니다.',
      treatmentName: '아쿠아필',
    },
    {
      title: '아쿠아필에 대한 궁금증',
      point1: '아쿠아필과 라라필의 차이점은 무엇인가요?',
      point2: '아쿠아필은 피지 정돈과 모공 청소에 집중한 시술입니다.',
      point3: '어떤 피부타입에 효과적인 시술인가요?',
      point4: '아쿠아필은 모든 피부 타입에 적용 가능합니다.',
      subject: 'Q&A',
      subTitle: '전문가가 답변해드립니다',
      description:
        '아쿠아필에 대해 자주 묻는 질문들에 대한 전문가의 명쾌한 답변을 확인하세요.',
      treatmentName: '아쿠아필',
    },
  ],
  // 모공 보톡스
  [
    {
      title: '모공 쫙! #홍대모공보톡스',
      point1: '#모공축소',
      point2: '#피지조절',
      subject: '메인썸네일',
      subTitle: '매끈한 피부결',
      description:
        '홍대모공보톡스로 불필요한 모공은 작별하고, 매끈한 피부를 경험하세요!',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스 이해하기',
      subject: '시술설명',
      subTitle: '피부의 숨구멍, 모공 관리',
      description:
        '홍대모공보톡스는 보툴리눔 톡신을 이용하여 모공을 조여주고 피지 분비를 조절함으로써 피부를 매끈하게 만들어주는 시술입니다. 진피층에 작용하여 탄력섬유와 모낭 신경 전달 물질에 영향을 주어 모공을 축소시키며 리프팅 효과까지 선사합니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스의 놀라운 효과',
      point1: '모공 축소',
      point2: '피지 분비 감소',
      point3: '리프팅 효과',
      point4: '빠른 일상 복귀',
      point5: '장기적인 관리 효과',
      subject: '시술효과',
      subTitle: '매끄러운 피부, 이제 가능해요',
      description:
        '단 10분의 시술로 모공을 눈에 띄게 줄이고, 피부의 매끄러움을 회복할 수 있습니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스의 과학적 원리',
      subject: '시술원리',
      subTitle: '피부 속 깊은 곳의 변화',
      description:
        '보툴리눔 톡신이 진피층 내 탄력섬유와 모낭 신경 전달 물질에 작용하여 모공을 조여주고, 피지 분비를 조절함으로써 피부의 탄력을 증진시키고 모공의 크기를 줄입니다. 이는 더욱 탄탄하고 매끈한 피부로 이어지는 효과적인 시술입니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스 시술 후 주의사항',
      point1: '세안이나 화장은 시술 2~3시간 후부터 가능합니다.',
      point2: '스크럽 제품 사용은 피해주세요.',
      point3: '시술 부위에 강한 압력을 피하시기 바랍니다.',
      point4: '햇볕에 노출되는 것을 최소화하세요.',
      subject: '주의사항',
      subTitle: '시술 후 즉시 일상으로',
      description:
        '시술 후에는 피부를 건강하게 관리하기 위해 몇 가지 주의사항을 따르시는 것이 좋습니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스를 추천하는 분들',
      point1: '넓어진 모공으로 고민하는 분',
      point2: '피지 분비가 많아 고민인 분',
      point3: '피부의 탄력이 떨어져 고민인 분',
      point4: '빠른 시술과 회복을 원하는 분',
      subject: '추천대상',
      subTitle: '모공 걱정, 이제 그만!',
      description:
        '홍대모공보톡스는 다양한 피부 고민을 가진 분들에게 적합한 시술입니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스의 장점과 특징',
      point1: '모공을 눈에 띄게 줄여주는 효과',
      point2: '피지 분비를 조절하여 피부를 매끈하게',
      point3: '면역력 향상에 도움을 줄 수 있음',
      point4: '단기간에 빠른 효과를 경험할 수 있음',
      point5: '최신 의료 기기를 이용한 안전한 시술',
      subject: '시술장점/특징',
      subTitle: '피부 고민, 해결의 시작',
      description:
        '홍대모공보톡스는 모공 축소와 피지 조절뿐만 아니라 리프팅 효과까지 제공하는 혁신적인 시술입니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스 시술 가능 부위',
      point1: '앞광대',
      point2: '나비존',
      point3: '이마',
      point4: '볼',
      point5: '턱',
      subject: '시술부위',
      subTitle: '집중 관리, 모공 축소',
      description: '홍대모공보톡스는 얼굴의 다양한 부위에 시술이 가능합니다.',
      treatmentName: '홍대모공보톡스',
    },
    {
      title: '홍대모공보톡스 자주 묻는 질문',
      point1: '모공보톡스 시술은 안전한가요?',
      point2:
        '네, FDA 승인을 받은 보툴리눔 톡신을 사용하여 안전하게 시술합니다.',
      point3: '시술 후 바로 일상생활이 가능한가요?',
      point4: '네, 시술 후 멍이나 부기가 적어 바로 일상생활로 복귀 가능합니다.',
      subject: 'Q&A',
      subTitle: '시술 전 궁금증 해소!',
      description:
        '홍대모공보톡스에 대해 궁금한 점을 명확하게 해결해 드립니다.',
      treatmentName: '홍대모공보톡스',
    },
  ],
  // 바디 라인 케어
  [
    {
      title: '몸매 관리',
      point1: '#바디라인케어',
      point2: '#피부탄력증진',
      subject: '메인썸네일',
      subTitle: '봄맞이 리프팅',
      description:
        '겨울철 옷에 가려진 몸매, 봄을 맞이해 바디 인모드로 리프팅 관리를 시작하세요.',
      treatmentName: '바디 인모드',
    },
    {
      title: '바디 인모드란?',
      subject: '시술설명',
      subTitle: '탄력있는 바디라인',
      description:
        '바디 인모드는 고주파 에너지를 이용하여 지방 세포를 사멸시키고 콜라겐과 엘라스틴 생성을 촉진하여 바디라인을 정돈하고 피부 탄력을 높여주는 혁신적인 리프팅 시술입니다.',
      treatmentName: '바디 인모드',
    },
    {
      title: '바디 인모드 효과',
      point1: '바디라인 정리',
      point2: '피부 탄력 리프팅',
      point3: '콜라겐 리모델링',
      point4: '타이트닝 효과',
      point5: '피부톤·피부결 개선',
      subject: '시술효과',
      subTitle: '아름다움의 변화',
      description:
        '탄력있는 바디라인과 콜라겐 리모델링으로 잔주름과 피부톤 개선을 경험하세요.',
      treatmentName: '바디 인모드',
    },
    {
      title: '시술 원리',
      subject: '시술원리',
      subTitle: '고주파의 비밀',
      description:
        '바디 인모드는 High Voltage Pulse(HVP)\n기술을 사용하여 지방 세포에만 정확히 전기 \n에너지를 전달하고, RF(고주파 에너지)와 \n진공처리를 통해 지속적으로 지방 세포를 \n유출시키며, 이는 지방 감소와 셀룰라이트 \n개선에 효과적인 시술 원리입니다.',
      treatmentName: '바디 인모드',
    },
    {
      title: '시술 후 주의사항',
      point1: '시술 후 일주일간 음주와 흡연을 자제하세요.',
      point2: '붉은기와 열감은 일시적인 현상입니다.',
      point3: '시술 후 부기나 멍은 3~4일 내에 사라집니다.',
      point4: '임산부는 시술을 받을 수 없습니다.',
      point5: '시술 당일은 휴식을 충분히 취해주세요.',
      subject: '주의사항',
      subTitle: '후속 관리 필수',
      description:
        '시술 후 음주와 흡연을 피하고, 사우나나 찜질방 방문을 자제하여 최상의 결과를 유지하세요.',
      treatmentName: '바디 인모드',
    },
    {
      title: '추천 대상 안내',
      point1: '지방 감소를 원하는 분',
      point2: '피부 탄력 개선을 원하는 분',
      point3: '셀룰라이트 개선을 원하는 분',
      point4: '부종이 있는 분',
      point5: '국소 부위 지방 제거를 원하는 분',
      subject: '추천대상',
      subTitle: '내게 맞는 시술',
      description:
        '지방 감소와 피부 탄력 개선을 원하는 분들에게 바디 인모드를 추천합니다.',
      treatmentName: '바디 인모드',
    },
    {
      title: '시술의 장점과 특징',
      point1: '지방 세포 사멸로 지방 개선',
      point2: '셀룰라이트 개선 효과',
      point3: '콜라겐, 엘라스틴 리모델링',
      point4: '지방 감소와 바디 탄력 증진',
      point5: '표피 온도 체크로 안전한 시술',
      subject: '시술장점/특징',
      subTitle: '지방 사멸 & 탄력 UP',
      description:
        '바디 인모드는 1초에 1,000번씩 표피 온도를 체크하여 화상, 물집, 통증을 최소화하는 안전한 리프팅 시술입니다.',
      treatmentName: '바디 인모드',
    },
    {
      title: '다양한 시술부위',
      point1: '복부',
      point2: '종아리',
      point3: '팔',
      point4: '다리',
      point5: '팔뚝',
      subject: '시술부위',
      subTitle: '목표 부위 집중케어',
      description:
        '바디 인모드 시술은 복부, 종아리, 팔, 다리 등 다양한 부위에 적용 가능하여 원하는 부위의 지방 감소와 탄력 증진을 도와줍니다.',
      treatmentName: '바디 인모드',
    },
    {
      title: '시술 관련 Q&A',
      point1: '인모드 FX 모드와 FORMA 모드의 \n차이는?',
      point2:
        'FX 모드는 지방 세포 사멸에, FORMA 모드는 콜라겐 리모델링에 효과적입니다.',
      point3: '시술 후 관리는 어떻게 해야 하나요?',
      point4:
        '음주, 흡연을 피하고, 충분한 휴식을 취하며, 열에 노출되는 활동은 자제해주세요.',
      subject: 'Q&A',
      subTitle: '궁금증 해소',
      description:
        '바디 인모드에 대한 궁금한 점을 해결해드립니다. 시술 전 꼭 확인하세요!',
      treatmentName: '바디 인모드',
    },
  ],
  // 입술 필러
  [
    {
      title: '입술 변신',
      point1: '#볼륨업',
      point2: '#주름개선',
      subject: '메인썸네일',
      subTitle: '매력적인 입술로 변신!',
      description:
        '에스리본의원 홍대점에서 제공하는 입술필러 시술로 자신감 넘치는 미소를 만들어보세요. 볼륨이 없거나 모양이 변한 입술에 생기를 불어넣고, 자연스러운 볼륨감을 선사합니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러의 모든 것',
      subject: '시술설명',
      subTitle: '입술필러로 되찾는 볼륨',
      description:
        '입술필러는 히알루론산을 기반으로 한 필러를 사용하여 입술에 자연스러운 볼륨을 더하고 주름을 개선하는 시술입니다. 피부 내 자연스럽게 존재하는 물질이기 때문에 안전하며, 에스리본의원 홍대점은 최신 기기와 전문적인 기술로 정밀하게 시술합니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러의 놀라운 효과',
      point1: '볼륨감 증가',
      point2: '모양 개선',
      point3: '주름 완화',
      point4: '비대칭 교정',
      subject: '시술효과',
      subTitle: '자연스러운 입술 볼륨 찾기',
      description:
        '입술필러는 볼륨 없는 입술에 생기를 불어넣고 주름을 매끄럽게 해 입술의 전체적인 모양을 개선합니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러, 어떻게 작용하나요?',
      subject: '시술원리',
      subTitle: '히알루론산의 자연스러운 볼륨',
      description:
        '입술필러는 히알루론산이라는 자연스러운 성분을 이용하여 입술에 볼륨을 더하고 주름을 개선합니다. 나이가 들어감에 따라 감소하는 콜라겐과 엘라스틴을 보충하여 입술을 탱탱하고 매끄럽게 만드는 원리입니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러 시술 후 주의사항',
      point1: '시술 직후 강한 자극은 피하세요.',
      point2: '충분한 수분 섭취를 해주세요.',
      point3: '과도한 표정 변화는 자제합니다.',
      point4: '시술 부위를 깨끗하게 유지하세요.',
      subject: '주의사항',
      subTitle: '안전하고 건강한 회복을 위해',
      description:
        '에스리본의원 홍대점에서 시술받은 후, 더 나은 결과를 위한 관리 팁을 제공합니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러, 누구에게 좋을까?',
      point1: '볼륨 없는 입술을 가진 분',
      point2: '입술 주름으로 고민하는 분',
      point3: '자연스러운 입술 모양을 원하는 분',
      point4: '입술의 비대칭이 신경 쓰이는 분',
      subject: '추천대상',
      subTitle: '입술필러가 필요한 분들',
      description:
        '입술필러는 볼륨이 없거나 주름이 생긴 입술을 가진 분들에게 추천드립니다. 자연스럽고 건강한 입술을 원하시는 분들에게 적합한 시술입니다.',
      treatmentName: '입술필러',
    },
    {
      title: '에스리본의원의 입술필러 장점',
      point1: '자연스러운 볼륨감',
      point2: '매끄러운 입술 완성',
      point3: '최신 의료 기기 사용',
      point4: '전문적인 시술',
      point5: '편리한 교통 접근성',
      subject: '시술장점/특징',
      subTitle: '당신의 입술을 위한 최선의 선택',
      description:
        '에스리본의원 홍대점의 입술필러는 최신 의료 기기와 전문적인 기술로 정밀하게 시술하며, 자연스러운 볼륨과 매끄러운 입술을 선사합니다. 대중교통 접근성이 좋아 편리하게 방문하실 수 있습니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러 시술 가능 부위',
      point1: '입술 전체',
      point2: '입꼬리',
      point3: '윗입술',
      point4: '아랫입술',
      point5: '입술 주름 부위',
      subject: '시술부위',
      subTitle: '입술필러로 변화할 수 있는 부위',
      description:
        '입술필러는 입술 전체에 적용 가능하며, 특히 볼륨이 필요한 부위나 주름이 있는 부분에 효과적입니다.',
      treatmentName: '입술필러',
    },
    {
      title: '입술필러에 대한 궁금증 해소',
      point1: '입술필러 시술 시간은 얼마나 걸리나요?',
      point2: '대략 10~20분 소요되며, 회복 시간은 당일입니다.',
      point3: '입술필러의 유지 기간은 어떻게 되나요?',
      point4: '국산 필러는 6~12개월, 수입 필러는 12~18개월 정도 유지됩니다.',
      subject: 'Q&A',
      subTitle: '자주 묻는 질문과 답변',
      description:
        '입술필러 시술에 대한 궁금증을 해결해드립니다. 에스리본의원 홍대점에서는 친절하고 상세한 상담을 통해 고객님의 의문을 명쾌하게 해소해드립니다.',
      treatmentName: '입술필러',
    },
  ],
  // 하이코
  [
    {
      title: '코의 변신',
      point1: '#자연스러운볼륨',
      point2: '#코끝리프팅',
      subject: '메인썸네일',
      subTitle: '자연스러운 라인',
      description:
        '하이코로 완성하는 자연스러운 코 라인, 시술로 달라지는 코의 아름다움을 경험하세요.',
      treatmentName: '하이코',
    },
    {
      title: '하이코의 모든 것',
      subject: '시술설명',
      subTitle: '절개 없는 리프팅',
      description:
        '하이코는 코 전용 실리프팅으로, 절개 없이 코 끝을 세우고 라인을 잡아주는 시술입니다. KFDA의 승인을 받아 안전하며, 돌기가 없는 투명한 하이코 실과 히알루론산 성분의 필러를 사용하여 시술 부위에 자연스러운 볼륨을 만들어냅니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코의 효과',
      point1: '콧대볼륨',
      point2: '코끝리프팅',
      point3: '자연스러움',
      point4: '효과지속',
      point5: '안전성',
      subject: '시술효과',
      subTitle: '즉각적인 변화',
      description:
        '하이코는 즉각적인 효과를 자랑하며 콧대와 코끝의 볼륨을 개선합니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코의 원리',
      subject: '시술원리',
      subTitle: 'PDO 실의 비밀',
      description:
        '하이코는 PDO 성분의 실을 코에 주입하여 콧대와 코끝을 리프팅합니다. 이후 히알루론산 필러로 볼륨을 채워 자연스러운 코 라인을 만들어내며, 실은 시간이 지나면서 체내에 흡수되어 콜라겐 생성을 촉진합니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코 주의사항',
      point1: '시술 후 음주, 흡연, 과도한 운동은 피하세요.',
      point2: '시술 당일에는 충분한 휴식을 취해주세요.',
      point3: '안경이나 선글라스 착용은 1~2주간 삼가해 주세요.',
      point4: '시술 부위에 이상 증상이 있을 경우 즉시 병원을 방문하세요.',
      subject: '주의사항',
      subTitle: '시술 후 관리',
      description:
        '하이코 시술 후 올바른 관리로 빠른 회복과 효과적인 결과를 얻으세요.',
      treatmentName: '하이코',
    },
    {
      title: '하이코 추천대상',
      point1: '자연스러운 리프팅을 원하는 분',
      point2: '코가 힘없고 흐물거리는 분',
      point3: '콧대가 낮아 고민인 분',
      point4: '코 모양 개선을 원하는 분',
      subject: '추천대상',
      subTitle: '누구에게 좋을까?',
      description:
        '하이코는 다양한 코의 고민을 가진 분들에게 적합한 시술입니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코의 장점',
      point1: '돌기 없는 투명 실 사용',
      point2: '녹는 실로 부작용 감소',
      point3: 'KFDA 승인으로 안전성 확보',
      point4: '콜라겐 생성 촉진',
      point5: '자연스러운 볼륨 유지',
      subject: '시술장점/특징',
      subTitle: '자연스러운 아름다움',
      description:
        '하이코 실은 돌기가 없고 투명하여 시술 후 코에 비쳐 보이지 않아 자연스러운 시술이 가능합니다. 또한 PDO 성분으로 이루어져 몸에서 녹기 때문에 부작용이나 이물감이 적습니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코 시술부위',
      point1: '콧대',
      point2: '콧볼',
      point3: '코끝',
      subject: '시술부위',
      subTitle: '코 전체 개선',
      description:
        '하이코 시술은 콧대와 콧볼, 코끝에 이루어져 코 전체의 아름다움을 끌어올립니다.',
      treatmentName: '하이코',
    },
    {
      title: '하이코 Q&A',
      point1: '코 볼륨을 줄이는데에도 효과가 있나요?',
      point2:
        '하이코 실리프팅은 콧대를 세우거나 볼륨을 만들어 주는 시술입니다.',
      point3: '코 필러와 하이코의 차이는?',
      point4:
        '하이코는 콧대와 코끝을 개선하고 필러를 지탱해주는 녹는 실이 있어 더 오래 지속됩니다.',
      subject: 'Q&A',
      subTitle: '궁금증 해소',
      description:
        '하이코 시술에 대한 자주 묻는 질문과 답변으로 여러분의 궁금증을 해결해 드립니다.',
      treatmentName: '하이코',
    },
  ],
];

export const getRandomBlogTextInfo = () => {
  const randomIndex = Math.floor(Math.random() * BLOG_TEXT_SAMPLES.length);
  return BLOG_TEXT_SAMPLES[randomIndex];
};
