import { observer } from 'mobx-react-lite';
import Select from '@components/v2/antd/data-entry/Select';
import { IMAGE_CATEGORY_OPTIONS } from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
import React from 'react';

// @ts-ignore
const ImageCategorySelect = observer(({ element }) => {
  let value;
  const { custom = {} } = element;
  if (custom) {
    const { imageCategory } = custom;
    value = imageCategory;
  }
  return (
    <Select
      allowClear
      placeholder="카테고리"
      value={value}
      style={{
        width: '180px',
      }}
      options={IMAGE_CATEGORY_OPTIONS}
      onChange={(value) => {
        element.set({
          custom: {
            ...custom,
            imageCategory: value,
          },
        });
      }}
    />
  );
});

export default ImageCategorySelect;
