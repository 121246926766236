import { ImagesGrid, SectionTab } from 'polotno/side-panel';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { PolotnoIconUpload } from '@assets/svg/polotno';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AppError } from '@networks/index';
import { getImageSize } from 'polotno/utils/image';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { requestUploadImage } from '../../../../../../../@apis';

function PolotnoImageUploadTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="업로드" {...props}>
      <PolotnoIconUpload />
    </SectionTab>
  );
}
PolotnoImageUploadTab.displayName = '';

function PolotnoImageUploadPanel({ store }: { store: any }) {
  const [imageFetching, setImageFetching] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [images, setImages] = useState<{ url: string }[]>([]);

  const uploadInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImageFetching(true);
    const localImages = getLocalImages();
    setImages(localImages);
    setImageFetching(false);
  }, []);

  const deleteLocalImage = (image: { url: string }) => {
    const localImages = getLocalImages();
    const updatedImages = localImages.filter(({ url }) => {
      return url !== image.url;
    });
    saveImagesToLocal(updatedImages);
    setImageFetching(true);
    setImages(updatedImages);
    setImageFetching(false);
  };

  const handleImageSelect = async (
    image: { url: string },
    pos: any,
    element: any,
  ) => {
    const { url } = image;
    const { width, height } = (await getImageSize(url)) as {
      width: number;
      height: number;
    };
    const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

    const type = isSVG ? 'svg' : 'image';

    if (
      element &&
      element.type === 'svg' &&
      !element.locked &&
      type === 'image'
    ) {
      element.set({ maskSrc: url });
      return;
    }

    if (
      element &&
      element.type === 'image' &&
      !element.locked &&
      type === 'image'
    ) {
      element.set({ src: url });
      return;
    }

    const scale = Math.min(store.width / width, store.height / height, 1);
    const scaledImageWidth = width * scale;
    const scaledImageHeight = height * scale;

    const x = (pos?.x || store.width / 2) - scaledImageWidth / 2;
    const y = (pos?.y || store.height / 2) - scaledImageHeight / 2;

    store.activePage?.addElement({
      type,
      src: url,
      x,
      y,
      width,
      height,
    });
  };

  const getLocalImages = () => {
    const jsonString = localStorage.getItem('images');
    let localImages: { url: string }[] = [];
    try {
      if (jsonString) {
        localImages = JSON.parse(jsonString);
      }
    } catch (e) {
      localImages = [];
    }
    return localImages;
  };

  const saveImagesToLocal = (newImageList: { url: string }[]) => {
    localStorage.setItem('images', JSON.stringify(newImageList));
  };

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { files } = target;
    if (files && files.length > 0) {
      const fileList = Array.from(files);
      const [file] = fileList;
      setImageUploading(true);
      try {
        const { rows } = await requestUploadImage({
          file,
          folder: 'ai-design-template-templates/',
          service: 'BOS',
          includeCdn: true,
        });
        const updatedImages = [
          ...images,
          ...rows.map((imageUrl) => ({
            url: imageUrl,
          })),
        ];
        setImages(updatedImages);
        saveImagesToLocal(updatedImages);
      } catch (e) {
        const error = e as AppError;
        // eslint-disable-next-line no-alert
        alert(error.message);
      } finally {
        setImageUploading(false);
      }
    }
    // 동일 파일 재선택시 onChange 이벤트 트리거가 동작하지 않아 value 초기화
    if (uploadInputRef && uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  };

  const renderDeleteAction = (image: { url: string }) => {
    return (
      <div>
        <Button
          icon={<DeleteOutlined />}
          onClick={async (e) => {
            e.stopPropagation();
            if (window.confirm('이미지를 삭제하시겠습니까?')) {
              deleteLocalImage(image);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginBottom: '16px',
        }}
      >
        <Button
          disabled={imageUploading}
          loading={imageUploading}
          icon={<UploadOutlined />}
          style={{
            minWidth: '200px',
          }}
          onClick={() => {
            const input = document.querySelector('#input-file');
            if (input) (input as HTMLInputElement).click();
          }}
        >
          이미지 업로드
        </Button>
        <input
          ref={uploadInputRef}
          type="file"
          accept="image/*"
          id="input-file"
          style={{ display: 'none' }}
          onInput={handleUploadImage}
        />
      </div>

      <div>
        <ImagesGrid
          crossOrigin="anonymous"
          images={images}
          onSelect={handleImageSelect}
          isLoading={imageFetching}
          getPreview={(image) => image.url}
          getCredit={renderDeleteAction}
        />
      </div>
    </div>
  );
}

const PolotnoImageUploadSection = {
  name: 'custom-upload',
  Tab: PolotnoImageUploadTab,
  Panel: PolotnoImageUploadPanel,
};

export default PolotnoImageUploadSection;
