/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ChangeEvent,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _, { includes, isEqual } from 'lodash';
import { Dropdown, Select } from 'antd';
import styled from 'styled-components';
import colorSet from '@styles/colors';
import { ReactComponent as Close } from '@assets/svg/Close.svg';
import { BaseSelectRef } from 'rc-select';
import Typo from '../Typo';
import Check from '../Check';
import Input from '../Input';

interface BadgeMultiSelectProps<T> {
  options?: { label: string; value: T }[];
  value: T[];
  onChange: (value: T[]) => void;
  onChangeAll?: (value: T[]) => void;
  placeholder?: string;
  hasAllSelect?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
  sRef?: Ref<BaseSelectRef>;
}

function BadgeMultiSelect<T>(props: BadgeMultiSelectProps<T>) {
  const {
    options,
    value,
    onChange,
    onChangeAll,
    placeholder,
    hasAllSelect,
    showLabel = true,
    disabled,
    sRef,
  } = props;
  const [optionFilter, setOptionFliter] = useState<
    { label: string; value: T }[]
  >(options || []);
  const [inputText, setInputText] = useState<string>('');
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visiable, setVisiable] = useState(false);

  const handleAddValue = (valueParam: T) => {
    onChange([...value, valueParam]);
  };
  const handleRemoveValue = (valueParam: T) => {
    onChange(value.filter((item) => item !== valueParam));
  };
  const handleOptionClick = (valueParam: any) => {
    if (value.includes(valueParam)) {
      handleRemoveValue(valueParam);
    } else {
      handleAddValue(valueParam);
    }
  };

  const handleOptionKeyPress = (valueParam: any) => {
    if (value.includes(valueParam)) {
      return;
    }
    handleAddValue(valueParam);
  };

  const handleOnChange = (e: string) => {
    setInputText(e);
    setSelectIdx(0);
    if (options) {
      const newOptions = [..._.cloneDeep(options)];
      if (e !== '') {
        setOptionFliter([
          ...newOptions.filter((item) => item.label.includes(e)),
        ]);
      } else {
        setOptionFliter([...newOptions]);
      }
    }
  };

  const filteredOptions = useMemo(
    () => options?.filter((li) => includes(li.label, inputText)) || [],
    [inputText, options],
  );

  const handleAllSelect = useCallback(() => {
    if (onChangeAll) {
      if (filteredOptions.every((op) => includes(value, op.value))) {
        // 이미 모든값이 포함되어 있음
        onChangeAll(
          value.filter(
            (li) =>
              !includes(
                filteredOptions.map((op) => op.value),
                li,
              ),
          ),
        );
      } else {
        onChangeAll(
          Array.from(
            new Set([...value, ...filteredOptions.map((item) => item.value)]),
          ),
        );
      }
    } else if (value.length === filteredOptions?.length) {
      onChange([]);
    } else {
      onChange(filteredOptions.map((item) => item.value));
    }
  }, [filteredOptions, onChange, onChangeAll, value]);

  const [selectIdx, setSelectIdx] = useState(0);
  const selectRef = useRef<HTMLUListElement>(null);

  const renderDropdown = (menu: React.ReactNode) => {
    return (
      <SelectList ref={selectRef}>
        {hasAllSelect && filteredOptions.length > 0 && (
          <AllSelectItem
            data-focused={selectIdx === 0}
            onClick={handleAllSelect}
            onMouseOver={() => {
              setSelectIdx(0);
            }}
          >
            <Check
              checked={filteredOptions.every((op) => includes(value, op.value))}
            />
            <Typo as="p" typoType="b9">
              전체 선택
            </Typo>
          </AllSelectItem>
        )}
        <BorderLine />
        {filteredOptions.map((item, idx) => (
          <SelectItem
            onMouseOver={() => {
              setSelectIdx(idx + 1);
            }}
            onClick={() => {
              handleOptionClick(item.value);
            }}
            data-checked={value.includes(item.value)}
            data-focused={idx + 1 === selectIdx}
          >
            <Check
              style={{ top: '-2px' }}
              checked={value.includes(item.value)}
            />
            <Typo as="p" typoType="b9">
              {item.label}
            </Typo>
            <span className="icon" />
          </SelectItem>
        ))}
      </SelectList>
    );
  };

  useEffect(() => {
    if (options) {
      setOptionFliter(options);
    }
  }, [options]);

  return (
    <DropdownWrapper>
      <Select
        ref={sRef}
        disabled={disabled}
        className="custome-select-none-tag"
        mode="tags"
        // eslint-disable-next-line react/no-unstable-nested-components
        tagRender={() => <div />}
        // onChange={handleOnChange}
        dropdownRender={(menu) => {
          return renderDropdown(menu);
        }}
        onDropdownVisibleChange={setOpen}
        // open={open}
        // open
        // trigger={['click']}
        suffixIcon={null}
        placeholder={placeholder}
        onSearch={handleOnChange}
        onSelect={() => handleOnChange('')}
        // defaultOpen
        autoClearSearchValue={false}
        onInputKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.stopPropagation();
            setOpen(false);
          }
          if (e.key === 'Enter' && open) {
            if (selectIdx === 0) {
              handleAllSelect();
            } else {
              handleOptionClick(optionFilter[selectIdx - 1].value);
            }
          }
          if (e.key === 'ArrowDown') {
            const select = selectRef?.current?.children[selectIdx + 2];

            if (
              (selectRef?.current?.scrollTop || 0) +
                (selectRef.current?.clientHeight || 0) <=
              (select?.clientHeight || 0) * (selectIdx + 1)
            ) {
              selectRef?.current?.scrollTo(
                0,
                (select?.clientHeight || 0) * (selectIdx + 2) -
                  selectRef.current.clientHeight,
              );
            }

            if (optionFilter.length > selectIdx) {
              setSelectIdx((prev) => prev + 1);
            } else {
              setSelectIdx(0);
              selectRef?.current?.scrollTo(0, 0);
            }
          }
          if (e.key === 'ArrowUp') {
            const select = selectRef?.current?.children[selectIdx];
            if (
              (selectRef?.current?.scrollTop || 0) >=
              (select?.clientHeight || 0) * selectIdx
            ) {
              selectRef?.current?.scrollTo(
                0,
                (select?.clientHeight || 0) * selectIdx -
                  (select?.clientHeight || 0),
              );
            }
            if (selectIdx > 0) {
              setSelectIdx((prev) => prev - 1);
            } else {
              setSelectIdx(optionFilter.length);
              selectRef?.current?.scrollTo(0, selectRef?.current.scrollHeight);
            }
          }
        }}
        showAction={['click', 'focus']}
        options={options}
        // open={open}
        // autoFocus

        // visible={visiable}
      />

      {showLabel && value.length > 0 && (
        <LabelWrapper>
          {options
            ? options
                .filter((item) => value.includes(item.value))
                .map((item) => (
                  <LabelBox>
                    <Typo typoType="btn3">{item.label}</Typo>
                    <CloseIcon onClick={() => handleOptionClick(item.value)} />
                  </LabelBox>
                ))
            : value.map((item) => (
                <LabelBox>
                  <Typo typoType="btn2">{item as ''}</Typo>
                  <CloseIcon onClick={() => handleOptionClick(item)} />
                </LabelBox>
              ))}
        </LabelWrapper>
      )}
    </DropdownWrapper>
  );
}

export default BadgeMultiSelect;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MultiSelectContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
`;

const SelectList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 8px;
  max-height: 254px;
  overflow-y: scroll;
`;
const SelectItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;

  > .icon {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  // &:hover {
  //   background-color: ${colorSet.gray12};
  // }

  &[data-focused='true'] {
    background-color: ${colorSet.primary9};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 2px;

  //   width: 67px;
  height: 32px;

  /* Gray Scale/Gray12, BG */

  background: #f6f8fb;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  path {
    fill: ${colorSet.gray2};
  }
`;

const AllSelectItem = styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;

  > label {
    position: relative;
    top: 1px;
  }

  // &::before {
  //   position: absolute;
  //   bottom: 4px;
  //   content: '';
  //   display: flex;
  //   width: 90%;
  //   height: 1px;
  //   background: ${colorSet.gray10};
  // }

  > .icon {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  &[data-checked='true'] {
    > .icon {
      display: flex;
    }
  }

  &[data-focused='true'] {
    background-color: ${colorSet.primary9};
  }
`;

const BorderLine = styled.li`
  height: 1px;
  width: 100%;
  background: ${colorSet.gray10};
  display: flex;
  position: relative;
  display: flex;
  padding: 0.5px 0;
  margin: 0 auto;
`;
