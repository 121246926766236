import React from 'react';
import { Input as AntInput, InputProps } from 'antd';

interface Props extends InputProps {}

const Input: React.FC<Props> = (props: Props) => {
  const { style, ...rest } = props;

  return (
    <AntInput
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

Input.defaultProps = {};
export default Input;
