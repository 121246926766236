import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Table, TableProps } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button } from '@components/atoms';
import { PartnerAssistantLanguage } from '@types';
import { useAlertStore } from '@store/useAlertStore';
import dayjs from 'dayjs';
import { requestAssistants, requestDeleteAssistant } from '@apis';
import { assistantsQueryKeys } from '@apis/assistants/queryKeys';
import { ASSISTANT_LANGUAGE_TO_KOREAN } from 'constant';
import EditAssistantModal from './EditAssistantModal';

type AssistantTableProps = {
  partnerId: number;
};
function AssistantTable({ partnerId }: AssistantTableProps) {
  const { show: showAlert } = useAlertStore((state) => state);
  const queryClient = useQueryClient();

  const { data: assistants = [], isFetching } = useQuery({
    queryKey: assistantsQueryKeys.partner(partnerId),
    queryFn: () => requestAssistants({ partnerId }),
    select: (d) => d.rows,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!partnerId,
  });

  const { mutate } = useMutation({
    mutationFn: requestDeleteAssistant,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        assistantsQueryKeys.partner(partnerId),
      );
    },
  });

  const [assistantIdForEdit, setAssistantIdForEdit] = useState<string | null>(
    null,
  );
  const editAssistant = assistants.find(
    (call) => call.id === Number(assistantIdForEdit),
  );

  const showDeleteAlert = (language: PartnerAssistantLanguage) => {
    showAlert({
      size: 360,
      title: 'AI 상담 어시스턴트 삭제',
      message: '정말 어시스턴트를 삭제하시겠습니까?',
      actions: [
        { label: '취소' },
        {
          label: '삭제',
          onClick: () => mutate({ partnerId, language }),
        },
      ],
    });
  };

  const cols: TableProps['columns'] = [
    {
      width: 120,
      dataIndex: 'createdAt',
      title: '등록일',
      key: 'createdAt',
      render: (_, record) => {
        const { createdAt } = record;
        return createdAt ? dayjs(createdAt).format('YYYY-MM-DD HH:mm') : '-';
      },
    },
    {
      width: 120,
      dataIndex: 'updatedAt',
      title: '수정일',
      key: 'updatedAt',
      render: (_, record) => {
        const { updatedAt } = record;
        return updatedAt ? dayjs(updatedAt).format('YYYY-MM-DD HH:mm') : '-';
      },
    },
    {
      width: 120,
      dataIndex: 'assistantId',
      title: '어시스턴트 아이디',
      key: 'assistantId',
      // render: (_, record) => {
      //   const { marketingChannel } = record;
      //   return MARKETING_CHANNEL_TO_KOREAN[
      //     marketingChannel as MarketingChannel
      //   ];
      // },
    },
    {
      width: 60,
      dataIndex: 'language',
      title: '언어',
      key: 'language',
      render: (_, record) => {
        const { language } = record;
        return ASSISTANT_LANGUAGE_TO_KOREAN[
          language as PartnerAssistantLanguage
        ];
      },
    },
    {
      width: 60,
      dataIndex: 'actions',
      title: '수정/삭제',
      key: 'actions',
      render: (_, record) => {
        const { id, language } = record;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <Button
              buttonSize={28}
              buttonColor="gray"
              onClick={() => setAssistantIdForEdit(id)}
            >
              수정
            </Button>
            <Button
              buttonSize={28}
              buttonColor="lightRed"
              onClick={() => showDeleteAlert(language)}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Table
        style={{
          width: '100%',
        }}
        size="middle"
        pagination={false}
        columns={cols}
        loading={isFetching}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 20,
              }}
            >
              등록된 어시스턴트 정보가 없습니다.
            </div>
          ),
        }}
        dataSource={assistants.map((assistant) => {
          return {
            ...assistant,
            key: assistant.id,
          };
        })}
      />
      {editAssistant &&
        createPortal(
          <EditAssistantModal
            partnerId={partnerId}
            initialValues={editAssistant}
            onClose={() => setAssistantIdForEdit(null)}
          />,
          document.body,
        )}
    </>
  );
}

export default AssistantTable;
