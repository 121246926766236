import colorSet from '@styles/colors';
import { CSSProperties } from 'react';
import { css } from 'styled-components';
import { ButtonColorType, ButtonSizeType, ButtonStyleType } from './types';
import typo from '../Typo/types';

// Default style
export const getDefaultColor = (
  buttonColor: ButtonColorType,
  buttonStyle: ButtonStyleType,
) => {
  const isGhost = buttonStyle === 'line';

  // primary
  const style: CSSProperties = {
    backgroundColor: !isGhost ? colorSet.primary3 : colorSet.gray13,
    color: !isGhost ? colorSet.gray13 : colorSet.primary3,
    border: !isGhost ? 'none' : `1px solid ${colorSet.primary3}`,
  };

  if (buttonColor === 'lightPrimary') {
    style.backgroundColor = !isGhost ? colorSet.primary9 : colorSet.primary10;
    style.color = !isGhost ? colorSet.primary2 : colorSet.primary3;
    style.border = !isGhost ? 'none' : `1px dashed ${colorSet.primary7}`;
  }

  if (buttonColor === 'gray') {
    style.backgroundColor = !isGhost ? colorSet.gray11 : colorSet.gray13;
    style.color = colorSet.gray2;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.gray9}`;
  }

  if (buttonColor === 'plane') {
    style.backgroundColor = colorSet.gray13;
    style.color = colorSet.gray2;
    style.border = 'none';
  }

  if (buttonColor === 'red') {
    style.backgroundColor = !isGhost ? colorSet.red4 : colorSet.gray13;
    style.color = !isGhost ? colorSet.gray13 : colorSet.red3;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.red3}`;
  }

  if (buttonColor === 'lightRed') {
    style.backgroundColor = colorSet.red8;
    style.color = colorSet.red3;
    style.border = 'none';
  }

  return css`
    background-color: ${style.backgroundColor};
    color: ${style.color};
    fill: ${style.color};
    border: ${style.border};
  `;
};

// Hovered style
export const getHoveredColor = (
  buttonColor: ButtonColorType,
  buttonStyle: ButtonStyleType,
) => {
  const isGhost = buttonStyle === 'line';

  // primary
  const style: CSSProperties = {
    backgroundColor: !isGhost ? colorSet.primary2 : colorSet.gray13,
    color: !isGhost ? colorSet.gray13 : colorSet.primary2,
    border: !isGhost ? 'none' : `1px solid ${colorSet.primary2}`,
  };

  if (buttonColor === 'lightPrimary') {
    style.backgroundColor = !isGhost ? colorSet.primary8 : colorSet.primary9;
    style.color = colorSet.primary2;
    style.border = !isGhost ? 'none' : `1px dashed ${colorSet.primary5}`;
  }

  if (buttonColor === 'gray') {
    style.backgroundColor = !isGhost ? colorSet.gray10 : colorSet.gray13;
    style.color = colorSet.gray2;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.gray5}`;
  }

  if (buttonColor === 'plane') {
    style.backgroundColor = colorSet.gray11;
    style.color = colorSet.gray2;
    style.border = `none`;
  }

  if (buttonColor === 'red') {
    style.backgroundColor = !isGhost ? colorSet.red2 : colorSet.gray13;
    style.color = !isGhost ? colorSet.gray13 : colorSet.red2;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.red2}`;
  }

  if (buttonColor === 'lightRed') {
    style.backgroundColor = colorSet.red7;
    style.color = colorSet.red2;
    style.border = `none`;
  }

  return css`
    background-color: ${style.backgroundColor};
    color: ${style.color};
    fill: ${style.color};
    border: ${style.border};
  `;
};

// Pressed style
export const getPressedColor = (
  buttonColor: ButtonColorType,
  buttonStyle: ButtonStyleType,
) => {
  const isGhost = buttonStyle === 'line';

  // primary
  const style: CSSProperties = {
    backgroundColor: !isGhost ? colorSet.primary1 : colorSet.gray13,
    color: !isGhost ? colorSet.gray13 : colorSet.primary1,
    border: !isGhost ? 'none' : `1px solid ${colorSet.primary1}`,
  };

  if (buttonColor === 'lightPrimary') {
    style.backgroundColor = !isGhost ? colorSet.primary7 : colorSet.primary8;
    style.color = colorSet.primary1;
    style.border = !isGhost ? 'none' : `1px dashed ${colorSet.primary4}`;
  }

  if (buttonColor === 'gray') {
    style.backgroundColor = !isGhost ? colorSet.gray9 : colorSet.gray13;
    style.color = colorSet.gray2;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.gray2}`;
  }

  if (buttonColor === 'plane') {
    style.backgroundColor = colorSet.gray9;
    style.color = colorSet.gray2;
    style.border = `none`;
  }

  if (buttonColor === 'red') {
    style.backgroundColor = !isGhost ? colorSet.red1 : colorSet.gray13;
    style.color = !isGhost ? colorSet.gray13 : colorSet.red1;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.red1}`;
  }

  if (buttonColor === 'lightRed') {
    style.backgroundColor = colorSet.red6;
    style.color = colorSet.red1;
    style.border = `none`;
  }

  return css`
    background-color: ${style.backgroundColor};
    color: ${style.color};
    fill: ${style.color};
    border: ${style.border};
  `;
};

// Disabled style
export const getDisabledColor = (
  buttonColor: ButtonColorType,
  buttonStyle: ButtonStyleType,
) => {
  const isGhost = buttonStyle === 'line';

  // primary
  const style: CSSProperties = {
    backgroundColor: !isGhost ? colorSet.gray11 : colorSet.gray13,
    color: colorSet.gray8,
    border: !isGhost ? 'none' : `1px solid ${colorSet.gray8}`,
  };

  if (buttonColor === 'lightPrimary') {
    style.backgroundColor = colorSet.gray11;
    style.color = colorSet.gray8;
    style.border = !isGhost ? 'none' : `1px dashed ${colorSet.gray9}`;
  }

  if (buttonColor === 'gray') {
    style.backgroundColor = !isGhost ? colorSet.gray11 : colorSet.gray13;
    style.color = colorSet.gray8;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.gray8}`;
  }

  if (buttonColor === 'plane') {
    style.backgroundColor = colorSet.gray13;
    style.color = colorSet.gray8;
    style.border = `none`;
  }

  if (buttonColor === 'red') {
    style.backgroundColor = !isGhost ? colorSet.gray11 : colorSet.gray13;
    style.color = colorSet.gray8;
    style.border = !isGhost ? 'none' : `1px solid ${colorSet.gray8}`;
  }

  if (buttonColor === 'lightRed') {
    style.backgroundColor = colorSet.gray11;
    style.color = colorSet.gray8;
    style.border = `none`;
  }

  return css`
    background-color: ${style.backgroundColor};
    color: ${style.color};
    fill: ${style.color};
    border: ${style.border};
  `;
};

export const getTypoSize = (buttonSize: ButtonSizeType) => {
  switch (buttonSize) {
    case 56:
      return css`
        ${typo.btn1};
        padding: 16px 24px;
        border-radius: 12px;
      `;
    case 48:
      return css`
        ${typo.btn2};
        padding: 14px 20px;
        border-radius: 11px;
      `;
    case 40:
      return css`
        ${typo.btn2};
        padding: 10px 16px;
        border-radius: 8px;
      `;
    case 32:
      return css`
        ${typo.btn3};
        padding: 7px 12px;
        border-radius: 8px;
      `;
    case 28:
      return css`
        ${typo.btn4};
        padding: 7px 10px;
        border-radius: 7px;
      `;
    default:
      return css`
        ${typo.btn2};
        padding: 16px 24px;
        border-radius: 12px;
      `;
  }
};

export const getIconSize = (buttonSize: ButtonSizeType) => {
  // default - 40
  const styles: CSSProperties = {
    width: 18,
    height: 18,
  };
  if (buttonSize === 56) {
    styles.width = 20;
    styles.height = 20;
  }
  if (buttonSize === 48) {
    styles.width = 18;
    styles.height = 18;
  }
  if (buttonSize === 32) {
    styles.width = 16;
    styles.height = 16;
  }
  if (buttonSize === 28) {
    styles.width = 14;
    styles.height = 14;
  }

  return css`
    width: ${styles.width};
    height: ${styles.height};
  `;
};
