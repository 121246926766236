import { AppError, Rows, request } from '@networks/index';
import { UploadFileRequest } from '@types';

const URL = {
  // 대용량 다중 이미지 업로드
  BIG_IMAGE_PATH: '/media/big-images',
};

export const imgPrefix = process.env.REACT_APP_IMAGE_PRIFIX;

export const requestUploadImage = async (body: UploadFileRequest) => {
  try {
    const formData = new FormData();

    if (body.file && 'length' in body.file && body.file.length) {
      (body.file as Blob[]).forEach((item) => {
        formData.append('image', item as Blob);
      });
    } else if (body.file) {
      formData.append('image', body.file as Blob);
    }
    formData.append('folder', body.folder);
    formData.append('service', body.service);
    if (typeof body.includeCdn === 'boolean') {
      formData.append('isIncludeCdn', `${body.includeCdn}`);
    }

    const response = await request<Rows<string>>({
      method: 'post',
      url: URL.BIG_IMAGE_PATH,
      requestBody: formData,
    });

    return { ...response?.data, thumbnail: body.file };
  } catch (e) {
    throw new AppError(e);
  }
};
