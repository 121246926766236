import React from 'react';
import {
  InputNumber as AntInputNumber,
  InputNumberProps as AntInputNumberProps,
} from 'antd';

interface InputNumberProps extends AntInputNumberProps {}

const InputNumber: React.FC<AntInputNumberProps> = (
  props: InputNumberProps,
) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntInputNumber
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

InputNumber.defaultProps = {};
export default InputNumber;
