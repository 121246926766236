export enum GlobalCatalogWorkStatus {
  WAITING = 'WAITING', //대기중
  INSPECTING = 'INSPECTING', //검수중
  INSPECTION_COMPLETED = 'INSPECTION_COMPLETED', //검수 완료
  FINAL_COMPLETED = 'FINAL_COMPLETED', //최종검수완료
  REJECTED = 'REJECTED', //반려
}

export enum GlobalCatalogTranslations {
  WAITING = '대기중',
  INSPECTING = '검수중',
  INSPECTION_COMPLETED = '1차검수완료',
  FINAL_COMPLETED = '최종검수완료',
  REJECTED = '반려',
  ko = '한국어', // 한국어
  en = '영어', // 영어
  zh = '중국어', // 중국어
  ja = '일본어', // 일본어
  th = '태국어', // 태국어
  vi = '베트남어', //베트남어
}

export enum GlobalCatalogLanguageType {
  ko = 'ko', // 한국어
  en = 'en', // 영어
  zh = 'zh', // 중국어
  ja = 'ja', // 일본어
  th = 'th', // 태국어
  vi = 'vi', //베트남어
}

export const GLOBAL_CATALOG_WORK_STATUS_OPTIONS = [
  {
    label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.WAITING],
    value: GlobalCatalogWorkStatus.WAITING,
  },
  {
    label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.INSPECTING],
    value: GlobalCatalogWorkStatus.INSPECTING,
  },
  {
    label:
      GlobalCatalogTranslations[GlobalCatalogWorkStatus.INSPECTION_COMPLETED],
    value: GlobalCatalogWorkStatus.INSPECTION_COMPLETED,
  },
  {
    label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.FINAL_COMPLETED],
    value: GlobalCatalogWorkStatus.FINAL_COMPLETED,
  },
  {
    label: GlobalCatalogTranslations[GlobalCatalogWorkStatus.REJECTED],
    value: GlobalCatalogWorkStatus.REJECTED,
  },
];

export const GLOBAL_CATALOG_LANGUAGE_TYPE_OPTIONS = Object.values(
  GlobalCatalogLanguageType,
).map((value) => {
  return {
    label: GlobalCatalogTranslations[value],
    value,
  };
});
