export enum PartnerHomePageType {
  COMMERCE = 'COMMERCE',
  HYBRID = 'HYBRID',
  BRANDING = 'BRANDING',
}

export enum PartnerCRMType {
  VEGAS = 'DR_PALETTE',
  SMART = 'SMART',
  DR_PALETTE = 'DR_PALETTE',
}

export enum ConsultationActiveHours {
  OPERATING = 'OPERATING', // 운영시간에만 AI 상담 가능
  OFF = 'OFF', // 운영 외 시간에만 AI 상담 가능
  ALL = 'ALL', // 모든 시간에 AI 상담 가능
}

export enum ConsultationTimeSources {
  HOMEPAGE = 'HOMEPAGE',
  CHANNEL_TALK = 'CHANNEL_TALK',
}

export const PartnerTranslations = {
  OPERATING: '운영시간',
  OFF: '운영시간 외',
  ALL: '모든시간',
  HOMEPAGE: '홈페이지',
  CHANNEL_TALK: '채널톡',
};

export enum MedicalChargeType {
  COMMERCE = 'COMMERCE',
  BRANDING = 'BRANDING',
}

export const MedicalChargeTypeTranslations = {
  COMMERCE: '커머스형',
  BRANDING: '브랜딩형',
};

export const CONSULTATION_ACTIVE_HOURS = Object.values(
  ConsultationActiveHours,
).map((value) => ({
  label: PartnerTranslations[value],
  value,
}));

export const CONSULTATION_TIME_SOURCE_OPTIONS = Object.values(
  ConsultationTimeSources,
).map((value) => {
  return {
    label: PartnerTranslations[value],
    value,
  };
});

export const MEDICAL_CHARGE_TYPE_OPTIONS = Object.values(MedicalChargeType).map(
  (value) => ({
    label: MedicalChargeTypeTranslations[value],
    value,
  }),
);
