import React, { MouseEvent, RefObject } from 'react';
import { Typo } from '@components/atoms';
import colorSet, { ColorType } from '@styles/colors';
import styled from 'styled-components';
import typo from '../../../Typo/types';

export interface SelectOptionType<T> {
  value: T;
  label: string;
  color?: ColorType;
}

interface SelectOptionsProps<T> {
  options: SelectOptionType<T>[];
  value: T;
  handleChange: (value: T) => void;
  menuMaxWidth?: number;
  menuMaxHeight?: number;
  handleDropdownClose: () => void;
  onMouseDown?: (e: MouseEvent<HTMLUListElement>) => void;
  ref?: RefObject<HTMLUListElement>;
}

function SelectOptions<T>(props: SelectOptionsProps<T>) {
  const {
    options,
    value,
    handleChange,
    menuMaxWidth,
    menuMaxHeight = 224,
    handleDropdownClose,
    onMouseDown,
    ref,
  } = props;

  return (
    <SelectOptionsContainer
      ref={ref}
      maxWidth={menuMaxWidth}
      maxHeight={menuMaxHeight}
      onMouseDown={(e) => onMouseDown?.(e)}
    >
      {options.map((item) => (
        <SelectItem
          onClick={(e) => {
            e.stopPropagation();
            handleChange(item.value);
            handleDropdownClose();
          }}
          data-checked={String(value) === String(item.value)}
        >
          <Typo as="p" typoType="b9">
            {item.label}
          </Typo>
          <span className="icon" />
        </SelectItem>
      ))}
    </SelectOptionsContainer>
  );
}

const SelectOptionsContainer = styled.ul<{
  maxWidth?: number;
  maxHeight: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 8px;
  width: ${({ maxWidth }) => `${maxWidth}px` || 'auto'};
  max-width: ${({ maxWidth }) => `${maxWidth}px` || 'auto'};
  max-height: 230px;
  // max-height: ${({ maxHeight }) => `${maxHeight}px` || 'auto'};
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;
const SelectItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 12px 10px 16px;
  &:hover {
    background-color: #eee;
  }
  ${typo.b10};

  > .icon {
    display: none;
    width: 16px;
    height: 16px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  &[data-checked='true'] {
    p {
      color: ${colorSet.primary3};
    }
    > .icon {
      display: flex;
    }
  }
`;

export default SelectOptions;
