import Typo from '@components/atoms/Typo';
import React from 'react';
import { EmptyTableOverlayContainer } from './styles';
import { EmptyTableOverlayProps } from './types';

const DEFAULT_ICON_SRC = '/assets/icons/icon-table-placeholder.svg';
const DEFAULT_DESCRIPTION = '목록이 없어요';
const DEFAULT_ICON_WIDTH = 120;
const DEFAULT_ICON_HEIGHT = 120;

function EmptyTableOverlay(props: EmptyTableOverlayProps) {
  const {
    $iconSrc = DEFAULT_ICON_SRC,
    description = DEFAULT_DESCRIPTION,
    $iconWidth = DEFAULT_ICON_WIDTH,
    $iconHeight = DEFAULT_ICON_HEIGHT,
  } = props;

  return (
    <EmptyTableOverlayContainer
      $iconSrc={$iconSrc}
      $iconWidth={$iconWidth}
      $iconHeight={$iconHeight}
    >
      <span className="icon" />
      <Typo typoType="b8m" color="gray7">
        {description}
      </Typo>
    </EmptyTableOverlayContainer>
  );
}

export default EmptyTableOverlay;
