import React from 'react';
import ReactDOM from 'react-dom/client';
import GlobalStyle from 'styles/GlobalStyle';
import { Toast } from '@components/atoms';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { colorPrimaryTheme } from 'utils/antdPrimaryColorTheme';
import { ConfigProvider } from 'antd';
import './styles/font.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import {
  unstable_setTextVerticalResizeEnabled,
  setTranslations,
} from 'polotno/config';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import App from 'App';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import koKR from 'antd/locale/ko_KR';
import reportWebVitals from './reportWebVitals';
import editorTranslations from './pages/private/ai-design/ai-design-templates-v2/components-v2/editor.translations.json';
import 'styles/global.css';
import '@blueprintjs/core/lib/css/blueprint.css';

dayjs.extend(weekday);
dayjs.extend(localeData);

const isProd = process.env.REACT_APP_ENV === 'prod';

if (isProd) {
  (() => {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  })();
}

unstable_setTextVerticalResizeEnabled(true);
setTranslations(editorTranslations);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider theme={colorPrimaryTheme} locale={koKR}>
      <DndProvider backend={HTML5Backend}>
        <GlobalStyle />
        <Toast />
        <App />
      </DndProvider>
    </ConfigProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
