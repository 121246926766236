import { AppError, request, Rows } from '@networks/index';
import { Language } from '../@types/language/models/Language';

/**
 * 글로벌 언어 목록 조회
 */
export const fetchLanguages = async () => {
  const url = `/admin/v1/global-languages`;
  try {
    const { data } = await request<Rows<Language>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
