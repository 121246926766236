import styled, { css } from 'styled-components';
import colorSet from '@styles/colors';

export const TablePageContainer = styled.div`
  display: flex;
`;

export const PaginationTableWithFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 24px;
`;
export const TableTabs = styled.ul`
  display: flex;
  align-items: center;
`;
export const TableTab = styled.li<{ $isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 12px 20px;
  border-bottom: 2px solid transparent;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-bottom: 2px solid ${colorSet.gray1};

      > p {
        color: ${colorSet.gray1};
      }
    `}
`;

export const RightAccessory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ExtraHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 64px;

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .divider {
    display: flex;
    width: 1px;
    height: 60%;
    background: ${colorSet.gray10};
  }

  .right {
  }
`;

export const RightButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
