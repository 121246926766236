/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import Select from '@components/v2/antd/data-entry/Select';
import { BlogLabelForm } from '@pages/private/ai-data/blog-labels/types/BlogLabelForm';
import { fetchGlobalCatalogs } from '../../../../@apis/global-catalogs';
import {
  BLOG_LABEL_GRADE_OPTIONS,
  BLOG_LABEL_TYPE_OPTIONS,
  BLOG_LABEL_WORK_STATUS_OPTIONS,
  BlogLabelType,
  BlogLabelWorkStatus,
} from '../../../../@types/blog-label/blog-label.constants';
import { UpdateBlogLabelBody } from '../../../../@types/blog-label/params/UpdateBlogLabelBody';
import { CreateBlogLabelBody } from '../../../../@types/blog-label/params/CreateBlogLabelBody';
import {
  createBlogLabel,
  deleteBlogLabel,
  fetchBlogLabelDetail,
  updateBlogLabel,
} from '../../../../@apis/blog-labels';

const INITIAL_VALUES: BlogLabelForm = {
  // 기본 정보
  globalCatalogIds: [],
  workStatus: BlogLabelWorkStatus.WAITING,
  grade: 1,
  treatmentName: [],
  contents: '',
  label: BlogLabelType.GREETING,
};

function BlogLabelDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [globalCatalogOptions, setGlobalCatalogOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    contents: yup.string().required(),
    globalCatalogIds: yup.array().min(1),
  });

  const formik = useFormik<BlogLabelForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchBlogLabelDetail(id);
      const {
        globalCatalogIds,
        label,
        treatmentName,
        workStatus,
        grade,
        contents,
        // 상세 정보
      } = row;

      setFormInitialValues({
        globalCatalogIds,
        label,
        contents,
        treatmentName,
        workStatus,
        grade,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalCatalogCategoryOptions = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogs({
        page: 1,
        pageSize: 9999,
      });
      setGlobalCatalogOptions(
        rows.map(({ globalCatalogName, id }) => ({
          label: globalCatalogName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchGlobalCatalogCategoryOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateBlogLabelBody) => {
    try {
      await createBlogLabel(body);
      showToast({
        description: '블로그 라벨링 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (id: number | string, body: UpdateBlogLabelBody) => {
    try {
      await updateBlogLabel(id, body);
      showToast({
        description: '블로그 라벨링 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteData = async (id: number | string) => {
    try {
      await deleteBlogLabel(id);
      showToast({
        description: '블로그 라벨링 정보가 삭제되었습니다.',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '블로그 라벨링 정보 등록';
    let alertMessage = '작성하신 내용대로 블로그 라벨링 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '블로그 라벨링 정보 수정';
      alertMessage = '작성하신 내용대로 블로그 라벨링 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogLabelForm;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderGlobalCatalogSelectForm = () => {
    return (
      <FormItem label="시술선택">
        <Select
          mode="multiple"
          style={{
            width: '100%',
          }}
          showSearch
          allowClear
          value={formik.values.globalCatalogIds || undefined}
          options={globalCatalogOptions}
          onChange={async (value, options) => {
            await formik.setFieldValue('globalCatalogIds', value);
            formik.setFieldValue(
              'treatmentName',
              options.map(
                (option: { label: string; value: any }) =>
                  option.label as string,
              ),
            );
          }}
          placeholder="시술을 선택해주세요"
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof BlogLabelForm;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const { labelName, required = false, key, placeholder, options } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        {id && (
          <Button
            buttonColor="red"
            onClick={() => {
              showAlert({
                size: 360,
                title: '라벨링 정보 삭제',
                message:
                  '삭제하신 정보는 복구가 불가능해요.\n정말 삭제하시겠어요?',
                actions: [
                  { label: '취소' },
                  {
                    color: 'red',
                    label: '삭제',
                    onClick: async () => {
                      await deleteData(id);
                    },
                  },
                ],
              });
            }}
          >
            삭제
          </Button>
        )}
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'기본정보'}>
            <FormLayout>
              {renderGlobalCatalogSelectForm()}
              {renderTextArea({
                labelName: '내용',
                required: true,
                key: 'contents',
              })}
              {renderSelectForm({
                labelName: '라벨유형',
                required: true,
                key: 'label',
                options: BLOG_LABEL_TYPE_OPTIONS,
              })}
              {renderSelectForm({
                labelName: '등급',
                required: true,
                key: 'grade',
                options: BLOG_LABEL_GRADE_OPTIONS,
              })}
              {renderSelectForm({
                labelName: '검수상태',
                required: true,
                key: 'workStatus',
                options: BLOG_LABEL_WORK_STATUS_OPTIONS,
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default BlogLabelDetailPage;
