import colorSet from '@styles/colors';
import { MediaQuery } from '@styles/mediaQuery';
import { zIndex } from '@styles/zIndex';
import styled, { css, keyframes } from 'styled-components';

export const SUB_MENU_DEFAULT_CLOSE_WIDTH = 1024;

export const Gnb = styled.nav`
  flex-shrink: 0;
  position: relative;
  z-index: ${zIndex.GNB};
  background: ${colorSet.gray1};
  overflow: overlay;

  &::-webkit-scrollbar {
    display: none;
  }

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: none;
  }
`;
export const TabletBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 11;

  display: none;
  ${MediaQuery.FROM_PC_TO_TABLET} {
    display: block;
  }
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: none;
  }
`;
export const ToggleContainer = styled.div<{ $isSubNavOpen: boolean }>`
  padding: 14px 20px;

  > button {
    width: 32px;
    height: 32px;
    background: url('/assets/icons/gnb/icon-double-arrow_white.svg') no-repeat
      center center / contain;
    transform: rotate(
      ${({ $isSubNavOpen }) => ($isSubNavOpen ? '0deg' : '180deg')}
    );

    &[disabled='true'] {
      background: none;
    }
  }
`;

export const NavList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 6px;

  &:not(:last-child) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0.5px;
      width: 24px;
      height: 1px;
      background: ${colorSet.gray4};
    }
  }
`;
export const NavItem = styled.li<{ $isSelected: boolean; $iconName: string }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${colorSet.gray3};
      > svg > path {
        fill: ${colorSet.gray13};
      }
      > svg > rect {
        fill: ${colorSet.gray13};
      }

      > p {
        color: ${colorSet.gray13};
      }
    `}

  > .icon {
    display: flex;
    width: 22px;
    height: 22px;
    background: ${({ $iconName }) =>
      `url(/assets/icons/gnb/${$iconName}.svg) no-repeat center center / contain`};
  }

  &:hover {
    > svg > path {
      fill: ${colorSet.gray13};
    }
    > svg > rect {
      fill: ${colorSet.gray13};
    }

    > p {
      color: ${colorSet.gray13};
    }
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      > .icon {
        fill: red;
      }

      > p {
        color: ${colorSet.gray13};
      }
      background: ${colorSet.gray3};
    `}
`;

export const SubNav = styled.div<{ $isSubNavOpen: boolean }>`
  flex-shrink: 0;
  position: relative;
  z-index: ${zIndex.SUB_NAV};
  width: ${({ $isSubNavOpen }) => ($isSubNavOpen ? '228px' : '0')};
  transition: width 0.3s;
  background: ${colorSet.gray2};
  overflow: overlay;
  > p {
    width: 228px;
    padding: 16px 12px;
  }

  ${MediaQuery.FROM_PC_TO_TABLET} {
    position: fixed;
    left: 72px;
    height: 100%;
  }
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    display: none;
  }
`;
export const SubNavList = styled.ul`
  padding: 0 8px;
`;
export const SubNavItem = styled.li<{ $isSelected: boolean; icon?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 212px;
  border-radius: 8px;
  padding: 10px 16px;

  &:hover {
    > p {
      color: ${colorSet.gray13};
    }
    svg > path {
      fill: ${colorSet.gray13};
    }
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${colorSet.gray3};
      > p {
        color: ${colorSet.gray13};
      }
    `}

  ${({ icon }) =>
    icon
      ? css`
          &:hover > .icon {
            background: ${`url('/assets/icons/gnb/${icon}_hover.svg') no-repeat center center / contain`};
          }

          > .icon {
            display: flex;
            width: 24px;
            height: 24px;
            background: ${`url('/assets/icons/gnb/${icon}.svg') no-repeat center center / contain`};
          }
        `
      : css`
          > .icon {
            display: none;
          }
        `}

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    width: 100%;
  }
`;

export const MobileSubNav = styled.div`
  display: none;

  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    position: fixed;
    z-index: ${zIndex.SUB_NAV};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.3);
  }
`;

const MobileGnbInitAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const MobileGnbCloseAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const MobileGnb = styled.nav<{ isClosed: boolean }>`
  display: flex;
  flex-direction: column;
  width: 228px;
  height: 100%;
  background: ${colorSet.gray2};
  transform: translateX(0);
  transition: 0.3s;
  animation: ${MobileGnbInitAnimation} 0.3s forwards;

  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid ${colorSet.gray3};

    > .icon {
      display: flex;
      width: 42px;
      height: 42px;
      background: url('/assets/icons/icon-logo_symbol.svg') no-repeat center
        center / contain;
    }
  }
  > ul {
    flex: 1;
  }

  ${({ isClosed }) =>
    isClosed &&
    css`
      animation: ${MobileGnbCloseAnimation} 0.3s forwards;
    `}
`;

export const MobileNavList = styled.ul`
  overflow: overlay;
`;
export const MobileNavItem = styled.li`
  cursor: pointer;
`;
export const MobileNavTitle = styled.div<{ $isOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;

  > .icon {
    display: flex;
    width: 16px;
    height: 16px;
    background: url('/assets/icons/icon-line-arrow_gray.svg') no-repeat center
      center / contain;
  }

  ${({ $isOpened }) =>
    $isOpened &&
    css`
      > .icon {
        transform: rotate(180deg);
      }
    `}
`;

export const MobileNavFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px 0 40px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  button {
    padding: 0;
    > span {
      display: flex;
      align-items: center;
      gap: 2px;
      * {
        fill: ${colorSet.gray9};
      }
    }

    &:hover {
      > span {
        color: ${colorSet.gray13};
        * {
          fill: ${colorSet.gray13};
        }
      }
    }
  }
`;
