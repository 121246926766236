import React from 'react';
import { Table, TableProps, Tag } from 'antd';
import dayjs from 'dayjs';
import Button from '@components/atoms/Button';
import { useAlertStore } from '@store/useAlertStore';
import { PartnerGroupPartnerMember } from '../../../../../@types/partner/models/PartnerMember';
import { getFormatTelNumber } from '../../../../../utils/format';

export interface PartnerMemberTableProps {
  members: PartnerGroupPartnerMember[];
  onDeleteClick?: (member: PartnerGroupPartnerMember) => void;
}

function PartnerMemberTable(props: PartnerMemberTableProps) {
  const { members, onDeleteClick } = props;
  const { show: showAlert } = useAlertStore();
  const cols: TableProps<PartnerGroupPartnerMember>['columns'] = [
    {
      width: 150,
      dataIndex: 'createdAt',
      title: '등록일',
      key: 'createdAt',
      render: (_, record) => {
        const { createdAt } = record;
        return createdAt ? dayjs(createdAt).format('YYYY-MM-DD HH:mm') : '-';
      },
    },
    {
      width: 150,
      dataIndex: 'memberAId',
      title: '계정명',
      key: 'memberAId',
      render: (_, record) => {
        const { member } = record;
        const { memberAId } = member;
        return memberAId;
      },
    },
    {
      width: 80,
      dataIndex: 'memberPosition',
      title: '사용자 그룹',
      render: (_, record) => {
        const { member } = record;
        const { memberGroup } = member;
        if (memberGroup) {
          switch (memberGroup) {
            case 'ADMIN':
              return '관리자';
            case 'PARTNER':
              return '병원사용자';
            case 'OV':
              return 'OV';
            case 'OMA':
              return 'OMA';
            default:
              return '';
          }
        }
        return '';
      },
    },
    {
      width: 150,
      dataIndex: 'memberName',
      title: '사용자명',
      key: 'memberName',
      render: (_, record) => {
        const { member } = record;
        const { memberName, isBoostree } = member;

        let tag;

        if (isBoostree) {
          tag = <Tag>부스트리</Tag>;
        }

        return (
          <div
            style={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {tag && tag}
            {memberName}
          </div>
        );
      },
    },
    {
      width: 150,
      dataIndex: 'memberAuthority',
      title: '사용권한',
      key: 'memberAuthority',
      render: (_, record) => {
        const { memberAuthority } = record;
        return memberAuthority?.authorityName;
      },
    },
    {
      width: 150,
      dataIndex: 'memberPhone',
      title: '연락처',
      key: 'memberPhone',
      render: (_, record) => {
        const { member } = record;
        const { memberPhone } = member;
        return getFormatTelNumber(memberPhone);
      },
    },
    {
      width: 32,
      dataIndex: 'actions',
      title: '',
      key: 'actions',
      render: (_, record) => {
        const { member } = record;
        const { memberGroup } = member;
        if ((memberGroup && memberGroup === 'OV') || memberGroup === 'OMA') {
          return (
            <Button
              buttonColor="red"
              buttonSize={32}
              onClick={() => {
                if (onDeleteClick) {
                  showAlert({
                    size: 360,
                    title: '사용자 삭제',
                    message: '해당 사용자를 삭제하시겠어요?',
                    actions: [
                      { label: '취소' },
                      {
                        label: '삭제',
                        color: 'red',
                        onClick: async () => {
                          onDeleteClick(record);
                        },
                      },
                    ],
                  });
                }
              }}
            >
              삭제
            </Button>
          );
        }
        return '';
      },
    },
  ];
  return (
    <Table
      style={{
        width: '100%',
      }}
      size="middle"
      pagination={false}
      columns={cols}
      locale={{
        emptyText: (
          <div
            style={{
              padding: 20,
            }}
          >
            등록된 계정 정보가 없습니다
          </div>
        ),
      }}
      dataSource={members.map((member) => {
        return {
          ...member,
          key: member.id,
        };
      })}
    />
  );
}

export default PartnerMemberTable;
