import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
  TableTabFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { LabelColorType } from '@components/atoms/Label/types';
import { getGradeText } from '@pages/private/ai-data/blog-labels/utils';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchBlogLabels } from '../../../../@apis/blog-labels';
import { FetchBlogLabelListQuery } from '../../../../@types/blog-label/params/FetchBlogLabelListQuery';
import { BlogLabel } from '../../../../@types/blog-label/models/BlogLabel';
import {
  BLOG_LABEL_GRADE_OPTIONS,
  BLOG_LABEL_TYPE_OPTIONS,
  BlogLabelTranslations,
  BlogLabelWorkStatus,
} from '../../../../@types/blog-label/blog-label.constants';
import { renderTags } from '../../../../utils/tableRenderHelper';

function BlogLabelListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    treatmentName: '',
    label: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'label',
      options: BLOG_LABEL_TYPE_OPTIONS,
      label: '라벨유형',
      placeholder: '라벨유형 선택',
    },
    {
      type: 'select',
      field: 'grade',
      options: BLOG_LABEL_GRADE_OPTIONS,
      label: '등급',
      placeholder: '등급 선택',
    },
  ];

  const tabFilterInfo: TableTabFilterInfo = {
    filterKey: 'workStatus',
    tabs: [
      {
        label: BlogLabelTranslations[BlogLabelWorkStatus.WAITING],
        filterValue: BlogLabelWorkStatus.WAITING,
      },
      {
        label: BlogLabelTranslations[BlogLabelWorkStatus.INSPECTING],
        filterValue: BlogLabelWorkStatus.INSPECTING,
      },
      {
        label: BlogLabelTranslations[BlogLabelWorkStatus.INSPECTION_COMPLETED],
        filterValue: BlogLabelWorkStatus.INSPECTION_COMPLETED,
      },
      {
        label: BlogLabelTranslations[BlogLabelWorkStatus.FINAL_COMPLETED],
        filterValue: BlogLabelWorkStatus.FINAL_COMPLETED,
      },
      {
        label: BlogLabelTranslations[BlogLabelWorkStatus.REJECTED],
        filterValue: BlogLabelWorkStatus.REJECTED,
      },
    ],
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBlogLabelListQuery> =
    {
      filterKey: 'treatmentName',
      searchInputPlaceholder: '시술명 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'treatmentName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '연관시술',
      cellRenderer: ({ data }: ICellRendererParams<BlogLabel>) => {
        if (data) {
          const { treatmentName } = data;
          return treatmentName.join(', ');
        }
        return '';
      },
      sortable: false,
    },
    {
      field: 'label',
      minWidth: 153,
      width: 153,
      headerName: '라벨유형',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<BlogLabel>) => {
        if (data) {
          const { label } = data;
          return BlogLabelTranslations[label];
        }
        return '';
      },
    },
    {
      field: 'grade',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '등급',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<BlogLabel>) => {
        if (data) {
          const { grade } = data;
          return getGradeText(grade);
        }
        return '';
      },
    },
    {
      field: 'workStatus',
      minWidth: 153,
      width: 153,
      headerName: '검수상태',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<BlogLabel>) => {
        const { data } = col;
        if (data) {
          const { workStatus } = data;
          const translation = BlogLabelTranslations[workStatus];

          let color: LabelColorType = 'gray';
          switch (workStatus) {
            case BlogLabelWorkStatus.WAITING:
              color = 'gray';
              break;
            case BlogLabelWorkStatus.INSPECTING:
              color = 'black';
              break;
            case BlogLabelWorkStatus.INSPECTION_COMPLETED:
              color = 'blue';
              break;
            case BlogLabelWorkStatus.FINAL_COMPLETED:
              color = 'blue';
              break;
            case BlogLabelWorkStatus.REJECTED:
              color = 'red';
              break;
            default:
              break;
          }

          return renderTags({
            value: translation,
            color,
            type: 'light',
          });
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: FetchBlogLabelListQuery) => {
    const { rows, count } = await fetchBlogLabels(query);
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: BlogLabel) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DATA_BLOG_LABELS_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DATA_BLOG_LABELS_ADD}>
          <Button>라벨링 정보 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      tabFilterInfo={tabFilterInfo}
      filterForms={filterForms}
    />
  );
}

export default BlogLabelListPage;
