import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown as DropdownAntd, DropdownProps } from 'antd';
import styled, { css } from 'styled-components';
import colorSet, { ColorType } from '@styles/colors';
import SelectOptions from '../Select/components/SelectOptions';
import typo from '../Typo/types';
import Typo from '../Typo';

export interface TableSelectOption<T> {
  key: T;
  label: string;
  color?: ColorType;
}

interface TableSelectProps<T> extends DropdownProps {
  value: T;
  options: TableSelectOption<T>[];
  onChange: (value: T) => void;
  placeholder?: string;
  menuMaxWidth?: number;
  menuMaxHeight?: number;
  isDot?: boolean;
}

function TableSelect<T>(props: TableSelectProps<T>) {
  const {
    value,
    onChange,
    options,
    placeholder,
    menuMaxWidth,
    menuMaxHeight,
    isDot,
    ...rest
  } = props;

  const triggerRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const selectedItem = useMemo(
    () => options.find((item) => String(item.key) === String(value)),
    [options, value],
  );

  const renderDropdownMenu = (handleDropdownClose: () => void) => {
    return options ? (
      <SelectOptions
        value={value}
        handleChange={onChange}
        options={options.map((item) => ({
          value: item.key,
          label: item.label,
        }))}
        menuMaxWidth={menuMaxWidth}
        menuMaxHeight={menuMaxHeight}
        handleDropdownClose={handleDropdownClose}
      />
    ) : null;
  };

  const handleDropdownClose = () => {
    setOpen(false);
  };

  const handleDropdownCloseEvent = (e: MouseEvent) => {
    if (!triggerRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleDropdownCloseEvent);

    return () => {
      window.removeEventListener('click', handleDropdownCloseEvent);
    };
  }, []);

  return (
    <StyledDropdown
      placement="bottomRight"
      open={open}
      menu={{ items: [] }}
      trigger={['click']}
      dropdownRender={() => renderDropdownMenu(handleDropdownClose)}
      {...rest}
    >
      <Trigger
        type="button"
        isEmpty={!selectedItem?.label}
        ref={triggerRef}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        {isDot && <Dot color={selectedItem?.color} />}
        {selectedItem?.label ? (
          <Typo typoType="b9" color={selectedItem.color}>
            {selectedItem.label}
          </Typo>
        ) : (
          placeholder || '선택'
        )}
        <span className="icon" />
      </Trigger>
    </StyledDropdown>
  );
}

const StyledDropdown = styled(DropdownAntd)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Trigger = styled.button<{ isEmpty: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  ${typo.b9};

  > .icon {
    width: 20px;
    height: 20px;
    background: url('/assets/icons/icon-arrow_black.svg') no-repeat center
      center / contain;
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${colorSet.gray7};
    `}
`;

const Dot = styled.span<{ color?: ColorType }>`
  margin-right: 12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => colorSet[color || 'gray2']};
`;

export default TableSelect;
