import { get, isEmpty } from 'lodash';

class AxiosError {
  readonly statusCode?: number;

  readonly message?: string;

  readonly translate?: string;

  readonly error?: unknown;

  readonly data?: { nameList: string[] } | string[];

  constructor(e: unknown) {
    this.statusCode = get(e, 'response.data.statusCode') as unknown as number;
    this.message = get(e, 'response.data.message') as unknown as string;
    this.translate = get(e, 'response.data.translate') as string | undefined;
    this.data = get(e, 'response.data.data') as
      | { nameList: string[] }
      | string[]
      | undefined;

    if (isEmpty(this.translate)) {
      if (Array.isArray(this.message)) {
        this.message = String(this.message);
      } else if (typeof this.message === 'object') {
        this.message = JSON.stringify(this.message);
      } else if (!this.message) {
        if (this.statusCode !== 404) {
          this.message = String(e);
        }
        this.message = '';
      }
    }

    this.error = get(e, 'response.data.error');
  }
}

export default AxiosError;
