import { observer } from 'mobx-react-lite';
import Select from '@components/v2/antd/data-entry/Select';
import { THEME_ELEMENT_OPTIONS } from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
import React from 'react';

// @ts-ignore
const ColorThemeKeySelect = observer(({ element }) => {
  let value;
  const { custom = {} } = element;
  if (custom) {
    const { themeKey } = custom;
    value = themeKey;
  }
  return (
    <Select
      allowClear
      placeholder="색상 테마 적용 범위"
      value={value}
      style={{
        width: '240px',
      }}
      options={THEME_ELEMENT_OPTIONS}
      onChange={(value) => {
        element.set({
          custom: {
            ...custom,
            themeKey: value,
          },
        });
      }}
    />
  );
});

export default ColorThemeKeySelect;
