import React, { HTMLAttributes } from 'react';
import { styled } from 'styled-components';
import colorSet from '@styles/colors';
import { ReactComponent as DragSvg } from '../../../../assets/svg/DragHandler.svg';

interface DragHandlerProps extends HTMLAttributes<HTMLDivElement> {}

function DragHandler(props: DragHandlerProps) {
  return (
    <div {...props}>
      <DragIcon />
    </div>
  );
}

export default DragHandler;

const DragIcon = styled(DragSvg)`
  border-radius: 6px;
  height: 40px;
  &:hover {
    cursor: grab;
    background-color: ${colorSet.gray11};
    path {
      fill: ${colorSet.gray7};
    }
  }
`;
