import styled, { css } from 'styled-components';
import { EmptyTableOverlayStyleProps } from './types';

export const EmptyTableOverlayContainer = styled.div<EmptyTableOverlayStyleProps>`
  ${({ $iconSrc, $iconWidth, $iconHeight }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    > .icon {
      width: ${$iconWidth}px;
      height: ${$iconHeight}px;
      background: url(${$iconSrc}) no-repeat center center / contain;
    }
  `};
`;
