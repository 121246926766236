const colorSet = {
  // DEV
  primary1: '#000000',
  primary2: '#222529',
  primary3: '#353A3F',
  primary4: '#4A5056',
  primary5: '#5E656E',
  primary6: '#6E757C',
  primary7: '#8C939A',
  primary8: '#ACB3BA',
  primary9: '#CFD4D9',
  primary10: '#DEE2E6',

  gray1: '#000000',
  gray2: '#222529',
  gray3: '#353A3F',
  gray4: '#4A5056',
  // Sub Text
  gray5: '#5E656E',
  gray6: '#6E757C',
  // Sub Text 2
  gray7: '#8C939A',
  gray8: '#ACB3BA',
  // Input Border
  gray9: '#CFD4D9',
  // Table Border
  gray10: '#DEE2E6',
  gray11: '#F1F3F6',
  gray12: '#F6F8FB',
  gray13: '#FFFFFF',

  red1: '#B90000',
  red2: '#D71308',
  // Default
  red3: '#FA362B',
  red4: '#FF6256',

  red5: '#FF9B8F',
  red6: '#FFC3BB',
  red7: '#FFD8D2',
  red8: '#FFF1EF',

  teal1: '#22D3C8',
  teal2: '#28AEED',
  // Default
  teal3: '#29D1FF',
  teal4: '#A3EEEA',
  teal5: '#A9EDFF',
  teal6: '#E4F9FF',

  yellow1: '#F2AE00',
  // Default
  yellow2: '#FFCC00',
  yellow3: '#FFD633',
  yellow4: '#FFE066',
  yellow5: '#FFF0B3',
  yellow6: '#FFF7D9',

  blue1: '#003EC3',
  blue2: '#005CE1',
  // Default
  blue3: '#007AFF',
  blue4: '#3395FF',
  blue5: '#66AFFF',
  blue6: '#B3D7FF',
  blue7: '#CEE5FF',
  blue8: '#E5F2FF',

  purple1: '#6B3CC9',
  // Default
  purple2: '#8556E3',
  purple3: '#A17DF5',
  purple4: '#BD9FF5',
  purple5: '#D4C4F5',
  purple6: '#F5EBFF',

  pink1: '#6B3CC9',
  // Default
  pink2: '#FF2F7A',
  pink3: '#FF608F',
  pink4: '#FF9BB8',
  pink5: '#FFD1DF',
  pink6: '#FFEDF2',

  // gradient
  gradient1: `linear-gradient(269.38deg, #95DD7B 1.07%, #41D07A 65.8%, #0CB952 98.93%)`,
  boxShadow: `rgba(34, 37, 41, 0.12)`,

  // toast
  toastGray: 'rgba(34, 37, 41, 0.7)',
  toastPrimary: 'rgba(60, 198, 118, 0.7)',
  toastRed: 'rgba(250, 54, 43, 0.7);',

  showdow1: '2px 2px 8px rgba(34, 37, 41, 0.08)',
  showdow2: '2px 2px 16px rgba(34, 37, 41, 0.12);',
  showdow3: '2px 2px 32px rgba(34, 37, 41, 0.12)',
  showdow4: '0px 0px 60px rgba(34, 37, 41, 0.06)',
} as const;

export default colorSet;

type ColorType = keyof typeof colorSet;
export { type ColorType };
