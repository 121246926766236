import { FilterForm } from '@components/organisms/TableFilter/types';
import _ from 'lodash';

export const getFilterCount = (
  filterFormValues: Record<string, any>,
  filterForm: FilterForm[],
) => {
  try {
    return Object.entries(filterFormValues).reduce((acc, [key, value]) => {
      const findFilterValue = filterForm.find((item) => item.field === key);

      const isEmpty =
        value !== null &&
        !(
          (typeof value === 'object' && Object.keys(value).length !== 0) ||
          (typeof value !== 'object' &&
            value !== '' &&
            value !== null &&
            value !== undefined)
        );

      if (findFilterValue && !isEmpty) {
        return [...acc, key];
      }

      if (key.includes('From') && !isEmpty) {
        const originKey = key.split('From')[0];
        if (!acc.includes(originKey)) {
          return [...acc, originKey];
        }
      }
      if (key.includes('To') && !isEmpty) {
        const originKey = key.split('To')[0];
        if (!acc.includes(originKey)) {
          return [...acc, originKey];
        }
      }

      return acc;
    }, [] as string[]).length;
  } catch (e) {
    console.warn(e);
    return 0;
  }
};

// 중복 제거
export const uniqueCheckArray = (data: any[], key: string, key2?: string) => {
  return [..._.cloneDeep(data)].filter((item, idx, arr) => {
    if (!key2)
      return arr.findIndex((i) => i[`${key}`] === item[`${key}`]) === idx;
    return (
      arr.findIndex(
        (i) =>
          i[`${key}`] === item[`${key}`] && i[`${key2}`] === item[`${key2}`],
      ) === idx
    );
  });
};

export const uniqueCheckArrayCatalog = (data: any[], key: string) => {
  return [..._.cloneDeep(data)].filter((item, idx, arr) => {
    return (
      arr.findIndex((i) => i.catalog[`${key}`] === item.catalog[`${key}`]) ===
      idx
    );
  });
};
