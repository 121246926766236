import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { getGradeText } from '@pages/private/ai-data/blog-labels/utils';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { BLOG_LABEL_GRADE_OPTIONS } from '../../../../@types/blog-label/blog-label.constants';
import { FetchBlogReferenceListQuery } from '../../../../@types/blog-reference/params/FetchBlogReferenceListQuery';
import { BlogReference } from '../../../../@types/blog-reference/models/BlogReference';
import {
  BLOG_REFERENCE_CATEGORY_OPTIONS,
  BlogReferenceTranslations,
} from '../../../../@types/blog-reference/blog-reference.constants';
import { fetchBlogReferences } from '../../../../@apis/blog-references';

function BlogReferenceListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    keyword: '',
    category: undefined,
    grade: undefined,
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'category',
      options: BLOG_REFERENCE_CATEGORY_OPTIONS,
      label: '레퍼런스유형',
      placeholder: '레퍼런스유형 선택',
    },
    {
      type: 'select',
      field: 'grade',
      options: BLOG_LABEL_GRADE_OPTIONS,
      label: '등급',
      placeholder: '등급 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBlogReferenceListQuery> =
    {
      filterKey: 'keyword',
      searchInputPlaceholder: '검색 (시술명, 제목, 등급)',
    };

  const COLS: ColDef[] = [
    {
      field: 'treatmentName',
      minWidth: 153,
      width: 153,
      headerName: '시술명',
      cellRenderer: ({ data }: ICellRendererParams<BlogReference>) => {
        if (data) {
          const { treatmentName } = data;
          if (treatmentName) {
            return treatmentName.join(', ');
          }
        }
        return '';
      },
      sortable: false,
    },
    {
      field: 'title',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '제목',
      sortable: false,
    },
    {
      field: 'category',
      minWidth: 153,
      width: 153,
      headerName: '레퍼런스유형',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<BlogReference>) => {
        if (data) {
          const { category } = data;
          return BlogReferenceTranslations[category];
        }
        return '';
      },
    },
    {
      field: 'searchTags',
      minWidth: 200,
      width: 200,
      headerName: '검색태그',
      cellRenderer: ({ data }: ICellRendererParams<BlogReference>) => {
        if (data) {
          const { searchTags } = data;
          if (searchTags) {
            return searchTags.join(', ');
          }
        }
        return '';
      },
      sortable: false,
    },
    {
      field: 'grade',
      minWidth: 153,
      width: 153,
      headerName: '등급',
      sortable: false,
      cellRenderer: ({ data }: ICellRendererParams<BlogReference>) => {
        if (data) {
          const { grade } = data;
          return getGradeText(grade);
        }
        return '-';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchBlogReferenceListQuery) => {
      const { rows, count } = await fetchBlogReferences(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: BlogReference) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DATA_BLOG_REFERENCE_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DATA_BLOG_REFERENCE_ADD}>
          <Button>블로그 레퍼런스 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      filterForms={filterForms}
    />
  );
}

export default BlogReferenceListPage;
