import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import InputSearch from '@components/v2/antd/data-entry/InputSearch';
import { AppError } from '@networks/index';
import { Table, TableProps } from 'antd';
import { styled } from 'styled-components';
import { searchAddress } from '../../@apis/common';
import { Address } from '../../@types/common/models/Address';

export interface AddressSearchModalProps {
  onAddressSelect?: (address: Address) => void;
  onClose?: () => void;
}

function AddressSearchModal(props: AddressSearchModalProps) {
  const cols: TableProps<Address>['columns'] = [
    {
      dataIndex: 'zipCode',
      title: '우편번호',
      key: 'zipCode',
    },
    {
      width: '80%',
      dataIndex: 'street',
      title: '주소정보',
      key: 'street',
    },
  ];

  const { onAddressSelect, onClose } = props;
  const [keyword, setKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [rows, setRows] = useState<Address[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const requestSearchAddress = async () => {
    setSearching(true);
    try {
      const { rows } = await searchAddress(keyword);
      setRows(rows);
      setErrorMessage('');
    } catch (e) {
      const error = e as AppError;

      if (error.statusCode === 404 && error.message) {
        setErrorMessage(error.message);
      }
      setRows([]);
    } finally {
      setSearching(false);
    }
  };

  return (
    <Modal
      size={560}
      hideFooter
      title="주소검색"
      onClose={() => {
        if (onClose) onClose();
      }}
    >
      <InputSearch
        allowClear
        placeholder="주소를 입력해주세요"
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onSearch={async (value, event, info) => {
          if (info?.source === 'clear') {
            setKeyword(value);
            setRows([]);
          } else if (value) {
            await requestSearchAddress();
          }
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            if (keyword) {
              await requestSearchAddress();
            }
          }
        }}
      />
      <div
        style={{
          marginTop: '16px',
        }}
      >
        <StyledTable
          onRow={(record) => {
            return {
              onClick: () => {
                if (onAddressSelect) {
                  onAddressSelect(record);
                }
                if (onClose) {
                  onClose();
                }
              },
            };
          }}
          size="small"
          loading={searching}
          pagination={false}
          dataSource={rows.map((row) => {
            return {
              ...row,
              key: row.zipCode,
            };
          })}
          columns={cols}
          locale={{
            emptyText: (
              <div
                style={{
                  padding: 20,
                }}
              >
                {errorMessage || '검색어를 입력해주세요'}
              </div>
            ),
          }}
          scroll={{
            y: 300,
          }}
        />
      </div>
    </Modal>
  );
}

export default AddressSearchModal;

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;
