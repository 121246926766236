import {
  CellClassFunc,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { AgGridColumn, AgGridReact, AgGridReactProps } from 'ag-grid-react';
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  CSSProperties,
  useMemo,
} from 'react';
import EmptyTableOverlay from './components/EmptyTableOverlay';
import LoadingTableOverlay from './components/LoadingTableOverlay';
import { TableContainer, TableEmpty, TableLoading } from './styles';

export interface TableProps extends AgGridReactProps {
  rowData: any[];
  columnDefs: any[];
  hasSelect?: boolean;

  onSelect?: (values: any[]) => void;
  onSelectData?: (values: any[]) => void;
  onSortChange?: (value?: {
    field: string;
    sorts: 'ASC' | 'DESC' | null | undefined;
  }) => void;
  isLoading?: boolean;
  isError?: boolean;
  style?: CSSProperties;
  rowHeight?: number;
  rowDragManaged?: boolean;
  // table custom placeholder
  noRowsOverlayComponent?: any;
  onSelectionChanged?: any;
  onRowClick?: (event: RowClickedEvent<any>) => void;
  cellClass?: string | string[] | CellClassFunc<any> | undefined;
  headerCheckboxSelection?: boolean;
  notHover?: boolean;
}

export interface TableRef {
  selectionApi: {
    getCurrentSelectedRows: () => number[];
    getCurrentSelectedRowDatas: () => any[];
    setSelectedRowKeys: (ids: number[]) => void;
    handleSelectReset: () => void;
  };
  gridRef: React.MutableRefObject<GridReadyEvent<any> | null>;
}

const Table = forwardRef<TableRef, TableProps>((props, ref) => {
  const {
    columnDefs,
    hasSelect = false,
    onSelect,
    onSelectData,
    onSortChange,
    isError,
    isLoading,
    style,
    rowHeight,
    rowDragManaged,
    noRowsOverlayComponent,
    onSelectionChanged,
    onRowClick,
    headerCheckboxSelection = true,
    cellClass = 'ag-checkbox-cell',
    notHover = false,
    suppressRowClickSelection = true,
    ...rest
  } = props;

  const isEmpty = useMemo(() => rest.rowData.length === 0, [rest.rowData]);

  /* Grid Ref */
  const gridRef = useRef<GridReadyEvent<any> | null>(null);

  /* Checkbox State */
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedRowDatas, setSelectedRowDatas] = useState<any[]>([]);
  const [currentSelectedRows, setCurrentSelectedRows] = useState<number[]>([]);
  const [currentSelectedRowDatas, setCurrentSelectedRowDatas] = useState<any[]>(
    [],
  );
  const isSelectMove = useRef(false);

  /* Checkbox Event */
  const handleAddSelectedRow = (id: number) => {
    setSelectedRows((prev) => [...prev, id]);
    setCurrentSelectedRows((prev) => [...prev, id]);
  };
  const handleRemoveSelectedRow = (id: number) => {
    setSelectedRows((prev) => prev.filter((item) => item !== id));
    setCurrentSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  const handleAddSelectedRowData = (data: any) => {
    setSelectedRowDatas((prev) => [...prev, data]);
    setCurrentSelectedRowDatas((prev) => [...prev, data]);
  };
  const handleRemoveSelectedRowData = (id: number) => {
    setSelectedRowDatas((prev) => prev.filter((item) => item.id !== id));
    setCurrentSelectedRowDatas((prev) => prev.filter((item) => item.id !== id));
  };

  const handleRowSelect = (id: number) => {
    if (selectedRows.includes(id)) {
      handleRemoveSelectedRow(id);
    } else {
      handleAddSelectedRow(id);
    }
  };
  const handleRowDataSelect = (data: any) => {
    if (selectedRowDatas.find((item) => item.id === data.id)) {
      handleRemoveSelectedRowData(data.id);
    } else {
      handleAddSelectedRowData(data);
    }
  };

  const handleSelectionCheckForPageMove = (api: GridApi<any>) => {
    const current: number[] = [];

    isSelectMove.current = true;

    api.forEachNode(async (node) => {
      const nodeItemId = node.data.id;
      if (selectedRows.includes(nodeItemId)) {
        node.setSelected(true);
        current.push(nodeItemId);
      }
    });

    setTimeout(() => {
      isSelectMove.current = false;
      setCurrentSelectedRows(current);
      setCurrentSelectedRowDatas(current);
    }, 0);
  };
  const handleSelectReset = () => {
    isSelectMove.current = true;

    gridRef.current?.api.forEachNode(async (node) => {
      node.setSelected(false);
    });

    setTimeout(() => {
      isSelectMove.current = false;
      setSelectedRows([]);
      setCurrentSelectedRows([]);
      setSelectedRowDatas([]);
      setCurrentSelectedRowDatas([]);
    }, 0);
  };

  /* Foward Ref */
  useImperativeHandle(ref, () => ({
    selectionApi: {
      getCurrentSelectedRows: () => currentSelectedRows,
      getCurrentSelectedRowDatas: () => currentSelectedRowDatas,
      setSelectedRowKeys: (datas) => setCurrentSelectedRows(datas),
      handleSelectReset,
    },
    gridRef,
  }));

  useEffect(() => {
    onSelect?.(currentSelectedRows);
  }, [currentSelectedRows, onSelect]);

  useEffect(() => {
    onSelectData?.(currentSelectedRowDatas);
  }, [currentSelectedRowDatas, onSelectData]);

  return (
    <TableContainer
      className={`ag-theme-alpine ${notHover ? 'ag-focus-not-hover' : ''}`}
      style={style}
    >
      {isEmpty && isError
        ? noRowsOverlayComponent || (
            <TableEmpty>
              <EmptyTableOverlay />
            </TableEmpty>
          )
        : null}
      {isLoading ? (
        <TableLoading>
          <LoadingTableOverlay />
        </TableLoading>
      ) : null}
      <AgGridReact
        rowHeight={rowHeight}
        onGridReady={(gridRefParam) => {
          gridRef.current = gridRefParam;
          const allColumnIds: string[] = [];
          (gridRefParam.columnApi.getColumns() || []).forEach((column) => {
            allColumnIds.push(column.getId());
          });

          gridRefParam.columnApi.autoSizeColumns(allColumnIds);
        }}
        defaultColDef={{
          cellClass: 'ag-custom-border',
          sortable: true,
          comparator: () => 0,
          resizable: true,
        }}
        rowSelection={onSelectionChanged ? 'single' : 'multiple'}
        // rowSelection={'single'}
        suppressRowClickSelection={suppressRowClickSelection}
        unSortIcon
        onSortChanged={(e) => {
          const allColumns = e.columnApi.getAllGridColumns();
          const sortModel = allColumns
            .map((column) => ({
              field: column.getColId(),
              sorts: column.getSort()?.toUpperCase(),
            }))
            .filter((item) => !!item.sorts);

          onSortChange?.(sortModel?.[0] as any);
        }}
        loadingOverlayComponent={LoadingTableOverlay}
        suppressLoadingOverlay
        onRowSelected={(e) => {
          if (!isSelectMove.current) {
            if (onSelect) handleRowSelect(e.data.id);
            if (onSelectData) handleRowDataSelect(e.data);
          }
        }}
        onRowDataUpdated={(event) => {
          const { api } = event;

          handleSelectionCheckForPageMove(api);
        }}
        rowDragMultiRow
        rowDragManaged={rowDragManaged}
        onRowClicked={onSelectionChanged}
        suppressMovableColumns
        // eslint-disable-next-line react/no-unstable-nested-components
        noRowsOverlayComponent={() => <div />}
        {...rest}
      >
        {hasSelect && (
          <AgGridColumn
            field=""
            headerCheckboxSelection={headerCheckboxSelection}
            checkboxSelection
            showDisabledCheckboxes
            cellRenderer={({ value }: ICellRendererParams) => value}
            width={60}
            resizable={false}
            lockPosition
            cellClass={cellClass}
            pinned={'left'}
            rowDrag={rowDragManaged}
          />
        )}
        {columnDefs.map((item, index) => {
          return (
            <AgGridColumn
              key={index.toString()}
              cellClass={cellClass}
              valueGetter={(params) => {
                const field = params.data[item.field];

                return typeof field === 'number'
                  ? (field as number).toLocaleString()
                  : field;
              }}
              onCellClicked={(e) => {
                onRowClick?.(e);
              }}
              {...item}
            />
          );
        })}
      </AgGridReact>
    </TableContainer>
  );
});

export default Table;
