import React, { useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';

import { FormLayout } from '@components/utils/layout-utils';
import ColorPicker from '@components/v2/antd/data-entry/ColorPicker';
import { Checkbox, Form, Slider, Switch, Typography } from 'antd';
import { StoreType } from 'polotno/model/store';
import Button from '@components/v2/antd/general/Button';
import {
  getRandomColorFromHSB,
  getRandomSameToneColorsFromHSB,
  overrideTheme,
} from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-theme.utils';
import { BgColorsOutlined } from '@ant-design/icons';
import {
  ColorInfo,
  ColorTheme,
} from '../../../../../../../@types/ai-design-template/models/ColorTheme';

export function ColorThemeEditorTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="색 설정" {...props}>
      <BgColorsOutlined
        style={{
          fontSize: 20,
        }}
      />
    </SectionTab>
  );
}
ColorThemeEditorTab.displayName = '';

export interface ThemeEditorPanelProps {
  store: StoreType;
  onColorThemeChange?: (theme: ColorTheme) => void;
  initialColorTheme?: ColorTheme;
}

const DEFAULT_LIGHT_COLOR_THEME: ColorInfo = {
  hex: '#FFFFFF',
  hRange: [0, 360],
  sRange: [0, 100],
  bRange: [0, 100],
};

const DEFAULT_DARK_COLOR_THEME: ColorInfo = {
  hex: '#000000',
  hRange: [0, 360],
  sRange: [0, 100],
  bRange: [0, 100],
};
export const ThemeEditorPanel = (props: ThemeEditorPanelProps) => {
  const {
    store,
    initialColorTheme = {
      sameColorTone: true,
      enabled: false,
      primaryDark: DEFAULT_DARK_COLOR_THEME,
      primaryLight: DEFAULT_LIGHT_COLOR_THEME,
    },
    onColorThemeChange,
  } = props;

  const [colorTheme, setColorTheme] = useState<ColorTheme>(initialColorTheme);

  const isDisabled = () => {
    if (colorTheme) {
      return !colorTheme.enabled;
    }
    return false;
  };

  const updateSameToneColorCheckState = (checked: boolean) => {
    const updatedTheme = {
      ...colorTheme,
      sameColorTone: checked,
    };
    setColorTheme(updatedTheme);
    if (onColorThemeChange) {
      onColorThemeChange(updatedTheme);
    }
  };

  const updateCheckState = (checked: boolean) => {
    const updatedTheme = {
      ...colorTheme,
      enabled: checked,
    };
    setColorTheme(updatedTheme);
    if (onColorThemeChange) {
      onColorThemeChange(updatedTheme);
    }
  };

  const updatePrimaryLightHex = (
    hex: string,
    hRange: number[],
    sRange: number[],
    bRange: number[],
  ) => {
    if (onColorThemeChange) {
      const updatedTheme = {
        ...colorTheme,
        primaryLight: {
          hex,
          hRange,
          sRange,
          bRange,
        },
      };
      onColorThemeChange(updatedTheme);
      setColorTheme(updatedTheme);
    }
  };

  const updatePrimaryDarkHex = (
    hex: string,
    hRange: number[],
    sRange: number[],
    bRange: number[],
  ) => {
    if (onColorThemeChange) {
      const updatedTheme = {
        ...colorTheme,
        primaryDark: {
          hex,
          hRange,
          sRange,
          bRange,
        },
      };
      onColorThemeChange(updatedTheme);
      setColorTheme(updatedTheme);
    }
  };

  const updatePrimaryLightRange = (range: number[], key: keyof ColorInfo) => {
    const { primaryLight } = colorTheme;
    if (onColorThemeChange) {
      const updatedTheme = {
        ...colorTheme,
        primaryLight: {
          ...primaryLight,
          [key]: range,
        },
      };
      onColorThemeChange(updatedTheme);
      setColorTheme(updatedTheme);
    }
  };

  const updatePrimaryDarkRange = (range: number[], key: keyof ColorInfo) => {
    const { primaryDark } = colorTheme;
    if (onColorThemeChange) {
      const updatedTheme = {
        ...colorTheme,
        primaryDark: {
          ...primaryDark,
          [key]: range,
        },
      };
      onColorThemeChange(updatedTheme);
      setColorTheme(updatedTheme);
    }
  };

  return (
    <div style={{ height: '100%', padding: 20 }}>
      <FormLayout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
          }}
        >
          <div
            style={{
              paddingLeft: 4,
            }}
          />

          <div>
            <div
              style={{
                marginBottom: 24,
              }}
            >
              <Typography.Title level={5}>테마 활성화</Typography.Title>
              <Switch
                checked={colorTheme.enabled}
                onChange={(checked) => {
                  updateCheckState(checked);
                }}
              />
            </div>
            <div
              style={{
                marginBottom: 24,
              }}
            >
              <Typography.Title level={5}>색상 반전 처리</Typography.Title>
              <Button
                disabled={!colorTheme.enabled}
                onClick={() => {
                  const json: any = store.toJSON();
                  const invertJson = overrideTheme(json, {
                    invertDarkAndLightColor: true,
                  });
                  const newJson = overrideTheme(invertJson, {
                    colorInfo: {
                      primaryLight: colorTheme.primaryLight.hex,
                      primaryDark: colorTheme.primaryDark.hex,
                    },
                  });
                  store.loadJSON(newJson, true);
                }}
              >
                적용
              </Button>
            </div>

            <Typography.Title level={5}>컬러 범위 설정</Typography.Title>
            <Form.Item required label="Primary Light" labelCol={{ span: 24 }}>
              <ColorPicker
                disabled={isDisabled()}
                value={colorTheme.primaryLight.hex}
                format="hsb"
                showText
                onChangeComplete={(color) => {
                  const { s, b, h } = color.toHsb();
                  const hRange = [h, Math.min(h + 30, 360)];
                  const sRange = [s * 100, Math.min(s * 100 + 10, 100)];
                  const bRange = [b * 100, Math.min(b * 100 + 10, 100)];
                  updatePrimaryLightHex(
                    color.toHexString(),
                    hRange,
                    sRange,
                    bRange,
                  );
                }}
              />
            </Form.Item>
            <Form.Item label="H 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme.primaryLight.hRange}
                range
                min={0}
                max={360}
                marks={{
                  0: '0°',
                  360: '360°',
                }}
                onChange={(value) => {
                  updatePrimaryLightRange(value, 'hRange');
                }}
              />
            </Form.Item>
            <Form.Item label="S 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme.primaryLight?.sRange}
                range
                min={0}
                max={100}
                marks={{
                  0: '0%',
                  100: '100%',
                }}
                onChange={(value) => {
                  updatePrimaryLightRange(value, 'sRange');
                }}
              />
            </Form.Item>
            <Form.Item label="B 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme.primaryLight?.bRange}
                range
                min={0}
                max={100}
                marks={{
                  0: '0%',
                  100: '100%',
                }}
                onChange={(value) => {
                  updatePrimaryLightRange(value, 'bRange');
                }}
              />
            </Form.Item>
            <Form.Item required label="Primary Dark" labelCol={{ span: 24 }}>
              <ColorPicker
                disabled={isDisabled()}
                value={colorTheme?.primaryDark.hex}
                format="hsb"
                showText
                onChangeComplete={(color) => {
                  const { h, s, b } = color.toHsb();
                  const hRange = [h, Math.min(h + 30, 360)];
                  const sRange = [s * 100, Math.min(s * 100 + 10, 100)];
                  const bRange = [b * 100, Math.min(b * 100 + 10, 100)];
                  updatePrimaryDarkHex(
                    color.toHexString(),
                    hRange,
                    sRange,
                    bRange,
                  );
                }}
              />
            </Form.Item>
            <Form.Item label="H 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme?.primaryDark.hRange}
                range
                min={0}
                max={360}
                marks={{
                  0: '0°',
                  360: '360°',
                }}
                onChange={(value) => {
                  updatePrimaryDarkRange(value, 'hRange');
                }}
              />
            </Form.Item>
            <Form.Item label="S 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme?.primaryDark.sRange}
                range
                min={0}
                max={100}
                marks={{
                  0: '0%',
                  100: '100%',
                }}
                onChange={(value) => {
                  updatePrimaryDarkRange(value, 'sRange');
                }}
              />
            </Form.Item>
            <Form.Item label="B 범위">
              <Slider
                disabled={isDisabled()}
                value={colorTheme?.primaryDark.bRange}
                range
                min={0}
                max={100}
                marks={{
                  0: '0%',
                  100: '100%',
                }}
                onChange={(value) => {
                  updatePrimaryDarkRange(value, 'bRange');
                }}
              />
            </Form.Item>
          </div>
        </div>
      </FormLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: 8,
          alignItems: 'center',
        }}
      >
        <Button
          disabled={isDisabled()}
          onClick={() => {
            const { sameColorTone } = colorTheme;
            let primaryLightHex = '';
            let primaryDarkHex = '';

            if (sameColorTone) {
              const { lightColor, darkColor } = getRandomSameToneColorsFromHSB(
                colorTheme.primaryLight,
                colorTheme.primaryDark,
              );
              primaryLightHex = lightColor;
              primaryDarkHex = darkColor;
            } else {
              primaryLightHex = getRandomColorFromHSB(colorTheme.primaryLight);
              primaryDarkHex = getRandomColorFromHSB(colorTheme.primaryDark);
            }

            setColorTheme({
              ...colorTheme,
              primaryLight: {
                ...colorTheme.primaryLight,
                hex: primaryLightHex,
              },
              primaryDark: {
                ...colorTheme.primaryDark,
                hex: primaryDarkHex,
              },
            });

            const json: any = store.toJSON();
            const newJson = overrideTheme(json, {
              colorInfo: {
                primaryLight: primaryLightHex,
                primaryDark: primaryDarkHex,
              },
            });
            store.loadJSON(newJson, true);
          }}
        >
          적용 테스트
        </Button>
        <Checkbox
          checked={colorTheme.sameColorTone}
          onChange={(e) => {
            updateSameToneColorCheckState(e.target.checked);
          }}
        >
          동일 컬러톤 적용
        </Checkbox>
      </div>
    </div>
  );
};
