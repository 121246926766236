import { TagSelect, Typo } from '@components/atoms';
import { FontSizeType } from '@components/atoms/Typo/types';
import { ColorType } from '@styles/colors';
import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

interface TagSelectLabelProps {
  placeholder?: ReactNode;
  options?: any[];
  onSelect?: (e: any) => void;
  onChange?: (values: any[]) => void;
  value?: any[];
  gap?: number;
  labelAutoWidth?: boolean;
  label: ReactNode;
  labelColor?: ColorType;
  typoType?: FontSizeType;
}

function TagSelectLabel({
  placeholder,
  options,
  onSelect,
  onChange,
  value,
  gap = 8,
  label,
  labelColor = 'gray6',
  typoType = 'b11',
  labelAutoWidth = false,
}: TagSelectLabelProps) {
  // const [inputText, setInputText] = useState<string>('');
  return (
    <ProductInfoInputWrapper gap={gap}>
      <TypoBox labelAutoWidth={labelAutoWidth}>
        <Typo typoType={typoType} color={labelColor}>
          {label}
        </Typo>
      </TypoBox>
      <InputBox>
        <TagSelect
          // style={{}}
          placeholder={placeholder}
          options={options}
          mode="tags"
          onSelect={onSelect}
          // onSearch={(e) => setInputText(e)}
          onChange={onChange}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter' && onChange && value) {
          //     onChange([...value, inputText]);
          //   }
          // }}
          value={value}
          notFoundContent={
            <NotFoundContentWrapper>
              태그명을 입력 후 엔터를 치시면 태그가 입력됩니다.
            </NotFoundContentWrapper>
          }
        />
      </InputBox>
    </ProductInfoInputWrapper>
  );
}

export default TagSelectLabel;

const ProductInfoInputWrapper = styled.div<{ gap: number }>`
  display: flex;
  align-items: flex-start;
  gap: ${({ gap }) => `${gap}`}px;
  width: 100%;
`;

const TypoBox = styled.div<{ labelAutoWidth: boolean }>`
  display: flex;
  width: 100%;
  max-width: 120px;
  align-items: center;

  ${({ labelAutoWidth }) =>
    labelAutoWidth && `max-width: 100%; white-space: nowrap; width: auto;`}
  height: 40px;
`;

const InputBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 520px;
  min-height: 40px;
  & input {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const NotFoundContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;
