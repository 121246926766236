import { css } from 'styled-components';
import colorSet from './colors';

const editorCustom = css`
  .ck-editor__main {
    .ck-content {
      padding: 10px 16px;
      > p {
        padding: 0;
        margin: 0 !important;
        &::before {
          margin: 0;
        }
      }
    }
  }

  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: 1px solid ${colorSet.primary3} !important;
    box-shadow: 0 0 0 2px ${colorSet.primary8} !important;
  }
`;

export default editorCustom;
