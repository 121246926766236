/* eslint-disable no-param-reassign */
import {
  PolotnoElement,
  PolotnoPageJSON,
} from '@pages/private/ai-design/ai-design-templates-v2/types/PolotnoPageJSON';
import {
  FontCategory,
  ImageCategory,
  ThemeElementValue,
} from '@pages/private/ai-design/ai-design-templates-v2/constants/ai-design-template.constants';
// @ts-ignore
import Color from 'color';
import { PromptResult } from '@pages/private/ai-design/ai-design-templates-v2/types/PromptResult';
import { ColorInfo } from '../../../../../@types/ai-design-template/models/ColorTheme';

export const getRandomColorFromHSB = (colorInfo: ColorInfo) => {
  const { hRange = [0, 360], sRange = [0, 100], bRange = [0, 100] } = colorInfo;

  const [minH, maxH] = hRange;
  const randomH = getRandomInt(minH, maxH);

  const [minS, maxS] = sRange;
  const randomS = getRandomInt(minS, maxS);

  const [minB, maxB] = bRange;
  const randomB = getRandomInt(minB, maxB);
  const hslColor = Color.hsv(randomH, randomS, randomB);
  return hslColor.hex();
};

export const getRandomSameToneColorsFromHSB = (
  lightColorInfo: ColorInfo,
  darkColorInfo: ColorInfo,
) => {
  const {
    hRange: baseHRange = [0, 360],
    sRange: lightSRange = [0, 100],
    bRange: lightBRange = [0, 100],
  } = lightColorInfo;

  const { sRange: darkSRange = [0, 100], bRange: darkBRange = [0, 100] } =
    darkColorInfo;

  const [minH, maxH] = baseHRange;
  const baseH = getRandomInt(minH, maxH);
  // Light Color
  const [minLightS, maxLightS] = lightSRange;
  const randomLightS = getRandomInt(minLightS, maxLightS);
  const [minLightB, maxLightB] = lightBRange;
  const randomLightB = getRandomInt(minLightB, maxLightB);

  // Dark Color
  const [minDarkS, maxDarkS] = darkSRange;
  const randomDarkS = getRandomInt(minDarkS, maxDarkS);
  const [minDarkB, maxDarkB] = darkBRange;
  const randomDarkB = getRandomInt(minDarkB, maxDarkB);

  const lightColorHsv = Color.hsv(baseH, randomLightS, randomLightB);
  const darkColorHsv = Color.hsv(baseH, randomDarkS, randomDarkB);
  return {
    lightColor: lightColorHsv.hex(),
    darkColor: darkColorHsv.hex(),
  };
};

export const createGradientFill = (
  hexColor: string,
  direction: 'bottom' | 'top' | 'left' | 'right',
) => {
  const fromColor = Color(hexColor).alpha(0);
  const toColor = Color(hexColor).alpha(1);

  let deg: string;
  switch (direction) {
    case 'top':
      deg = '0deg';
      break;
    case 'right':
      deg = '90deg';
      break;
    case 'bottom':
      deg = '180deg';
      break;
    case 'left':
      deg = '270deg';
      break;
    default:
      deg = '0deg';
      break;
  }

  return `linear-gradient(${deg}, ${fromColor} 45.09090539180871%,${toColor} 60.24242054332386%)`;
};

export const overrideTheme = (
  json: PolotnoPageJSON,
  options: {
    colorInfo?: { primaryLight: string; primaryDark: string };
    mainFontFamily?: string;
    subFontFamily?: string;
    randomImageUrl?: string;
    randomBackgroundImageUrl?: string;
    logoUrl?: string;
    invertDarkAndLightColor?: boolean;
  },
) => {
  // Unfreeze
  const newJson = JSON.parse(JSON.stringify(json)) as PolotnoPageJSON;
  newJson.pages.forEach(({ children }) => {
    if (children) {
      setThemeField(children, options);
    }
  });
  return newJson;
};

const setThemeField = (
  elements: PolotnoElement[],
  options: {
    colorInfo?: { primaryLight: string; primaryDark: string };
    mainFontFamily?: string;
    subFontFamily?: string;
    randomImageUrl?: string;
    randomBackgroundImageUrl?: string;
    logoUrl?: string;
    invertDarkAndLightColor?: boolean;
  },
) => {
  elements.forEach((element) => {
    const { type, custom, children = [] } = element;

    const {
      colorInfo,
      mainFontFamily,
      subFontFamily,
      randomImageUrl,
      randomBackgroundImageUrl,
      logoUrl,
      invertDarkAndLightColor,
    } = options;

    let themeValue: string = '';
    let fontCategoryValue: string = '';
    let imageCategoryValue: string = '';
    if (custom) {
      const { themeKey, fontCategory, imageCategory } = custom;
      themeValue = themeKey;
      fontCategoryValue = fontCategory;
      imageCategoryValue = imageCategory;

      /**
       * 색상 반전 처리
       */
      if (invertDarkAndLightColor) {
        if (type === 'text' || type === 'figure') {
          let updatedThemeValue = themeValue;

          if (themeValue) {
            if (themeValue.includes('mainColor1')) {
              updatedThemeValue = themeValue.replace(
                'mainColor1',
                'mainColor2',
              );
            } else if (themeValue.includes('mainColor2')) {
              updatedThemeValue = themeValue.replace(
                'mainColor2',
                'mainColor1',
              );
            }
          }

          if (updatedThemeValue) {
            element.custom = {
              ...custom,
              themeKey: updatedThemeValue,
            };
          }
        } else if (type === 'group') {
          setThemeField(children, options);
        }
      }

      /**
       * 이미지 랜덤 적용
       */
      // 메인 모델 또는 장비
      if (imageCategoryValue && randomImageUrl) {
        if (type === 'image') {
          if (
            imageCategoryValue === ImageCategory.Model ||
            imageCategoryValue === ImageCategory.Equipment
          ) {
            element.src = randomImageUrl;
          }
        }
      }
      // 배경
      if (imageCategoryValue && randomBackgroundImageUrl) {
        if (type === 'image') {
          if (imageCategoryValue === ImageCategory.Background) {
            element.src = randomBackgroundImageUrl;
          }
        }
      }
      // 로고
      if (imageCategoryValue && logoUrl) {
        if (type === 'image') {
          if (imageCategoryValue === ImageCategory.Logo) {
            element.src = logoUrl;
          }
        }
      }

      if (themeValue && colorInfo) {
        /**
         * 컬러 랜덤 적용
         */
        const { primaryLight, primaryDark } = colorInfo;

        if (type === 'text' || type === 'figure') {
          if (themeValue === ThemeElementValue.MainColor1) {
            element.fill = primaryLight;
          }
          if (themeValue === ThemeElementValue.MainColor2) {
            element.fill = primaryDark;
          }
          if (themeValue === ThemeElementValue.MainColor1GradientToTop) {
            element.fill = createGradientFill(primaryLight, 'top');
          }
          if (themeValue === ThemeElementValue.MainColor2GradientToTop) {
            element.fill = createGradientFill(primaryDark, 'top');
          }
          if (themeValue === ThemeElementValue.MainColor1GradientToBottom) {
            element.fill = createGradientFill(primaryLight, 'bottom');
          }
          if (themeValue === ThemeElementValue.MainColor2GradientToBottom) {
            element.fill = createGradientFill(primaryDark, 'bottom');
          }
          if (themeValue === ThemeElementValue.MainColor1GradientToLeft) {
            element.fill = createGradientFill(primaryLight, 'left');
          }
          if (themeValue === ThemeElementValue.MainColor2GradientToLeft) {
            element.fill = createGradientFill(primaryDark, 'left');
          }
          if (themeValue === ThemeElementValue.MainColor1GradientToRight) {
            element.fill = createGradientFill(primaryLight, 'right');
          }
          if (themeValue === ThemeElementValue.MainColor2GradientToRight) {
            element.fill = createGradientFill(primaryDark, 'right');
          }
        }

        if (type === 'text') {
          if (themeValue === ThemeElementValue.MainColor1) {
            element.fill = primaryLight;
            element.backgroundEnabled = false;
          }
          if (themeValue === ThemeElementValue.MainColor2) {
            element.fill = primaryDark;
            element.backgroundEnabled = false;
          }
          if (themeValue === ThemeElementValue.MainColor1TextWithBg) {
            element.fill = primaryLight;
            element.backgroundColor = primaryDark;
            element.backgroundEnabled = true;
          }
          if (themeValue === ThemeElementValue.MainColor2TextWithBg) {
            element.fill = primaryDark;
            element.backgroundColor = primaryLight;
            element.backgroundEnabled = true;
          }
        } else if (type === 'group') {
          setThemeField(children, options);
        }
      }

      /**
       * Text Font 랜덤 적용
       */
      if (fontCategoryValue && mainFontFamily && subFontFamily) {
        if (type === 'text') {
          if (fontCategoryValue === FontCategory.Basic) {
            element.fontFamily = subFontFamily;
          }

          if (fontCategoryValue === FontCategory.Brand) {
            element.fontFamily = mainFontFamily;
          }
        } else if (type === 'group') {
          setThemeField(children, options);
        }
      }
    }
  });
};

export const mapBlogAIText = (
  json: PolotnoPageJSON,
  blogImageTextInfo: PromptResult[],
) => {
  const jsonString = JSON.stringify(json);
  const updatedJson = JSON.parse(jsonString) as PolotnoPageJSON;
  const { pages = [] } = updatedJson;

  pages.forEach((page) => {
    const { custom, children = [] } = page;
    if (custom) {
      const { subject } = custom;
      if (subject) {
        const matchedPageTextInfo = blogImageTextInfo.find((info) => {
          return info.subject === subject;
        });
        if (matchedPageTextInfo) {
          setAIField(children, matchedPageTextInfo);
        }
      }
    }
  });

  return updatedJson;
};

const setAIField = (elements: PolotnoElement[], textInfo: PromptResult) => {
  elements.forEach((element) => {
    const { type, custom, children = [] } = element;
    if (type === 'text' && custom) {
      const { blogKey } = custom;
      if (blogKey) {
        // @ts-ignore
        const value = textInfo[blogKey];
        if (value) {
          // eslint-disable-next-line no-param-reassign
          element.text = value;
        }
      }
    }
    if (type === 'group') {
      setAIField(children, textInfo);
    }
  });
};

export const getRandomFromStringArray = (strings: string[]) => {
  const randomIndex = getRandomInt(0, strings.length);
  return strings[randomIndex];
};

export const getRandomInt = (min: number, max: number) => {
  const minNumber = Math.ceil(min);
  const maxNumber = Math.floor(max);
  return Math.floor(Math.random() * (maxNumber - minNumber)) + minNumber; //최댓값은 제외, 최솟값은 포함
};

export const invertLightAndDarkColor = () => {};
