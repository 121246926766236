import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import { FormLayout } from '@components/utils/layout-utils';
import FormItem from '@components/molecules/FormItem';
import { useToastStore } from '@store/useToastStore';
import InputNumber from '@components/v2/antd/data-entry/InputNumber';
import Button from '@components/atoms/Button';
import AIModelBaseImageSelectionModal from '@pages/private/ai-design/ai-model-images/components/AIModelBaseImageSelectionModal';
import { Image, Spin } from 'antd';
import colorSet from '@styles/colors';
import { generateAIModelImages } from '../../../../../@apis/ai-models';

export interface AIModelImageGenerationModalProps {
  onCancel?: () => void;
  onDataChange?: () => void;
}
function AIModelImageGenerationModal(props: AIModelImageGenerationModalProps) {
  const { onCancel, onDataChange } = props;
  const [baseImageSelectionModalVisible, setBaseImageSelectionModalVisible] =
    useState(false);
  const [imageUrl, setBaseImageUrl] = useState<string>('');
  const [requestCount, setRequestCount] = useState<number>(1);
  const { showToast } = useToastStore();
  const [uploading, setUploading] = useState(false);

  const requestGenerateAIModelImages = async () => {
    try {
      setUploading(true);
      await generateAIModelImages(imageUrl, requestCount);
      showToast({
        description: '이미지 생성이 완료되었습니다.',
        status: 'Primary',
      });
      if (onDataChange) onDataChange();
      if (onCancel) onCancel();
    } catch (e) {
      console.error(e);
    } finally {
      setUploading(false);
    }
  };

  const canUpload = () => {
    return imageUrl && requestCount >= 1 && !uploading;
  };

  const handleModalClose = () => {
    if (onCancel) onCancel();
  };

  const handleModalConfirm = async () => {
    await requestGenerateAIModelImages();
  };
  const handleBaseImageSelectionModalVisible = () => {
    setBaseImageSelectionModalVisible(true);
  };

  return (
    <>
      <Modal
        size={560}
        title="AI 모델 이미지 생성"
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onConfirm={handleModalConfirm}
        confirmLabel="이미지 생성하기"
        cancelLabel={!uploading ? '닫기' : undefined}
        disabled={!canUpload()}
      >
        <Spin spinning={uploading} tip="이미지를 생성중 입니다">
          <FormLayout>
            <FormItem label={'기준 이미지 선택'}>
              <div>
                <Button onClick={handleBaseImageSelectionModalVisible}>
                  이미지 선택
                </Button>
                {imageUrl && (
                  <div
                    style={{
                      border: `1px solid ${colorSet.gray11}`,
                      marginTop: '16px',
                    }}
                  >
                    <Image width={200} src={imageUrl} />
                  </div>
                )}
              </div>
            </FormItem>
            <FormItem label={'이미지 생성 수'}>
              <InputNumber
                min={1}
                max={10}
                value={requestCount}
                placeholder="이미지 생성수 입력"
                onChange={(value) => {
                  setRequestCount(value as number);
                }}
                style={{
                  width: '100%',
                }}
              />
            </FormItem>
          </FormLayout>
        </Spin>
      </Modal>
      {baseImageSelectionModalVisible && (
        <AIModelBaseImageSelectionModal
          onImageSelect={(imageUrl) => {
            setBaseImageUrl(imageUrl);
          }}
          onCancel={() => {
            setBaseImageSelectionModalVisible(false);
          }}
        />
      )}
    </>
  );
}

export default AIModelImageGenerationModal;
