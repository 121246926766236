/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import Select from '@components/v2/antd/data-entry/Select';
import Input from '@components/v2/antd/data-entry/Input';
import { MenuForm } from '@pages/private/settings/menus/types/MenuForm';
import { deleteBlogReference } from '../../../../@apis/blog-references';
import {
  createMenu,
  fetchMenuDetail,
  fetchMenuGroups,
  updateMenu,
} from '../../../../@apis/menus';
import { CreateMenuBody } from '../../../../@types/menu/params/CreateMenuBody';
import { UpdateMenuBody } from '../../../../@types/menu/params/UpdateMenuBody';
import { MENU_USER_GROUP_OPTIONS } from '../../../../@types/menu/constants/menu.constants';

const INITIAL_VALUES: MenuForm = {
  memberGroups: [],
  menuName: '',
  menuCode: '',
  homepageMenuGroupId: undefined,
};

function MenuDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [menuGroupOptions, setMenuGroupOptions] = useState<
    { label: string; value: number }[]
  >([]);

  const formValidationSchema = yup.object({
    menuCode: yup.string().required(),
    menuName: yup.string().required(),
    menuGroups: yup.array().min(1),
    homepageMenuGroupId: yup.number().optional(),
  });

  const formik = useFormik<MenuForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchMenuDetail(id);
      const { menuName, menuCode, memberGroups, homepageMenuGroup } = row;

      let homepageMenuGroupId: number | undefined;
      if (homepageMenuGroup) {
        const { id } = homepageMenuGroup;
        homepageMenuGroupId = id;
      }

      setFormInitialValues({
        menuName,
        menuCode,
        memberGroups,
        homepageMenuGroupId,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchMenuGroupOptions = useCallback(async () => {
    try {
      const { rows } = await fetchMenuGroups();
      setMenuGroupOptions(
        rows.map(({ groupName, id }) => ({
          label: groupName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchMenuGroupOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const addData = async (body: CreateMenuBody) => {
    try {
      await createMenu(body);
      showToast({
        description: '메뉴 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (id: number | string, body: UpdateMenuBody) => {
    try {
      await updateMenu(id, body);
      showToast({
        description: '메뉴 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteData = async (id: number | string) => {
    try {
      await deleteBlogReference(id);
      showToast({
        description: '메뉴 정보가 삭제되었습니다.',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '메뉴 정보 등록';
    let alertMessage = '작성하신 내용대로 메뉴 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '메뉴 정보 수정';
      alertMessage = '작성하신 내용대로 메뉴 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof MenuForm;
    placeholder?: string;
    disabled?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      disabled = false,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Input
          disabled={disabled}
          id={key}
          name={key}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          className="input-container"
          placeholder={placeholderText}
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof MenuForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
    disabled?: boolean;
  }) => {
    const {
      mode,
      labelName,
      required = false,
      key,
      placeholder,
      options,
      disabled,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          disabled={disabled}
          mode={mode}
          showSearch
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        {/*{id && (*/}
        {/*  <Button*/}
        {/*    buttonColor="red"*/}
        {/*    onClick={() => {*/}
        {/*      showAlert({*/}
        {/*        size: 360,*/}
        {/*        title: '레퍼런스 삭제',*/}
        {/*        message:*/}
        {/*          '삭제하신 정보는 복구가 불가능해요.\n정말 삭제하시겠어요?',*/}
        {/*        actions: [*/}
        {/*          { label: '취소' },*/}
        {/*          {*/}
        {/*            color: 'red',*/}
        {/*            label: '삭제',*/}
        {/*            onClick: async () => {*/}
        {/*              await deleteData(id);*/}
        {/*            },*/}
        {/*          },*/}
        {/*        ],*/}
        {/*      });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    삭제*/}
        {/*  </Button>*/}
        {/*)}*/}
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'기본정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '메뉴 그룹',
                required: true,
                key: 'homepageMenuGroupId',
                options: menuGroupOptions,
              })}
              {renderInputForm({
                labelName: '메뉴명',
                required: true,
                key: 'menuName',
              })}
              {renderInputForm({
                labelName: '메뉴코드',
                required: true,
                key: 'menuCode',
                disabled: !!id,
              })}
              {renderSelectForm({
                labelName: '메뉴 사용자 그룹',
                required: true,
                key: 'memberGroups',
                options: MENU_USER_GROUP_OPTIONS,
                mode: 'multiple',
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default MenuDetailPage;
