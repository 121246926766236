import { Typo } from '@components/atoms';
import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { getCalculatorOne } from 'utils/calculator';
import { getNumberComma } from 'utils/regex';

interface CalculatorLabelProps {
  price: number;
  discount?: number;
  isPercent?: boolean;
  style?: CSSProperties;
}

function CalculatorLabel({
  price,
  discount = price,
  isPercent = true,
  style,
}: CalculatorLabelProps) {
  const percent = getCalculatorOne(price, discount);
  const renderDiscount = (discnt: number) => {
    if (discnt === 0) {
      return (
        <Wrapper style={{ ...style }}>
          <Typo isLineThrough typoType="b12" color="gray7">
            {getNumberComma(price)}
          </Typo>

          <CalculatorPriceWrapper>
            {isPercent && (
              <Typo typoType="b9" color="primary3">
                {getNumberComma(percent)}%
              </Typo>
            )}

            <Typo typoType="b9">{getNumberComma(discount)}</Typo>
          </CalculatorPriceWrapper>
        </Wrapper>
      );
    }
    if (!discnt) {
      return (
        <CalculatorPriceWrapper>
          <Typo typoType="b9">{getNumberComma(price)}</Typo>
        </CalculatorPriceWrapper>
      );
    }
    return (
      <Wrapper>
        <Typo isLineThrough typoType="b12" color="gray7">
          {getNumberComma(price)}
        </Typo>

        <CalculatorPriceWrapper>
          {isPercent && (
            <Typo typoType="b9" color="primary3">
              {getNumberComma(percent)}%
            </Typo>
          )}

          <Typo typoType="b9">{getNumberComma(discount)}</Typo>
        </CalculatorPriceWrapper>
      </Wrapper>
    );
  };

  return (
    <CalculatorWrapper>
      {price !== discount ? (
        renderDiscount(discount)
      ) : (
        <CalculatorPriceWrapper>
          <Typo typoType="b9">{getNumberComma(price)}</Typo>
        </CalculatorPriceWrapper>
      )}
    </CalculatorWrapper>
  );
}

export default CalculatorLabel;

const CalculatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CalculatorPriceWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
