import { AppError, request, Row, Rows } from '@networks/index';
import { AIDesignTemplate } from '../@types/ai-design-template/models/AIDesignTemplate';
import { CreateAIDesignTemplateBody } from '../@types/ai-design-template/params/CreateAIDesignTemplateBody';
import { FetchAIDesignTemplateListQuery } from '../@types/ai-design-template/params/FetchAIDesignTemplateListQuery';
import { UpdateAIDesignTemplateBody } from '../@types/ai-design-template/params/UpdateAIDesignTemplateBody';

const API_ROOT = 'admin/v1/ai-design-templates';

export const createAIDesignTemplate = async (
  body: CreateAIDesignTemplateBody,
) => {
  const {
    categories,
    partnerIds,
    templateType,
    templateScene,
    templateThumbnailImage,
    title,
    workStatus,
    aiPromptOutputStructure,
    templateSceneJson,
    colorTheme,
    randomMainFontFamilies,
    randomSubFontFamilies,
    randomImageUrls,
    sizeType,
  } = body;

  const formData = new FormData();
  formData.append('title', title);
  formData.append('templateType', templateType);
  formData.append('sizeType', sizeType);
  formData.append('workStatus', workStatus);
  if (templateScene) {
    formData.append('templateScene', templateScene);
  }
  if (templateThumbnailImage) {
    formData.append('templateThumbnailImage', templateThumbnailImage);
  }

  if (categories) {
    categories.forEach((category) => {
      formData.append('categories[]', category);
    });
  }

  if (partnerIds) {
    partnerIds.forEach((partnerId) => {
      formData.append('partnerIds[]', `${partnerId}`);
    });
  }
  if (randomImageUrls) {
    randomImageUrls.forEach((url) => {
      formData.append('randomImageUrls[]', url);
    });
  }

  if (randomMainFontFamilies) {
    randomMainFontFamilies.forEach((fontFamily) => {
      formData.append('randomMainFontFamilies[]', fontFamily);
    });
  }

  if (randomSubFontFamilies) {
    randomSubFontFamilies.forEach((fontFamily) => {
      formData.append('randomSubFontFamilies[]', fontFamily);
    });
  }

  if (aiPromptOutputStructure) {
    formData.append(
      'aiPromptOutputStructure',
      JSON.stringify(aiPromptOutputStructure),
    );
  }

  if (templateSceneJson) {
    formData.append('templateSceneJson', JSON.stringify(templateSceneJson));
  }

  if (colorTheme) {
    formData.append('colorTheme', JSON.stringify(colorTheme));
  }

  try {
    const { data } = await request<Row<AIDesignTemplate>>({
      method: 'post',
      url: API_ROOT,
      requestBody: formData,
      isMultipart: true,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAIDesignTemplates = async (
  query: FetchAIDesignTemplateListQuery,
) => {
  try {
    const { data } = await request<Rows<AIDesignTemplate>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAIDesignTemplateDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AIDesignTemplate>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateAIDesignTemplate = async (
  id: number | string,
  body: UpdateAIDesignTemplateBody,
) => {
  const {
    categories,
    partnerIds,
    templateType,
    templateScene,
    templateThumbnailImage,
    title,
    workStatus,
    aiPromptOutputStructure,
    templateSceneJson,
    colorTheme,
    randomMainFontFamilies,
    randomSubFontFamilies,
    randomImageUrls,
    sizeType,
  } = body;

  const formData = new FormData();
  if (title) formData.append('title', title);
  if (templateType) formData.append('templateType', templateType);
  if (sizeType) formData.append('sizeType', sizeType);
  if (workStatus) formData.append('workStatus', workStatus);
  if (templateScene) {
    formData.append('templateScene', templateScene);
  }
  if (templateThumbnailImage) {
    formData.append('templateThumbnailImage', templateThumbnailImage);
  }

  if (categories) {
    categories.forEach((category) => {
      formData.append('categories[]', category);
    });
  }

  if (randomImageUrls) {
    randomImageUrls.forEach((url) => {
      formData.append('randomImageUrls[]', url);
    });
  }

  if (randomMainFontFamilies) {
    randomMainFontFamilies.forEach((fontFamily) => {
      formData.append('randomMainFontFamilies[]', fontFamily);
    });
  }

  if (randomSubFontFamilies) {
    randomSubFontFamilies.forEach((fontFamily) => {
      formData.append('randomSubFontFamilies[]', fontFamily);
    });
  }

  if (partnerIds) {
    partnerIds.forEach((partnerId) => {
      formData.append('partnerIds[]', `${partnerId}`);
    });
  }

  if (aiPromptOutputStructure) {
    formData.append(
      'aiPromptOutputStructure',
      JSON.stringify(aiPromptOutputStructure),
    );
  }
  if (templateSceneJson) {
    formData.append('templateSceneJson', JSON.stringify(templateSceneJson));
  }

  if (colorTheme) {
    formData.append('colorTheme', JSON.stringify(colorTheme));
  }

  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<AIDesignTemplate>>({
      method: 'put',
      url,
      requestBody: formData,
      isMultipart: true,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchAIDesignTemplatePrompt = async (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        rows: [
          {
            subject: '메인 썸네일',
            title: '아쿠아필의\n모든 것',
            headline: '깨.끗.한 피부',
          },
          {
            subject: '시술설명',
            title: '아쿠아필이란?',
            headline: '기존 필링의 진화, 수분공급 미세박피술',
            description:
              '체내에서 분해되는 녹는실(PDO) 성분을 이용한 콜라겐 주사는 피부에 직접 주입되어 콜라겐의 지속적인 재생성을 유도합니다. 이는 꺼진 부위의 볼륨을 회복시키고 피부 탄력을 개선하여 노화로 인한 주름을 줄여주는 효과를 가져옵니다.',
          },
          {
            subject: '주의사항',
            title: '아쿠아필의\n눈에 띄는 효과',
            headline: '기존 필링의 진화, 수분공급 미세박피술',
            point1: '피부 각질 제거',
            point2: '피부 톤 개선',
            point3: '블랙헤드 제거',
            point4: '모낭충 제거',
          },
          {
            subject: '추천 대상',
            title: '아쿠아필 추천대상',
            headline: '기존 필링의 진화, 수분공급 미세박피술',
            point1: '만성피로에 시달리는분',
            point2: '갱년기 증상이 걱정되는 분',
            point3: '피부 미용에 관심이 많은 분',
            point4: '간 기능 개선이 필요한 분',
          },
        ],
      });
    }, 1500);
  });
};
