import styled, { css } from 'styled-components';

export const TabTrigger = styled.div`
  width: 100%;
`;
export const TabContent = styled.div`
  width: 100%;
  transition: height 0.3s;
  overflow: hidden;
`;
export const TabContentInner = styled.div`
  width: 100%;
`;
export const TabContainer = styled.div<{ isOpened: boolean; height: number }>`
  width: 100%;

  ${({ isOpened, height }) =>
    isOpened
      ? css`
          ${TabContent} {
            height: ${height}px;
          }
        `
      : css`
          ${TabContent} {
            height: 0px;
          }
        `}
`;
