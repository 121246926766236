import { Radio, RadioSizeType } from '@components/atoms';

import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';

export interface RadioGroupProps {
  items: Array<{
    label: string;
    key: string | number | undefined;
    disabled?: boolean;
  }>;
  value?: string | number | boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: RadioSizeType;
  style?: CSSProperties;
}

/**
 * @param size 20 | 24
 */
function RadioGroup(props: RadioGroupProps) {
  const {
    value: valueProps,
    items,
    onChange: onChangeProps,
    size = 24,
    style,
  } = props;
  const [name] = useState((new Date().getTime() + Math.random()).toString());
  const [value, setValue] = useState(valueProps ?? '');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChangeProps?.(e);
  };

  useEffect(() => {
    if (valueProps === undefined) return;

    setValue(valueProps);
  }, [valueProps]);

  return (
    <RadioGroupContainer style={style}>
      {items.map((item, index) => {
        return (
          <>
            {index > 0 && <Gap key={`gap-${item.key}`} />}
            <Radio
              size={size}
              key={item.key}
              value={item.key}
              label={item.label}
              onChange={onChange}
              defaultChecked={value === item.key}
              checked={value === item.key}
              name={name}
              disabled={item.disabled}
            />
          </>
        );
      })}
    </RadioGroupContainer>
  );
}

const RadioGroupContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  input:disabled {
    background: #dee2e6;
  }
`;

const Gap = styled.div`
  width: 20px;
`;

export default RadioGroup;
