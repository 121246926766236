import React, { ChangeEvent, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@components/atoms';
import Upload from '@components/v2/antd/data-entry/Upload';
import Input from '@components/v2/antd/data-entry/Input';
import Select from '@components/v2/antd/data-entry/Select';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import { useFormik } from 'formik';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useAlertStore } from '@store/useAlertStore';
import { useToastStore } from '@store/useToastStore';
import { Space } from 'antd/lib';
import { DefaultOptionType } from 'antd/es/select';
import { deleteAdsCampaign, getAdsCampaign, updateAdsCampaign } from '@apis';
import { CreateAdsCampaignInput } from '@apis/ads/create-ads-campaign/types';
import { useQuery } from 'react-query';
import { fetchPartners } from '@apis/partners';
import { AppError } from '@networks/index';
import { getPartnersOptions } from './data';

const INITIAL_VALUES: CreateAdsCampaignInput = {
  partnerId: 0,
  title: '',
  thumbnail: '',
  description: '',
  lendingUrl: undefined,
  originUrl: undefined,
  relatedPartnerIds: [],
};

function AdsCampaignDetailPage() {
  const { partnerid, campaignid } = useParams();
  const navigate = useNavigate();
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [partnerOptions, setPartnerOptions] = useState<DefaultOptionType[]>();

  const formValidationSchema = yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
    thumbnail: yup.string().required(),
    relatedPartnerIds: yup.array().min(1),
  });

  const formik = useFormik<CreateAdsCampaignInput>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      const body: CreateAdsCampaignInput = {
        ...values,
        partnerId: Number(partnerid),
      };
      updateData(body);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  useQuery(
    ['getAdsCampaign', partnerid],
    () => getAdsCampaign({ id: Number(campaignid) }),
    {
      onSuccess: async ({ row }) => {
        try {
          const { title, description, thumbnail, relatedPartners, lendingUrl } =
            row;
          setFormInitialValues({
            partnerId: Number(partnerid),
            title,
            description,
            thumbnail,
            lendingUrl,
            relatedPartnerIds: relatedPartners?.map((partner) =>
              Number(partner.id),
            ),
          });
        } catch (e) {
          throw new AppError(e);
        }
      },
      refetchOnWindowFocus: false,
    },
  );
  const { isLoading: getPartnersLoading } = useQuery(
    ['getPartners'],
    () => fetchPartners({ page: 1, pageSize: 1000 }),
    {
      onSuccess: ({ rows: partners }) => {
        const partnerOptions = getPartnersOptions({ partnerid, partners });
        setPartnerOptions(partnerOptions);
      },
      refetchOnWindowFocus: false,
    },
  );
  const updateData = async (requestBody: CreateAdsCampaignInput) => {
    console.log('requestBody', requestBody);
    try {
      const status = await updateAdsCampaign({
        id: Number(campaignid),
        requestBody,
      });
      if (status.toString().slice(0, 1) === '2') {
        showToast({
          description: '파트너 그룹 정보가 수정되었어요',
          status: 'Primary',
        });
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };
  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const handleUpdate = () => {
    showAlert({
      size: 360,
      title: '캠페인 저장',
      message: '수정된 내용을 저장할까요?',
      actions: [
        { label: '취소' },
        {
          label: '저장',
          onClick: async () => formik.handleSubmit(),
        },
      ],
    });
  };
  const handleCancel = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const handleDelete = () => {
    showAlert({
      title: '캠페인 삭제',
      message: '정말 캠페인을 삭제할까요?',
      actions: [
        {
          label: '취소',
        },
        {
          label: '삭제',
          color: 'red',
          onClick: () =>
            deleteAdsCampaign({
              ids: [Number(campaignid)],
            }).then((status) => {
              if (status.toString().slice(0, 1) === '2') {
                showAlert({
                  title: '삭제 완료',
                  message: '캠페인이 성공적으로 삭제되었습니다.',
                  actions: [
                    {
                      label: '확인',
                      onClick: () => navigate(-1),
                    },
                  ],
                });
              } else {
                showAlert({
                  title: '삭제 오류',
                  message: '캠페인이 삭제되지 않았습니다.',
                });
              }
            }),
        },
      ],
    });
  };
  const renderCopyForm = (info: {
    labelName: string;
    disabled?: boolean;
    key: keyof CreateAdsCampaignInput;
  }) => {
    const { labelName, disabled, key } = info;
    const value =
      key === 'originUrl'
        ? `https://docnawa.com/partners/${partnerid}/campaigns/${campaignid}`
        : formik.getFieldProps(key).value;
    return (
      <FormItem label={labelName}>
        <Space.Compact style={{ width: '100%' }}>
          <Input
            value={value}
            className="input-container"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            onChange={(value: ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(key, value.target.value);
            }}
            disabled={disabled}
          />
          <Button
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => {
              copy(value);
              showToast({
                description: '클립보드에 해당내용이 복사되었어요',
                status: 'Primary',
              });
            }}
          >
            주소 복사
          </Button>
        </Space.Compact>
      </FormItem>
    );
  };
  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateAdsCampaignInput;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      labelName,
      required = false,
      disabled,
      key,
      placeholder,
      infoLabel,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
            disabled={disabled}
          />
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };
  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateAdsCampaignInput;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };
  const renderSelect = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateAdsCampaignInput;
    placeholder?: string;
    separateWithSpaceAndComma?: boolean;
    loading?: boolean;
    options?: DefaultOptionType[];
    mode?: 'multiple' | 'tags' | undefined;
    disabled?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      separateWithSpaceAndComma = false,
      loading,
      options,
      mode,
      disabled,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let tokenSeparators: string[] = [];
    if (separateWithSpaceAndComma) {
      tokenSeparators = [',', ' '];
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          options={options}
          mode={mode}
          style={{
            width: '100%',
          }}
          tokenSeparators={tokenSeparators}
          showSearch
          value={formik.values[key] || undefined}
          onChange={(values) => updateForm(key, values)}
          placeholder={placeholderText}
          id={key}
          loading={loading}
          disabled={disabled}
        />
      </FormItem>
    );
  };
  const renderUpload = (info: {
    labelName: string;
    required?: boolean;
    key: keyof CreateAdsCampaignInput;
  }) => {
    const { labelName, required = false, key } = info;
    const url = formik.values[key];
    return url ? (
      <FormItem label={labelName} optional={!required}>
        <Upload
          initialImageUrls={[url] as string[]}
          onImageDataChange={(url: string[]) => {
            formik.setFieldValue(key, url[0]);
          }}
          maxCount={1}
        />
      </FormItem>
    ) : null;
  };
  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Button
          buttonStyle="line"
          buttonColor="gray"
          onClick={handleCancelClick}
        >
          취소
        </Button>
        <Button buttonStyle="solid" buttonColor="red" onClick={handleDelete}>
          삭제
        </Button>

        <Button disabled={!formik.isValid} onClick={handleUpdate}>
          수정
        </Button>
      </RightButtonWrapper>
    );
  };

  return (
    <ContentLayout
      title="캠페인 상세"
      onGoBack={handleCancel}
      headerRight={renderContentRightButton()}
    >
      <form>
        <FormSectionLayout>
          <Card title={'캠페인 상세 정보'}>
            <FormLayout>
              {renderCopyForm({
                labelName: '랜딩페이지 주소',
                disabled: true,
                key: 'originUrl',
              })}
              {renderCopyForm({
                labelName: '도착페이지 주소',
                key: 'lendingUrl',
              })}
              {renderInputForm({
                labelName: '캠페인 제목',
                required: true,
                key: 'title',
              })}
              {renderTextArea({
                labelName: '캠페인 설명',
                required: true,
                key: 'description',
              })}
              {renderSelect({
                labelName: '병원 선택',
                required: true,
                key: 'relatedPartnerIds',
                loading: getPartnersLoading,
                options: partnerOptions,
                mode: 'multiple',
              })}
              {renderUpload({
                labelName: '캠페인 이미지',
                required: true,
                key: 'thumbnail',
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;

export default AdsCampaignDetailPage;
