import styled from 'styled-components';

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;

  > main {
    flex: 1;
    overflow: hidden;

    > div {
      height: 100%;
      overflow: auto;
    }
  }
`;
