import { allSideNavList } from '@routes';
import { isEqual } from 'lodash';

export const getPathNameDepth = (path: string) => {
  const selectedMainNavItem = allSideNavList.find((item) =>
    path.includes(item.key),
  );

  const selectedSubNavItem = selectedMainNavItem?.children?.find((child) =>
    path.includes(child.key),
  );

  const selectedLastPathNavItem = selectedSubNavItem?.children?.find(
    (child) => {
      const formattedPath = path.split('/').slice(1, -1);
      const formattedChild = child.key.split('/').slice(1, -1);
      return isEqual(formattedPath.join('/'), formattedChild.join('/'));
    },
  );

  if (selectedLastPathNavItem) {
    return {
      mainPath: selectedMainNavItem,
      subPath: selectedSubNavItem,
      lastPath: selectedLastPathNavItem,
    };
  }

  return {
    mainPath: selectedMainNavItem,
    subPath: selectedSubNavItem,
  };
};
