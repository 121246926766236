import { CSSProperties } from 'react';
import colorSet from '@styles/colors';
import { css } from 'styled-components';
import { typo } from '@components/atoms';
import { LabelButtonSize, LabelButtonType } from './types';

// Default style
export const getColor = (buttonStyle?: LabelButtonType, selected?: boolean) => {
  const style: CSSProperties = {
    backgroundColor: colorSet.gray12,
    color: colorSet.gray2,
    fill: colorSet.gray2,
    border: `1px solid ${colorSet.gray12}`,
  };
  if (buttonStyle === 'ghost') {
    style.backgroundColor = colorSet.gray13;
    style.color = colorSet.gray2;
    style.fill = colorSet.gray2;
    style.border = `1px solid ${colorSet.gray9}`;
  }
  if (selected) {
    style.backgroundColor = colorSet.primary9;
    style.color = colorSet.primary2;
    style.fill = colorSet.primary2;
    style.border = `1px solid ${colorSet.primary2}`;
  }
  return style;
};

// Disabled style
export const getDisabledColor = () => {
  const style: CSSProperties = {
    backgroundColor: colorSet.gray12,
    color: colorSet.gray8,
    fill: colorSet.gray8,
    border: `1px solid ${colorSet.gray12}`,
  };
  return style;
};

// Text size
export const getTextSize = (size?: LabelButtonSize) => {
  if (size === 'sm') {
    return css`
      ${typo.btn3}
    `;
  }
  return css`
    ${typo.btn2}
  `;
};

// Icon size
export const getIconSize = (size?: LabelButtonSize) => {
  if (size === 'sm') {
    return css`
      width: 18px;
      height: 18px;
    `;
  }
  return css`
    width: 20px;
    height: 20px;
  `;
};

// Padding size
export const getPaddingSize = (size?: LabelButtonSize) => {
  if (size === 'sm') {
    return css`
      padding: 7px 12px;
    `;
  }
  return css`
    padding: 10px 16px;
  `;
};
