import { addGlobalFont } from 'polotno/config';

export type PolotnoFont = {
  fontFamily: string;
  url: string;
};

const FONT_BASE_URL = 'https://d1lwt7q4fvbsnv.cloudfront.net/fonts';
const getFontUrl = (path: string) => {
  return `${FONT_BASE_URL}/${path}`;
};

export const DEFAULT_NORMAL_FONT_FAMILIES: string[] = [
  '카페24 아네모네 에어',
  '전주공예고딕 Regular',
  'KoPub돋움체 Light',
  '평창체 Regular',
  'LINE SEED Regular',
  '에스코어드림 Light',
  '강원교육모두 Light',
  '카페24 서라운드 에어',
  '카페24 원프리티나이트',
  '카페24 심플해',
  'G마켓산스 Medium',
  'Pretendard Light',
];
export const DEFAULT_BRAND_FONT_FAMILIES: string[] = [
  'Pretendard Medium',
  'Pretendard Bold',
  '에스코어드림 Regular',
  '에스코어드림 Medium',
  '에스코어드림 Bold',
  'G마켓산스 Medium',
  'G마켓산스 Bold',
  '강원교육모두 Bold',
  '강원교육튼튼 Regular',
  '여기어때잘난체',
  '여기어때잘난체 고딕',
  '카페24 모야모야',
  '카페24 아네모네',
  '카페24 단정해',
  '카페24 당당해',
  '카페24 서라운드',
  '조선굵은고딕',
  '조선견고딕',
  '평창체Bold',
  '평창평화체Bold',
  '배인주아체',
  '설립체',
  '가나초콜릿',
  '순천체 Bold',
  'KoPub바탕체 Bold',
  'KoPub돋움체 Medium',
  'KoPub돋움체 Bold',
  'LINE SEED Bold',
  '티웨이스카이',
  '티웨이에어',
  '땅스부대찌게체 Light',
  '땅스부대찌게체 Medium',
  '땅스부대찌게체 Bold',
];

export const BASIC_FONTS: PolotnoFont[] = [
  {
    fontFamily: '카페24 아네모네 에어',
    url: getFontUrl('cafe24-ohsquare-air/Cafe24OhsquareAir-v2.0.woff2'),
  },
  {
    fontFamily: '전주공예고딕 Regular',
    url: getFontUrl('jeonju-gongye/JeonjuCraft_Go_Regular.ttf'),
  },
  {
    fontFamily: '전주공예명조 Light',
    url: getFontUrl('jeonju-gongye/JeonjuCraft_Mj_Light.ttf'),
  },
  {
    fontFamily: 'KoPub바탕체 Medium',
    url: getFontUrl('kopub/KoPub_Batang_Pro_Medium.otf'),
  },
  {
    fontFamily: 'KoPub돋움체 Light',
    url: getFontUrl('kopub/KoPub_Dotum_Pro_Light.otf'),
  },
  {
    fontFamily: '평창체 Regular',
    url: getFontUrl('pyeongchang/PyeongChang-Regular.otf'),
  },
  {
    fontFamily: 'LINE SEED Regular',
    url: getFontUrl('line/LINESeedKR-Rg.woff2'),
  },
  {
    fontFamily: '에스코어드림 Light',
    url: getFontUrl('s-core-dream/SCDream3.otf'),
  },
  {
    fontFamily: '강원교육모두 Light',
    url: getFontUrl('kangwon-edu/GangwonEdu_OTFBoldA.woff'),
  },
  {
    fontFamily: '카페24 서라운드 에어',
    url: getFontUrl('cafe24/Cafe24SsurroundAir-v1.1.woff2'),
  },
  {
    fontFamily: '카페24 원프리티나이트',
    url: getFontUrl('cafe24/Cafe24Oneprettynight-v2.0.woff2'),
  },
  {
    fontFamily: '조선일보명조',
    url: getFontUrl('chosun/Chosunilbo_myungjo.woff'),
  },
  {
    fontFamily: '카페24 심플해',
    url: getFontUrl('cafe24/Cafe24Simplehae-v2.0.woff2'),
  },
  {
    fontFamily: 'G마켓산스 Medium',
    url: getFontUrl('gmarket/GmarketSansMedium.otf'),
  },
  {
    fontFamily: 'Pretendard Light',
    url: getFontUrl('pretendard/Pretendard-Light.woff2'),
  },
];

export const CUSTOM_FONTS: PolotnoFont[] = [
  {
    fontFamily: 'Pretendard Thin',
    url: getFontUrl('pretendard/Pretendard-Thin.woff2'),
  },
  {
    fontFamily: 'Pretendard Light',
    url: getFontUrl('pretendard/Pretendard-Light.woff2'),
  },
  {
    fontFamily: 'Pretendard Regular',
    url: getFontUrl('pretendard/Pretendard-Regular.woff2'),
  },
  {
    fontFamily: 'Pretendard Medium',
    url: getFontUrl('pretendard/Pretendard-Medium.woff2'),
  },
  {
    fontFamily: 'Pretendard Bold',
    url: getFontUrl('pretendard/Pretendard-Bold.woff2'),
  },
  {
    fontFamily: '에스코어드림 Thin',
    url: getFontUrl('s-core-dream/SCDream1.otf'),
  },
  {
    fontFamily: '에스코어드림 Light',
    url: getFontUrl('s-core-dream/SCDream3.otf'),
  },
  {
    fontFamily: '에스코어드림 Regular',
    url: getFontUrl('s-core-dream/SCDream5.otf'),
  },
  {
    fontFamily: '에스코어드림 Medium',
    url: getFontUrl('s-core-dream/SCDream7.otf'),
  },
  {
    fontFamily: '에스코어드림 Bold',
    url: getFontUrl('s-core-dream/SCDream9.otf'),
  },
  {
    fontFamily: 'G마켓산스 Thin',
    url: getFontUrl('gmarket/GmarketSansLight.otf'),
  },
  {
    fontFamily: 'G마켓산스 Medium',
    url: getFontUrl('gmarket/GmarketSansMedium.otf'),
  },
  {
    fontFamily: 'G마켓산스 Bold',
    url: getFontUrl('gmarket/GmarketSansBold.otf'),
  },
  {
    fontFamily: '레페리베이스 Regular',
    url: getFontUrl('leferi/LeferiBaseRegular.otf'),
  },
  {
    fontFamily: '레페리베이스 Bold',
    url: getFontUrl('leferi/LeferiBaseBold.otf'),
  },
  {
    fontFamily: '레페리포인트 스페셜 Regular',
    url: getFontUrl('leferi/LeferiPointSpecial.otf'),
  },
  {
    fontFamily: '레페리포인트 스페셜 Italic',
    url: getFontUrl('leferi/LeferiPointSpecialItalic.otf'),
  },
  {
    fontFamily: '강원교육모두 Light',
    url: getFontUrl('kangwon-edu/GangwonEdu_OTFLightA.woff'),
  },
  {
    fontFamily: '강원교육모두 Bold',
    url: getFontUrl('kangwon-edu/GangwonEdu_OTFBoldA.woff'),
  },
  {
    fontFamily: '강원교육현옥샘 Regular',
    url: getFontUrl('kangwon-edu/GangwonEduHyeonokT_OTFMediumA.woff'),
  },
  {
    fontFamily: '강원교육튼튼 Regular',
    url: getFontUrl('kangwon-edu/tntn.otf'),
  },
  {
    fontFamily: '강원교육새음 Regular',
    url: getFontUrl('kangwon-edu/GangwonEduSaeeum_OTFMediumA.woff'),
  },
  {
    fontFamily: '전주공예고딕 Regular',
    url: getFontUrl('jeonju-gongye/JeonjuCraft_Go_Regular.ttf'),
  },
  {
    fontFamily: '전주공예명조 Light',
    url: getFontUrl('jeonju-gongye/JeonjuCraft_Mj_Light.ttf'),
  },
  {
    fontFamily: '전주공예명조 Bold',
    url: getFontUrl('jeonju-gongye/JeonjuCraft_Mj_Bold.ttf'),
  },
  {
    fontFamily: '여기어때잘난체',
    url: getFontUrl('jalnan/Jalnan2.otf'),
  },
  {
    fontFamily: '여기어때잘난체 고딕',
    url: getFontUrl('jalnan-gothic/JalnanGothic.otf'),
  },
  {
    fontFamily: '카페24 아네모네 에어',
    url: getFontUrl('cafe24-ohsquare-air/Cafe24OhsquareAir-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 모야모야',
    url: getFontUrl('cafe24-moyamoya/Cafe24Moyamoya-Regular-v1.0.woff2'),
  },
  {
    fontFamily: '카페24 아네모네',
    url: getFontUrl('cafe24/Cafe24Ohsquare-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 단정해',
    url: getFontUrl('cafe24/Cafe24Danjunghae-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 당당해',
    url: getFontUrl('cafe24/Cafe24Dangdanghae-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 클래식',
    url: getFontUrl('cafe24/Cafe24Classictype-v1.1.woff2'),
  },
  {
    fontFamily: '카페24 심플해',
    url: getFontUrl('cafe24/Cafe24Simplehae-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 서라운드 에어',
    url: getFontUrl('cafe24/Cafe24SsurroundAir-v1.1.woff2'),
  },
  {
    fontFamily: '카페24 서라운드',
    url: getFontUrl('cafe24/Cafe24Ssurround-v2.0.woff2'),
  },
  {
    fontFamily: '카페24 원프리티나이트',
    url: getFontUrl('cafe24/Cafe24Oneprettynight-v2.0.woff2'),
  },
  {
    fontFamily: '조선신명조',
    url: getFontUrl('chosun/ChosunSm.TTF'),
  },
  {
    fontFamily: '조선굵은명조',
    url: getFontUrl('chosun/ChosunKm.TTF'),
  },
  {
    fontFamily: '조선가는고딕',
    url: getFontUrl('chosun/ChosunSg.TTF'),
  },
  {
    fontFamily: '조선굵은고딕',
    url: getFontUrl('chosun/ChosunKg.TTF'),
  },
  {
    fontFamily: '조선견고딕',
    url: getFontUrl('chosun/ChosunBg.TTF'),
  },
  {
    fontFamily: '조선굴림체',
    url: getFontUrl('chosun/ChosunGu.TTF'),
  },
  {
    fontFamily: '조선로고체',
    url: getFontUrl('chosun/ChosunLo.TTF'),
  },
  {
    fontFamily: '조선일보명조',
    url: getFontUrl('chosun/Chosunilbo_myungjo.woff'),
  },
  {
    fontFamily: '평창체Regular',
    url: getFontUrl('pyeongchang/PyeongChang-Regular.otf'),
  },
  {
    fontFamily: '평창체Bold',
    url: getFontUrl('pyeongchang/PyeongChang-Bold.otf'),
  },
  {
    fontFamily: '평창평화체Light',
    url: getFontUrl('pyeongchang/PyeongChangPeace-Light.otf'),
  },
  {
    fontFamily: '평창평화체Bold',
    url: getFontUrl('pyeongchang/PyeongChangPeace-Bold.otf'),
  },
  {
    fontFamily: '배인주아체',
    url: getFontUrl('baemin/BMJUA.otf'),
  },
  {
    fontFamily: '롯데리아 촵땡겨체',
    url: getFontUrl('lotteria/chab.otf'),
  },
  {
    fontFamily: '롯데리아 딱붙어체',
    url: getFontUrl('lotteria/ddag.otf'),
  },
  {
    fontFamily: '창원단감아삭체',
    url: getFontUrl('etc/ChangwonDangamAsac-Bold_0712.otf'),
  },
  {
    fontFamily: '피렌체',
    url: getFontUrl('etc/EF_Firenze.otf'),
  },
  {
    fontFamily: '설립체',
    url: getFontUrl('etc/establish_Retrosans.otf'),
  },
  {
    fontFamily: '가나초콜릿',
    url: getFontUrl('etc/Ghanachocolate.otf'),
  },
  {
    fontFamily: '하나다움체',
    url: getFontUrl('etc/HANAMDAUM.otf'),
  },
  {
    fontFamily: 'Rix이누아리두리',
    url: getFontUrl('etc/RixInooAriDuri_Pro_Regular.otf'),
  },
  {
    fontFamily: '순천체 Regular',
    url: getFontUrl('suncheon/SuncheonR.ttf'),
  },
  {
    fontFamily: '순천체 Bold',
    url: getFontUrl('suncheon/SuncheonB.ttf'),
  },

  {
    fontFamily: 'KoPub바탕체 Light',
    url: getFontUrl('kopub/KoPub_Batang_Pro_Light.otf'),
  },
  {
    fontFamily: 'KoPub바탕체 Medium',
    url: getFontUrl('kopub/KoPub_Batang_Pro_Medium.otf'),
  },
  {
    fontFamily: 'KoPub바탕체 Bold',
    url: getFontUrl('kopub/KoPub_Batang_Pro_Bold.otf'),
  },

  {
    fontFamily: 'KoPub돋움체 Light',
    url: getFontUrl('kopub/KoPub_Dotum_Pro_Light.otf'),
  },
  {
    fontFamily: 'KoPub돋움체 Medium',
    url: getFontUrl('kopub/KoPub_Dotum_Pro_Medium.otf'),
  },
  {
    fontFamily: 'KoPub돋움체 Bold',
    url: getFontUrl('kopub/KoPub_Dotum_Pro_Bold.otf'),
  },

  {
    fontFamily: 'LINE SEED Thin',
    url: getFontUrl('line/LINESeedKR-Th.woff2'),
  },
  {
    fontFamily: 'LINE SEED Regular',
    url: getFontUrl('line/LINESeedKR-Rg.woff2'),
  },
  {
    fontFamily: 'LINE SEED Bold',
    url: getFontUrl('line/LINESeedKR-Bd.woff2'),
  },
  {
    fontFamily: '티웨이스카이',
    url: getFontUrl('tway/tway_sky.ttf'),
  },
  {
    fontFamily: '티웨이에어',
    url: getFontUrl('tway/tway_air.ttf'),
  },
  {
    fontFamily: '땅스부대찌게체 Light',
    url: getFontUrl('ddangs/ddangs_Light.otf'),
  },
  {
    fontFamily: '땅스부대찌게체 Medium',
    url: getFontUrl('ddangs/ddangs_Medium.otf'),
  },
  {
    fontFamily: '땅스부대찌게체 Bold',
    url: getFontUrl('ddangs/ddangs_Bold.otf'),
  },
  {
    fontFamily: '리디바탕',
    url: getFontUrl('etc/RIDIBatang.woff'),
  },
];

export const setupPolotnoEditorFont = () => {
  CUSTOM_FONTS.forEach((fontObject) => {
    addGlobalFont(fontObject);
  });
};
