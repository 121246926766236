import colorSet from '@styles/colors';
import styled from 'styled-components';
import typo from '../Typo/types';

export const NumberRangeInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 40px;
  padding: 9px 12px 9px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;

  &:focus-within,
  &:focus-within:hover {
    border: 1px solid ${colorSet.primary3};
    box-shadow: 0 0 0 2px ${colorSet.primary8};
  }

  > input {
    ${typo.b9};
    flex: 1;
    width: 30%;
    border: none;
    outline: none;
    &::placeholder {
      color: ${colorSet.gray7};
    }
  }
`;
