import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CommonStore } from './types';

const initialData: CommonStore = {
  isSubMenuOpen: null,
  setIsSubMenuOpen: () => {},
};

const useCommonSotre = create<CommonStore>()(
  persist<CommonStore>(
    (set) => ({
      ...initialData,
      setIsSubMenuOpen: (value: boolean) => set({ isSubMenuOpen: value }),
    }),
    {
      name: 'test',
    },
  ),
);

export default useCommonSotre;
