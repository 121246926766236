import { SectionTab } from 'polotno/side-panel';
import React, { useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, DatePicker, DatePickerProps, Form, Typography } from 'antd';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { StoreType } from 'polotno/model/store';
import { createCalendar } from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-calendar.utils';
import PolotnoFontSelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/common/PolotnoFontSelect';
import { CUSTOM_FONTS } from '@pages/private/ai-design/ai-design-templates-v2/utils/polotno-font.utils';
import { FormLayout } from '@components/utils/layout-utils';
import { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/es/interface';

const { RangePicker } = DatePicker;

function CalendarTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="캘린더" {...props}>
      <CalendarOutlined
        style={{
          fontSize: 20,
        }}
      />
    </SectionTab>
  );
}
CalendarTab.displayName = '';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

function CalendarPanel({ store }: { store: StoreType }) {
  const [fontFamily, setFontFamily] = useState<string>();
  const [value, setValue] = useState<RangeValue>(null);

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 31;
    }

    return false;
  };

  const cellRender = React.useCallback(
    (current: number | Dayjs, info: CellRenderInfo<Dayjs>) => {
      if (info.type !== 'date') {
        return info.originNode;
      }
      if (typeof current === 'number') {
        return <div className="ant-picker-cell-inner">{current}</div>;
      }
      return <div className="ant-picker-cell-inner">{current.date()}</div>;
    },
    [],
  );

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <FormLayout>
        <div>
          <Typography.Title level={5}>캘린더 설정</Typography.Title>
          <Form.Item required label="년/월 선택" labelCol={{ span: 24 }}>
            <RangePicker
              value={value}
              onChange={(dates) => {
                setValue(dates);
              }}
              // @ts-ignore
              cellRender={cellRender}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item required label="캘린터 폰트" labelCol={{ span: 24 }}>
            <PolotnoFontSelect
              value={fontFamily}
              fonts={CUSTOM_FONTS}
              placeholder="폰트를 선택해주세요"
              onChange={(value) => {
                if (typeof value === 'string') setFontFamily(value);
              }}
            />
          </Form.Item>
        </div>
      </FormLayout>

      <Button
        type="primary"
        disabled={!value}
        onClick={() => {
          if (value) {
            const [start, end] = value;
            if (start && end) {
              const dates: { year: number; month: number; day: number }[] = [];

              const diff = end.diff(start, 'day');
              for (let i = 0; i <= diff; i += 1) {
                const date = start.add(i, 'day');
                dates.push({
                  year: date.get('year'),
                  month: date.get('month') + 1,
                  day: date.get('date'),
                });
              }

              createCalendar(store, {
                fontFamily,
                dates,
              });
            }
          }
        }}
      >
        캘린더 생성
      </Button>
    </div>
  );
}

const CalendarSection = {
  name: 'calendar',
  Tab: CalendarTab,
  Panel: CalendarPanel,
};

export default CalendarSection;
