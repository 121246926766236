import { fetchLLMIntents } from '@apis/ai-consultation-prompts';
import { useQuery } from 'react-query';

export const useIntentOptions = () => {
  return useQuery({
    queryKey: ['ai-consultation-responses', 'intents'],
    queryFn: () => fetchLLMIntents(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.rows.map((d) => ({ label: d.roleName, value: d.role })),
  });
};
