import React from 'react';
import { Modal } from '@components/atoms';
import Input from '@components/v2/antd/data-entry/Input';
import Select from '@components/v2/antd/data-entry/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled } from 'styled-components';
import { FormItem } from '@components/molecules';
import { PartnerAssistantDto, PartnerAssistantLanguage } from '@types';
import { ASSISTANT_LANGUAGE_TO_KOREAN } from 'constant';
import { useMutation, useQueryClient } from 'react-query';
import { requestEditAssistant } from '@apis';
import { assistantsQueryKeys } from '@apis/assistants/queryKeys';

const formValidationSchema = yup.object({
  assistantId: yup.string().required('어시스턴트 아이디를 입력해주세요.'),
  language: yup.string().required('언어를 선택해주세요.'),
});

export interface EditAssistantModalProps {
  partnerId: number;
  initialValues: PartnerAssistantDto;
  onClose?: () => void;
}

const LANGUAGE_OPTIONS = Object.entries(ASSISTANT_LANGUAGE_TO_KOREAN).map(
  ([value, label]) => ({ label, value }),
);

function EditAssistantModal(props: EditAssistantModalProps) {
  const { onClose, partnerId, initialValues } = props;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: requestEditAssistant,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        assistantsQueryKeys.partner(partnerId),
      );
      onClose?.();
    },
  });

  const formik = useFormik<{
    assistantId: string;
    language: PartnerAssistantLanguage;
  }>({
    initialValues: {
      assistantId: initialValues.assistantId,
      language: initialValues.language,
    },
    onSubmit: (values) => {
      mutate({
        partnerId,
        assistantId: values.assistantId,
        language: values.language,
      });
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  return (
    <Modal
      size={560}
      confirmLabel="확인"
      cancelLabel="취소"
      disabled={!formik.isValid || isLoading}
      title="어시스턴트 수정"
      onConfirm={() => {
        formik.submitForm();
      }}
      onClose={() => {
        onClose?.();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          <FormItem label={'어시스턴트 아이디'}>
            <Input
              autoFocus
              onChange={(ev) => {
                formik.setFieldValue('assistantId', ev.target.value);
              }}
              defaultValue={initialValues.assistantId}
              placeholder={'아이디를 입력해주세요.'}
            />
          </FormItem>
          <FormItem label={'언어'}>
            <Select
              style={{ width: '100%' }}
              allowClear
              options={LANGUAGE_OPTIONS}
              defaultValue={initialValues.language}
              disabled
              onChange={(value) => {
                formik.setFieldValue('language', value);
              }}
              placeholder={'언어를 선택해주세요.'}
            />
          </FormItem>
        </FormLayout>
      </form>
    </Modal>
  );
}

export default EditAssistantModal;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
