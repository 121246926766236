import React from 'react';
import { ReactComponent as FilterSvg } from '@assets/svg/Filter.svg';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';

interface FilterButtonProps {
  filterCount?: number;
  handleToggleFilterOpen: () => void;
}

function FilterButton({
  filterCount,
  handleToggleFilterOpen,
}: FilterButtonProps) {
  return (
    <Button
      style={{ height: 40 }}
      buttonStyle="line"
      buttonColor={filterCount ? 'primary' : 'gray'}
      onClick={handleToggleFilterOpen}
    >
      <FilterIcon />
      필터 {filterCount || null}
    </Button>
  );
}

export default FilterButton;

const FilterIcon = styled(FilterSvg)`
  width: 16px;
  height: 16px;
  margin-right: 2px;
`;
