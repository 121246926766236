import { Label, Toggle, Typo } from '@components/atoms';
import Bullet from '@components/atoms/Bullet';
import { LabelColorType, LabelStyleType } from '@components/atoms/Label/types';
import ProductBadge from '@components/atoms/ProductBadge';
import TableSelect, { TableSelectOption } from '@components/atoms/TableSelect';
import { ColorType } from '@styles/colors';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components';
import { isArray } from 'lodash';

export type TableTag = {
  value: string;
  color?: LabelColorType;
  type?: LabelStyleType;
};

// 스위치 렌더
export const renderSwitch = (
  onChange: (params: ICellRendererParams) => void,
) => {
  return function renderer(params: ICellRendererParams) {
    const { value } = params;
    return <Toggle isActive={value} onChange={() => onChange(params)} />;
  };
};

// 스위치 렌더
export const renderTags = (tagInfo: TableTag[] | TableTag) => {
  if (isArray(tagInfo)) {
    return (
      <div
        style={{
          display: 'flex',
          gap: 4,
        }}
      >
        {tagInfo.map(({ value, color, type }) => {
          return (
            <Label
              key={value}
              sizeType={24}
              colorType={color || 'teal'}
              styleType={type || 'light'}
            >
              {value}
            </Label>
          );
        })}
      </div>
    );
  }
  const { value, color, type } = tagInfo;
  return (
    <Label
      key={value}
      sizeType={24}
      colorType={color || 'teal'}
      styleType={type || 'light'}
    >
      {value}
    </Label>
  );
};

// 시술 상품 렌더
export const renderProduct = () => {
  return function renderer(params: ICellRendererParams) {
    return (
      <ProductCellContainer>
        <ProductCellImage src={params?.data.thumbnailImageUrl?.[0] || ''} />
        <Typo as="p" typoType="b9">
          {params?.data.productName}
        </Typo>
        {params?.data.productBadge && (
          <ProductBadge size={20} type={params?.data.productBadge} />
        )}
      </ProductCellContainer>
    );
  };
};

const ProductCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const ProductCellImage = styled.div<{ src: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  background: ${({ src }) => `url(${src})`} no-repeat center center / cover;
`;

// bullet 렌더
export const renderBullet = (options: { value: any; color: ColorType }[]) => {
  return function renderer({ value }: ICellRendererParams) {
    return (
      <Bullet color={options.find((item) => item.value === value)?.color}>
        {value}
      </Bullet>
    );
  };
};

// select 렌더
export const renderSelect = <T extends {}>(
  options: TableSelectOption<T>[],
  handleChange: (value: T, params: ICellRendererParams) => void,
  option?: {
    menuMaxWidth?: number;
    placeholder?: string;
    isDot?: boolean;
  },
) => {
  return function renderer(params: ICellRendererParams) {
    const { value } = params;

    return (
      <TableSelect<T>
        placement="bottomRight"
        value={value}
        onChange={(changeValue) => handleChange(changeValue, params)}
        options={options}
        menuMaxWidth={option?.menuMaxWidth}
        placeholder={option?.placeholder}
        isDot={option?.isDot}
      />
    );
  };
};
