import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { storageKey } from '@store/types';
import { AuthStore } from './types';

const initialData = {
  loginUser: undefined,
  token: undefined,
};

const useAuthStore = create<AuthStore>()(
  persist<AuthStore>(
    (set) => ({
      ...initialData,
      saveLoginUser: (loginUser) => {
        set({
          loginUser,
        });
      },
      saveToken: (token) => {
        set({
          token,
        });
      },
      clearAuthState: () => {
        set(initialData);
      },
    }),
    {
      name: storageKey.AUTH_STORE,
    },
  ),
);

export default useAuthStore;
