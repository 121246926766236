import React from 'react';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { useAlertStore } from '@store/useAlertStore';
import { PolotnoIconImage } from '@assets/svg/polotno';
import { styled } from 'styled-components';
import { overrideTheme } from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-theme.utils';
import { fetchAIDesignAssets } from '../../../../../../../@apis/ai-design-assets';
import { AIDesignAsset } from '../../../../../../../@types/ai-design-asset/models/AIDesignAsset';

function PolotnoLogoImageTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="로고" {...props}>
      <PolotnoIconImage />
    </SectionTab>
  );
}
PolotnoLogoImageTab.displayName = '';

export const PolotnoLogoImagePanel = observer(({ store }: any) => {
  const showAlert = useAlertStore((state) => state.show);
  // load data
  const { items, isLoading } = useInfiniteAPI({
    getAPI: () => {
      return process.env.REACT_APP_API_BASE_URL || '';
    },
    fetchFunc: async () => {
      const { rows } = await fetchAIDesignAssets({
        page: 1,
        pageSize: 9999,
        categories: ['로고'],
      });
      return rows;
    },
  });

  return (
    <Wrapper>
      <ImagesGrid
        shadowEnabled
        getImageClassName={() => {
          return 'logo-image';
        }}
        images={items}
        // @ts-ignore
        getPreview={(item: AIDesignAsset) => {
          return item.assetUrl;
        }}
        isLoading={isLoading}
        onSelect={async (item: AIDesignAsset) => {
          const { assetUrl } = item;
          showAlert({
            size: 360,
            title: '로고 변경',
            message: '선택하신 로고로 모든 이미지가 변경됩니다.',
            actions: [
              { label: '취소' },
              {
                label: '변경',
                color: 'primary',
                onClick: () => {
                  const json: any = store.toJSON();
                  const newJson = overrideTheme(json, {
                    logoUrl: assetUrl,
                  });
                  store.loadJSON(newJson, true);
                },
              },
            ],
          });
        }}
        rowsNumber={2}
      />
    </Wrapper>
  );
});

export const PolotnoLogoImageSection = {
  name: 'logo-assets',
  visibleInList: false,
  Tab: PolotnoLogoImageTab,
  Panel: PolotnoLogoImagePanel,
};

const Wrapper = styled.div`
  .logo-image {
    background: rgba(0, 0, 0, 0.1);
  }
  height: 100%;
`;
