import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { fetchMenus } from '../../../../@apis/menus';
import { FetchMenuListQuery } from '../../../../@types/menu/params/FetchMenuListQuery';
import { Menu } from '../../../../@types/menu/models/Menu';
import {
  MENU_TRANSLATIONS,
  MenuUserGroup,
} from '../../../../@types/menu/constants/menu.constants';

function MenuListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    menuName: '',
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchMenuListQuery> = {
    filterKey: 'menuName',
    searchInputPlaceholder: '메뉴명 검색',
  };

  const COLS: ColDef[] = [
    {
      field: 'memberGroup',
      minWidth: 200,
      width: 200,
      headerName: '메뉴그룹명',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<Menu>) => {
        const { data } = col;
        if (data) {
          const { homepageMenuGroup } = data;

          if (homepageMenuGroup) {
            const { groupName } = homepageMenuGroup;
            return groupName;
          }
        }
        return '-';
      },
    },
    {
      field: 'menuName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '메뉴명',
      sortable: false,
    },
    {
      field: 'menuCode',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '메뉴코드',
      sortable: false,
    },
    {
      field: 'memberGroups',
      minWidth: 300,
      width: 300,
      headerName: '사용자 그룹',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<Menu>) => {
        const { data } = col;
        if (data) {
          const { memberGroups = [] } = data;

          if (memberGroups.length === Object.values(MenuUserGroup).length) {
            return '전체';
          }
          const translations = memberGroups.map((memberGroup) => {
            return MENU_TRANSLATIONS[memberGroup] || '';
          });
          return translations.join(', ');
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: FetchMenuListQuery) => {
    const { rows, count } = await fetchMenus(query);
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: Menu) => {
    const { id } = data;
    navigate(PAGE_PATHS.SETTINGS_MENUS_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.SETTINGS_MENU_ADD}>
          <Button>메뉴 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default MenuListPage;
