import React, { CSSProperties } from 'react';
import {
  DatePicker as AntDatePicker,
  DatePickerProps as AntDatePickerProps,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import 'dayjs/locale/ko';
import datePickerLocale from '@components/utils/antd-date-picker-locale';

export type DatePickerProps = AntDatePickerProps & { style?: CSSProperties };

const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { style, ...rest } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <AntDatePicker
      // @ts-ignore
      locale={datePickerLocale}
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

DatePicker.defaultProps = {};
export default DatePicker;
