import { MediaQuery } from '@styles/mediaQuery';
import React from 'react';
import styled from 'styled-components';

function MobileFooter({ children }: { children: React.ReactNode }) {
  return <MobileFooterContainer>{children}</MobileFooterContainer>;
}

const MobileFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 16px 20px;
  gap: 8px;

  > * {
    max-width: none;
    flex: 1;
  }

  ${MediaQuery.FROM_PHONE} {
    display: flex;
  }
`;

export default MobileFooter;
