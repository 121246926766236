import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';

const { TextArea: AntTextArea } = Input;

interface Props extends TextAreaProps {}

const TextArea: React.FC<Props> = (props: Props) => {
  const { rows = 4 } = props;
  return <AntTextArea rows={rows} {...props} />;
};

export default TextArea;
