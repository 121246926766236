import { AppError, request, Row, Rows } from '@networks/index';
import { GlobalCatalog } from '../@types/global-catalog/models/GlobalCatalog';
import { CreateGlobalCatalogBody } from '../@types/global-catalog/params/CreateGlobalCatalogBody';
import { FetchGlobalCatalogListQuery } from '../@types/global-catalog/params/FetchGlobalCatalogListQuery';
import { UpdateGlobalCatalogBody } from '../@types/global-catalog/params/UpdateGlobalCatalogBody';
import { GlobalCatalogCategory } from '../@types/global-catalog/models/GlobalCatalogCategory';
import { SyncPartnerGlobalCatalogBody } from '../@types/global-catalog/params/SyncPartnerGlobalCatalogBody';
import { MedicalSubject } from '../@types/global-catalog/models/MedicalSubject';

const API_ROOT = 'admin/v1/global-catalogs';

export const createGlobalCatalog = async (body: CreateGlobalCatalogBody) => {
  try {
    const { data } = await request<Rows<GlobalCatalog>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalCatalogCategories = async () => {
  try {
    const url = `${API_ROOT}/categories`;
    const { data } = await request<Rows<GlobalCatalogCategory>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalCatalogs = async (
  query: FetchGlobalCatalogListQuery,
) => {
  try {
    const { data } = await request<Rows<GlobalCatalog>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchMedicalSubjects = async () => {
  try {
    const { data } = await request<Rows<MedicalSubject>>({
      method: 'get',
      url: `admin/medical-subject`,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchGlobalCatalogDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<GlobalCatalog>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateGlobalCatalog = async (
  id: number | string,
  body: UpdateGlobalCatalogBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<GlobalCatalog>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const syncGlobalCatalog = async (body: SyncPartnerGlobalCatalogBody) => {
  const url = `admin/v1/catalogs/sync-global-catalog`;
  try {
    const { data } = await request<Row<GlobalCatalog>>({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
