import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import DraggableTableRow from '@components/organisms/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Typo } from '@components/atoms';

type EditableTableProps = Parameters<typeof Table>[0];
interface FAQRepeaterDataType {
  key: number;
  question: string;
  answer: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DEFAULT_ROW = {
  key: 1,
  question: '',
  answer: '',
};

export interface FAQRepeaterProps {
  initialValues?: { Q: string; A: string }[];
  onChange?: (values: { Q: string; A: string }[]) => void;
  label?: string;
}
const FAQInputRepeater: React.FC<FAQRepeaterProps> = (
  props: FAQRepeaterProps,
) => {
  const { initialValues = [], onChange, label = '내용' } = props;
  const [dataSource, setDataSource] = useState<FAQRepeaterDataType[]>([
    DEFAULT_ROW,
  ]);

  useEffect(() => {
    if (initialValues.length === 0) {
      setDataSource([DEFAULT_ROW]);
    } else {
      setDataSource(
        initialValues.map((value, index) => {
          const { Q = '', A = '' } = value;
          return {
            key: index + 1,
            question: Q,
            answer: A,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDataChange = (newDataSource: FAQRepeaterDataType[]) => {
    setDataSource(newDataSource);
    if (onChange) {
      onChange(
        newDataSource
          .filter(({ question, answer }) => question || answer)
          .map(({ question, answer }) => {
            return {
              Q: question,
              A: answer,
            };
          }),
      );
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    handleDataChange(newData);
  };

  const defaultColumns: ColumnTypes[number][] = [
    {
      key: 'sort',
    },
    {
      title: label,
      dataIndex: 'content',
      width: '90%',
      render: (_, record, index) => {
        const { question = '', answer = '' } = record;
        return (
          <div>
            <Typo typoType="label2">질문</Typo>
            <TextArea
              style={{
                marginTop: 4,
                marginBottom: 16,
              }}
              autoSize
              placeholder="질문을 입력해주세요"
              value={question}
              onChange={(e) => {
                const { target } = e;
                const { value } = target;
                const newDataSource = [...dataSource];
                newDataSource.splice(index, 1, {
                  ...newDataSource[index],
                  question: value,
                });
                handleDataChange(newDataSource);
              }}
            />
            <Typo typoType="label2">답변</Typo>
            <TextArea
              style={{
                marginTop: 4,
              }}
              autoSize
              placeholder="답변을 입력해주세요"
              value={answer}
              onChange={(e) => {
                const { target } = e;
                const { value } = target;
                const newDataSource = [...dataSource];
                newDataSource.splice(index, 1, {
                  ...newDataSource[index],
                  answer: value,
                });
                handleDataChange(newDataSource);
              }}
            />
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                handleDelete(record.key);
              }}
              disabled={dataSource.length === 1}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    const newRow: FAQRepeaterDataType = {
      key: dataSource.length + 1,
      question: '',
      answer: '',
    };
    const newData = [...dataSource, newRow];
    handleDataChange(newData);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.key === active.id);
      const overIndex = dataSource.findIndex((i) => i.key === over?.id);
      const newData = arrayMove(dataSource, activeIndex, overIndex);
      handleDataChange(newData);
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // @ts-ignore
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={defaultColumns}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>

      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button
          onClick={handleAdd}
          type="primary"
          style={{ marginTop: 16, marginRight: 8 }}
        >
          추가하기
        </Button>
      </div>
    </div>
  );
};

export default FAQInputRepeater;
