export enum AIDesignBlogCopySubject {
  MainThumbNail = '메인썸네일',
  TreatmentDescription = '시술설명',
  TreatmentEffect = '시술효과',
  TreatmentMechanism = '시술원리',
  TreatmentSpeciality = '시술장점/특징',
  TreatmentArea = '시술부위',

  TreatmentCautions = '주의사항',
  TreatmentTarget = '추천대상',
  TreatmentQnA = 'Q&A',

  // 신규
  DiseaseDescription = '질환설명',
  TermsDescription = '용어설명',
  Cause = '원인',
  Symptom = '증상',
  Cure = '치료법',
  SurgicalCure = '치료법(수술)',
  NonSurgicalCure = '치료법(비수술)',
  Preventive = '예방법',
  SelfDiagnosisMethod = '자가진단법',
  DiseaseCourse = '질환경과',
  InspectionMethod = '검사방법',
  Closing = '클로징',
  PostProcedureCare = '시술후관리',
}

export const AI_DESIGN_BLOG_COPY_SUBJECT_OPTIONS = Object.values(
  AIDesignBlogCopySubject,
).map((value) => {
  return {
    label: value,
    value,
  };
});
