import { AppError, request, Row } from '@networks/index';
import { CreateBlogPartnerInfoBody } from '../@types/blog-partner-info/params/CreateBlogPartnerInfoBody';
import { BlogPartnerInfo } from '../@types/blog-partner-info/models/BlogPartnerInfo';
import { UpdateBlogPartnerInfoBody } from '../@types/blog-partner-info/params/UpdateBlogPartnerInfoBody';

const API_ROOT = 'admin/partners';

export const createBlogPartnerInfo = async (
  partnerId: number | string,
  body: CreateBlogPartnerInfoBody,
) => {
  const url = `${API_ROOT}/${partnerId}/blog-partner-infos`;
  try {
    const { data } = await request<Row<BlogPartnerInfo>>({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateBlogPartnerInfo = async (
  partnerId: number | string,
  body: UpdateBlogPartnerInfoBody,
) => {
  const url = `${API_ROOT}/${partnerId}/blog-partner-infos/this`;
  try {
    const { data } = await request<Row<BlogPartnerInfo>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchBlogPartnerInfoDetail = async (
  partnerId: number | string,
) => {
  const url = `${API_ROOT}/${partnerId}/blog-partner-infos/this`;
  try {
    const { data } = await request<Row<BlogPartnerInfo>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
