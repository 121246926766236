import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
  TableTabFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { LabelColorType } from '@components/atoms/Label/types';
import colorSet from '@styles/colors';
import { renderTags, TableTag } from '../../../../utils/tableRenderHelper';
import { FetchAIDesignAssetListQuery } from '../../../../@types/ai-design-asset/params/FetchAIDesignAssetListQuery';
import { AIDesignAsset } from '../../../../@types/ai-design-asset/models/AIDesignAsset';
import {
  AIDesignAssetStatus,
  AIDesignAssetTranslations,
} from '../../../../@types/ai-design-asset/constants/ai-design-asset.constants';
import { fetchAIDesignAssets } from '../../../../@apis/ai-design-assets';

function AIDesignAssetListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    status: '',
    keyword: '',
  };

  const tabFilterInfo: TableTabFilterInfo = {
    filterKey: 'status',
    tabs: [
      {
        label: AIDesignAssetTranslations[AIDesignAssetStatus.UnUsable],
        filterValue: AIDesignAssetStatus.UnUsable,
      },
      {
        label: AIDesignAssetTranslations[AIDesignAssetStatus.Usable],
        filterValue: AIDesignAssetStatus.Usable,
      },
    ],
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchAIDesignAssetListQuery> =
    {
      filterKey: 'keyword',
      searchInputPlaceholder: '검색 (채널,카테고리,태그)',
    };

  const COLS: ColDef[] = [
    {
      field: 'assetUrl',
      minWidth: 300,
      width: 300,
      headerName: '이미지',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignAsset>) => {
        const { data } = col;
        if (data) {
          const { assetUrl } = data;
          const Image = (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <img
                style={{
                  border: `1px solid ${colorSet.gray10}`,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                }}
                width={100}
                src={assetUrl}
                alt="썸네일"
              />
            </div>
          );
          return Image;
        }
        return '';
      },
    },
    {
      field: 'status',
      minWidth: 153,
      width: 153,
      headerName: '상태',
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignAsset>) => {
        const { data } = col;
        if (data) {
          const { status } = data;
          const translation = AIDesignAssetTranslations[status];
          let color: LabelColorType = 'gray';
          switch (status) {
            case AIDesignAssetStatus.Usable:
              color = 'blue';
              break;
            default:
              break;
          }
          return renderTags({
            value: translation,
            color,
            type: 'light',
          });
        }
        return '';
      },
    },
    {
      field: 'displayChannels',
      minWidth: 153,
      width: 153,
      headerName: '활용채널',
      flex: 1,
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignAsset>) => {
        const { data } = col;
        if (data) {
          const { displayChannels } = data;
          const tags: TableTag[] = displayChannels.map((channel) => {
            return {
              value: channel,
              color: 'primary',
              type: 'light',
            };
          });
          return renderTags(tags);
        }
        return '';
      },
    },
    {
      field: 'categories',
      minWidth: 153,
      width: 153,
      headerName: '카테고리',
      flex: 1,
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignAsset>) => {
        const { data } = col;
        if (data) {
          const { categories } = data;
          const tags: TableTag[] = categories.map((category) => {
            return {
              value: category,
              color: 'primary',
              type: 'light',
            };
          });
          return renderTags(tags);
        }
        return '';
      },
    },
    {
      field: 'tags',
      minWidth: 153,
      width: 153,
      headerName: '검색태그',
      flex: 1,
      sortable: false,
      cellRenderer: (col: ICellRendererParams<AIDesignAsset>) => {
        const { data } = col;
        if (data) {
          const { tags: rawTags } = data;
          const tags: TableTag[] = rawTags.map((tag) => {
            return {
              value: tag,
              color: 'primary',
              type: 'light',
            };
          });
          return renderTags(tags);
        }
        return '';
      },
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchAIDesignAssetListQuery) => {
      const { rows, count } = await fetchAIDesignAssets(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: AIDesignAsset) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DESIGNER_ASSET_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DESIGNER_ASSET_ADD}>
          <Button>디자인 에셋 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
      tabFilterInfo={tabFilterInfo}
      rowHeight={200}
    />
  );
}

export default AIDesignAssetListPage;
