import { Check, Typo } from '@components/atoms';
import { CheckboxItemProps } from '@components/atoms/Check';
import { FontSizeType } from '@components/atoms/Typo/types';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { styled } from 'styled-components';

interface CheckBoxLabelProps extends CheckboxItemProps {
  value: boolean;
  onChange: () => void;
  onCheckChange?: (e: CheckboxChangeEvent) => void;
  label: string;
  type?: FontSizeType;
}

function CheckBoxLabel({
  value,
  onChange,
  onCheckChange,
  label,
  type,
  ...rest
}: CheckBoxLabelProps) {
  const onChangeVal = () => {
    onChange();
  };

  return (
    <CheckBox onClick={onChangeVal}>
      <Check
        defaultChecked={value}
        checked={!!value}
        // onChange={onCheckChange || onChangeVal}
        {...rest}
      />
      <Typo typoType={type || 'b8'} style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Typo>
    </CheckBox>
  );
}

export default CheckBoxLabel;

const CheckBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
