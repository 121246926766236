import { AppError, request, Row, Rows } from '@networks/index';
import { ReviewTemplate } from '../@types/review/models/ReviewTemplate';
import { ReviewTemplateDetail } from '../@types/review/models/ReviewTemplateDetail';
import { AddReviewTemplateBody } from '../@types/review/params/AddReviewTemplateBody';
import { UpdateReviewTemplateBody } from '../@types/review/params/UpdateReviewTemplateBody';
import { ReviewQuestion } from '../@types/review/models/ReviewQuestion';
import { AddReviewQuestionBody } from '../@types/review/params/AddReviewQuestionBody';
import { FetchReviewTemplateListQuery } from '../@types/review/params/FetchReviewTemplateListQuery';
import { FetchReviewQuestionListQuery } from '../@types/review/params/FetchReviewQuestionListQuery';
import { ReviewQuestionDetail } from '../@types/review/models/ReviewQuestionDetail';
import { UpdateReviewQuestionBody } from '../@types/review/params/UpdateReviewQuestionBody';

/**
 * 리뷰 템플릿 등록
 */
export const addReviewTemplate = async (body: AddReviewTemplateBody) => {
  const url = `/admin/v1/reviews/templates`;
  try {
    await request({
      method: 'post',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 템플릿 목록 조회
 */
export const fetchReviewTemplates = async (
  query: FetchReviewTemplateListQuery,
) => {
  const url = `/admin/v1/reviews/templates`;
  try {
    const { data } = await request<Rows<ReviewTemplate>>({
      method: 'get',
      url,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 템플릿 상세 조회
 */
export const fetchReviewTemplateDetail = async (id: string | number) => {
  const url = `/admin/v1/reviews/templates/${id}`;
  try {
    const { data } = await request<Row<ReviewTemplateDetail>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 템플릿 수정
 */
export const updateReviewTemplate = async (
  id: number | string,
  body: UpdateReviewTemplateBody,
) => {
  const url = `/admin/v1/reviews/templates/${id}`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 문항 등록
 */
export const addReviewQuestion = async (body: AddReviewQuestionBody) => {
  const url = `/admin/v1/reviews/questions`;
  try {
    await request({
      method: 'post',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 문항 목록 조회
 */
export const fetchReviewQuestions = async (
  query: FetchReviewQuestionListQuery,
) => {
  const url = `/admin/v1/reviews/questions`;
  try {
    const { data } = await request<Rows<ReviewQuestion>>({
      method: 'get',
      url,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 문항 상세 조회
 */
export const fetchReviewQuestionDetail = async (id: number | string) => {
  const url = `/admin/v1/reviews/questions/${id}`;
  try {
    const { data } = await request<Row<ReviewQuestionDetail>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 문항 수정
 */
export const updateReviewQuestion = async (
  id: number | string,
  body: UpdateReviewQuestionBody,
) => {
  const url = `/admin/v1/reviews/questions/${id}`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * 리뷰 문항 삭제
 */
export const deleteReviewQuestion = async (id: number | string) => {
  const url = `/admin/v1/reviews/questions/${id}`;
  try {
    await request({
      method: 'delete',
      url,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
