import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { storageKey } from '@store/types';
import { AgencyStore } from '@store/useAgencyStore/types';

const initialData = {
  agencies: [],
  agencyOptions: [],
};

const useAgencyStore = create<AgencyStore>()(
  persist<AgencyStore>(
    (set) => {
      return {
        ...initialData,
        saveAgencies: (agencies) => {
          set({
            agencies,
            agencyOptions: agencies.map(({ id, agencyName }) => ({
              label: agencyName,
              value: id,
            })),
          });
        },
      };
    },
    {
      name: storageKey.AGENCY_STORE,
    },
  ),
);

export default useAgencyStore;
