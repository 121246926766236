import React, { useRef } from 'react';
import { DefaultLayoutContainer } from '../styles';
import Header from './components/Header';
import SideNav from './components/SideNav';
import { SideNavRef } from './components/SideNav/types';
import { BodyContainer } from './styles';

interface DefaultLayoutProps {
  children: React.ReactNode;
  isFullPage?: boolean;
}

function DefaultLayout(props: DefaultLayoutProps) {
  const { children, isFullPage } = props;

  // ref
  const sideNavRef = useRef<SideNavRef>(null);

  return (
    <DefaultLayoutContainer className="notranslate">
      {!isFullPage && (
        <>
          <Header sideNavRef={sideNavRef} />
          <BodyContainer>
            <SideNav ref={sideNavRef} />
            <main style={{ backgroundColor: '#F6F8FB' }}>{children}</main>
          </BodyContainer>
        </>
      )}
    </DefaultLayoutContainer>
  );
}

export default DefaultLayout;
