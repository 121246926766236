import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { css, styled } from 'styled-components';
import { Typo } from '@components/atoms';
import { LabelButtonSize, LabelButtonType } from './types';
import {
  getColor,
  getDisabledColor,
  getIconSize,
  getPaddingSize,
  getTextSize,
} from './utils';
import { ReactComponent as CloseSvg } from '../../../../assets/svg/Close.svg';

interface LabelButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize?: LabelButtonSize;
  buttonType?: LabelButtonType;
  selected?: boolean;
  disabled?: boolean;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
  children?: ReactNode;
  CustomIcon?: any;
}

function LabelButton({
  buttonSize = 'lg',
  buttonType = 'solid',
  selected,
  disabled,
  isLeftIcon,
  isRightIcon,
  children,
  CustomIcon,
  ...rest
}: LabelButtonProps) {
  return (
    <LabelButtonConatiner
      id="labelButton"
      buttonSize={buttonSize}
      buttonType={buttonType}
      selected={selected}
      disabled={disabled}
      {...rest}
    >
      {isLeftIcon &&
        (CustomIcon ? (
          <CustomIcon
            buttonSize={buttonSize}
            selected={selected}
            disabled={disabled}
            style={{ marginRight: 2 }}
          />
        ) : (
          <CloseIcon
            buttonSize={buttonSize}
            selected={selected}
            disabled={disabled}
            style={{ marginRight: 2 }}
          />
        ))}
      <LabelButtonText
        buttonSize={buttonSize}
        buttonType={buttonType}
        selected={selected}
        disabled={disabled}
      >
        {children}
      </LabelButtonText>
      {isRightIcon &&
        (CustomIcon ? (
          <CustomIcon
            buttonSize={buttonSize}
            selected={selected}
            disabled={disabled}
            style={{ marginRight: 2 }}
          />
        ) : (
          <CloseIcon
            buttonSize={buttonSize}
            selected={selected}
            disabled={disabled}
            style={{ marginLeft: 2 }}
          />
        ))}
    </LabelButtonConatiner>
  );
}

export default LabelButton;

const LabelButtonConatiner = styled.button<{
  buttonSize?: LabelButtonSize;
  buttonType?: LabelButtonType;
  selected?: boolean;
  disabled?: boolean;
}>`
  &:disabled {
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  ${({ buttonSize }) => getPaddingSize(buttonSize)};
  ${({ buttonType, selected }) => {
    return css`
      background-color: ${getColor(buttonType, selected).backgroundColor};
      border: ${getColor(buttonType, selected).border};
    `;
  }}
  &:disabled {
    background-color: ${getDisabledColor().backgroundColor};
    border: ${getDisabledColor().border};
  }
`;

const LabelButtonText = styled(Typo)<{
  buttonSize?: LabelButtonSize;
  buttonType?: LabelButtonType;
  selected?: boolean;
  disabled?: boolean;
}>`
  ${({ buttonSize }) => getTextSize(buttonSize)};
  color: ${({ buttonType, disabled, selected }) => {
    if (disabled) return getDisabledColor().color;
    return getColor(buttonType, selected).color;
  }};
`;

const CloseIcon = styled(CloseSvg)<{
  buttonSize?: LabelButtonSize;
  selected?: boolean;
  disabled?: boolean;
}>`
  ${({ buttonSize }) => getIconSize(buttonSize)};
  path {
    fill: ${({ disabled, selected }) => {
      if (disabled) return getDisabledColor().fill;
      return getColor(undefined, selected).fill;
    }};
  }
`;
