/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { css, keyframes, styled } from 'styled-components';
import { ReactComponent as ArrowUp } from '@assets/svg/ArrowUp-fill.svg';
import { ReactComponent as Handle } from '@assets/svg/Handler2.svg';
import colorSet from '@styles/colors';
import { typo } from '@components/atoms';
import { MediaQuery } from '@styles/mediaQuery';
import Typo from '../../atoms/Typo';
import { IsEditableProps } from '../EditorInput';
import CheckBoxLabel from '../CheckBoxLabel';

interface AuthCardProps {
  children: React.ReactNode;
  title: string;
  id?: number;
  isToggle?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  borderColor?: string;
  right?: React.ReactNode;
  type?: string;
  isDrag?: boolean;
  isDraggable?: boolean;
  setIsDraggable?: (bool: boolean) => void;
  isTitleEditable?: IsEditableProps;
  bottom?: React.ReactNode;
  isEditable?: boolean;
  value: boolean;
  onChange: () => void;
}

function AuthCard({
  children,
  title,
  id,
  isToggle,
  style,
  contentStyle,
  borderColor,
  right,
  type = 'default',
  isDrag = false,
  isDraggable,
  setIsDraggable,
  isTitleEditable,
  bottom,
  isEditable,
  value,
  onChange,
}: AuthCardProps) {
  // 아코디언 여닫기 Default true (열림)
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  // 아코디언 컨텐츠 Wrapper Ref
  const cardContentWrapperRef = useRef<HTMLDivElement>(null);
  // 아코디언 컨텐츠 Ref
  const cardContentRef = useRef<HTMLDivElement>(null);

  //  isToggle Event
  const handleToggleClick = useCallback(() => {
    setContentHeight(
      cardContentRef.current?.getBoundingClientRect().height || 0,
    );
    if (cardContentWrapperRef?.current) {
      cardContentWrapperRef.current.style.animationName = '';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  const [contentHeight, setContentHeight] = useState<number>(0);

  // Header Left Render
  // const renderHeaderLeft = (val: boolean) => {
  //   return (
  //     <CardTitle>
  //       {title}
  //       <Check value={val} onChange={() => onChange()} />
  //     </CardTitle>
  //   );
  // };
  // useEffect(() => {
  //   console.log(value, '::: value');
  // }, [value]);

  const handelDragOnDown = (mouseType: string) => {
    if (setIsDraggable) {
      if (mouseType === 'down') {
        setIsDraggable(true);
      } else {
        setIsDraggable(false);
      }
    }
  };

  useLayoutEffect(() => {
    if (isEditable) {
      setIsCollapse(true);
    }
  }, [isEditable]);

  useLayoutEffect(() => {
    setContentHeight(
      cardContentRef.current?.getBoundingClientRect().height || 0,
    );

    if (cardContentWrapperRef?.current) {
      cardContentWrapperRef.current.style.animationName = 'none';
    }
  }, []);

  return (
    <CardWrapper
      style={{
        ...style,
      }}
      draggable={isDraggable}
    >
      <CardHeaderWrapper
        collpase={isCollapse.toString()}
        style={{
          border: borderColor ? '1px solid' : '',
        }}
      >
        <CardHeaderLeft>
          {isDrag && (
            <HandleIcon
              isEditable={!isTitleEditable?.editable}
              onMouseDown={(e) => {
                if (isTitleEditable?.editable) e.preventDefault();
                if (!isTitleEditable?.editable) handelDragOnDown('down');
              }}
            />
          )}
          <CardTitle>
            <CheckBoxLabel
              value={value}
              onChange={onChange}
              label={title}
              type="h8"
            />
            {/* {title}
            <Check
              checked={!!value}
              defaultChecked={value}
              onChange={() => onChange()}
            /> */}
          </CardTitle>
        </CardHeaderLeft>
        <CardHeaderRight>
          {right && right}
          {isToggle && (
            <CardHeaderRightToggle onClick={handleToggleClick}>
              <ArrowIcon $collapse={isCollapse.toString()} />
            </CardHeaderRightToggle>
          )}
        </CardHeaderRight>
      </CardHeaderWrapper>
      <CardContentWrapper
        $collapse={isCollapse.toString()}
        $bottomExist={!!bottom}
        style={{
          border:
            borderColor && isCollapse ? `1px solid ${borderColor}` : `none`,
        }}
        height={contentHeight}
        ref={cardContentWrapperRef}
      >
        <CardContent
          id={'test'}
          style={{ ...contentStyle }}
          ref={cardContentRef}
          collpase={isCollapse}
        >
          {children}
        </CardContent>
      </CardContentWrapper>
      {bottom && <CardBottomWrapper>{bottom}</CardBottomWrapper>}
    </CardWrapper>
  );
}

export default AuthCard;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardHeaderWrapper = styled.div<{ collpase: string }>`
  display: flex;
  padding-left: 24px;
  padding-right: 12px;
  height: 60px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  ${({ collpase }) =>
    collpase === 'true' && `border-bottom: 1px solid ${colorSet.gray10};`};
  background-color: #fff;
  border: 1px solid ${colorSet.gray10};
`;

const CardBottomWrapper = styled.div`
  display: flex;
  padding: 13px 24px;
  border-radius: 0px 0px 12px 12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colorSet.gray10};
  background-color: #fff;
`;

const CardHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardHeaderRightToggle = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

interface CardContentWrapperProps {
  $collapse: string;
  $bottomExist?: boolean;
  height?: number;
}

const CardContentWrapper = styled.div<CardContentWrapperProps>`
  // transition: all 0.35s ease;
  width: 100%;

  display: flex;
  overflow: hidden;

  border-radius: ${({ $bottomExist }) =>
    $bottomExist ? '0px' : '0px 0px 20px 20px'};

  ${({ $collapse, height }) =>
    $collapse === 'true'
      ? css`
          height: 100%;
          animation: ${keyframes`
				0% {
					height: 0;
				}
				100% {
					height: ${height}px;
				}
			`} 0.35s;
        `
      : css`
          height: 0;
          animation: ${keyframes`
				0% {
					height: ${height}px;
				}
				100% {
					height: 0;
				}
			`} 0.35s;
        `}
`;

interface CardContentProps {
  collpase: boolean;
}

const CardContent = styled.div<CardContentProps>`
  transition: all 0.35s ease;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;
  display: flex;
  height: fit-content;
  align-items: center;

  flex: 100%;
`;

interface ArrowIconProps {
  $collapse: string;
}

const ArrowIcon = styled(ArrowUp)<ArrowIconProps>`
  width: 24px;
  height: 24px;
  transition-property: 'height, visibility';
  transition-duration: 0.35s;
  transition-timing-function: ease;
  ${({ $collapse }) =>
    $collapse === 'true'
      ? `transform: rotate(0deg);`
      : `transform: rotate(180deg);`}
`;

interface IconProps {
  isEditable?: boolean;
}

const HandleIcon = styled(Handle)<IconProps>`
  ${({ isEditable }) =>
    isEditable ? `cursor: pointer;` : `cursor: not-allowed`};
  &:hover > path {
    ${({ isEditable }) => (isEditable ? `fill: ${colorSet.gray2};` : ``)}
  }
`;

const CardTitle = styled(Typo)`
  ${typo.h3}
  ${MediaQuery.FROM_PHONE} {
    ${typo.h6}
  }
  display: flex;
  align-items: center;
  gap: 12px;
`;
