import React from 'react';
import {
  Pagination as AntdPagination,
  PaginationProps as AntdPaginationProps,
} from 'antd';

function Pagination(props: AntdPaginationProps) {
  return <AntdPagination {...props} />;
}

export default Pagination;
