import { AppError, Row, Rows, request } from '@networks/index';
import { PartnerAssistantDto, PartnerAssistantLanguage } from '@types';
import { isAxiosError } from 'axios';

const URL = {
  ASSISTANTS: '/admin/partners/{partnerId}/consultation/assistants',
};

export const requestAssistants = async (params: { partnerId: number }) => {
  const { partnerId } = params;
  try {
    const response = await request<Rows<PartnerAssistantDto>>({
      method: 'get',
      url: URL.ASSISTANTS.replace('{partnerId}', String(partnerId)),
    });

    return response?.data;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      return { rows: [], count: 0 };
    }

    throw new AppError(err);
  }
};

export const requestCreateAssistant = async (params: {
  partnerId: number;
  assistantId: string;
  language: PartnerAssistantLanguage;
}) => {
  const { partnerId, assistantId, language } = params;
  try {
    const response = await request<Row<PartnerAssistantDto>>({
      method: 'post',
      url: URL.ASSISTANTS.replace('{partnerId}', String(partnerId)),
      requestBody: {
        assistantId,
        language,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const requestEditAssistant = async (params: {
  partnerId: number;
  assistantId?: string;
  language?: PartnerAssistantLanguage;
}) => {
  const { partnerId, assistantId, language } = params;
  try {
    const response = await request<void>({
      method: 'put',
      url: URL.ASSISTANTS.replace('{partnerId}', String(partnerId)),
      requestBody: {
        assistantId,
        language,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const requestDeleteAssistant = async (params: {
  partnerId: number;
  language: PartnerAssistantLanguage;
}) => {
  const { partnerId, language } = params;
  try {
    const response = await request<void>({
      method: 'delete',
      url: URL.ASSISTANTS.replace('{partnerId}', String(partnerId)),
      requestBody: {
        language,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};
