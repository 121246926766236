import React from 'react';
import { Modal } from '@components/atoms';
import Input from '@components/v2/antd/data-entry/Input';
import Select from '@components/v2/antd/data-entry/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled } from 'styled-components';
import { FormItem } from '@components/molecules';
import { MarketingChannel } from '@types';
import { CALL_NUM_REGEX } from 'utils/format';
import { MARKETING_CHANNEL_TO_KOREAN } from 'constant';
import { useMutation, useQueryClient } from 'react-query';
import { requestCreateVirtualCall } from '@apis/virtual-calls';
import { showResponseToast } from '@networks/utils';
import { virtualCallsQueryKeys } from '@apis/virtual-calls/queryKeys';
import { useToastStore } from '@store/useToastStore';
import { AppError } from '@networks/index';

const formValidationSchema = yup.object({
  marketingChannel: yup.string().required('마케팅 채널을 선택해주세요'),
  receivingNumber: yup
    .string()
    .matches(CALL_NUM_REGEX, '착신번호를 다시 확인해주세요.')
    .required(),
});

export interface AddVirtualCallModalProps {
  partnerId: number;
  onClose?: () => void;
}

const CHANNEL_OPTIONS = Object.entries(MARKETING_CHANNEL_TO_KOREAN).map(
  ([value, label]) => ({ label, value }),
);

function AddVirtualCallModal(props: AddVirtualCallModalProps) {
  const { onClose, partnerId } = props;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: requestCreateVirtualCall,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        virtualCallsQueryKeys.partner(partnerId),
      );
      onClose?.();
    },
  });

  const formik = useFormik<{
    virtualCallName: string | undefined;
    marketingChannel: MarketingChannel | undefined;
    receivingNumber: string;
  }>({
    initialValues: {
      marketingChannel: undefined,
      receivingNumber: '',
      virtualCallName: undefined,
    },
    onSubmit: (values) => {
      if (values.marketingChannel === undefined) return;

      mutate({
        partnerId,
        marketingChannel: values.marketingChannel,
        receivingNumber: values.receivingNumber.replace(/-/g, ''),
        virtualCallName: values.virtualCallName
          ? values.virtualCallName
          : MARKETING_CHANNEL_TO_KOREAN[values.marketingChannel],
      });
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  return (
    <Modal
      size={560}
      confirmLabel="확인"
      cancelLabel="취소"
      disabled={!formik.isValid || isLoading}
      title="가상번호 등록"
      onConfirm={async () => {
        await formik.submitForm();
      }}
      onClose={() => {
        onClose?.();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          <FormItem label={'마케팅 채널'}>
            <Select
              style={{ width: '100%' }}
              autoFocus
              allowClear
              options={CHANNEL_OPTIONS}
              onChange={(value) => {
                formik.setFieldValue('marketingChannel', value);
              }}
              placeholder={'마케팅 채널을 선택해주세요'}
            />
          </FormItem>
          <FormItem label={'착신번호'}>
            <Input
              onChange={(ev) => {
                formik.setFieldValue('receivingNumber', ev.target.value);
              }}
              placeholder={'착신번호를 입력해주세요'}
            />
          </FormItem>
          <FormItem label={'가상번호명'} optional>
            <Input
              onChange={(ev) => {
                formik.setFieldValue('virtualCallName', ev.target.value);
              }}
              placeholder={'가상번호명을 입력해주세요'}
            />
          </FormItem>
        </FormLayout>
      </form>
    </Modal>
  );
}

export default AddVirtualCallModal;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
