/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input, Typo } from '@components/atoms';
import { styled } from 'styled-components';
import { ReactComponent as Check } from '@assets/svg/Check.svg';
import { ReactComponent as Close } from '@assets/svg/Close.svg';
import { ReactComponent as PenFill } from '@assets/svg/Pen-fill.svg';
import { FontSizeType } from '@components/atoms/Typo/types';
import colorSet from '@styles/colors';

export interface IsEditableProps {
  idx: number;
  editable: boolean;
}

export interface HandleInputProps {
  isError: boolean;
  onChange?: (value: string) => void;
}

interface EditorInputProps {
  text?: string;
  id?: number;
  placeholder?: string;
  typoType?: FontSizeType;
  onChangeText?: (str: string) => void;
  onSubmit?: (str: string, id?: number) => void;
  type?: string;
  disabled?: boolean;
  isEditable?: IsEditableProps;
  setIsEditable?: Dispatch<SetStateAction<IsEditableProps>>;
  handleInput?: HandleInputProps;
}

function EditorInput({
  text,
  id,
  placeholder,
  typoType = 'h3',
  onChangeText,
  onSubmit,
  type,
  disabled,
  isEditable,
  setIsEditable,
  handleInput,
}: EditorInputProps) {
  const [isEditor, setIsEditor] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>(text as '');
  const [isBlur, setIsBlur] = useState<boolean>(false);
  const InputRef = useRef<HTMLInputElement>(null);
  const handelIsEditor = useCallback(() => {
    if (!isEditable?.editable) {
      setIsEditor(!isEditor);
      InputRef.current?.focus();
      setIsEditable?.({ idx: id as 0, editable: true });
    }
  }, [setIsEditor, isEditor, setIsEditable, isEditable, id]);

  const handleSubmit = () => {
    if (inputText !== '' || type === 'EVENT') {
      if (onSubmit) onSubmit(inputText, id);
      setIsEditor(false);
      setIsEditable?.((prev) => {
        return { idx: prev.idx, editable: false };
      });
    }
  };

  const handelIsEditorClose = () => {
    if (text !== '') {
      setIsEditor(false);
      setIsEditable?.((prev) => {
        return { idx: prev.idx, editable: false };
      });
    }
  };

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeText) onChangeText(event.target.value);
    setInputText(event.target.value);

    if (handleInput?.onChange) {
      handleInput.onChange(event.target.value);
    }
  };

  const handleInputOnKeyPress = (str: string) => {
    if (onSubmit) onSubmit(str, id);
    setIsEditor(false);
  };

  useEffect(() => {
    if (inputText === '' && type !== 'EVENT') {
      setIsEditor(true);
    }
  }, [inputText, type]);

  useEffect(() => {
    if (isEditor) {
      InputRef?.current?.focus();
    }
  }, [isEditor]);

  if (type === 'EVENT') {
    return (
      <InputWrpeer>
        {!isEditor && (
          <>
            <Typo typoType="b9">
              {text === `` ? '한정수량 없음' : `재고 ${text}`}
            </Typo>
            {disabled && (
              <PenFillBox onClick={handelIsEditor}>
                <PenIcon width={20} height={20} />
              </PenFillBox>
            )}
          </>
        )}
        {isEditor && !disabled && (
          <Typo typoType="b9">
            {text === `` ? '한정수량 없음' : `재고 ${text}`}
          </Typo>
        )}
        {isEditor && disabled && (
          <>
            <Input
              ref={InputRef}
              placeholder={placeholder}
              value={text}
              onChange={handleInputOnChange}
              // onChangeText={handleInputOnChangeText}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleInputOnKeyPress(inputText);
                }
              }}
            />
            <CheckIcon width={24} height={24} onClick={handleSubmit} />
          </>
        )}
      </InputWrpeer>
    );
  }

  return (
    <InputWrpeer>
      {!isEditor && (
        <>
          <Typo typoType={typoType}>{text}</Typo>
          <PenIcon
            onMouseDown={(e) => e.preventDefault()}
            isEditable={isEditable?.editable}
            onClick={handelIsEditor}
          />
        </>
      )}

      {isEditor && (
        <>
          <Input
            onBlur={() => setIsBlur(true)}
            onFocus={() => setIsBlur(false)}
            ref={InputRef}
            placeholder={placeholder}
            defaultValue={text}
            onChange={handleInputOnChange}
            // onChangeText={handleInputOnChangeText}
            isError={
              handleInput ? handleInput.isError : inputText === '' || isBlur
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (inputText !== '') {
                  handleInputOnKeyPress(inputText);
                }
              }
            }}
          />
          <CheckIcon
            onClick={handleSubmit}
            onMouseDown={(e) => e.preventDefault()}
          />
          <CloseIcon
            onClick={handelIsEditorClose}
            onMouseDown={(e) => e.preventDefault()}
          />
        </>
      )}
    </InputWrpeer>
  );
}

export default EditorInput;

const InputWrpeer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CheckIcon = styled(Check)`
  cursor: pointer;
  &:hover > path {
    fill: ${colorSet.gray2};
  }
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover > g > path {
    fill: #fa362b;
  }
`;

interface PenIconProps {
  isEditable?: boolean;
}

const PenIcon = styled(PenFill)<PenIconProps>`
  ${({ isEditable }) =>
    isEditable ? `cursor: not-allowed` : `cursor: pointer;`};
  &:hover > path {
    ${({ isEditable }) => (isEditable ? `` : `fill: ${colorSet.gray2};`)}
  }
`;

const PenFillBox = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
