export enum BlogConsultationChannel {
  KakaoTalk = 'KAKAO_TALK',
  Tel = 'TEL',
}

export enum BlogPersonaType {
  Kind = 'KIND',
  Witty = 'WITTY',
}

export enum BlogPartnerInfoTranslations {
  KIND = '친절형',
  WITTY = '재치형',
  KAKAO_TALK = '카카오톡',
  TEL = '전화',
}

export const BLOG_CONSULTATION_CHANNEL_OPTIONS = Object.values(
  BlogConsultationChannel,
).map((value) => ({
  label: BlogPartnerInfoTranslations[value],
  value,
}));

export const BLOG_PERSONA_TYPE_OPTIONS = Object.values(BlogPersonaType).map(
  (value) => ({
    label: BlogPartnerInfoTranslations[value],
    value,
  }),
);
