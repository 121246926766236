import colorSet from '@styles/colors';
import React, {
  // ForwardedRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  RefObject,
  // RefObject,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { css, styled } from 'styled-components';
import typo from '../Typo/types';
import { ReactComponent as SearchSvg } from '../../../assets/svg/Search.svg';
import { ReactComponent as Warning } from '../../../assets/svg/Warning-triangle.svg';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputwidth?: number;
  isSearch?: boolean;
  isError?: boolean;
  errIcon?: boolean;
  isValid?: boolean;
  onClear?: () => void;
  validationType?: 'ERROR' | 'VALID';
  validationMsg?: string;
  isDropDown?: boolean;
}

/**
 * @param isValid - validation
 * @param isError - error validation
 * @param isSearch - search icon
 * @param onClear - claer function
 */
const Input = forwardRef<HTMLInputElement | null, InputProps>(
  (
    {
      value,
      type,
      inputwidth,
      isSearch,
      isError,
      errIcon = true,
      isValid,
      onClear,
      isDropDown,
      ...rest
    },
    ref,
  ) => {
    const isClear = useMemo(() => !!onClear && !!value, [onClear, value]);

    const [visible, setVisible] = useState(false);
    const [customType, setCustomType] = useState<
      HTMLInputTypeAttribute | undefined
    >(undefined);
    const memoizedType = useMemo(() => customType || type, [customType, type]);
    return (
      <InputView inputwidth={inputwidth}>
        <StyledInput
          ref={ref}
          autoComplete="off"
          value={value}
          $isError={isError}
          $isValid={isValid}
          inputwidth={inputwidth}
          $isPrefix={isSearch}
          $isPasswordType={type === 'password'}
          $isSuffix={isClear}
          type={memoizedType}
          {...rest}
        />
        {isSearch && <SearchIcon />}
        {isError && errIcon && <WarningIcon />}
        {onClear && value && (
          <ClearIcon
            $passwordType={type === 'password'}
            className="clear-icon"
            onClick={() => {
              onClear?.();
              if (ref) {
                (ref as RefObject<HTMLInputElement>).current?.focus();
              }
            }}
          />
        )}
        {type === 'password' && (
          <VisibleIcon
            $visible={visible}
            className="clear-icon"
            onClick={() => {
              if (visible) {
                setVisible(false);
                setCustomType(type);
              } else {
                setVisible(true);
                setCustomType('text');
              }
            }}
          />
        )}
      </InputView>
    );
  },
);

export default Input;

const InputView = styled.div<{ inputwidth?: number }>`
  position: relative;
  width: ${({ inputwidth }) => (inputwidth ? `${inputwidth}px` : '100%')};
`;

const StyledInput = styled.input<{
  inputwidth?: number;
  $isError?: boolean;
  $isValid?: boolean;
  $isPrefix?: boolean;
  $isSuffix?: boolean;
  $isPasswordType?: boolean;
  disabled?: boolean;
}>`
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: ${({ $isPasswordType, $isSuffix }) => {
    if ($isPasswordType && $isSuffix) return 76;
    if ($isPasswordType || $isSuffix) return 44;
    return 12;
  }}px;
  padding-left: ${({ $isPrefix }) => {
    if ($isPrefix) return 44;
    return 16;
  }}px;
  width: ${({ inputwidth }) => (inputwidth ? `${inputwidth}px` : '100%')};
  height: 40px;
  min-height: 40px;

  border-radius: 10px;
  transition: 0.3s all;
  ${typo.b9};

  -webkit-appearance: none;
  -moz-appearance: none;

  appearance: none;

  &:focus {
    outline: none;
    border: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${colorSet.gray2};
    -webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.3) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    color: ${colorSet.gray7};
  }

  &:focus-within,
  &:focus-within:hover {
    border: 1px solid ${colorSet.primary3};
    box-shadow: 0 0 0 2px ${colorSet.primary8};
  }

  &:focus-within:focus + svg > path {
    ${({ $isError }) => ($isError ? `` : `fill: ${colorSet.primary3};`)}
  }

  border: ${({ disabled, $isError, $isValid }) => {
    if ($isError) return `1px solid ${colorSet.red3} !important`;
    if (disabled) return 'none';

    if ($isValid) return `1px solid ${colorSet.primary3}`;

    return `1px solid ${colorSet.gray9}`;
  }};

  box-shadow: ${({ $isError, $isValid }) => {
    if ($isError) return `0 0 0 2px ${colorSet.red7} !important`;
    if ($isValid) return `0 0 0 2px ${colorSet.primary8}`;

    return 'none';
  }};
`;

const iconStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchIcon = styled(SearchSvg)`
  ${iconStyles};
  left: 12px;
  width: 24px;
  height: 24px;
`;

const WarningIcon = styled(Warning)`
  ${iconStyles};
  right: 12px;
  width: 20px;
  height: 20px;
`;

const ClearIcon = styled.figure<{ $passwordType?: boolean }>`
  ${iconStyles};
  right: ${({ $passwordType }) => ($passwordType ? 44 : 12)}px;
  width: 20px;
  height: 20px;
  background: url('/assets/icons/icon-close_fill.svg') no-repeat center center /
    contain;
  &:hover {
    cursor: pointer;
  }
`;

const VisibleIcon = styled.figure<{ $visible?: boolean }>`
  ${iconStyles};
  right: 12px;
  width: 20px;
  height: 20px;
  background: url('/assets/icons/icon-visible_off.svg') no-repeat center center /
    contain;
  ${({ $visible }) => {
    if ($visible)
      return css`
        background: url('/assets/icons/icon-visible_off.svg') no-repeat center
          center / contain;
      `;
    return css`
      background: url('/assets/icons/icon-visible_on.svg') no-repeat center
        center / contain;
    `;
  }}
  &:hover {
    cursor: pointer;
  }
`;
