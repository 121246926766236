import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntdDatePicker, ConfigProvider, GetProps } from 'antd';
import locale from 'antd/lib/date-picker/locale/ko_KR';
import { colorPrimaryTheme } from 'utils/antdPrimaryColorTheme';
import colorSet from '@styles/colors';
import datePickerLocale from '@components/utils/antd-date-picker-locale';

export interface DatePickerProps extends GetProps<typeof AntdDatePicker> {}

function DatePicker(props: DatePickerProps) {
  return (
    <ConfigProvider theme={colorPrimaryTheme}>
      <StyledAntdDatePicker
        // @ts-ignore
        locale={datePickerLocale}
        clearIcon={
          <img
            src={'/assets/icons/icon-close_fill.svg'}
            width={20}
            height={20}
            alt="input-clear"
          />
        }
        suffixIcon={
          props?.value ? (
            <img
              src={'/assets/icons/icon-close_fill.svg'}
              width={20}
              height={20}
              alt="input-clear"
            />
          ) : (
            <img
              src={'/assets/icons/icon_calendar.svg'}
              width={20}
              height={20}
              alt="suffixIcon"
            />
          )
        }
        {...props}
      />
    </ConfigProvider>
  );
}

export default DatePicker;

const StyledAntdDatePicker = styled(AntdDatePicker)`
  .ant-picker-input > input {
    font-size: 15px;
    line-height: 22px;
    color: ${colorSet.gray2};

    &::placeholder {
      color: ${colorSet.gray7};
    }
  }

  &:hover {
    border: 1px solid ${colorSet.primary3};
  }

  :where(.css-dev-only-do-not-override-mxhywb).ant-picker-focused {
    border: 1px solid ${colorSet.primary3};
    box-shadow: 0 0 0 2px ${colorSet.primary8};
  }

  :where(.css-dev-only-do-not-override-14dj3yf).ant-picker-dropdown
    .ant-picker-today-btn {
    color: red;
  }

  padding: 9px 16px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};

  &:hover {
    border: 1px solid ${colorSet.primary3};
  }
  &:focus-within {
    border: 1px solid ${colorSet.primary3};
    box-shadow: 0 0 0 2px ${colorSet.primary8};
  }
  & .ant-picker .ant-picker-clear {
    right: 0;
  }
`;
