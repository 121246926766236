import { css } from 'styled-components';
import { IconButtonSize } from './types';

// Icon size
export const getIconSize = (size?: IconButtonSize) => {
  if (size === 'sm') {
    return css`
      width: 20px;
      height: 20px;
    `;
  }
  return css`
    width: 24px;
    height: 24px;
  `;
};
