import { Button, Input, Typo, typo, Validation } from '@components/atoms';
import { INITIAL_PRIVATE_ROUTE } from '@routes/constants';
import { useAuthStore } from '@store/useAuthStore';
import { useAgencyStore } from '@store/useAgencyStore';
import { usePartnerStore } from '@store/usePartnerStore';
import { MediaQuery } from '@styles/mediaQuery';
import colorSet from '@styles/colors';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { signIn } from '../../../@apis/administrators';
import { fetchAgencies } from '../../../@apis/agencies';
import { fetchPartners } from '../../../@apis/partners';

const INIT = { administratorAId: '', password: '' };
const VALID_INIT = { administratorAId: '', password: '' };

function LoginPage() {
  const { loginUser } = useAuthStore();
  const [requesting, setRequesting] = useState(false);
  const [saveLoginUser] = useAuthStore((state) => [state.saveLoginUser]);
  const [saveAgencies] = useAgencyStore((state) => [state.saveAgencies]);
  const [savePartners] = usePartnerStore((state) => [state.savePartners]);

  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState(INIT);
  const [validationForm, setValidationForm] =
    useState<typeof VALID_INIT>(VALID_INIT);

  useEffect(() => {
    if (loginUser) {
      navigate(INITIAL_PRIVATE_ROUTE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestSignIn = async () => {
    return signIn({
      administratorAId: formData.administratorAId,
      password: formData.password,
    });
  };

  const agencyProcess = async () => {
    const { rows } = await fetchAgencies({
      page: 1,
      pageSize: 1000,
    });
    saveAgencies(rows);
  };

  const partnerProcess = async () => {
    const { rows } = await fetchPartners({
      page: 1,
      pageSize: 1000,
    });
    savePartners(rows);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setRequesting(true);
    try {
      const { row: loginUser } = await requestSignIn();
      saveLoginUser(loginUser);
      agencyProcess();
      partnerProcess();
      navigate(INITIAL_PRIVATE_ROUTE, { replace: true });
    } catch (e) {
      console.error(e);
    } finally {
      setRequesting(false);
    }
  };

  // onChange
  const handleIdChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, administratorAId: e.target.value }));
    setValidationForm((prev) => ({ ...prev, administratorAId: '' }));
  }, []);
  const handlePwdChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, password: e.target.value }));
    setValidationForm((prev) => ({ ...prev, password: '' }));
  }, []);

  // onClear
  const handleIdClear = useCallback(() => {
    emailRef?.current?.focus();
    setFormData((prev) => ({ ...prev, administratorAId: '' }));
  }, []);
  const handlePwdClear = useCallback(() => {
    passwordRef?.current?.focus();
    setFormData((prev) => ({ ...prev, password: '' }));
  }, []);

  const handleAccountIdKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      passwordRef.current?.focus();
    }
  };

  const disabled = useMemo(
    () =>
      !formData.administratorAId ||
      !formData.password ||
      !!validationForm.password ||
      !!validationForm.administratorAId,
    [formData, validationForm],
  );

  return (
    <LoginBackground>
      <LoginLayout>
        <FormLayout>
          <LogoImage
            src="/assets/icons/icon-logo_synbol_login.svg"
            alt="logo"
          />
          <Form onSubmit={handleSubmit}>
            <section>
              <FormItemView>
                <FormLabelText>아이디</FormLabelText>
                <Input
                  ref={emailRef}
                  id="administratorAId"
                  name="administratorAId"
                  value={formData.administratorAId}
                  onClear={handleIdClear}
                  onChange={handleIdChange}
                  placeholder="아이디를 입력해주세요"
                  isError={!!validationForm.administratorAId}
                  onKeyDown={handleAccountIdKeyDown}
                />
                {!!validationForm.administratorAId && (
                  <InputValidation validationType="ERROR">
                    {validationForm.administratorAId}
                  </InputValidation>
                )}
              </FormItemView>
              <FormItemView>
                <FormLabelText>비밀번호</FormLabelText>
                <Input
                  ref={passwordRef}
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  isError={!!validationForm.password}
                  onClear={handlePwdClear}
                  onChange={handlePwdChange}
                  placeholder="비밀번호를 입력해주세요"
                />
                {!!validationForm.password && (
                  <InputValidation validationType="ERROR">
                    {validationForm.password}
                  </InputValidation>
                )}
              </FormItemView>
            </section>

            <Button
              type="submit"
              buttonSize={56}
              onClick={handleSubmit}
              style={{ maxWidth: '100%' }}
              aria-selected
              disabled={disabled || requesting}
            >
              로그인
            </Button>
          </Form>
        </FormLayout>
      </LoginLayout>
    </LoginBackground>
  );
}

export default LoginPage;

const LoginBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: ${colorSet.gray12};
  overflow: auto;

  ${MediaQuery.FROM_PHONE} {
    background: none;
  }
`;

const LoginLayout = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${MediaQuery.FROM_PHONE} {
    position: static;
    left: 0;
    top: 0;
    transform: none;
  }
`;

const FormLayout = styled.div`
  width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px 80px;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 32px rgba(34, 37, 41, 0.12);
  border-radius: 20px;

  ${MediaQuery.FROM_PHONE} {
    width: auto;
    min-height: 100vh;
    overflow: auto;
    padding: 80px 40px;
    box-shadow: none;
  }
`;

const LogoImage = styled.img`
  width: 128px;
  height: 100px;
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 320px;

  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  ${MediaQuery.FROM_PHONE} {
    width: 100%;
  }
`;

const FormItemView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormLabelText = styled(Typo)`
  ${typo.b11}
  color: ${colorSet.gray6};
`;

const InputValidation = styled(Validation)`
  margin-top: 6px;
`;
