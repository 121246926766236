import React, { useState } from 'react';
import { Modal } from '@components/atoms';
import { useQuery } from 'react-query';
import { Empty, Image } from 'antd';
import Gallery from 'react-photo-gallery';
import colorSet from '@styles/colors';
import { fetchAIModelBaseImages } from '../../../../../@apis/ai-model-base-images';
import { AIModelBaseImage } from '../../../../../@types/ai-model-base-image/models/AIModelBaseImage';
import { AIModelImageInspectionStatus } from '../../../../../@types/ai-model-base-image/constants/ai-model-image.constants';

export interface AIModelImageGenerationModalProps {
  onCancel?: () => void;
  onImageSelect?: (string: string) => void;
}
function AIModelBaseImageSelectionModal(
  props: AIModelImageGenerationModalProps,
) {
  const [rows, setRows] = useState<AIModelBaseImage[]>([]);
  const { onCancel, onImageSelect } = props;
  const { isFetching } = useQuery(
    ['fetchAIModelBaseImages'],
    () => {
      return fetchAIModelBaseImages({
        page: 1,
        pageSize: 1000,
        imageStatus: AIModelImageInspectionStatus.Approved,
      });
    },
    {
      onSuccess: (res) => {
        setRows([...res.rows]);
      },
      onError: () => {
        setRows([]);
      },
      enabled: true,
    },
  );

  const handleModalClose = () => {
    if (onCancel) onCancel();
  };

  const renderEmptyView = () => {
    if (!isFetching && rows.length === 0) {
      return (
        <div
          style={{
            padding: '20px 60px',
          }}
        >
          <Empty description="등록된 기준 이미지가 없습니다" />
        </div>
      );
    }

    return '';
  };

  return (
    <Modal
      size={680}
      title="AI 모델 기준 이미지 선택"
      onClose={handleModalClose}
      onCancel={handleModalClose}
      hideFooter
    >
      {renderEmptyView()}
      <Gallery
        margin={4}
        photos={rows.map(({ id, imageUrl, imageStatus }) => {
          return {
            key: `${id}`,
            src: imageUrl,
            width: 5,
            height: 5,
            imageStatus,
          };
        })}
        renderImage={({ photo, margin }) => {
          const { src, key } = photo as any;
          return (
            <div
              key={key}
              style={{
                margin,
                minWidth: 100,
                position: 'relative',
              }}
            >
              <div
                style={{
                  border: `1px solid ${colorSet.gray11}`,
                  borderRadius: '10px',
                }}
              >
                <Image
                  onClick={() => {
                    if (onImageSelect) onImageSelect(src);
                    if (onCancel) onCancel();
                  }}
                  style={{
                    aspectRatio: 'auto',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  preview={false}
                  width={200}
                  src={src}
                  alt="AI 모델"
                />
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
}

export default AIModelBaseImageSelectionModal;
