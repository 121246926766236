import { AppError, request, Rows } from '@networks/index';
import { AIModelBaseImage } from '../@types/ai-model-base-image/models/AIModelBaseImage';
import { FetchAIModelBaseImageListQuery } from '../@types/ai-model-base-image/params/FetchAIModelImageListQuery';
import { UpdateAIModelBaseImageBody } from '../@types/ai-model-base-image/params/UpdateAIModelBaseImageBody';

const API_ROOT = 'admin/v1/ai-model-base-images';

/**
 * AI 모델 기준 이미지 업로드
 */
export const uploadAIModelBaseImages = async (
  images: File[],
  searchTags?: string[],
) => {
  try {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('image', image);
    });
    if (searchTags) {
      searchTags.forEach((tag) => {
        formData.append('searchTags[]', tag);
      });
    }
    const { data } = await request<Rows<AIModelBaseImage>>({
      method: 'post',
      url: API_ROOT,
      requestBody: formData,
      isMultipart: true,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 기준 이미지 리스트 조회
 */
export const fetchAIModelBaseImages = async (
  query: FetchAIModelBaseImageListQuery,
) => {
  try {
    const { data } = await request<Rows<AIModelBaseImage>>({
      method: 'get',
      url: API_ROOT,
      queryParams: {
        ...query,
        rand: new Date().getTime(),
      },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 기준 이미지 정보 수정
 */
export const updateAIModelBaseImage = async (
  id: number | string,
  body: UpdateAIModelBaseImageBody,
) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

/**
 * AI 모델 기준 이미지 삭제
 */
export const deleteAIModelBaseImage = async (id: number | string) => {
  try {
    const url = `${API_ROOT}/${id}`;
    const { data } = await request({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
