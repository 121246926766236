import axios, { AxiosResponse, ResponseType } from 'axios';
import { get, isEqual, set } from 'lodash';
import qs from 'qs';

import { useErrorNavigate } from '@store/useErrorNavigate';
import bcrypt from 'bcryptjs-react';
import { useAuthStore } from '@store/useAuthStore';
import { useToastStore } from '@store/useToastStore';
import { RequestParams } from './types';
import { showResponseToast } from './utils';

const HASH_KEY = 'qntmxmfleoqkrskfk';
const salt = bcrypt.genSaltSync();
const roleKey = bcrypt.hashSync(HASH_KEY, salt);

const DEV_URL = process.env.REACT_APP_API_BASE_URL;
// const DEV_URL = 'https://be-api-dev.boostree.partners/';
// const DEV_URL = 'http://localhost:4001/';
const { showToast } = useToastStore.getState();

export const client = axios.create({
  // Timeout 10분
  timeout: 600000,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
  baseURL: DEV_URL,
});

client.interceptors.request.use((config) => {
  const { token } = useAuthStore.getState();

  if (token) {
    set(config, 'headers.authorization', token);
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    const { headers } = response;

    const token = get(headers, 'authorization') as string | undefined;

    if (token) {
      useAuthStore.getState().saveToken(token);
    }
    return response;
  },
  (err) => {
    const {
      status,
      data,
      config: { method },
    } = err.response;
    if (isEqual(method, 'get')) {
      // get 분기
      if (isEqual(status, 403)) {
        showResponseToast(data);
        useErrorNavigate.setState({ url: '/' });
      }
    } else if (!(isEqual(data.statusCode, 404) && isEqual(method, 'get'))) {
      // 404 에러 핸들링
      if (isEqual(status, 401)) {
        showResponseToast(data);
        useAuthStore.setState({
          loginUser: undefined,
          token: undefined,
        });
      } else if (isEqual(status, 403)) {
        showResponseToast(data);
      } else if (!status) {
        showToast({
          description: '서버와의 연결이 올바르지 않습니다',
          status: 'Red',
        });
      } else {
        showResponseToast(data);
      }
    }
    return Promise.reject(err);
  },
);

export const request = async <T>({
  method,
  url,
  queryParams,
  requestBody,
  isMultipart,
  responseType,
  hasRoleKey,
}: RequestParams): Promise<AxiosResponse<T>> => {
  let headers: Record<string, any> = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  };
  if (isMultipart) {
    headers = { ...headers, 'Content-Type': 'multipart/form-data' };
  }
  let myResponseType: ResponseType = 'json';
  if (responseType) myResponseType = responseType;

  switch (method) {
    case 'get':
      if (hasRoleKey) {
        headers = { ...headers, 'Role-Key': roleKey };
      }

      return client.get(url, {
        params: queryParams,
        headers,
        responseType: myResponseType,
      });
    case 'post':
      return client.post(url, requestBody, { params: queryParams, headers });
    case 'put':
      return client.put(url, requestBody, { params: queryParams, headers });
    case 'delete':
      return client.delete(url, { data: requestBody, params: queryParams });
    default:
      return Promise.reject(new Error('Invalid HttpMethod'));
  }
};

export const revaliRequest = async <T>({
  method,
  url,
  queryParams,
  requestBody,
  isMultipart,
  responseType,
}: RequestParams): Promise<AxiosResponse<T>> => {
  let headers = {};
  if (isMultipart) {
    headers = { 'Content-Type': 'multipart/form-data' };
  }
  headers = {
    ...headers,
    'Access-Control-Allow-Origin': '*',
  };

  let myResponseType: ResponseType = 'json';
  if (responseType) myResponseType = responseType;

  switch (method) {
    case 'get':
      return client.get(url, {
        params: queryParams,
        headers,
        responseType: myResponseType,
      });
    case 'post':
      return client.post(url, requestBody, { params: queryParams, headers });
    case 'put':
      return client.put(url, requestBody, { params: queryParams, headers });
    case 'delete':
      return client.delete(url, { data: requestBody, params: queryParams });
    default:
      return Promise.reject(new Error('Invalid HttpMethod'));
  }
};

export { default as AppError } from './AppError';
export * from './types';
