import React, { useState } from 'react';
import { SectionTab } from 'polotno/side-panel';
import { SectionTabProps } from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/types';
import { FormLayout } from '@components/utils/layout-utils';
import { Form, Typography } from 'antd';
import { StoreType } from 'polotno/model/store';
import Button from '@components/v2/antd/general/Button';
import PolotnoFontSelect from '@pages/private/ai-design/ai-design-templates-v2/components-v2/polotno-addon/common/PolotnoFontSelect';
import {
  BASIC_FONTS,
  CUSTOM_FONTS,
  DEFAULT_BRAND_FONT_FAMILIES,
  DEFAULT_NORMAL_FONT_FAMILIES,
} from '@pages/private/ai-design/ai-design-templates-v2/utils/polotno-font.utils';
import { FontColorsOutlined } from '@ant-design/icons';
import {
  getRandomFromStringArray,
  overrideTheme,
} from '@pages/private/ai-design/ai-design-templates-v2/utils/ai-design-theme.utils';
import { isArray } from 'lodash';

export function FontThemeEditorTab(props: SectionTabProps): React.JSX.Element {
  return (
    <SectionTab name="폰트 설정" {...props}>
      <FontColorsOutlined
        style={{
          fontSize: 20,
        }}
      />
    </SectionTab>
  );
}
FontThemeEditorTab.displayName = '';

export interface FontEditorPanelProps {
  store: StoreType;
  onFontChange?: (mainFonts: string[], subFonts: string[]) => void;
  randomMainFontFamilies?: string[];
  randomSubFontFamilies?: string[];
}
export const FontEditorPanel = (props: FontEditorPanelProps) => {
  const {
    store,
    onFontChange,
    randomMainFontFamilies = [],
    randomSubFontFamilies = [],
  } = props;

  const [mainFonts, setMainFonts] = useState<string[]>(randomMainFontFamilies);
  const [subFonts, setSubFonts] = useState<string[]>(randomSubFontFamilies);

  const handleMainFontUpdate = (fonts: string[]) => {
    setMainFonts(fonts);
    if (onFontChange) {
      onFontChange(fonts, subFonts);
    }
  };

  const handleSubFontUpdate = (fonts: string[]) => {
    setSubFonts(fonts);
    if (onFontChange) {
      onFontChange(mainFonts, fonts);
    }
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: 8,
        }}
      >
        <Button
          onClick={() => {
            const main = getRandomFromStringArray(mainFonts);
            const sub = getRandomFromStringArray(subFonts);
            const json: any = store.toJSON();
            const newJson = overrideTheme(json, {
              mainFontFamily: main,
              subFontFamily: sub,
            });
            store.loadJSON(newJson, true);
          }}
        >
          적용 테스트
        </Button>
        <Button
          type="default"
          onClick={() => {
            const subFontFamilies = DEFAULT_NORMAL_FONT_FAMILIES;
            const mainFontFamilies = DEFAULT_BRAND_FONT_FAMILIES;

            setMainFonts(mainFontFamilies);
            setSubFonts(subFontFamilies);

            if (onFontChange) {
              onFontChange(mainFontFamilies, subFontFamilies);
            }
          }}
        >
          기본값 적용
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <FormLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                paddingLeft: 4,
              }}
            />

            <div>
              <Typography.Title level={5}>폰트 적용 범위 설정</Typography.Title>
              <Form.Item
                required
                label="브랜드 폰트 리스트"
                labelCol={{ span: 24 }}
              >
                <PolotnoFontSelect
                  mode="multiple"
                  value={mainFonts}
                  fonts={CUSTOM_FONTS}
                  placeholder="랜덤으로 적용될 폰트를 선택해주세요"
                  onChange={(value) => {
                    if (isArray(value)) {
                      handleMainFontUpdate(value);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                required
                label="본문 폰트 리스트"
                labelCol={{ span: 24 }}
              >
                <PolotnoFontSelect
                  mode="multiple"
                  value={subFonts}
                  fonts={BASIC_FONTS}
                  placeholder="랜덤으로 적용될 폰트를 선택해주세요"
                  onChange={(value) => {
                    if (isArray(value)) {
                      handleSubFontUpdate(value);
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </FormLayout>
      </div>
    </div>
  );
};
