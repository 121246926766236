import { fetchGlobalPromptRoles } from '@apis/global-prompts';
import { useQuery } from 'react-query';

export const useGlobalPromptRoleOptions = () => {
  return useQuery({
    queryKey: ['globalPrompt', 'roles'],
    queryFn: () => fetchGlobalPromptRoles(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.rows.map((d) => ({ label: d.roleName, value: d.role })),
  });
};
