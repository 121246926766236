import { AppError, request } from '@networks/index';
import { CreateAdsCampaignInput } from './types';

export const createAdsCampaign = async (
  requestBody: CreateAdsCampaignInput,
) => {
  try {
    const { status } = await request({
      method: 'post',
      url: '/admin/advertising-campaigns',
      requestBody,
    });
    return status;
  } catch (e) {
    throw new AppError(e);
  }
};
