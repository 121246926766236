import React, { useEffect } from 'react';
import { Typo } from '@components/atoms';
import { TextButton } from '@components/atoms/MinorButton';
import { styled } from 'styled-components';
import colorSet from '@styles/colors';
import { useFormik } from 'formik';
import { FilterType } from '@components/organisms/TableFilter/components/FilterAccordion/types';
import FilterAccordion from './components/FilterAccordion';
import { TableFilterProps } from './types';
import { ReactComponent as Reset } from '../../../assets/svg/Reset.svg';

function TableFilter(props: TableFilterProps) {
  const {
    filterForm,
    filterValues = {},
    filterInitialValues = {},
    isOpen,
    handleClose,
    onSearchReset,
    onSearchSubmit,
  } = props;

  const formik = useFormik({
    initialValues: filterInitialValues,
    onSubmit: (values) => {
      if (onSearchSubmit) onSearchSubmit(values);
    },
    onReset: () => {
      if (onSearchReset) onSearchReset();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (filterValues) formik.setValues(filterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const handleFilterChange = async (
    key: string,
    value: any,
    type: FilterType,
  ) => {
    await formik.setFieldValue(key, value);
    if (type === 'radio' || type === 'select' || type === 'checkbox') {
      formik.handleSubmit();
    }
  };

  return (
    <FilterContainer isOpen={isOpen}>
      <FilterHeader>
        <button
          type="button"
          aria-label="닫기"
          className="close icon"
          onClick={handleClose}
        />
      </FilterHeader>
      <FilterFormWrapper onSubmit={formik.handleSubmit}>
        <header>
          <h3>
            <Typo typoType="h3">필터</Typo>
          </h3>
          <TextButton
            onClick={(e) => {
              formik.handleReset(e);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              필터초기화 <Reset />
            </div>
          </TextButton>
        </header>
        {filterForm.map((item) => {
          let value;
          if (typeof formik.values[item.field] === 'boolean') {
            value = formik.values[item.field];
          } else {
            value = formik.values[item.field] || '';
          }

          return (
            <FilterAccordion
              field={item.field}
              label={item.label}
              type={item.type}
              value={value}
              options={item.options}
              onFilterValueChange={handleFilterChange}
              placeholder={item.placeholder}
              fields={item.fields}
            />
          );
        })}
      </FilterFormWrapper>
    </FilterContainer>
  );
}

const FilterContainer = styled.aside<{ isOpen: boolean }>`
  position: sticky;
  top: 0;
  overflow: auto;
  width: ${({ isOpen }) => (isOpen ? '320px' : '0')};
  transition: width 0.3s;
  background: ${colorSet.gray13};
  box-shadow: 0 0 60px rgba(34, 37, 41, 0.06);
`;

const FilterHeader = styled.header`
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 20px 16px;

  > .icon.close {
    display: flex;
    width: 32px;
    height: 32px;
    background: url('/assets/icons/icon-close_gray2.svg') no-repeat center
      center / contain;
  }
`;

const FilterFormWrapper = styled.form`
  width: 320px;

  > header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 20px;

    > h3 {
      flex: 1;
    }
  }
`;

export default TableFilter;
