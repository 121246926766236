/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';

import ReviewQuestionRepeater from '@pages/private/review-config/review-templates/components/ReviewQuestionRepeater';
import { ReviewTemplateForm } from '@pages/private/review-config/review-templates/types/ReviewTemplateForm';
import Select from '@components/v2/antd/data-entry/Select';
import HtmlEditor from '@components/molecules/HtmlEditor';
import {
  addReviewTemplate,
  fetchReviewQuestions,
  fetchReviewTemplateDetail,
  updateReviewTemplate,
} from '../../../../@apis/reviews';
import { ReviewQuestion } from '../../../../@types/review/models/ReviewQuestion';
import { AddReviewTemplateBody } from '../../../../@types/review/params/AddReviewTemplateBody';
import { UpdateReviewTemplateBody } from '../../../../@types/review/params/UpdateReviewTemplateBody';
import { fetchPartners } from '../../../../@apis/partners';

const INITIAL_VALUES: ReviewTemplateForm = {
  partnerId: undefined,
  reviewQuestions: [],
  eventContents: '',
  introMessage: '',
};

function ReviewTemplateDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [questions, setQuestions] = useState<ReviewQuestion[]>([]);
  const [partnerOptions, setPartnerOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const formValidationSchema = yup.object({
    partnerId: yup.lazy(() => {
      if (!id) {
        return yup.number().required();
      }
      return yup.number().optional();
    }),
    reviewQuestions: yup.lazy((value) => {
      return yup.array();
    }),
  });

  const formik = useFormik<ReviewTemplateForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);

      const { reviewQuestions, partnerId, eventContents, introMessage } =
        values;
      const reviewQuestionIds = reviewQuestions
        .filter((reviewQuestion) => !!reviewQuestion)
        .map(({ id }) => id);

      // 수정
      if (id) {
        const body: UpdateReviewTemplateBody = {
          reviewQuestionIds,
          eventContents: eventContents || null,
          introMessage: introMessage || null,
        };
        await updateData(id, body);
      }
      // 등록
      else if (partnerId) {
        const body: AddReviewTemplateBody = {
          introMessage: introMessage || null,
          eventContents: eventContents || null,
          partnerId,
          reviewQuestionIds,
        };
        await addData(body);
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchReviewTemplateDetail(id);
      const { reviewQuestions, partnerId, introMessage, eventContents } = row;

      setFormInitialValues({
        reviewQuestions,
        partnerId,
        introMessage: introMessage || '',
        eventContents: eventContents || '',
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchQuestions = useCallback(async () => {
    try {
      const { rows } = await fetchReviewQuestions({
        page: 1,
        pageSize: 9999,
      });
      setQuestions(rows);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await fetchQuestions();
      await fetchPartnerOptions();

      setDataFetching(false);
    })();
  }, []);

  const fetchPartnerOptions = useCallback(async () => {
    try {
      const { rows } = await fetchPartners({
        page: 1,
        pageSize: 9999,
      });
      setPartnerOptions(
        rows.map(({ partnerName, displayName, id }) => ({
          label: displayName || partnerName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const addData = async (body: AddReviewTemplateBody) => {
    try {
      await addReviewTemplate(body);
      showToast({
        description: '리뷰 템플릿이 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateReviewTemplateBody,
  ) => {
    try {
      await updateReviewTemplate(id, body);
      showToast({
        description: '리뷰 템플릿이 수정되었어요',
        status: 'Primary',
      });
      await fetchDetailData(id);
      // navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '리뷰 템플릿 등록';
    let alertMessage = '작성하신 내용대로 템플릿을 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '메뉴 정보 수정';
      alertMessage = '작성하신 내용대로 템플릿을 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              <FormItem label={'대상 병원'}>
                <Select
                  style={{
                    width: '100%',
                  }}
                  disabled={!!id}
                  showSearch
                  allowClear
                  value={formik.values.partnerId || undefined}
                  options={partnerOptions}
                  onChange={(value) => {
                    updateForm('partnerId', value || null);
                  }}
                  placeholder={'대상 병원을 선택해주세요'}
                />
              </FormItem>

              <FormItem label={'리뷰 타이틀'} optional>
                <HtmlEditor
                  placeholder="리뷰 소개글을 입력해주세요"
                  data={formik.values.introMessage}
                  onChange={(e) => {
                    updateForm('introMessage', e);
                  }}
                />
              </FormItem>
              <FormItem label={'기타 이벤트 정보'} optional>
                <HtmlEditor
                  placeholder="기타 이벤트 정보를 입력해주세요"
                  data={formik.values.eventContents}
                  onChange={(e) => {
                    updateForm('eventContents', e);
                  }}
                />
              </FormItem>
            </FormLayout>
          </Card>
          <Card title="문항등록">
            <ReviewQuestionRepeater
              reviewQuestions={questions}
              initialValues={formik.values.reviewQuestions}
              onChange={(values) => {
                updateForm('reviewQuestions', values);
              }}
            />
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default ReviewTemplateDetailPage;
