import { Button, Typo } from '@components/atoms';
import { allSideNavList, sideNavList1 } from '@routes';
import { SideMenuItem } from '@routes/types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCommonSotre } from 'store/useCommonStore';
import { PAGE_PATHS } from '@routes/constants';
import { isEqual } from 'lodash';
import { breakpoints } from '@styles/mediaQuery';
import { useAuthStore } from '@store/useAuthStore';
import Tab from './components/Tab';
import {
  Gnb,
  MobileGnb,
  MobileNavFooter,
  MobileNavItem,
  MobileNavList,
  MobileNavTitle,
  MobileSubNav,
  NavItem,
  NavList,
  SUB_MENU_DEFAULT_CLOSE_WIDTH,
  SubNav,
  SubNavItem,
  SubNavList,
  TabletBackground,
  ToggleContainer,
} from './styles';
import { SideNavRef } from './types';
import { NavIcon } from './components';

const SideNav = forwardRef<SideNavRef>((_, ref) => {
  // util
  const [loginUser, clearAuthState] = useAuthStore((state) => [
    state.loginUser,
    state.clearAuthState,
  ]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    clearAuthState();
    navigate(PAGE_PATHS.LOGIN);
  }, [clearAuthState, navigate]);

  const filteredSideNavList1 = useMemo(() => sideNavList1, []);

  // store
  const { isSubMenuOpen, setIsSubMenuOpen } = useCommonSotre();

  // props
  const [selectedNavItem, setSelectedNavItem] = useState(
    allSideNavList.find((item) => pathname.includes(item.key)),
  );
  const [selectedSubNavItem, setSelectedSubNavItem] = useState(
    selectedNavItem?.children?.find((child) =>
      isEqual(
        child.key,
        `/${[pathname.split('/')[1], pathname.split('/')[2]].join('/')}`,
      ),
    )?.key,
  );

  const [isSubNavOpen, setIsSubNavOpen] = useState(
    !(window.innerWidth < SUB_MENU_DEFAULT_CLOSE_WIDTH) &&
      (selectedNavItem?.children?.length !== 0 && isSubMenuOpen !== null
        ? isSubMenuOpen
        : selectedNavItem?.children?.length !== 0),
  );
  const [isMobileSubNavOpen, setIsMobileSubNavOpen] = useState(false);
  const [isSubNavClose, setIsSubNavClose] = useState(false);

  // event
  const handleCloseSubNav = useCallback(() => {
    setIsSubNavOpen(false);
    setIsSubNavClose(false);
  }, []);

  const handleToggleSubNav = useCallback(() => {
    setIsSubNavOpen((prev) => {
      setIsSubMenuOpen(!prev);
      return !prev;
    });
  }, [setIsSubMenuOpen]);

  const handleOpenSubNav = useCallback(() => {
    setIsSubNavOpen(!!isSubMenuOpen);
  }, [isSubMenuOpen]);

  const handleSideMenuClick = (item: SideMenuItem) => {
    setIsSubMenuOpen(true);
    setTimeout(() => {
      setIsSubNavOpen(true);
    }, 0);
    const childHrefLink = item.children?.[0]?.key;

    if (childHrefLink) {
      handleOpenSubNav();
      navigate(childHrefLink);
    } else {
      handleCloseSubNav();
      navigate(item.key);
    }
  };
  const handleSubMenuClick = (item: SideMenuItem) => {
    navigate(item.key);

    const windowWidth = window.innerWidth;

    if (windowWidth < breakpoints.pc) {
      setTimeout(() => {
        handleCloseSubNav();
      }, 0);
    }
  };

  // render
  const renderMobileNavItem = (isOpen: boolean, item: SideMenuItem) => (
    <MobileNavTitle $isOpened={isOpen}>
      <Typo as="p" typoType="h8" color="gray9">
        {item.value}
      </Typo>
      {item.children?.length ? <span className="icon" /> : null}
    </MobileNavTitle>
  );

  useImperativeHandle(ref, () => ({
    handleToggleSubNavOpen: () => {
      setIsMobileSubNavOpen((prev) => !prev);
    },
  }));

  useEffect(() => {
    setSelectedNavItem(
      allSideNavList.find((item) => pathname.includes(item.key)),
    );
    setSelectedSubNavItem(
      selectedNavItem?.children?.find((child) =>
        isEqual(
          child.key,
          `/${[pathname.split('/')[1], pathname.split('/')[2]].join('/')}`,
        ),
      )?.key,
    );
  }, [pathname, selectedNavItem?.children]);

  return (
    <>
      {isSubNavOpen ? (
        <TabletBackground
          onClick={() => {
            handleCloseSubNav();
          }}
        />
      ) : null}
      <Gnb>
        <ToggleContainer $isSubNavOpen={isSubNavOpen}>
          <button
            type="button"
            onClick={handleToggleSubNav}
            aria-label="메뉴 열기 / 접기"
            // disabled={!selectedSubNavItem}
          />
        </ToggleContainer>
        {filteredSideNavList1.length > 0 && (
          <NavList>
            {filteredSideNavList1.map((item) => (
              <NavItem
                key={item.key}
                $isSelected={item.key === selectedNavItem?.key}
                $iconName={item.icon || ''}
                onClick={() => {
                  handleSideMenuClick(item);
                }}
              >
                {item.component && item.component}
                <NavIcon
                  name={item.icon}
                  selected={item.key === selectedNavItem?.key}
                />
                {/* <span className="icon" /> */}
                <Typo as="p" typoType="b14" color="gray10">
                  {item.value}
                </Typo>
              </NavItem>
            ))}
          </NavList>
        )}
      </Gnb>
      <SubNav $isSubNavOpen={isSubNavOpen}>
        <Typo as="p" typoType="h8" color="gray13">
          {selectedNavItem?.value}
        </Typo>
        <SubNavList>
          {selectedNavItem &&
            selectedNavItem.children?.map((item) => {
              return (
                <SubNavItem
                  key={item.key}
                  $isSelected={item.key === selectedSubNavItem}
                  onClick={() => {
                    handleSubMenuClick(item);
                  }}
                  icon={item.icon}
                >
                  <Typo as="p" typoType="b10m" color="gray9">
                    {item.value}
                  </Typo>
                  <NavIcon
                    name={item.icon}
                    selected={item.key === selectedNavItem?.key}
                  />
                  {!!item.component && item.component}
                </SubNavItem>
              );
            })}
        </SubNavList>
      </SubNav>
      {isMobileSubNavOpen && (
        <MobileSubNav
          onClick={() => {
            setIsSubNavClose((prev) => !prev);
          }}
          onAnimationEnd={() => {
            if (isSubNavClose) {
              setIsMobileSubNavOpen(false);
              setIsSubNavClose(false);
            }
          }}
        >
          <MobileGnb
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            isClosed={isSubNavClose}
          >
            <header>
              <span className="icon" />
              <Typo as="h1" typoType="b9m" color="gray13">
                {loginUser?.administratorName}
              </Typo>
              <Typo as="p" typoType="b11" color="gray8">
                {loginUser?.administratorEmail}
              </Typo>
            </header>
            <MobileNavList>
              <MobileNavItem>
                {allSideNavList.map((item) => (
                  <Tab
                    key={item.key}
                    defaultOpen={window.location.pathname.includes(item.key)}
                    triggerElement={(isClose) => {
                      return renderMobileNavItem(isClose, item);
                    }}
                    contentElement={
                      <SubNavList>
                        {item.children?.map((child) => (
                          <SubNavItem
                            key={child.key}
                            $isSelected={child.key === selectedSubNavItem}
                            onClick={() => {
                              setIsSubNavClose(true);
                              handleSubMenuClick(child);
                            }}
                          >
                            <Typo as="p" typoType="b10m" color="gray9">
                              {child.value}
                            </Typo>
                          </SubNavItem>
                        ))}
                      </SubNavList>
                    }
                  />
                ))}
              </MobileNavItem>
            </MobileNavList>
            <MobileNavFooter>
              <Button buttonColor="red" buttonSize={28} onClick={handleLogout}>
                로그아웃
              </Button>
            </MobileNavFooter>
          </MobileGnb>
        </MobileSubNav>
      )}
    </>
  );
});

SideNav.displayName = 'SideNav';

export default SideNav;
