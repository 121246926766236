import React, { CSSProperties, useRef, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import colorSet from '@styles/colors';
import { MediaQuery } from '@styles/mediaQuery';
import { ReactComponent as ArrowUp } from '@assets/svg/ArrowUp-fill.svg';
import Typo from '../Typo';
import Check from '../Check';
import typo from '../Typo/types';

interface MultiSelectProps<T> {
  options: { label: string; value: T }[];
  value: T[];
  onChange: (value: T[]) => void;
  placeholder?: string;
  hasAllSelect?: boolean;
  style?: CSSProperties;
}

function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    options = [],
    value,
    onChange,
    placeholder,
    hasAllSelect,
    style,
  } = props;
  const [open, setOpen] = useState(false);

  const handleAddValue = (valueParam: T) => {
    onChange([...value, valueParam]);
  };
  const handleRemoveValue = (valueParam: T) => {
    onChange(value.filter((item) => item !== valueParam));
  };
  const handleOptionClick = (valueParam: T) => {
    if (value.includes(valueParam)) {
      handleRemoveValue(valueParam);
    } else {
      handleAddValue(valueParam);
    }
  };
  const handleAllSelect = () => {
    if (value.length === options?.length) {
      onChange([]);
    } else {
      onChange(options?.map((item) => item.value));
    }
  };

  const [selectIdx, setSelectIdx] = useState(0);
  const selectRef = useRef<HTMLUListElement>(null);

  const renderDropdown = () => {
    return (
      <SelectList ref={selectRef}>
        {hasAllSelect && (
          <AllSelectItem
            aria-disabled={options?.length === 0}
            data-focused={options?.length > 0 && selectIdx === 0}
            onClick={handleAllSelect}
            onMouseOver={() => {
              setSelectIdx(0);
            }}
          >
            <Check
              disabled={options?.length === 0}
              size={20}
              checked={options.length > 0 && value.length === options.length}
            />
            <Typo as="p" typoType="b9">
              전체 선택
            </Typo>
          </AllSelectItem>
        )}
        <BorderLine />
        {options?.map((item, idx) => (
          <SelectItem
            onMouseOver={() => {
              setSelectIdx(idx + 1);
            }}
            onClick={() => {
              handleOptionClick(item.value);
            }}
            data-checked={value.includes(item.value)}
            data-focused={idx + 1 === selectIdx}
          >
            <Check size={20} checked={value.includes(item.value)} />
            <Typo as="p" typoType="b9">
              {item.label}
            </Typo>
          </SelectItem>
        ))}
      </SelectList>
    );
  };

  return (
    <DropdownWrapper style={style}>
      <StyledSelect
        value={
          value?.length
            ? options
                ?.filter((item) => value.includes(item.value))
                .map((item) => item.label)
                .join(', ')
            : null
        }
        dropdownRender={() => {
          return renderDropdown();
        }}
        suffixIcon={<ArrowIcon open={open} />}
        autoClearSearchValue={false}
        onDropdownVisibleChange={setOpen}
        onInputKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (selectIdx === 0) {
              handleAllSelect();
            } else {
              handleOptionClick(options[selectIdx - 1].value);
            }
          }
          if (e.key === 'ArrowDown') {
            const select = selectRef?.current?.children[selectIdx + 2];
            if (
              (selectRef?.current?.scrollTop || 0) +
                (selectRef.current?.clientHeight || 0) <=
              (select?.clientHeight || 0) * (selectIdx + 2.5)
            ) {
              selectRef?.current?.scrollTo(
                0,
                (select?.clientHeight || 0) * (selectIdx + 2) -
                  selectRef.current.clientHeight,
              );
            }

            if (options?.length > selectIdx) {
              setSelectIdx((prev) => prev + 1);
            } else {
              setSelectIdx(0);
              selectRef?.current?.scrollTo(0, 0);
            }
          }
          if (e.key === 'ArrowUp') {
            const select = selectRef?.current?.children[selectIdx];

            if (
              (selectRef?.current?.scrollTop || 0) >=
              (select?.clientHeight || 0) * (selectIdx - 1.5)
            ) {
              selectRef?.current?.scrollTo(
                0,
                (select?.clientHeight || 0) * selectIdx -
                  (select?.clientHeight || 0),
              );
            }
            if (selectIdx > 0) {
              setSelectIdx((prev) => prev - 1);
            } else {
              setSelectIdx(options?.length);
              selectRef?.current?.scrollTo(0, selectRef?.current.scrollHeight);
            }
          }
        }}
        showAction={['click', 'focus']}
        placeholder={placeholder}
        options={options}
      />
    </DropdownWrapper>
    // <Dropdown
    //   onOpenChange={setOpen}
    //   dropdownRender={() => renderDropdown()}
    //   trigger={['click']}
    // >
    //   <MultiSelectContainer isOpen={open}>
    //     {value.length ? (
    //       <Typo as="p" typoType="b9">
    //         {options
    //           .filter((item) => value.includes(item.value))
    //           .map((item) => item.label)
    //           .join(', ')}
    //       </Typo>
    //     ) : (
    //       <Typo as="p" typoType="b9" color="gray7">
    //         {placeholder || '선택'}
    //       </Typo>
    //     )}
    //     <span className="icon" />
    //   </MultiSelectContainer>
    // </Dropdown>
  );
}

export default MultiSelect;

const StyledSelect = styled(Select)`
  min-width: 160px;
  height: 100%;
  // max-width: 200px;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    min-width: 120px;
  }

  ${MediaQuery.FROM_PHONE} {
    max-width: 1fr;
    width: 100%;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  div.ant-select-selector {
    //padding-top: 4px !important;
    //padding-bottom: 4px !important;
    //height: 100% !important;
  }

  span.ant-select-selection-search {
    padding: 8px;
  }

  span.ant-select-selection-placeholder {
    ${typo.b9};
    color: ${colorSet.gray7};
  }
`;

const AllSelectItem = styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;

  > label {
    position: relative;
    top: 1px;
  }

  // &::before {
  //   position: absolute;
  //   bottom: 4px;
  //   content: '';
  //   display: flex;
  //   width: 90%;
  //   height: 1px;
  //   background: ${colorSet.gray10};
  // }

  > .icon {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  &[data-checked='true'] {
    > .icon {
      display: flex;
    }
  }

  &[data-focused='true'] {
    background-color: ${colorSet.primary9};
  }

  &[aria-disabled='true'] {
    cursor: auto;
    background-color: ${colorSet.gray12};

    p {
      color: ${colorSet.gray8};
    }
  }
`;

const SelectList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 254px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar- {
    display: none; /* Chrome, Safari, Opera */
  }

  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 8px;
`;
const SelectItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;

  > .icon {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  // &:hover {
  //   background-color: ${colorSet.gray12};
  // }

  &[data-focused='true'] {
    background-color: ${colorSet.primary9};
  }
`;

const BorderLine = styled.li`
  height: 1px;
  width: 100%;
  background: ${colorSet.gray10};
  display: flex;
  position: relative;
  display: flex;
  padding: 0.5px 0;
  margin: 0 auto;
`;

interface ArrowIconProps {
  open: boolean;
}

const ArrowIcon = styled(ArrowUp)<ArrowIconProps>`
  transition-property: 'height, visibility';
  transition-duration: 0.35s;
  transition-timing-function: ease;
  ${({ open }) =>
    open ? `transform: rotate(0deg);` : `transform: rotate(180deg);`}
`;
