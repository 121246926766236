import { css } from 'styled-components';
import { typo } from '@components/atoms';
import colorSet from './colors';

const antdCustom = css`
  // .custome-select-none-tag-select {
  // }
  // .custome-select-none-tag {
  //   position: relative;
  //   &::after {
  //     content: '';
  //     width: 24px;
  //     height: 24px;
  //     left: 12px;
  //     top: 0;
  //     bottom: 0;
  //     position: absolute;
  //     height: 100%;
  //     background: url('/assets/icons/icon-search.svg') no-repeat center;
  //   }
  //   .ant-select-selection-overflow {
  //     padding-left: 28px;
  //     padding-right: 6px;
  //     padding-top: 6px;
  //     padding-bottom: 6px;
  //   }
  //   .ant-select-selection-placeholder {
  //     padding-left: 28px;
  //     ${typo.b9};
  //     color: ${colorSet.gray7};
  //
  //     &::after {
  //       content: '' !important;
  //     }
  //   }
  //   .ant-select-selection-item {
  //     display: none;
  //   }
  // }
  //
  // .ag-cell {
  //   padding: 12px 16px;
  // }
  // .ant-select {
  //   .ant-select-selector {
  //     ${typo.b9}
  //     padding: 0 16px !important;
  //   }
  //   .ant-select-selection-overflow-item-suffix {
  //     width: auto;
  //   }
  //   .ant-select-selection-placeholder {
  //     inset-inline-start: 16px !important;
  //     ${typo.b9}
  //     color: ${colorSet.gray7};
  //     &::after {
  //       content: '' !important;
  //     }
  //   }
  //
  //   .ant-select-selection-overflow-item {
  //     .ant-select-selection-search {
  //       margin-inline-start: 0px !important;
  //       .ant-select-selection-search-input {
  //         ${typo.b9}
  //       }
  //     }
  //   }
  //
  //   .ant-select-selection-item {
  //     padding-inline-end: 30px !important;
  //
  //     .ant-select-selection-item-remove {
  //       display: flex;
  //       align-items: center;
  //     }
  //     .anticon-close {
  //       width: 16px;
  //       height: 16px;
  //
  //       > svg {
  //         width: 80%;
  //         height: 80%;
  //       }
  //     }
  //   }
  //   .ant-select-selector {
  //     border-radius: 10px;
  //   }
  //   &.ant-select-open {
  //     .ant-select-selection-item {
  //       color: ${colorSet.gray2};
  //     }
  //     > .ant-select-selector {
  //       box-shadow: 0 0 0 2px ${colorSet.primary8} !important;
  //     }
  //     .ant-select-icon {
  //       transform: rotate(180deg);
  //     }
  //   }
  // }
  // .ant-select-dropdown {
  //   box-shadow: none;
  //   padding: 0;
  //   background: #ffffff;
  //   box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  //   border-radius: 8px;
  // }
  // .css-dev-only-do-not-override-11rqhbg.ant-pagination
  //   .ant-pagination-item-active {
  //   background: ${colorSet.primary9};
  //   border-color: ${colorSet.primary3};
  //
  //   > a {
  //     font-weight: 400;
  //   }
  // }
  //
  // .ant-picker .ant-picker-clear {
  //   // right: 3.45%;
  // }
  //
  // .antd-focus-not-hover.ant-table-wrapper tbody > tr:hover > td {
  //   background: inherit !important;
  // }
  //
  // .ant-segmented {
  //   background-color: ${colorSet.gray12};
  //   border-radius: 12px;
  //   .ant-segmented-item {
  //     border-radius: 8px;
  //   }
  // }
  //
  // .ant-table-wrapper .ant-table-thead > tr > th,
  // .ant-table-wrapper .ant-table-thead > tr > td {
  //   background-color: ${colorSet.gray12};
  // }
  //
  // .ant-table-wrapper .ant-table-thead *,
  // .ant-table-wrapper .ant-table-tbody * {
  //   border-color: ${colorSet.gray10};
  // }
  //
  // .ant-table-wrapper .ant-table-tbody > tr:last-child > th,
  // .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  //   border-bottom: 0px;
  // }
  //
  // .ant-select-arrow {
  //   display: none;
  // }
`;

export default antdCustom;
