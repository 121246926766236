import { AppError, Rows, request } from '@networks/index';
import { FetchPartnerCategoryListQuery } from '../@types/partner-categories/params/FetchPartnerCategoryListQuery';
import { PartnerCategory } from '../@types/partner-categories/models/PartnerCategory';

const API_ROOT = '/admin/partner-categories';

export const fetchPartnerCategories = async (
  params: FetchPartnerCategoryListQuery = {},
) => {
  try {
    const url = API_ROOT;
    const { data } = await request<Rows<PartnerCategory>>({
      method: 'get',
      url,
      queryParams: { ...params },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
