export const getGradeText = (grade: number) => {
  switch (grade) {
    case 1:
      return '하';
    case 3:
      return '중';
    case 4:
      return '중상';
    case 5:
      return '상';
    default:
      return '-';
  }
};
