import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import DraggableTableRow from '@components/organisms/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Select from '@components/v2/antd/data-entry/Select';
import { ReviewQuestion } from '../../../../../@types/review/models/ReviewQuestion';
import {
  ReviewTarget,
  ReviewTranslations,
} from '../../../../../@types/review/constants/reviews.constants';

type EditableTableProps = Parameters<typeof Table>[0];
interface ReviewQuestionRepeaterDataType {
  key: number;
  reviewQuestion?: ReviewQuestion;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DEFAULT_ROW = {
  key: 1,
  reviewQuestion: undefined,
};

export interface ReviewQuestionRepeaterProps {
  initialValues?: ReviewQuestion[];
  onChange?: (reviewQuestions: (ReviewQuestion | undefined)[]) => void;
  reviewQuestions?: ReviewQuestion[];
}
const ReviewQuestionRepeater: React.FC<ReviewQuestionRepeaterProps> = (
  props: ReviewQuestionRepeaterProps,
) => {
  const { initialValues = [], onChange, reviewQuestions = [] } = props;
  const [dataSource, setDataSource] = useState<
    ReviewQuestionRepeaterDataType[]
  >([DEFAULT_ROW]);

  useEffect(() => {
    if (initialValues.length === 0) {
      setDataSource([DEFAULT_ROW]);
    } else {
      setDataSource(
        initialValues.map((value, index) => {
          return {
            key: index + 1,
            reviewQuestion: value,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReviewQuestionOptions = useCallback(
    (currentId?: number) => {
      const selectedQuestionIds = dataSource
        .filter(({ reviewQuestion }) => !!reviewQuestion)
        .map(({ reviewQuestion }) => {
          return reviewQuestion?.id;
        });
      return reviewQuestions
        .filter(({ id }) => {
          if (id === currentId) {
            return true;
          }

          return !selectedQuestionIds.includes(id);
        })
        .map((data) => {
          const { id, title } = data;
          return {
            label: title,
            value: id,
            data,
          };
        });
    },
    [reviewQuestions, dataSource],
  );

  const handleDataChange = (
    newDataSource: ReviewQuestionRepeaterDataType[],
  ) => {
    setDataSource(newDataSource);
    if (onChange) {
      onChange(
        newDataSource
          .filter(({ reviewQuestion }) => !!reviewQuestion)
          .map(({ reviewQuestion }) => {
            return reviewQuestion;
          }),
      );
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    handleDataChange(newData);
  };

  const defaultColumns: ColumnTypes[number][] = [
    {
      key: 'sort',
      width: '40px',
    },
    {
      title: '문항선택',
      dataIndex: 'content',
      width: '45%',
      ellipsis: true,
      render: (value, record, index) => {
        const data = record as ReviewQuestionRepeaterDataType;
        const { reviewQuestion } = data;
        let selectValue;
        if (reviewQuestion) {
          selectValue = reviewQuestion.id;
        }

        return (
          <Select
            style={{
              width: '100%',
            }}
            allowClear
            showSearch
            value={selectValue}
            placeholder="리뷰에 등록할 문항을 선택해주세요"
            options={getReviewQuestionOptions(selectValue)}
            onChange={(_, option) => {
              let newValue: ReviewQuestion | undefined;
              if (option) {
                // @ts-ignore
                const { data } = option;
                newValue = data as ReviewQuestion;
              }

              const newDataSource = [...dataSource];
              newDataSource.splice(index, 1, {
                ...newDataSource[index],
                reviewQuestion: newValue as ReviewQuestion,
              });
              handleDataChange(newDataSource);
            }}
          />
        );
      },
    },
    {
      title: '통계용 별칭',
      dataIndex: 'titleAlias',
      width: '200px',
      render: (_, record) => {
        const data = record as ReviewQuestionRepeaterDataType;
        const { reviewQuestion } = data;

        if (reviewQuestion) {
          const { titleAlias } = reviewQuestion;
          return titleAlias || '-';
        }

        return '-';
      },
    },
    {
      title: '답변유형',
      dataIndex: 'responseType',
      width: '100px',
      render: (_, record) => {
        const data = record as ReviewQuestionRepeaterDataType;
        const { reviewQuestion } = data;

        if (reviewQuestion) {
          const { responseType } = reviewQuestion;
          return ReviewTranslations[responseType] || '-';
        }

        return '-';
      },
    },
    {
      title: '집계대상',
      dataIndex: 'targetBy',
      width: '100px',
      render: (_, record) => {
        const data = record as ReviewQuestionRepeaterDataType;
        const { reviewQuestion } = data;

        if (reviewQuestion) {
          const { targetBy } = reviewQuestion;
          return ReviewTranslations[targetBy] || '';
        }

        return '-';
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '80px',
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                handleDelete(record.key);
              }}
              disabled={dataSource.length === 1}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    const newRow: ReviewQuestionRepeaterDataType = {
      key: dataSource.length + 1,
      reviewQuestion: undefined,
    };
    const newData = [...dataSource, newRow];
    handleDataChange(newData);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.key === active.id);
      const overIndex = dataSource.findIndex((i) => i.key === over?.id);
      const newData = arrayMove(dataSource, activeIndex, overIndex);
      handleDataChange(newData);
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // @ts-ignore
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={defaultColumns}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>

      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button
          onClick={handleAdd}
          type="primary"
          style={{ marginTop: 16, marginRight: 8 }}
        >
          추가하기
        </Button>
      </div>
    </div>
  );
};

export default ReviewQuestionRepeater;
