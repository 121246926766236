import React from 'react';
import Lottie from 'react-lottie';
import LoadingLottie from '@assets/lottie/lottie-loading.json';
import { LoadingTableOverlayContainer } from './styles';

function LoadingTableOverlay() {
  return (
    <LoadingTableOverlayContainer>
      <Lottie
        options={{
          loop: true,
          animationData: LoadingLottie,
        }}
        width={60}
        height={60}
      />
    </LoadingTableOverlayContainer>
  );
}

export default LoadingTableOverlay;
