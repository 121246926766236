import { AppError, request, Row, Rows } from '@networks/index';
import { FindLLMIntentResponseTemplateListQuery } from '../@types/ai-consultation-prompt/params/FindLLMIntentResponseTemplateListQuery';
import { LLMIntentResponseTemplate } from '../@types/ai-consultation-prompt/models/LLMIntentResponseTemplate';

const URLS = {
  PROMPT_RESPONSES: '/admin/consultation/prompt/responses',
  PROMPT_RESPONSE: '/admin/consultation/prompt/response',
};

export const fetchAiConsultationResponses = async (
  query: FindLLMIntentResponseTemplateListQuery,
) => {
  try {
    const { data } = await request<Rows<LLMIntentResponseTemplate>>({
      method: 'get',
      url: URLS.PROMPT_RESPONSES,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchAiConsultationResponseDetail = async (
  id: number | string,
) => {
  const url = `${URLS.PROMPT_RESPONSES}/${id}`;
  try {
    const { data } = await request<Row<LLMIntentResponseTemplate>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const createAiConsultationResponse = async (params: {
  intent: LLMIntentResponseTemplate['intent'];
  language: LLMIntentResponseTemplate['language'];
  message: LLMIntentResponseTemplate['message'];
  partnerId: number;
}) => {
  const url = URLS.PROMPT_RESPONSES;
  try {
    const { data } = await request<Row<LLMIntentResponseTemplate>>({
      method: 'post',
      url,
      requestBody: params,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateAiConsultationResponse = async (params: {
  id: number | string;
  partnerId: number;
  update?: Partial<
    Pick<LLMIntentResponseTemplate, 'intent' | 'language' | 'message'>
  >;
}) => {
  const { id, partnerId, update } = params;
  const url = `${URLS.PROMPT_RESPONSES}/${id}`;
  try {
    const { data } = await request<Row<LLMIntentResponseTemplate>>({
      method: 'put',
      url,
      requestBody: { partnerId, ...update },
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const deleteAiConsultationResponse = async (id: string | number) => {
  const url = `${URLS.PROMPT_RESPONSES}/${id}`;
  try {
    const { data } = await request<void>({
      method: 'delete',
      url,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchLLMIntents = async () => {
  const url = `${URLS.PROMPT_RESPONSE}/intent`;
  try {
    const { data } = await request<Rows<{ role: string; roleName: string }>>({
      method: 'get',
      url,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
