import { AppError, request, Row, Rows } from '@networks/index';
import { CreateMenuBody } from '../@types/menu/params/CreateMenuBody';
import { Menu } from '../@types/menu/models/Menu';
import { FetchMenuListQuery } from '../@types/menu/params/FetchMenuListQuery';
import { UpdateMenuBody } from '../@types/menu/params/UpdateMenuBody';
import { MenuGroup } from '../@types/menu/models/MenuGroup';

const API_ROOT = 'admin/homepage-menus';

export const createMenu = async (body: CreateMenuBody) => {
  try {
    const { data } = await request<Row<Menu>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchMenus = async (query: FetchMenuListQuery) => {
  try {
    const { data } = await request<Rows<Menu>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchMenuGroups = async () => {
  try {
    const { data } = await request<Rows<MenuGroup>>({
      method: 'get',
      url: '/admin/homepage-menu-groups',
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
export const fetchMenuDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Menu>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateMenu = async (id: number | string, body: UpdateMenuBody) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Menu>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
