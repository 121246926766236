export enum BlogPromptKeys {
  Title = 'title',
  Subject = 'subject',
  SubTitle = 'subTitle',
  Headline = 'headline',
  Description = 'description',
  Description1 = 'description1',
  Description2 = 'description2',
  TreatmentName = 'treatmentName',
  Point1 = 'point1',
  Point2 = 'point2',
  Point3 = 'point3',
  Point4 = 'point4',
  Point5 = 'point5',
}

export enum EventMenuBookTemplateKey {
  ProductName1 = 'productName1',
  ProductName2 = 'productName2',
  ProductName3 = 'productName3',
  ProductName4 = 'productName4',
  ProductName5 = 'productName5',
  ProductName6 = 'productName6',
  ProductDescription1 = 'productDescription1',
  ProductDescription2 = 'productDescription2',
  ProductDescription3 = 'productDescription3',
  ProductDescription4 = 'productDescription4',
  ProductDescription5 = 'productDescription5',
  ProductDescription6 = 'productDescription6',
  ProductPrice1 = 'productPrice1',
  ProductPrice2 = 'productPrice2',
  ProductPrice3 = 'productPrice3',
  ProductPrice4 = 'productPrice4',
  ProductPrice5 = 'productPrice5',
  ProductPrice6 = 'productPrice6',
  ProductOriginPrice1 = 'productOriginPrice1',
  ProductOriginPrice2 = 'productOriginPrice2',
  ProductOriginPrice3 = 'productOriginPrice3',
  ProductOriginPrice4 = 'productOriginPrice4',
  ProductOriginPrice5 = 'productOriginPrice5',
  ProductOriginPrice6 = 'productOriginPrice6',
  ProductTitle = 'productTitle',
  ProductSubTitle = 'productSubTitle',
}

export enum ThemeElementValue {
  MainColor1 = 'mainColor1',
  MainColor2 = 'mainColor2',
  MainColor1GradientToBottom = 'mainColor1GradientToBottom',
  MainColor2GradientToBottom = 'mainColor2GradientToBottom',
  MainColor1GradientToTop = 'mainColor1GradientToTop',
  MainColor2GradientToTop = 'mainColor2GradientToTop',
  MainColor1GradientToRight = 'mainColor1GradientToRight',
  MainColor2GradientToRight = 'mainColor2GradientToRight',
  MainColor1GradientToLeft = 'mainColor1GradientToLeft',
  MainColor2GradientToLeft = 'mainColor2GradientToLeft',
  MainColor1TextWithBg = 'mainColor1TextWithBg',
  MainColor2TextWithBg = 'mainColor2TextWithBg',
}

export enum ImageCategory {
  Background = '배경',
  Logo = '병원로고',
  Model = '인물',
  Equipment = '장비',
  OrthopedicsCommon = '정형외과/의료공통', // 랜덤 선택
  OrthopedicsDisease = '정형외과/질환', // OMA Admin에서 Pick
}

export enum FontCategory {
  Brand = 'brand',
  Basic = 'basic',
}

export const BLOG_ELEMENT_OPTIONS = [
  {
    label: '시술명',
    value: BlogPromptKeys.TreatmentName,
  },
  {
    label: '타이틀',
    value: BlogPromptKeys.Title,
  },
  {
    label: '서브타이틀',
    value: BlogPromptKeys.SubTitle,
  },
  {
    label: '상세설명',
    value: BlogPromptKeys.Description,
  },
  {
    label: '상세설명1',
    value: BlogPromptKeys.Description1,
  },
  {
    label: '상세설명2',
    value: BlogPromptKeys.Description2,
  },
  {
    label: '포인트1',
    value: BlogPromptKeys.Point1,
  },
  {
    label: '포인트2',
    value: BlogPromptKeys.Point2,
  },
  {
    label: '포인트3',
    value: BlogPromptKeys.Point3,
  },
  {
    label: '포인트4',
    value: BlogPromptKeys.Point4,
  },
  {
    label: '포인트5',
    value: BlogPromptKeys.Point5,
  },
];

export const EVENT_MENUBOOK_TEMPLATE_KEY_OPTIONS = [
  {
    label: '기본정보',
    value: '기본정보',
    children: [
      {
        label: '상품명',
        value: EventMenuBookTemplateKey.ProductTitle,
      },
      {
        label: '상품설명',
        value: EventMenuBookTemplateKey.ProductSubTitle,
      },
    ],
  },
  {
    label: '시술명',
    value: '시술명',
    children: [
      {
        label: '시술명-1',
        value: EventMenuBookTemplateKey.ProductName1,
      },
      {
        label: '시술명-2',
        value: EventMenuBookTemplateKey.ProductName2,
      },
      {
        label: '시술명-3',
        value: EventMenuBookTemplateKey.ProductName3,
      },
      {
        label: '시술명-4',
        value: EventMenuBookTemplateKey.ProductName4,
      },
      {
        label: '시술명-5',
        value: EventMenuBookTemplateKey.ProductName5,
      },
      {
        label: '시술명-6',
        value: EventMenuBookTemplateKey.ProductName6,
      },
    ],
  },
  {
    label: '시술설명',
    value: '시술설명',
    children: [
      {
        label: '시술설명-1',
        value: EventMenuBookTemplateKey.ProductDescription1,
      },
      {
        label: '시술설명-2',
        value: EventMenuBookTemplateKey.ProductDescription2,
      },
      {
        label: '시술설명-3',
        value: EventMenuBookTemplateKey.ProductDescription3,
      },
      {
        label: '시술설명-4',
        value: EventMenuBookTemplateKey.ProductDescription4,
      },
      {
        label: '시술설명-5',
        value: EventMenuBookTemplateKey.ProductDescription5,
      },
      {
        label: '시술설명-6',
        value: EventMenuBookTemplateKey.ProductDescription6,
      },
    ],
  },
  {
    label: '가격(할인전)',
    value: '가격(할인전)',
    children: [
      {
        label: '가격(할인전)-1',
        value: EventMenuBookTemplateKey.ProductOriginPrice1,
      },
      {
        label: '가격(할인전)-2',
        value: EventMenuBookTemplateKey.ProductOriginPrice2,
      },
      {
        label: '가격(할인전)-3',
        value: EventMenuBookTemplateKey.ProductOriginPrice3,
      },
      {
        label: '가격(할인전)-4',
        value: EventMenuBookTemplateKey.ProductOriginPrice4,
      },
      {
        label: '가격(할인전)-5',
        value: EventMenuBookTemplateKey.ProductOriginPrice5,
      },
      {
        label: '가격(할인전)-6',
        value: EventMenuBookTemplateKey.ProductOriginPrice6,
      },
    ],
  },
  {
    label: '가격',
    value: '가격',
    children: [
      {
        label: '가격-1',
        value: EventMenuBookTemplateKey.ProductPrice1,
      },
      {
        label: '가격-2',
        value: EventMenuBookTemplateKey.ProductPrice2,
      },
      {
        label: '가격-3',
        value: EventMenuBookTemplateKey.ProductPrice3,
      },
      {
        label: '가격-4',
        value: EventMenuBookTemplateKey.ProductPrice4,
      },
      {
        label: '가격-5',
        value: EventMenuBookTemplateKey.ProductPrice5,
      },
      {
        label: '가격-6',
        value: EventMenuBookTemplateKey.ProductPrice6,
      },
    ],
  },
];

export const THUMBNAIL_ELEMENT_OPTIONS = [
  {
    label: '타이틀',
    value: BlogPromptKeys.Title,
  },
  {
    label: '서브타이틀',
    value: BlogPromptKeys.SubTitle,
  },
];

export const THEME_ELEMENT_OPTIONS = [
  {
    label: 'Primary Light',
    value: ThemeElementValue.MainColor1,
  },
  {
    label: 'Primary Dark',
    value: ThemeElementValue.MainColor2,
  },
  {
    label: 'Primary Light Gradient(Top)',
    value: ThemeElementValue.MainColor1GradientToTop,
  },
  {
    label: 'Primary Dark Gradient(Top)',
    value: ThemeElementValue.MainColor2GradientToTop,
  },
  {
    label: 'Primary Light Gradient(Bottom)',
    value: ThemeElementValue.MainColor1GradientToBottom,
  },
  {
    label: 'Primary Dark Gradient(Bottom)',
    value: ThemeElementValue.MainColor2GradientToBottom,
  },
  {
    label: 'Primary Light Gradient(Left)',
    value: ThemeElementValue.MainColor1GradientToLeft,
  },
  {
    label: 'Primary Dark Gradient(Left)',
    value: ThemeElementValue.MainColor2GradientToLeft,
  },
  {
    label: 'Primary Light Gradient(Right)',
    value: ThemeElementValue.MainColor1GradientToRight,
  },
  {
    label: 'Primary Dark Gradient(Right)',
    value: ThemeElementValue.MainColor2GradientToRight,
  },
  {
    label: 'Primary Light (텍스트 + 배경)',
    value: ThemeElementValue.MainColor1TextWithBg,
  },
  {
    label: 'Primary Dark (텍스트 + 배경)',
    value: ThemeElementValue.MainColor2TextWithBg,
  },
];

export const IMAGE_CATEGORY_OPTIONS = Object.values(ImageCategory).map(
  (value) => {
    return {
      label: value,
      value,
    };
  },
);

export const FONT_CATEGORY_OPTIONS = [
  {
    label: '브랜드 폰트',
    value: FontCategory.Brand,
  },
  {
    label: '본문 폰트',
    value: FontCategory.Basic,
  },
];
