import { AppError, Row, Rows, request } from '@networks/index';
import { MarketingChannel, VirtualCallDto } from '@types';

const URL = {
  VIRTUAL_CALLS: '/admin/virtual-calls',
  EDIT_VIRTUAL_CALL: '/admin/virtual-calls/{id}',
  DELETE_VIRTUAL_CALL: '/admin/virtual-calls/{id}',
};

export const requestVirtualCalls = async (params: {
  partnerId: number;
  page?: number;
  pageSize?: number;
}) => {
  const { partnerId, page = 1, pageSize = 1000 } = params;
  try {
    const response = await request<Rows<VirtualCallDto>>({
      method: 'get',
      url: URL.VIRTUAL_CALLS,
      queryParams: {
        partnerId,
        page,
        pageSize,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const requestCreateVirtualCall = async (params: {
  partnerId: number;
  marketingChannel: MarketingChannel;
  receivingNumber: string;
  virtualCallName?: string;
  virtualCallNumber?: string;
}) => {
  const {
    partnerId,
    marketingChannel,
    receivingNumber,
    virtualCallName,
    virtualCallNumber,
  } = params;
  try {
    const response = await request<Row<VirtualCallDto>>({
      method: 'post',
      url: URL.VIRTUAL_CALLS,
      requestBody: {
        partnerId,
        marketingChannel,
        receivingNumber,
        virtualCallName,
        virtualCallNumber,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const requestEditVirtualCall = async (params: {
  id: number;
  marketingChannel?: MarketingChannel;
  receivingNumber?: string;
  virtualCallName?: string;
}) => {
  const { id, marketingChannel, receivingNumber, virtualCallName } = params;
  try {
    const response = await request<void>({
      method: 'put',
      url: URL.EDIT_VIRTUAL_CALL.replace('{id}', String(id)),
      requestBody: {
        marketingChannel,
        receivingNumber,
        virtualCallName,
      },
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const requestDeleteVirtualCall = async (params: { id: number }) => {
  const { id } = params;
  try {
    const response = await request<void>({
      method: 'delete',
      url: URL.DELETE_VIRTUAL_CALL.replace('{id}', String(id)),
    });

    return response?.data;
  } catch (err) {
    throw new AppError(err);
  }
};
