import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { keyframes, styled } from 'styled-components';
import colorSet from '@styles/colors';
import { zIndex } from '@styles/zIndex';
import { MediaQuery } from '@styles/mediaQuery';
import { ModalSizeType } from './types';
import typo from '../Typo/types';
import Button from '../Button';
import { ButtonColorType, ButtonStyleType } from '../Button/types';

interface ModalProps {
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmColor?: ButtonColorType;
  confirmStyle?: ButtonStyleType;
  onConfirm?: () => void;
  cancelColor?: ButtonColorType;
  cancelStyle?: ButtonStyleType;
  onCancel?: () => void;
  disabled?: boolean;
  onClose: () => void;
  /**
   * @param size 480 | 560 | 680 | 860
   */
  size?: ModalSizeType;
  zIndexCustom?: number;
  footerLeft?: ReactNode;
  children?: ReactNode;
  headerRight?: ReactNode;
  modalStyle?: CSSProperties;
  notUsedConfirm?: boolean;
  hideFooter?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Modal({
  title,
  confirmLabel,
  cancelLabel,
  confirmColor,
  confirmStyle,
  onConfirm,
  cancelColor,
  cancelStyle,
  onCancel,
  disabled,
  onClose,
  size = 480,
  zIndexCustom,
  footerLeft,
  children,
  headerRight,
  modalStyle,
  notUsedConfirm,
  hideFooter = false,
}: ModalProps) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      width: 100%;
      -ms-overflow-style: none; /* IE, Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
      }
      `;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <ModalOverlay zIndexCustom={zIndexCustom}>
      <ModalArticle size={size}>
        <ModalTitleView>
          <ModalTitle>{title ?? '타이틀'}</ModalTitle>
          <HeaderRightView>
            {headerRight && headerRight}
            <CloseIcon onClick={onClose} />
          </HeaderRightView>
        </ModalTitleView>
        <ModalContent
          size={size}
          style={modalStyle}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </ModalContent>
        {!hideFooter && (
          <ModalFooter>
            {footerLeft ?? <FooterLeftView>{footerLeft}</FooterLeftView>}
            <ModalButtonView>
              {cancelLabel && (
                <>
                  <LeftButton
                    onClick={onCancel || onClose}
                    buttonSize={48}
                    buttonColor={cancelColor || 'gray'}
                    buttonStyle={cancelStyle || 'solid'}
                  >
                    {cancelLabel}
                  </LeftButton>
                  <MobileLeftButton
                    onClick={onCancel || onClose}
                    buttonSize={40}
                    buttonColor={cancelColor || 'gray'}
                    buttonStyle={cancelStyle || 'solid'}
                  >
                    {cancelLabel}
                  </MobileLeftButton>
                </>
              )}
              {!notUsedConfirm && (
                <>
                  <RightButton
                    onClick={onConfirm || onClose}
                    disabled={disabled}
                    buttonSize={48}
                    buttonColor={confirmColor || 'primary'}
                    buttonStyle={confirmStyle || 'solid'}
                  >
                    {confirmLabel ?? 'button'}
                  </RightButton>
                  <MobileRightButton
                    onClick={onConfirm || onClose}
                    disabled={disabled}
                    buttonSize={40}
                    buttonColor={confirmColor || 'primary'}
                    buttonStyle={confirmStyle || 'solid'}
                  >
                    {confirmLabel ?? 'button'}
                  </MobileRightButton>
                </>
              )}
            </ModalButtonView>
          </ModalFooter>
        )}
      </ModalArticle>
    </ModalOverlay>
  );
}

export default Modal;

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -30%) scale(0.2);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

`;

const ModalOverlay = styled.div<{ zIndexCustom?: number }>`
  position: fixed;
  background: rgba(0, 0, 0, 0.45);
  inset: 0;
  z-index: ${({ zIndexCustom }) => zIndexCustom || zIndex.MODAL};
  animation: ${overlayShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1);
`;

const ModalArticle = styled.article<{ size?: ModalSizeType }>`
  position: relative;
  width: calc(100% - 40px);
  max-width: ${({ size }) => (size ? `${size}px` : '100%')};
  max-height: min(100vh - 120px);
  overflow-x: auto;
  height: auto;
  background: ${colorSet.gray13};
  box-shadow: 0px 4px 24px rgba(94, 101, 110, 0.2);
  border-radius: 16px;
  /* margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${contentShow};

  overflow-y: auto;
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

const ModalTitleView = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${colorSet.gray10};
  background-color: ${colorSet.gray13};
  overflow: hidden;
`;

const HeaderRightView = styled.div`
  display: flex;
  flex-direction: wrap;
  align-items: center;
  gap: 12px;
`;

const ModalTitle = styled.h4`
  ${typo.h2};
  color: ${colorSet.gray2};
`;

const CloseIcon = styled.figure`
  width: 32px;
  height: 24px;
  background: url('/assets/icons/icon-close_gray2.svg') no-repeat center center /
    contain;
  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div<{ size?: ModalSizeType }>`
  padding: 24px;
  max-height: calc(100vh - 280px);
  overflow: auto;
`;

const ModalFooter = styled.footer`
  background-color: ${colorSet.gray13};
  padding: 16px 24px;
  border-top: 1px solid ${colorSet.gray10};
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: 72px;
`;

const FooterLeftView = styled.div`
  margin-left: 0;
  margin-right: auto;
`;

const ModalButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
`;

const LeftButton = styled(Button)`
  margin-right: 8px;
  display: block;
  ${MediaQuery.FROM_PHONE} {
    display: none;
  }
`;

const MobileLeftButton = styled(Button)`
  margin-right: 8px;
  display: none;
  ${MediaQuery.FROM_PHONE} {
    display: block;
  }
`;

const RightButton = styled(Button)`
  display: block;
  ${MediaQuery.FROM_PHONE} {
    display: none;
  }
`;

const MobileRightButton = styled(Button)`
  display: none;
  ${MediaQuery.FROM_PHONE} {
    display: block;
  }
`;
