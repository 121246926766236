import React, { useCallback, useEffect, useState } from 'react';
import { fetchGlobalCatalogs, syncGlobalCatalog } from '@apis/global-catalogs';
import { fetchPartners } from '@apis/partners';
import Select from '@components/v2/antd/data-entry/Select';
import { showSuccessToast } from '@networks/utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { Modal } from '@components/atoms';
import { styled } from 'styled-components';
import { FormItem } from '@components/molecules';

const formValidationSchema = yup.object({
  partnerId: yup.number().required('의원을 선택해주세요.'),
  globalCatalogIds: yup
    .array()
    .required('최소 1개 이상 시술을 선택해주세요.')
    .min(1, '최소 1개 이상 시술을 선택해주세요.'),
});

export interface AddPartnerCatalogModalProps {
  onClose?: () => void;
  onAddCatalog?: () => void;
}

function AddPartnerCatalogModal(props: AddPartnerCatalogModalProps) {
  const { onClose, onAddCatalog } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [partnerList, setPartnerList] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);
  const [globalCatalogList, setGlobalCatalogList] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const fetchPartnerList = useCallback(async () => {
    try {
      const { rows } = await fetchPartners({ page: 1, pageSize: 9999999 });
      setPartnerList(
        rows.map(({ displayName, id, partnerName }) => ({
          label: displayName || partnerName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchGlobalCatalogList = useCallback(async () => {
    try {
      const { rows } = await fetchGlobalCatalogs({
        page: 1,
        pageSize: 9999999,
      });
      setGlobalCatalogList(
        rows.map(({ globalCatalogName, id }) => ({
          label: globalCatalogName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetchData() {
      setDataFetching(true);
      await fetchPartnerList();
      await fetchGlobalCatalogList();
      setDataFetching(false);
    })();
  }, [fetchPartnerList, fetchGlobalCatalogList]);

  const { mutate, isLoading } = useMutation({
    mutationFn: syncGlobalCatalog,
    onSuccess: async () => {
      showSuccessToast('동기화에 성공했습니다.');
      onAddCatalog?.();
      onClose?.();
    },
  });

  const formik = useFormik<{
    partnerId: number | undefined;
    globalCatalogIds: number[];
  }>({
    initialValues: {
      partnerId: undefined,
      globalCatalogIds: [],
    },
    onSubmit: (values) => {
      const { partnerId, globalCatalogIds } = values;
      if (partnerId && globalCatalogIds.length) {
        mutate({
          partnerId,
          globalCatalogIds,
        });
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  return (
    <Modal
      size={560}
      confirmLabel="동기화"
      cancelLabel="취소"
      disabled={!formik.isValid || isLoading}
      title="시술 정보 동기화"
      onConfirm={() => {
        formik.handleSubmit();
      }}
      onClose={() => {
        onClose?.();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          <FormItem label={'의원'}>
            <Select
              style={{ width: '100%' }}
              autoFocus
              allowClear
              options={partnerList}
              onChange={(value) => {
                formik.setFieldValue('partnerId', value);
              }}
              placeholder={'의원을 선택해주세요'}
            />
          </FormItem>
          <FormItem label={'시술'}>
            <Select
              mode={'multiple'}
              style={{ width: '100%' }}
              autoFocus
              allowClear
              options={globalCatalogList}
              onChange={(value) => {
                formik.setFieldValue('globalCatalogIds', value);
              }}
              placeholder={'시술을 선택해주세요'}
            />
          </FormItem>
        </FormLayout>
      </form>
    </Modal>
  );
}

export default AddPartnerCatalogModal;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
