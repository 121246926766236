import React from 'react';
import { Input as AntInput } from 'antd';
import { SearchProps } from 'antd/es/input/Search';

const { Search } = AntInput;

interface Props extends SearchProps {}

const InputSearch: React.FC<Props> = (props: Props) => {
  const { style, ...rest } = props;

  return (
    <Search
      style={{
        ...style,
      }}
      {...rest}
    />
  );
};

InputSearch.defaultProps = {};
export default InputSearch;
