export enum AIModelImageInspectionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export const AI_DESIGN_TRANSLATIONS: Record<string, string> = {
  // 모델 이미지 검수 상태
  [AIModelImageInspectionStatus.Pending]: '검수대기',
  [AIModelImageInspectionStatus.Approved]: '사용가능',
  [AIModelImageInspectionStatus.Rejected]: '반려',
};
