import React from 'react';
import { css, styled } from 'styled-components';
import colorSet from '@styles/colors';

interface ToggleProps {
  isActive?: boolean;
  size?: 'default' | 'small';
  onChange: () => void;
}

function Toggle({ isActive, onChange, size = 'default' }: ToggleProps) {
  return (
    <div style={{ overflow: 'hidden', padding: 2 }}>
      <ToggleBtn onClick={onChange} toggle={isActive} size={size}>
        <Circle toggle={isActive} size={size} />
      </ToggleBtn>
    </div>
  );
}

export default Toggle;

const ToggleBtn = styled.button<{
  toggle?: boolean;
  size: 'default' | 'small';
}>`
  width: 50px;
  height: 30px;
  border-radius: 30px;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: ${({ toggle }) =>
    toggle ? colorSet.primary3 : colorSet.gray9};

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 40px;
      height: 24px;
    `}
`;
const Circle = styled.div<{ toggle?: boolean; size: 'default' | 'small' }>`
  background-color: ${colorSet.gray13};
  width: 26px;
  height: 26px;
  border-radius: 50px;
  position: absolute;
  left: 5%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
  ${({ toggle }) =>
    toggle &&
    css`
      transform: translate(18px, 0);
      transition: all 0.2s ease-in-out;
    `}

  ${({ size, toggle }) =>
    size === 'small' &&
    css`
      width: 20.8px;
      height: 20.8px;

      ${toggle &&
      css`
        transform: translate(15px, 0);
        transition: all 0.2s ease-in-out;
      `}
    `}
`;
