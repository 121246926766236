import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { fetchReviewTemplates } from '../../../../@apis/reviews';
import { FetchReviewTemplateListQuery } from '../../../../@types/review/params/FetchReviewTemplateListQuery';
import { ReviewTemplate } from '../../../../@types/review/models/ReviewTemplate';

function ReviewTemplateListPage() {
  const navigate = useNavigate();

  const COLS: ColDef[] = [
    {
      field: 'partnerName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '대상 병원명',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchReviewTemplateListQuery) => {
      const { rows, count } = await fetchReviewTemplates(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: ReviewTemplate) => {
    const { id } = data;
    navigate(PAGE_PATHS.REVIEW_CONFIG_TEMPLATES_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.REVIEW_CONFIG_TEMPLATE_ADD}>
          <Button>템플릿 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      onRowClick={handleTableRowClick}
    />
  );
}

export default ReviewTemplateListPage;
