import React from 'react';
import { Typo, typo } from '@components/atoms';
import colorSet from '@styles/colors';
import { MediaQuery } from '@styles/mediaQuery';
import { zIndex } from '@styles/zIndex';
import { styled } from 'styled-components';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';
import Card from '../Card';

interface SkeletonProps {
  headerRight?: React.ReactNode;
  isToggle?: boolean;
}

function Skeleton({ headerRight, isToggle = true }: SkeletonProps) {
  return (
    <LayoutWarpper>
      <TitleWarpper id="haeder" className="flex flex-column">
        <TitleLeft style={{ gap: '6px' }}>
          <TopDepth>
            <SkeletonItem width={50} style={{ height: '20px' }} />
          </TopDepth>
          <ArrowIcon />
          <TopDepth>
            <SkeletonItem width={50} style={{ height: '20px' }} />
          </TopDepth>
        </TitleLeft>
        <TitleHeader>
          <TitleLeft style={{ paddingTop: '4px' }}>
            {/* 3 Depth 있을 시 */}
            <BottomDepth>
              <SkeletonItem width={180} style={{ height: '40px' }} />
            </BottomDepth>
          </TitleLeft>
          <TitleRight>{headerRight}</TitleRight>
        </TitleHeader>
      </TitleWarpper>
      <ContentWarpper>
        <Card
          title={<SkeletonItem width={160} style={{ height: '34px' }} />}
          isToggle={isToggle}
        >
          <Content>
            <SkeletonItem width={520} />
            <SkeletonItem width={520} />
            <SkeletonItem width={520} style={{ height: '300px' }} />
          </Content>
        </Card>
      </ContentWarpper>
    </LayoutWarpper>
  );
}

export default Skeleton;

const LayoutWarpper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TitleWarpper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 20px;
  background: ${colorSet.gray12};
  z-index: ${zIndex.HEADER};
`;

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  //   margin-top: 4px;
`;

const TitleLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  white-space: nowrap;
`;

const ContentWarpper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 60px;
`;

const TopDepth = styled(Typo)`
  color: ${colorSet.gray7};
  ${typo.b10m}
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    ${typo.b11m}
  }
`;

const BottomDepth = styled(Typo)`
  color: ${colorSet.gray2};
  ${typo.d4}
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    ${typo.h2}
  }
`;

const ArrowIcon = styled(ArrowRight)`
  width: 20px;
  height: 20px;
  ${MediaQuery.FROM_TABLET_TO_PHONE} {
    width: 16px;
    height: 16px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface SkeletonItemProps {
  width?: number;
}

const SkeletonItem = styled.div<SkeletonItemProps>`
  width: 100%;
  height: 30px;
  background-color: #d7dee4;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  width: ${({ width }) => (width ? `${width}px` : `100%`)};

  @keyframes skeleton-gradient {
    0% {
      transform: translateX(-${({ width }) => (width ? `${width}px` : `0px`)});
    }
    50%,
    100% {
      transform: translateX(${({ width }) => (width ? `${width}px` : `0px`)});
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #d7dee4, #f4f4f4 45.83%, #d7dee4);
    animation: skeleton-gradient 3s infinite ease-in-out;
  }
`;
