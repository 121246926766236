import React, { HTMLAttributes, ReactNode } from 'react';
import { styled } from 'styled-components';
import { LabelColorType, LabelSizeType, LabelStyleType } from './types';
import { getLabelColor, getLabelStyle, getTypoSize } from './utils';

interface LabelProps extends HTMLAttributes<HTMLButtonElement> {
  colorType?: LabelColorType;
  styleType?: LabelStyleType;
  sizeType?: LabelSizeType;
  children?: ReactNode;
  // cursor?: boolean;
}

function Label({
  colorType = 'black',
  styleType = 'fill',
  /**
   * @description 20 | 24
   */
  sizeType = 20,

  // cursor = true,
  children,
  ...rest
}: LabelProps) {
  return (
    <LabelView
      {...rest}
      $labelSize={sizeType}
      $labelColor={colorType}
      $labelStyle={styleType}
      // cursor={cursor}
    >
      {children}
    </LabelView>
  );
}

export default Label;

const LabelView = styled.button<{
  $labelColor: LabelColorType;
  $labelStyle?: LabelStyleType;
  $labelSize: LabelSizeType;
  // cursor?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
  max-width: fit-content;
  outline: none;
  &:disabled {
    cursor: not-allowed;
  }
  ${({ $labelSize }) => getTypoSize($labelSize)};
  ${({ $labelColor, $labelStyle }) =>
    getLabelColor($labelColor, $labelStyle === 'light')}
  ${({ $labelSize }) => getLabelStyle($labelSize)}
  svg>path {
    ${({ $labelColor, $labelStyle }) =>
      getLabelColor($labelColor, $labelStyle === 'light')}
  }
`;

/* cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')}; */
